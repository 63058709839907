import { useContext, useEffect, useState } from "react"
import { agovContext, formatCommentsDate, blobContainerName, blobStorageAccountName } from "../../Constant";
import { addComments, approveDocument, createTask, downloadPolicyTemplate, getAssignedTask, getMyRequestTask, getTaskAssignee, getTaskDependency, getTaskDynamicData, getTaskSource, getTaskType, getViewTask, updateTask } from "../../Services/Task/TaskService";
import { Loader } from "../Loader/Loader";
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import TaskAlertpopup from "./TaskAlertPopup";
import SubtaskForm from "./SubtaskForm";
import SuccessToastMessage from "./SuccessToastMessage";
import FileSaver from "file-saver";
import { generateSasToken } from "../../Services/GRC/GrcService";
import { BlockBlobClient, StorageSharedKeyCredential } from "@azure/storage-blob";
import moment from "moment";
import { v4 as uuid } from "uuid";

export default function TaskForm(props) {

    /**
     * PC_TF_07
     * Create required variables
     */
    let { employeeId, employeeName, roles, userId, profileUrl } = useContext(agovContext);
    const validateTaskObj = {
        categoryId: false,
        sourceId: false,
        title: false,
        description: false,
        startDate: false,
        endDate: false,
        taskTypeId: false,
        priorityId: false,
        assigneeType: false,
        assignee: false,
        recurrenceType: false
    };

    const taskDataConfig = {
        categoryId: '',
        sourceId: '',
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        priorityId: '',
        priority: '',
        assigneeType: '',
        assignee: [],
        taskTypeId: '',
        recurrenceType: ''
    };

    const state = useLocation();

    let { isCreateTask, setIsCreateTask, isSuccessToastMessage,
        setIsSuccessToastMessage, toastMessage, setToastMessage,
        taskId, isViewTask, setIsViewTask,
        isUpdateTask, setIsUpdateTask, loadTaskDetails ,taskTab } = props.props

    let taskDynamicDataConfig = {
        taskCategory: [],
        taskSource: [],
        taskAssignee: [],
        taskPriority: [],
        taskStatus: [],
        taskType: []
    };

    /**
     * PC_TF_08 - PC_TF_09
     * Create required state variables
    */
    const [viewTaskId, setViewTaskId] = useState(taskId);
    const [validateTaskData, setValidateTaskData] = useState(validateTaskObj);
    const [taskData, setTaskData] = useState(taskDataConfig);
    const [taskDynamicData, setTaskDynamicData] = useState(taskDynamicDataConfig);
    const [isOverDue, setIsOverDue] = useState(false);
    const [isSLABreach, setIsSLABreach] = useState(false);
    const [slaBreachTime, setSlaBreachTime] = useState(null);
    const [viewTaskComments, setViewTaskComments] = useState('');
    const [subtaskDetails, setSubtaskDetails] = useState('');
    const [policyDetails, setPolicyDetails] = useState('');
    const [approvedPolicyCount, setApprovedPolicyCount] = useState(0);
    const [evidenceDetails, setEvidenceDetails] = useState('');
    const [approvedEvidenceCount, setApprovedEvidenceCount] = useState(0);
    const [documentDetails, setDocumentDetails] = useState('');
    const [isThreat, setIsThreat] = useState(false);
    const [isCreateSubtask, setIsCreateSubtask] = useState(false);
    const [taskAssigneeData, setTaskAssigneeData] = useState();
    const [taskComments, setTaskComments] = useState('');
    const [validateTaskComments, setValidateTaskComments] = useState(false);
    const [isAlreadyAssigned, setIsAlreadyAssigned] = useState(false);
    const [uploadedDocument, setUploadedDocument] = useState([]);
    const [isDropdownSelected, setIsDropdownSelected] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [taskAssignee, setTaskAssignee] = useState();
    const [previousTaskId, setPreviousTaskId] = useState();
    const [deleteDocumentId, setDeleteDocumentId] = useState([]);
    const [isSaveTask, setIsSaveTask] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFalsePositve, setIsFalsePositive] = useState(false);
    const [isDocumentSizeExceed, setIsDocumentSizeExceed] = useState(false);
    const [subTaskLength,setSubtaskLength] = useState(0)
    //console.log(taskData, "taskData")

    /**
     * PC_TF_11
     * In the pageload useeffect will be triggered and inside the useEffect
     * loadTaskDynamicData() method will be called
     */
    /**
     * PC_TF_12
     * This useEffect will be triggered based on the dependency variable viewTaskId
     */
    useEffect(() => {
        loadTaskForm()
    }, [viewTaskId]);

    /**
     * PC_TF_13
     * This useEffect will be running for every second to bind the SLA time dynamically
     */
    useEffect(() => {
        const interval = setInterval(() => {
            if (slaBreachTime > 0) {
                setSlaBreachTime((prevSeconds) => prevSeconds - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const loadTaskForm = async () => {
        if (viewTaskId) {
            await loadViewTask();
        }
        else {
            await loadTaskDyanmicData();
        }
    }
    /**
     * PC_TF_14 - PC_TF_35
     * loadTaskDynamicData() method is used to bind the category, status, priority of the task
     */
    const loadTaskDyanmicData = async (categoryName) => {
        try {
            setIsLoading(true)
            const response = await getTaskDynamicData({ userId: userId, employeeId: employeeId, categoryName: categoryName ? categoryName : '' });
            setTaskDynamicData({
                ...taskDynamicData,
                taskCategory: response?.data?.taskCategory,
                taskStatus: response?.data?.taskStatus,
                taskPriority: response?.data?.taskPriority,
            
            });
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    };

    /**
     * PC_TF_36 - PC_TF_69
     * If user clicks on the task Id, loadViewTask() method will be triggered. 
     * In loadViewTask() method, make an API call to get the respective task details.
     */
    const loadViewTask = async (taskId) => {
        try {
            setIsLoading(true)
            const viewTaskConfig = {
                userId: userId,
                taskId: taskId ? taskId : viewTaskId,
            };
            const response = await getViewTask(viewTaskConfig);
            if (response?.data?.taskDetails?.categoryName == 'Risk') {
                await loadTaskDependency()
            }
            await loadTaskDyanmicData(response?.data?.taskDetails?.categoryName)
            //console.log(response?.data?.taskDetails,"response?.data?.taskDetails");
            setTaskData(response?.data?.taskDetails);
            setViewTaskComments(response?.data?.taskComments);
            setSubtaskDetails(response?.data?.subtaskDetails ? response?.data?.subtaskDetails : '');
            setIsSLABreach(response?.data?.isSlaPause ? true : false);
            setSlaBreachTime(response?.data?.isSlaPause ? '' : response?.data?.slaTime);
            setPolicyDetails(response?.data?.policyName ? response?.data?.policyName : '');
            setApprovedPolicyCount(response?.data?.approvedPolicy ? response?.data?.approvedPolicy : 0);
            setEvidenceDetails(response?.data?.evidenceName ? response?.data?.evidenceName : '');
            setApprovedEvidenceCount(response?.data?.approvedEvidence ? response?.data?.approvedEvidence : 0);
            setIsOverDue(response?.data?.taskDetails?.isOverdue === 1 ? true : false);
            setDocumentDetails(response?.data?.documentDetails === 'No Documents' || !response?.data?.documentDetails ? [] : response?.data?.documentDetails);
            setIsThreat(response?.data?.isThreat === 1 ? false : true);
            setIsFalsePositive(response?.data?.isThreat === 0 ? true : false)
            setViewTaskComments(response?.data?.taskComments)
            setSubtaskLength(response?.data?.taskDetails.taskId.split('-').length)
            const assingeeData = []
            response?.data?.taskAssignee?.map((value) => {
                assingeeData.push(
                    {
                        value: value?.value,
                        label: value?.label ? value.label : value?.value,
                        imageUrl: value?.profileUrl
                    }
                )
            })
            if (response?.data?.taskDetails.assignee && response?.data?.taskDetails.assigneeName) {
                setTaskAssignee({ value: response?.data?.taskDetails.assignee, label: response?.data?.taskDetails.assigneeName })
            }
            setTaskAssigneeData(assingeeData);
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    };

    /**
     * PC_TF75 - PC_TF_77
     * SLAFormatTime() method, will convert the normal datetime format into hr min format
     */
    const SLAFormatTime = () => {
        const hours = Math.floor(slaBreachTime / 3600);
        const minutes = Math.floor((slaBreachTime % 3600) / 60);
        const hoursStr = String(hours).padStart(2, '0');
        const minutesStr = String(minutes).padStart(2, '0');
        return `${hoursStr} hr ${minutesStr} min`;
    };

    /**
     * PC_TF_79 - PC_TF_80
     * When user enters the data in the task form, handleTaskData() method will be triggered
     * In handleTaskData() method, store the filled data
     */
    const handleTaskData = (event) => {
        //console.log(event.target.name, event.target.value, "event name and value");
        setTaskData({ ...taskData, [event.target.name]: event.target.value });
    }

    /**
     * PC_TF_82 - PC_TF_125
     *loadTaskSource() method is used to fetch the source details based on the selected category
     */
    const loadTaskSource = async (categoryId) => {
        try {
            setIsLoading(true)
            if (!(taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == categoryId && item.categoryName == "Compliance"))) {
                let taskSourceConfig = {
                    userId: userId,
                    categoryId: categoryId
                };


                let response = await getTaskSource(taskSourceConfig);

                setTaskDynamicData({ ...taskDynamicData, taskSource: response?.data });
            }
            else {
                let taskTypeConfig = {
                    userId: userId,
                    categoryId: 1
                };

                let response = await getTaskType(taskTypeConfig);

                setTaskDynamicData({ ...taskDynamicData, taskType: response?.data });
            }
            setValidateTaskData({
                categoryId: false,
                sourceId: false,
                title: false,
                description: false,
                startDate: false,
                endDate: false,
                taskTypeId: false,
                priorityId: false,
                assigneeType: false,
                assignee: false,
                recurrenceType: false
            })
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    }

    /**
     * PC_TF_133 - PC_TF_154
     * In the loadTaskDependency() method, fetch the respective task's title, description, startDate, endDate,
     * priorityId, recurrenceType, sourceId, assignee
     */
    const loadTaskDependency = async (sourceId) => {

        try {
            setIsLoading(true)
            let taskDependencyConfig = {
                userId: userId,
                categoryId: taskData?.taskTypeId ? taskData?.taskTypeId : taskData?.categoryId,
                sourceId: sourceId
            }
            let response = await getTaskDependency(taskDependencyConfig);

            setTaskData({
                ...taskData,
                title: response?.data?.taskData[0]?.title,
                description: response?.data?.taskData[0]?.description,
                startDate: response?.data?.taskData[0]?.startDate?.split('T')[0],
                endDate: response?.data?.taskData[0]?.endDate?.split('T')[0],
                priorityId: response?.data?.taskData[0]?.priorityId,
                priority: response?.data?.taskData[0]?.priority,
                recurrenceType: response?.data?.taskData[0]?.recurrenceType,
                sourceId: sourceId,
                assignee: response?.data?.taskData[0]?.userId
            })
            const assingeeData = []
            response?.data?.taskAssignee?.map((value) => {
                assingeeData.push(
                    {
                        value: value?.userId,
                        label: value?.employeeName ? value.employeeName : value?.userId,
                        imageUrl: value?.profileUrl
                    }
                )
            })
            setTaskAssigneeData(assingeeData);
            setIsLoading(false)
            setValidateTaskData({
                categoryId: false,
                sourceId: false,
                title: false,
                description: false,
                startDate: false,
                endDate: false,
                taskTypeId: false,
                priorityId: false,
                assigneeType: false,
                assignee: false,
                recurrenceType: false
            })
        }
        catch (error) {
            //console.log(error.message)
        }
    }

    /**
     * PC_TF_168 - PC_TF_185
     * In loadTaskAssignee() method, get the assignee details based on the assignee type
     */
    const loadTaskAssignee = async (assigneeType) => {

        try {
            setIsLoading(true)
            let taskAssigneeConfig = {
                userId: userId,
                assigneeType: assigneeType,
                sourceId: taskData?.sourceId
            }
            let response = await getTaskAssignee(taskAssigneeConfig);

            const assingeeData = []
            response?.data?.map((value) => {
                assingeeData.push(
                    {
                        value: value?.value,
                        label: value?.label ? value.label : value?.value,
                        imageUrl: value?.profileUrl
                    }
                )
            })
            setTaskAssigneeData(assingeeData);
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    }

    /**
     * PC_TF_427 - PC_TF_428
     * In handleMultiSelece() method, store the selected value
     */
    const handleMultiSelect = (assignee) => {
        setTaskAssignee(assignee);
        let multiAssignee = [];
        assignee?.map((value) => {
            multiAssignee.push(value.value)
        })
        setTaskData((pre) => ({ ...pre, ['assignee']: multiAssignee }))
    }

    /**
     * PC_TF_429
     * In bindMultiSelectProfile() method, return the HTML for the selected value in the 
     * multiselect list
     */
    const bindMultiSelectProfile = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src='E:\Azure AGov\AGOV One stop solution\AGOV Task FE\LOCAL-AVA-AGOV-FE\public\logo192.png' alt="Profile" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
            <span>{taskAssigneeData.label}</span>
        </div>
    );

    /**
     * PC_TF_420 - PC_TF_421
     * In handleClear() method, empty the key in the taskData variable
     */
    const handleClear = (clearValue) => {
        clearValue?.map((value) => {
            setTaskData((pre) => ({ ...pre, [value]: "" }))
            if (value == "assignee") setTaskAssigneeData(''); setTaskAssignee('')
        })
    }

    const validateFileType = (event) => {
        const allowedTypes = [
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        ];
        const file = event;
        if (file) {
            const fileType = file.type;
            const fileExtension = file.name.split(".").pop().toLowerCase();

            // Check if the file type or extension is allowed
            if (
                allowedTypes.includes(fileType) ||
                allowedTypes.includes(`.${fileExtension}`)
            ) {
                return true;
            }
            return false;
        }
    };

    /**
     * PC_TF_212 - PC_TF_248
     * handleUpload() method is used to upload the document in the blob 
     */
    const handleUpload = async () => {
        try {
            let blobDetails = [];
            if (uploadedDocument[0].length == 0) {
                return false;
            }
            const containerName = blobContainerName;
            const storageAccountName = blobStorageAccountName;

            let index = 0
            for (const value of uploadedDocument) {
                if (value.size > 50 * 1024 * 1024) {
                    uploadedDocument.splice(index, 1);
                    setIsDocumentSizeExceed('File size should not exceed 50MB');
                    return false
                }
                if (taskData.categoryName == "Policy") {
                    if (!validateFileType(value) && taskData?.categoryName == 'Policy') {
                        setIsDocumentSizeExceed('File Type should be .docx');
                        uploadedDocument.splice(index, 1);
                        return false;
                    }
                    else {
                        setIsDocumentSizeExceed(false);
                    }
                }
                else {
                    setIsDocumentSizeExceed(false);
                }
                let instance = moment().format("DDHHMMmmYYYYss");
                instance += uuid() + "-iiiii-";
                instance += value.name;
                const blobName = instance;

                let response = await generateSasToken({
                    resourceTypes: "o",
                    permission: "w",
                });

                const sasToken = response.responseData;
                const blobService = new BlockBlobClient(
                    `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blobName}${sasToken}`
                );

                const fileSize = value.size;
                const chunkSize = 4 * 1024 * 1024;
                let offset = 0;
                const blockList = [];

                while (offset < fileSize) {
                    const remainingSize = fileSize - offset;
                    const chunk = value.slice(
                        offset,
                        offset + Math.min(chunkSize, remainingSize)
                    );
                    const blockNumber = Math.floor(offset / chunkSize) + 1; // Calculate block number
                    const paddedBlockNumber = String(blockNumber).padStart(6, "0"); // Pad block number
                    const blockId = btoa(`block-${paddedBlockNumber}`); // Generate block ID with padded block number
                    try {
                        await blobService.stageBlock(blockId, chunk, chunk.size);
                        blockList.push(blockId);
                    } catch (error) {
                        //console.log("Error:", error);
                    }
                    offset += chunkSize;
                }

                try {
                    await blobService.commitBlockList(blockList);
                    //console.log("Block list committed successfully");
                } catch (error) {
                    //console.log("Error committing block list:", error);
                }
                let blobUrl = blobService.url;
                blobUrl = blobUrl.split("?");
                blobDetails.push(
                    {
                        blobName: value.name,
                        blobLink: blobUrl[0]
                    }
                );
                index++;
            }
            return blobDetails;
        }
        catch (error) {
            //console.log(error.message, "handleUpload Task")
        }
    };

    /**
     * PC_TF_194 - PC_TF_206
     * validateTask() method is used to check whether the mandatory fields in the 
     * task form is filled or not
     */
    const validateTask = () => {
        if (!taskData?.categoryId || taskData?.categoryId == 'Select') {
            validateTaskObj.categoryId = true
        }
        if (((taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "Compliance"))
            && (!taskData?.taskTypeId || taskData?.taskTypeId == 'Select') && !isViewTask)) {
            validateTaskObj.taskTypeId = true
        }
        if (!taskData?.sourceId || taskData?.sourceId == 'Select') {
            validateTaskObj.sourceId = true
        }
        if (!(taskData?.title?.trim())) {
            validateTaskObj.title = true
        }
        if (!(taskData?.description?.trim())) {
            validateTaskObj.description = true
        }
        if (!(taskData?.startDate)) {
            validateTaskObj.startDate = true
        }
        if (!(taskData?.endDate)) {
            validateTaskObj.endDate = true
        }
        if (!taskData?.priorityId || taskData?.priorityId == 'Select') {
            validateTaskObj.priorityId = true
        }
        if (((taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS"))
            && (!taskData?.assigneeType || taskData?.assigneeType == 'Select') && isCreateTask)) {
            validateTaskObj.assigneeType = true
        }
        if ((taskData?.assignee?.length == 0 || taskData?.assignee == 'Select') &&
            (!(taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "Access Request")))) {
            validateTaskObj.assignee = true
        }
        if ((!taskData?.recurrenceType || taskData?.recurrenceType == 'Select') &&
            ((taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS")) ||
                (taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "Evidence")) ||
                (taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Evidence")))) {
            validateTaskObj.recurrenceType = true
        }

        setValidateTaskData(validateTaskObj);

        if (validateTaskObj.categoryId || validateTaskObj.taskTypeId || validateTaskObj.sourceId ||
            validateTaskObj.title || validateTaskObj.description || validateTaskObj.startDate ||
            validateTaskObj.endDate || validateTaskObj.priorityId || validateTaskObj.assigneeType ||
            validateTaskObj.assignee || validateTaskObj.recurrenceType) {

            return false
        }

        return true;
    }

    /**
     * PC_TF_192 - PC_TF_292
     * In saveTask() method, when user update or create task, this method will be triggerd
     */
    const saveTask = async (alreadyAssigned = false) => {
        try {
            setIsLoading(true)
            if (validateTask()) {
                if (viewTaskId) {
                    let blobDetails;
                    if (uploadedDocument.length > 0) {
                        blobDetails = await handleUpload();
                        if (!blobDetails) {
                            setIsLoading(false);
                            return
                        }
                    }
                    let updateTaskConfig = {
                        ...taskData,
                        isThreat: isFalsePositve,
                        blobDetails: blobDetails,
                        userId: userId,
                        deleteDocumentId: deleteDocumentId,
                        taskTab:taskTab
                    };
                    let response = await updateTask(updateTaskConfig);
                    if (response.data = 'Success') {
                        setIsUpdateTask(false);
                        setIsViewTask(false);
                        setIsSuccessToastMessage(true);
                        setToastMessage('Task Updated Successfully');
                        loadTaskDetails();
                    }

                } else {
                    let response = await createTask({ taskData, alreadyAssigned, userId: userId });
                    if (response.data == "Already Assigned") {
                        setIsAlreadyAssigned(true);
                    }
                    if (response.data == "Success") {
                        setIsCreateTask(false);
                        setIsSuccessToastMessage(true);
                        setToastMessage("Task Created Successfully");
                        loadTaskDetails();
                    }
                }
            }
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    }

    /**
     * PC_TF_423 - PC_TF_424
     * When user select the assignee, changeDropDown method will be triggered and selected user
     * details wil be stored
     */
    const changeDropDown = (userId, profileUrl, employeeName) => {
        setSelectedItem({
            ...selectedItem,
            profileUrl: profileUrl,
            employeeName: employeeName,
        });
        setTaskData(prevState => ({
            ...prevState,
            assignee: [userId]
        }))
        setIsDropdownSelected(false);
        userId ? setValidateTaskData((pre) => ({ ...pre, ['assignee']: false })) : setValidateTaskData((pre) => ({ ...pre, ['assignee']: true }))
    };

    /**
     * PC_TF_353 - PC_TF_376
     * sendComments method used to make and API call and post the comments into the database
     */
    const sendComments = async () => {
        try {
            setIsLoading(true)
            if (taskComments) {
                let addCommentsConfig = {
                    userId: userId,
                    taskDetailsId: taskData?.taskDetailsId,
                    taskComments: taskComments
                };

                let response = await addComments(addCommentsConfig);

                if (response.data === "Success") {
                    await loadViewTask();
                    setTaskComments('');
                    setIsSuccessToastMessage(true);
                    setToastMessage("Comment Added")
                }
            }
            else {
                setValidateTaskComments(true);
            }
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    };

    /**
     * PC_TF_326 - PC_TF_
     * handlePolicyTemplateDownload() method is used to make an API call and download the
     * respective policy's template
     */
    const handlePolicyTemplateDownload = async () => {
        try {
            setIsLoading(true)
            let templateDownloadConfig = {
                userId: userId,
                sourceId: taskData?.sourceId
            };

            let response = await downloadPolicyTemplate(templateDownloadConfig);
            FileSaver.saveAs(response?.data?.blobLink, `${response?.data?.blobName}`);
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    };

    /**
     * PC_TF_414 - PC_TF_417
     * handleDocumentDownload() method is used to download the document in the local file
     */
    const handleDocumentDownload = async (documentName, documentUrl) => {
        try {
            setIsLoading(true);
            FileSaver.saveAs(documentUrl, `${documentName}`);
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    }

    /**
     * PC_TF_302
     * In handleApprove method is used to approve the document which is uploaded by the user
     */
    const handleApprove = async () => {
        try {
            setIsLoading(true)
            let approveDocumentConfig = {
                userId: userId,
                taskDetailsId: taskData?.taskDetailsId,
                taskId: taskData?.taskId
            };

            let response = await approveDocument(approveDocumentConfig);
            if (response.data == 'Success') {
                setIsUpdateTask(false);
                setIsViewTask(false);
                setIsSuccessToastMessage(true);
                setToastMessage("Document Approved");
                loadViewTask();
            }
            setIsLoading(false)
        }
        catch (error) {
            //console.log(error.message)
        }
    };

    /**
     * PC_TF_127 
     * In bindTaskType() method, bind the value of the compliance category is 
     * Evidence or Policy
     */
    const bindTaskType = () => {
        return taskDynamicData?.taskType?.map((value) => {
            return (
                <option value={value?.taskCategoryId} id={value?.categoryName}>{value?.categoryName}</option>
            )
        })
    }

    /**
     * PC_TF_72
     * bindTaskComments() method is used to bind the comments of the task
     */
    const bindTaskComments = () => {
        if (viewTaskComments && viewTaskComments != 'No Comments') {
            return viewTaskComments.map((value) => {
                return (
                    <div className="d-flex">
                        <div>
                            <img src={value.profileUrl} className="comment-prof me-3" />
                        </div>
                        <div className="py-2">
                            <p className="font-regular font-12 primary-textcolor mb-1">{value.employeeName} <span className="font-regular font-10 grey-secondary mb-2">{formatCommentsDate(value.createdOn)}</span></p>
                            <p className="font-regular font-10 line-height-24">{value?.taskComments}</p>
                        </div>
                    </div>
                )
            })
        }
    };

    /**
     * PC_TF_69
     * bindTaskCategory() method is used to dynamically bind the category of the task
     */
    const bindTaskCategory = () => {
        return taskDynamicData?.taskCategory?.map((value) => {
            return (
                <option value={value?.taskCategoryId} id={value?.categoryName}>{value?.categoryName}</option>
            )
        })
    }

    /**
     * PC_TF_70
     * bindTaskStatus() method is used to dynamically bind the status of the task
     */
    const bindTaskStatus = () => {
        return taskDynamicData?.taskStatus?.map((value) => {
            return (
                <option value={value?.taskStatusId} id={value?.taskStatus}>{value?.taskStatus}</option>
            )
        })
    }

    /**
     * PC_TF_71
     * bindTaskPriority() method is used to dynamically bind the priority of the task
     */
    const bindTaskPriority = () => {
        return taskDynamicData?.taskPriority?.map((value) => {
            return (
                <option value={value?.taskPriorityId} id={value?.taskPriority}>{value?.taskPriority}</option>
            )
        })
    }

    /**
     * PC_TF_186
     * bindTaskAssigneeType() method id used to bind the assigneeType dropdown values
     */
    const bindTaskAssigneeType = () => {
        return (
            <>
                <option value='Individual' id='Individual'>Individual</option>
                <option value='Department' id='Department'>Department</option>
            </>
        )
    }

    /**
     * PC_TF_126
     * In bindTaskSource() method, bind the HTML part of the 
     * Application Name, Policy Name, Evidence Name, Campaign name field
     */
    const bindTaskSource = () => {
        return taskDynamicData?.taskSource?.map((value) => {
            return (
                <option value={value?.sourceId} id={value?.sourceName}>{`${value?.sourceName} ${value?.createdOn ? '-' + value?.createdOn?.split('T')[0] : ''}`}</option>
            )
        })
    }

    /**
     * PC_TF_74
     * bindDocumentPolicy() method is used to bind the policy for the compliance task
     */
    const bindDocumentPolicy = () => {
        return policyDetails?.map((value) => {
            return (
                <div className="task-badge font-regular font-12 me-2" value={value?.policyId} id={value.policyName}>{value.policyName}</div>
            )
        })
    }

    /**
     * PC_TF_74
     * bindDocumentEvidence() method is used to bind the evidence for the compliance task
     */
    const bindDocumentEvidence = () => {
        return evidenceDetails?.map((value) => {
            return (
                <div className="task-badge font-regular font-12 me-2" value={value?.evidenceId} id={value.evidenceName}>{value.evidenceName}</div>
            )
        })
    }

    /**
     * PC_TF_73
     * bindSubtaskDetails() method is used to dynamically bind the subtask's created for the 
     * respective main task
     */
    const bindSubtaskDetails = () => {
        if (subtaskDetails != 'No Subtask' && subtaskDetails) {
            return subtaskDetails?.map((value) => {
                return (
                    <div className="d-flex subtask-cotainer justify-content-between align-items-center mb-2 p-2">
                        <p className="secondary-textcolor font-13 font-medium mb-0 cursor-pointer" onClick={() => { setViewTaskId(value.taskId); setPreviousTaskId(taskData?.taskId); }}>{value.taskId}</p>
                        <div className="d-flex align-items-center">
                            <p className="subtask-date font-13 font-regular mb-0 me-3">{`${moment(value?.startDate).format('MM-DD-YYYY')} - ${moment(value?.endDate).format('MM-DD-YYYY')}`}</p>
                            <img src={`${value.profileUrl}`} className="subtask-prof me-3" />
                            <span className={value.taskStatus === "Open" ? "green-dot me-2" : value.taskStatus === "In Progress" ? "orange-dot me-2" :
                                value.taskStatus === "Awaiting Confirmation" ? "blue-dot me-2" : "grey-dot me-2"} />
                        </div>
                    </div>
                )
            })
        }
    }

    /**
     * PC_TF_413
     * bindUploadedDocument() method is used to bind the HTML part of the uploaded documents
     */
    const bindUploadedDocument = () => {
        if (uploadedDocument.length > 0) {
            return uploadedDocument?.map((value) => {
                return (
                    <>
                        <a className="secondary-textcolor font-medium font-13 text-decoration-none d-block align-items-center" href={URL.createObjectURL(value)} target="_blank" rel="noopener noreferrer">
                            <span className="blue-dot-small me-1"></span>
                            <span className="me-2 mb-1"></span>{value?.name}</a> <img className="cursor-pointer" src="images/delete-icon-grey.svg"
                                onClick={() => {
                                    const deleteDocument = uploadedDocument.filter(item => item.size != value.size);
                                    setUploadedDocument(deleteDocument)
                                }}></img>
                    </>
                )
            })
        }
        else if (documentDetails != 'No Documents') {
            return documentDetails?.map((value) => {
                return (
                    <>
                        <a className="secondary-textcolor font-medium font-13 text-decoration-none d-block align-items-center">
                            <span className="blue-dot-small me-1"></span>
                            <span className="me-2 mb-1" onClick={() => { handleDocumentDownload(value?.documentName, value?.documentUrl) }}>{value?.documentName}</span> {taskData?.taskStatus == 'In Progress' ? <img className="cursor-pointer" src="images/delete-icon-grey.svg"
                                onClick={() => {
                                    const deleteDocument = documentDetails?.filter(item => item.uploadedDocumentId != value.uploadedDocumentId);
                                    setDocumentDetails(deleteDocument)
                                    setDeleteDocumentId([...deleteDocumentId, value.uploadedDocumentId])
                                    setIsSaveTask(true)
                                }}></img> : <></>}</a>
                    </>
                )
            })
        }
        else {
            return (
                <></>
            )
        }
    }

    function HandleSubtask(){
        setIsCreateSubtask(false)
        setIsViewTask(false);
        setIsSuccessToastMessage(true);
        setToastMessage("Task Updated Successfully");
        loadTaskDetails()
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="offcanvas show offcanvas-end custom-offcanvas p-4" tabIndex={-1}
                id="offcanvasLms" style={{ zIndex: 1051, transition: "all 10s" }}>
                {isCreateTask || isUpdateTask ? <div className="create-task-menu border-0">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="font-24 font-bold text-black mb-0">Create New Task</h5>
                            <button type="button" className="btn-close ms-auto" onClick={() => {
                                setIsCreateTask(false);
                                setIsUpdateTask(false);
                                setIsViewTask(false);
                            }} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                            <div className="mb-4">
                                <label className="form-label text-black font-14 font-medium">Category<span className="required">*</span>
                                </label>
                                {isCreateTask ? <select className="form-select custom-form-control font-12 font-regular" name='categoryId' aria-label="Default select example"
                                    value={taskData?.categoryId} onChange={(e) => {
                                        handleTaskData(e);
                                        loadTaskSource(e.target.value);
                                        handleClear(['sourceId', 'taskTypeId', 'title', 'description', 'startDate', 'endDate', 'priorityId', 'assigneeType', 'assignee']);
                                        setTaskData((pre) => ({ ...pre, ['sourceId']: "" }));
                                        e.target.value && e.target.value != 'Select' ? setValidateTaskData((pre) => ({ ...pre, ['categoryId']: false })) : setValidateTaskData((pre) => ({ ...pre, ['categoryId']: true }))
                                    }}>
                                    <option>Select</option>
                                    {bindTaskCategory()}
                                </select> : <></>}
                                <span className="required font-12">{validateTaskData.categoryId ? ' Please select category' : ''}</span>
                                {isUpdateTask ? <p className="font-regular font-13">{taskData?.categoryName}</p> : <></>}
                            </div>
                        </div>

                        {((taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "Compliance"))
                            && taskData?.categoryId && taskData?.categoryId != 'Select')
                            ? <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium">
                                        Type <span className="required">*</span>
                                    </label>
                                    <select className="form-select custom-form-control font-12 font-regular" name='taskTypeId' value={taskData?.taskTypeId}
                                        aria-label="Default select example" onChange={(e) => {
                                            handleTaskData(e);
                                            loadTaskSource(e.target.value);
                                            handleClear(['sourceId', 'title', 'description', 'startDate', 'endDate', 'priorityId', 'assigneeType', 'assignee']);
                                            e.target.value && e.target.value != 'Select' ? setValidateTaskData((pre) => ({ ...pre, ['taskTypeId']: false })) : setValidateTaskData((pre) => ({ ...pre, ['taskTypeId']: true }))
                                        }}>
                                        <option>Select</option>
                                        {bindTaskType()}
                                    </select>
                                    <span className="required font-12">{validateTaskData.taskTypeId ? ' Please select Type' : ''}</span>
                                </div>
                            </div> : <></>}

                        {((!(taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "Compliance")))
                            && taskData?.categoryId && taskData?.categoryId != 'Select' && isCreateTask)
                            ? <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium">
                                        {taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS") ?
                                            'Campaign Name' : taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "Access Request") ?
                                                'Application Name' : taskDynamicData?.taskType?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Policy") ?
                                                    'Policy Name' : taskDynamicData?.taskType?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Evidence") ?
                                                        'Evidence Name' : ''} <span className="required">*</span>
                                    </label>
                                    {isCreateTask ? <select className="form-select custom-form-control font-12 font-regular" name='sourceId' value={taskData?.sourceId}
                                        onChange={(e) => {
                                            handleTaskData(e);
                                            handleClear(['taskTypeId', 'title', 'description', 'startDate', 'endDate', 'priorityId', 'assigneeType', 'assignee']);
                                            loadTaskDependency(e.target.value);
                                            e.target.value && e.target.value != 'Select' ? setValidateTaskData((pre) => ({ ...pre, ['sourceId']: false })) : setValidateTaskData((pre) => ({ ...pre, ['sourceId']: true }));
                                        }} aria-label="Default select example">
                                        <option>Select</option>
                                        {bindTaskSource()}
                                    </select> : <></>}
                                    <span className="required font-12">{validateTaskData.sourceId ? ` Please select ${taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS") ?
                                        'Campaign Name' : taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "Access Request") ?
                                            'Application Name' : taskDynamicData?.taskType?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Policy") ?
                                                'Policy Name' : taskDynamicData?.taskType?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Evidence") ?
                                                    'Evidence Name' : ''}` : ''}</span>
                                </div>
                            </div> : <></>}
                        {isUpdateTask ? <div className="col-md-6 col-sm-12">
                            <div className="mb-4">
                                <label className="form-label text-black font-14 font-medium">{taskData?.categoryName === "Access Request" ? 'Application Name' : taskData?.categoryName === 'Compliance' ? 'Control Name' : taskData?.categoryName === 'Policy' ? 'Policy Name' :
                                    taskData?.categoryName === 'Evidence' ? 'Evidence Name' : taskData?.categoryName === 'LMS' ? 'Campaign Name' : ''}</label>
                                <p className="font-regular font-13">{taskData?.campaignName ? taskData?.campaignName : taskData?.applicationName ? taskData?.applicationName : taskData?.evidenceName ? taskData?.evidenceName : taskData?.policyName ? taskData?.policyName : taskData?.controlName}</p>
                            </div>
                        </div> : <></>}
                        {((taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "Compliance"))
                            && taskData?.taskTypeId && taskData?.taskTypeId != 'Select')
                            ? <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium">
                                        {taskDynamicData?.taskType?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Policy") ?
                                            'Policy Name' : taskDynamicData?.taskType?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Evidence") ?
                                                'Evidence Name' : ''} <span className="required">*</span>
                                    </label>
                                    <select className="form-select custom-form-control font-12 font-regular" name='sourceId' value={taskData?.sourceId}
                                        onChange={(e) => {
                                            handleTaskData(e);
                                            handleClear(['title', 'description', 'startDate', 'endDate', 'priorityId', 'assigneeType', 'assignee']);
                                            loadTaskDependency(e.target.value);
                                            e.target.value && e.target.value != 'Select' ? setValidateTaskData((pre) => ({ ...pre, ['sourceId']: false })) : setValidateTaskData((pre) => ({ ...pre, ['sourceId']: true }))
                                        }} aria-label="Default select example">
                                        <option>Select</option>
                                        {bindTaskSource()}
                                    </select>
                                    <span className="required font-12">{validateTaskData.sourceId ? ` Please select ${taskDynamicData?.taskType?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Policy") ?
                                        'Policy Name' : taskDynamicData?.taskType?.some(item => item.taskCategoryId == taskData?.taskTypeId && item.categoryName == "Evidence") ?
                                            'Evidence Name' : ''}` : ''}</span>
                                </div>
                            </div> : <></>}

                        <div className="col-md-12 col-sm-12">
                            <div className="mb-4">
                                <label className="form-label text-black font-14 font-medium">Title<span className="required">*</span>
                                </label>
                                <input type="text" className="form-control custom-form-control theme-form font-regular font-14" maxLength={200}
                                    id="ticketTitle" placeholder="Enter Ticket Title" name='title' value={taskData?.title} onChange={(e) => {
                                        handleTaskData(e);
                                        e.target.value.trim() ? setValidateTaskData((pre) => ({ ...pre, ['title']: false })) : setValidateTaskData((pre) => ({ ...pre, ['title']: true }))
                                    }} />
                                <span className="required font-12">{validateTaskData.title ? ' Please enter Title' : ''}</span>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="mb-4">
                                <label className="form-label text-black font-14 font-medium">Description<span className="required">*</span>
                                </label>
                                <textarea type="textarea" className="form-control custom-form-control theme-form font-regular no-resize font-14" rows={3}
                                    id="ticketDescription" placeholder="Enter Description" defaultValue={""} name="description" value={taskData?.description}
                                    onChange={(e) => {
                                        handleTaskData(e);
                                        e.target.value.trim() ? setValidateTaskData((pre) => ({ ...pre, ['description']: false })) : setValidateTaskData((pre) => ({ ...pre, ['description']: true }))
                                    }} />
                                <span className="required font-12">{validateTaskData.description ? ' Please enter Description' : ''}</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="mb-4">
                                <label htmlFor="StartDate" className="form-label text-black font-14 font-medium">Start Date<span className="required">*</span></label>
                                {isCreateTask ? <input type="date" className="form-control custom-date custom-form-control font-12 font-regular" id="StartDate" name="startDate" min={new Date().toISOString().split('T')[0]} max={taskData?.endDate?.split('T')[0]}
                                    value={taskData?.startDate?.split('T')[0]}
                                    onKeyDown={(event) => event.preventDefault()}
                                    onChange={(e) => {
                                        handleTaskData(e);
                                        e.target.value ? setValidateTaskData((pre) => ({ ...pre, ['startDate']: false })) : setValidateTaskData((pre) => ({ ...pre, ['startDate']: true }))
                                    }} disabled={taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS")} /> : <></>}
                                <span className="required font-12">{validateTaskData.startDate ? ' Please select start date' : ''}</span>
                                {isUpdateTask ? <p className="font-regular font-13">{moment(taskData?.startDate).format('MM-DD-YYYY')}</p> : <></>}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="mb-4">
                                <label htmlFor="endDate" className="form-label text-black font-14 font-medium">End Date<span className="required">*</span></label>
                                {isCreateTask ? <input type="date" className="form-control custom-date custom-form-control font-12 font-regular" id="endDate" name="endDate" min={taskData?.startDate?.split('T')[0] || new Date().toISOString().split('T')[0]}
                                    value={taskData?.endDate?.split('T')[0]} 
                                    onKeyDown={(event) => event.preventDefault()}
                                    onChange={(e) => {
                                        handleTaskData(e);
                                        e.target.value ? setValidateTaskData((pre) => ({ ...pre, ['endDate']: false })) : setValidateTaskData((pre) => ({ ...pre, ['endDate']: true }))
                                    }} disabled={taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS")} /> : <></>}
                                <span className="required font-12">{validateTaskData.endDate ? ' Please select end date' : ''}</span>
                                {isUpdateTask ? <p className="font-regular font-13">{moment(taskData?.endDate).format('MM-DD-YYYY')}</p> : <></>}
                            </div>
                        </div>
                        {taskData?.recurrenceType ? <div className="col-md-6 col-sm-12">
                            <div className="mb-">
                                <label htmlFor="endDate" className="form-label text-black font-14 font-medium mb-3">Recurrence</label>
                                <p className="font-regular font-13">{taskData?.recurrenceType}</p>
                            </div>
                        </div> : <></>}
                        <div className="col-md-6 col-sm-12">
                            <div className="mb-4">
                                <label className="form-label text-black font-14 font-medium">Priority<span className="required">*</span>
                                </label>
                                <select className="form-select custom-form-control font-12 font-regular" name='priorityId' aria-label="Default select example"
                                    onChange={(e) => {
                                        handleTaskData(e);
                                        e.target.value && e.target.value != 'Select' ? setValidateTaskData((pre) => ({ ...pre, ['priorityId']: false })) :
                                         setValidateTaskData((pre) => ({ ...pre, ['priorityId']: true }))
                                    }} value={taskData?.priorityId} >
                                    <option selected>Select</option>
                                    {bindTaskPriority()}
                                </select>
                                
                                
                                <span className="required font-12">{validateTaskData.priorityId ? ' Please select Priority' : ''}</span>
                            </div>
                        </div>
                        {((taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS"))
                            && taskData?.categoryId && taskData?.categoryId != 'Select' && taskData?.sourceId) && isCreateTask ?
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium">Assignee Type<span className="required">*</span>
                                    </label>
                                    <select className="form-select custom-form-control font-12 font-regular" aria-label="Default select example" value={taskData?.assigneeType}
                                        name="assigneeType" onChange={(e) => {
                                            handleClear(['assignee']);
                                            handleTaskData(e);
                                            loadTaskAssignee(e.target.value);
                                            e.target.value && e.target.value != 'Select' ? setValidateTaskData((pre) => ({ ...pre, ['assigneeType']: false })) : setValidateTaskData((pre) => ({ ...pre, ['assigneeType']: true }))
                                        }}>
                                        <option selected>Select</option>
                                        {bindTaskAssigneeType()}
                                    </select>
                                    <span className="required font-12">{validateTaskData.assigneeType ? ' Please select Assignee Type' : ''}</span>
                                </div>
                            </div> : <></>}
                        {(!(taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS")) && taskAssigneeData?.length > 0) ||
                            ((taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS")) && taskAssigneeData?.length > 0) ?
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium">Assignee<span className="required">*</span>
                                    </label>
                                    {(!(taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS")) && taskAssigneeData?.length > 0) ?
                                        <Select
                                            style={{ width: '100%' }}
                                            options={taskAssigneeData}
                                            value={taskAssignee}
                                            onChange={(e) => {
                                                setTaskAssignee(e);
                                                setTaskData((pre) => ({ ...pre, ['assignee']: e?.value ? [e.value] : "" }))
                                                e ? setValidateTaskData((pre) => ({ ...pre, ['assignee']: false })) : setValidateTaskData((pre) => ({ ...pre, ['assignee']: true }));
                                                setIsSaveTask(true);
                                            }}
                                            isSearchable
                                            isClearable
                                            closeMenuOnSelect={true}
                                        /> : <></>}
                                    {((taskDynamicData?.taskCategory?.some(item => item.taskCategoryId == taskData?.categoryId && item.categoryName == "LMS")) && taskAssigneeData?.length > 0) ?
                                        <Select
                                            style={{ width: '100%' }}
                                            options={taskAssigneeData}
                                            isMulti
                                            value={taskAssignee}
                                            onChange={(e) => {
                                                handleMultiSelect(e);
                                                e ? setValidateTaskData((pre) => ({ ...pre, ['assignee']: false })) : setValidateTaskData((pre) => ({ ...pre, ['assignee']: true }))
                                            }}
                                            isSearchable
                                            isClearable
                                            closeMenuOnSelect={false}
                                        /> : <></>}
                                </div>
                                <span className="required font-12">{validateTaskData.assignee ? ' Please select Assignee' : ''}</span>
                            </div> : <></>}
                    </div>
                    {isUpdateTask && taskData?.categoryName != 'Access Request' && taskData?.employeeName ?
                        <div className="mb-">
                            <label htmlFor="endDate" className="form-label text-black font-14 font-medium mb-3">Assignee</label>
                            <p className="font-regular font-13">{taskData?.employeeName}</p>
                        </div>
                        : <></>}
                    <div className="text-end mt-4 mb-2">
                        <button type="button" className="primary-btn font-14 font-medium me-2" onClick={() => {
                            setIsCreateTask(false);
                            setIsUpdateTask(false);
                            setIsViewTask(false);
                        }}>
                            Cancel
                        </button>
                        <button type="button" className="dark-btn font-14 font-medium px-4" disabled={taskData.startDate > taskData.endDate} onClick={() => { saveTask(); }}>
                            {isCreateTask ? 'Create' : 'Update'}
                        </button>
                    </div>
                </div> : <></>
                }

                {/*LMS ticket creation*/}
                {/*LMS ticket filled*/}
                {
                    isViewTask ? <div>
                        <div className="col-md-12 overdue">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h5 className="font-24 font-bold text-black mb-0">{previousTaskId ? <img className="me-3 mb-1 cursor-pointer" src="images/back-arrow.svg" alt="back-arrow" onClick={() => {
                                    setViewTaskId(previousTaskId);
                                    setPreviousTaskId('');
                                }} /> : <></>}{taskData?.taskId}</h5>
                                {(isOverDue && taskData?.categoryName == 'LMS') || (taskData?.categoryName == 'LMS' && new Date(taskData.endDate) < new Date()) ? <div className="overdue-badge font-medium font-14" > <img src="images/warning-icon.svg" className="icon"/>Overdue</div> : <></>}
                                {(isSLABreach) && taskData?.categoryName == 'Risk' ? <div className="slaBreached font-medium font-14">SLA Paused</div> : <></>}
                                {!isSLABreach && taskData?.categoryName == 'Risk' && slaBreachTime < 0 ? <div className="slaBreached font-medium font-14"
                                > <img src="images/warning-icon.svg" className="icon"/>SLA Breached</div> : <></>}
                                <button type="button" className="btn-close ms-auto" onClick={() => { setIsViewTask(false) }} />
                            </div>
                        </div>
                        {taskData?.categoryName === 'Risk' && slaBreachTime > 0 ? <div className="col-md-12 col-sm-12">
                            <div id="liveToast" className="toast time-left-toast show mb-4" role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-body py-2">
                                    <p className="font-regular font-14 me-3 mb-0"><img src="images/warning-icon.svg" className="time-left-icon mb-1 me-2" />You have <span className="font-medium">{SLAFormatTime(slaBreachTime)}</span> to complete this action item</p>
            </div>
        </div>
                        </div> : <></>}
                        <div className="row mt-3">
                            {taskData?.categoryName === "LMS" || taskData?.categoryName === "Access Request" ? <div className="col-md-12 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium mb-3">{
                                        taskData?.categoryName === "LMS" ? `Campaign Name` : taskData?.categoryName === "Access Request" ?
                                            `Application Name` : ``
                                    }
                                    </label>
                                    <p className="font-regular font-13">{taskData?.campaignName ? taskData?.campaignName : taskData?.applicationName}</p>
                                </div>
                            </div> : <></>}
                            <div className="col-md-12 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium mb-3">Title
                                    </label>
                                    <p className="font-regular font-13">{taskData?.title}</p>
                                </div>
                            </div>
                            {taskData?.categoryName === 'Compliance' && (policyDetails.length > 0 || evidenceDetails.length > 0) ? <div>
                                <h5 className="font-16 font-medium text-black mt-3 mb-3">Documents needed</h5>
                                <div className="col-md-12 col-sm-12">
                                    {policyDetails.length > 0 ? <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label className="form-label text-black font-14 font-medium mb-3">Policy
                                            </label>
                                            <p className="font-regular font-regular font-10"><span className="orange-dot me-2" />{approvedPolicyCount}/{policyDetails.length} Completed</p>
                                        </div>
                                        <div className="d-flex">
                                            {bindDocumentPolicy()}
                                        </div>
                                    </div> : <></>}
                                    {evidenceDetails.length > 0 ? <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label className="form-label text-black font-14 font-medium mb-3">Evidence
                                            </label>
                                            <p className="font-regular font-regular font-10"><span className="orange-dot me-2" />{approvedEvidenceCount}/{evidenceDetails.length} Completed</p>
                                        </div>
                                        <div className="d-flex">
                                            {bindDocumentEvidence()}
                                        </div>
                                    </div> : <></>}
                                </div>
                            </div> : <></>}
                            {taskData?.performedBy ? <div className="col-md-12 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium mb-3">Username
                                    </label>
                                    <p className="font-regular font-13">{taskData?.performedBy}</p>
                                </div>
                            </div> : <></>}
                            <div className="col-md-12 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium mb-3">Description
                                    </label>
                                    <p className="font-regular font-13">{taskData?.description}</p>
                                </div>
                            </div>
                            {taskData?.categoryName ? <div className="col-md-6 col-sm-12">
                                <div className="mb-">
                                    <label htmlFor="endDate" className="form-label text-black font-14 font-medium mb-3">Category</label>
                                    <p className="font-regular font-13">{taskData?.categoryName}</p>
                                </div>
                            </div> : <></>}
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label htmlFor="endDate" className="form-label text-black font-14 font-medium mb-3">Priority
                                    </label>
                                    <p className={`font-regular font-medium font-13`}>{taskData?.taskPriority}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label htmlFor="StartDate" className="form-label text-black font-14 font-medium mb-3">Start Date</label>
                                    <p className="font-regular font-13">{moment(taskData?.startDate).format('MM-DD-YYYY')}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-">
                                    <label htmlFor="endDate" className="form-label text-black font-14 font-medium mb-3">End Date</label>
                                    <p className="font-regular font-13">{moment(taskData?.endDate).format('MM-DD-YYYY')}</p>
                                </div>
                            </div>
                            {taskData?.recurrenceType ? <div className="col-md-6 col-sm-12">
                                <div className="mb-">
                                    <label htmlFor="endDate" className="form-label text-black font-14 font-medium mb-3">Recurrence</label>
                                    <p className="font-regular font-13">{taskData?.recurrenceType}</p>
                                </div>
                            </div> : <></>}
                            {taskData?.assignee == userId && taskData?.taskStatus != 'Closed' && taskData?.categoryName != 'LMS' ?
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-4">
                                        <label className="form-label text-black font-14 font-medium">Status</label>
                                        <select className="form-select custom-form-control font-12 font-regular" aria-label="Default select example" value={taskData?.statusId}
                                            name="statusId" onChange={(e) => {
                                                handleTaskData(e);
                                                setIsSaveTask(true);
                                            }}>
                                            {bindTaskStatus()}
                                        </select>
                                    </div>
                                </div> :
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-4">
                                        <label htmlFor="endDate" className="form-label text-black font-14 font-medium mb-3">Status
                                        </label>
                                        <p className="font-regular font-medium font-13"><span className={`me-2 ${taskData?.taskStatus === "Open" ? "green-dot me-2" : taskData?.taskStatus === "In Progress" ? "orange-dot me-2" :
                                            taskData?.taskStatus === "Awaiting Confirmation" ? "blue-dot me-2" : "grey-dot me-2"
                                            }`} />{taskData?.taskStatus}</p>
                                    </div>
                                </div>}
                            {taskData?.taskStatus == 'In Progress' && taskData?.categoryName == 'Risk' && subTaskLength==1  ? <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label text-black font-14 font-medium mb-3">Mark as False Positive
                                    </label>
                                    <input type="checkbox" className="d-block form-check-input custom-check-input" id="falsecheckbox" checked={isFalsePositve}
                                        name="isFalsePositive" value={isFalsePositve} onChange={(e) => { setIsFalsePositive(e.target.checked); setIsSaveTask(true); }} 
                                        disabled={isThreat}/>
                                        
                                </div>
                            </div> : <></>}
                            {taskData?.taskStatus != 'Completed' && (taskData?.categoryName === 'Risk' && !taskData.taskId.includes("-")) ?
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-4">
                                        <label className="form-label text-black font-14 font-medium">Assigned to</label>
                                        <Select
                                            style={{ width: '100%' }}
                                            options={taskAssigneeData}
                                            value={taskAssignee}
                                            onChange={(e) => {
                                                setTaskAssignee(e);
                                                //console.log(e.value,"DropDown valueeeeeee")
                                                setTaskData((pre) => ({ ...pre, ['assignee']: e?.value ? [e.value] : "" }))
                                                e ? setValidateTaskData((pre) => ({ ...pre, ['assignee']: false })) : setValidateTaskData((pre) => ({ ...pre, ['assignee']: true }));
                                                setIsSaveTask(true);
                                            }}
                                            components={bindMultiSelectProfile}
                                            isSearchable
                                            isClearable
                                            closeMenuOnSelect={true}
                                        />
                                    </div>
                                </div> : <></>}
                                {taskData?.categoryName === 'Access Request' ?
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-4">
                                        <label className="form-label text-black font-14 font-medium">Assigned to</label>
                                        <p className="font-regular font-medium font-13">{taskData?.employeeName}</p>
                                    </div>
                                </div>
                                : <></>}
                            {taskData.categoryName === 'Policy' ? <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label htmlFor="upload-policy" className="form-label font-medium font-14 primary-textcolor">Download
                                        Policy Template</label>
                                    <span className="me-2 d-block cursor-pointer ">
                                        <button type="button" className="cust-filter-btn font-14  font-regular primary-color download-btn "
                                            onClick={() => {
                                                handlePolicyTemplateDownload();
                                            }}
                                        ><img src="images/download-icon-filled.svg" alt="browse" className="me-2" />Download</button>
                                    </span>
                                </div>
                            </div> : <></>}
                            {(taskData?.taskStatus != 'Open' && taskData?.taskStatus != 'Closed' && taskData?.assignee == userId) && (taskData?.categoryName === 'Evidence' || taskData?.categoryName === 'Policy') ? <div className="col-md-6 col-sm-12">
                                <div className="mb-4">
                                    <label htmlFor="upload-policy" className="form-label font-medium font-14 primary-textcolor">Upload {`${taskData?.categoryName}`}</label>
                                    {documentDetails?.length != 0 ?
                                        <div>{bindUploadedDocument()}</div> :
                                        uploadedDocument.length == 0 && !documentDetails[0] ? <><span className="upload-btn-wrapper me-2 d-block cursor-pointer ">
                                            <button type="button" className="cust-filter-btn font-14  font-regular primary-color upload-btn "><img src="images/upload.svg" alt="browse"
                                                className="me-2" />Upload</button>
                                            <input type="file" name="myfile" className="cursor-pointer upload-btn-input" multiple={taskData?.categoryName === 'Policy' ? false : true}
                                                onChange={(e) => { setUploadedDocument(Array.from(e.target.files)); setIsSaveTask(true); }} />
                                        </span>
                                            <span className="required font-12">{isDocumentSizeExceed ? isDocumentSizeExceed : ''}</span> </> : <div>
                                            {bindUploadedDocument()}
                                        </div>
                                    }
                                </div>
                            </div> : <></>}
                            {((taskData?.categoryName === 'Compliance' || taskData?.categoryName === 'Risk') && !taskData?.taskId?.includes("-")) && userId == taskData?.assignee ? <div>
                                <div className="mt-5 mb-4">
                                    <div className="col-md-12 col-sm-12">
                                        {taskData?.categoryName === 'Compliance' && !subtaskDetails.length && taskData.taskStatus == 'In Progress' ? <div className="col-md-12 col-sm-12">
                                            <div id="liveToast" className="toast create-subtask-toast show mb-4" role="alert" aria-live="assertive" aria-atomic="true">
                                                <div className="toast-body py-2">
                                                    <p className="font-regular font-14 me-2 mb-0">Create a sub-task to assign individual policies or evidences</p>
                                                </div>
                                            </div>
                                        </div> : <></>}
                                        <div className="d-flex justify-content-between mb-4">
                                            {subtaskDetails.length > 0 ? <h5 className="font-18 font-bold text-black mt-3">Subtasks</h5> : <></>}
                                            {taskData?.taskStatus == 'In Progress' ? <button className="subtask-btn d-flex align-items-center font-13 font-medium"
                                                type="button" aria-controls="subtaskPopup" aria-expanded="true" onClick={() => {
                                                    setIsCreateSubtask(true);
                                                }}>
                                                <img src="images/plus-icon-2.svg" alt="add-icon" className="filt-icon me-2" />Create a subtask
                                            </button> : <></>}
                                        </div>
                                    </div>
                                    {subtaskDetails.length > 0 ? <div className="col-md-12 col-sm-12">
                                        {bindSubtaskDetails()}
                                    </div> : <></>}
                                </div>
                            </div> : <></>}
                            {taskData?.campaignName != 'Compliance' && isSaveTask ? <div className="text-end mt-4 mb-2">
                                <button type="button" className="primary-btn font-14 font-medium me-2"
                                    onClick={() => {
                                        setIsCreateTask(false);
                                        setIsUpdateTask(false);
                                        setIsViewTask(false);
                                    }}>
                                    Cancel
                                </button>
                                <button type="button" className="dark-btn font-14 font-medium px-4 cursor-pointer" onClick={() => { saveTask(); }}>
                                    Save
                                </button>
                            </div> : <></>}
                            {isViewTask && documentDetails?.length > 0 && taskData?.taskStatus != 'Closed'
                                && (taskData?.categoryName == 'Policy' || taskData?.categoryName == 'Evidence') && taskData?.raisedUserId == userId ? <div className="text-end mt-4 mb-2">
                                <button type="button" className="green-btn font-14 font-medium me-2"
                                    onClick={() => {
                                        handleApprove()
                                    }}>
                                    Approve 
                                    {/* {taskData?.taskStatus} */}
                                </button>
                            </div> : <></>}
                            <h5 className="font-16 font-medium text-black mt-3 mb-4">Comments</h5>
                            <div className="col-md-12 col-sm-12 comment-input">
                                <div className="d-flex mb-3">
                                    <img src={profileUrl} className="comment-prof me-3" />
                                    <input type="text" className="form-control custom-form-control theme-form font-regular font-12 custom-comment"
                                        id="ticketTitle" placeholder="Add a comment" name='comments' maxLength={200} value={taskComments} onChange={(e) => {
                                            setTaskComments(e.target.value);
                                            e.target.value ? setValidateTaskComments(false) : setValidateTaskComments(true);
                                        }}
                                        onKeyDown={(e)=> e.key === 'Enter' ? sendComments() : null} />
                                    <button className="bg-transparent border-0" onClick={() => {
                                        sendComments();
                                    }}>
                                        <img src="images/message-send-icon.svg" className="comment-prof me-3" />
                                    </button>
                                    <span className="required font-12">{validateTaskComments ? 'Please add comments' : ''}</span>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                {bindTaskComments()}
                            </div>
                        </div>
                    </div> : <></>
                }
                {
                    isCreateSubtask ? <SubtaskForm
                        props={{
                            taskData: taskData,
                            isCreateSubtask: isCreateSubtask,
                            setIsCreateSubtask: setIsCreateSubtask,
                            setViewTaskId: setViewTaskId,
                            setIsSuccessToastMessage: setIsSuccessToastMessage,
                            toastMessage: toastMessage,
                            setToastMessage, setToastMessage,
                            loadViewTask: loadViewTask,
                            onSubtask:HandleSubtask
                        }}
                    /> : <></>
                }
                <SuccessToastMessage props={{
                    isSuccessToastMessage: isSuccessToastMessage,
                    setIsSuccessToastMessage: setIsSuccessToastMessage,
                    toastMessage: toastMessage,
                    setToastMessage: setToastMessage
                }} />

                {
                    isAlreadyAssigned ? <TaskAlertpopup props={
                        {
                            isAlreadyAssigned: isAlreadyAssigned,
                            setIsAlreadyAssigned: setIsAlreadyAssigned,
                            saveTask: saveTask,
                            controlDetails: taskDynamicData?.taskSource?.filter(item => { return item.sourceId == taskData.sourceId }),
                            type: taskDynamicData?.taskType?.filter(item => { return item.taskCategoryId == taskData.taskTypeId })
                        }
                    } /> : <></>
                }

                {isCreateSubtask ? <div class="modal-backdrop fade show"></div> : <></>}
            </div >
        </>
    )
}