import { useMsal } from "@azure/msal-react";
import * as SignalR from "@microsoft/signalr";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IdleTimer from "../../Configs/idleTimer";
import { agovContext, encryptStorage, notificationLogs, signalRAzureFunc } from "../../Constant";
import { useProfile } from '../../ProfileContext';
import { getNotifications, readNotifications } from "../../Services/Admin/AdminService";
import { getUserProfile } from '../../Services/Agov/governanceService';
import profilepicture from '../HeaderComponent/user-icon.png';
import NotificationLoader from "./NotificationLoader";
import NoNotificationFound from "./NoNotificationFound";

export default function HeaderComponent() {
  const { profilePicture: profilePictureFromContext, setProfilePicture } = useProfile();
  
  const { roles, userId, profileUrl } = useContext(agovContext);

  // HANDLES USERS AUTHENTICATION IN AZURE AD
  const { instance } = useMsal();

  const location = useLocation();
  const navigate = useNavigate();

  const [hidetabs, setHideTabs] = useState(false);
  const [timeoutPopup, setTimeoutPopup] = useState(true);
  const [selectedPath, setSelectedPath] = useState("");
  const [userConnectionId, setUserConnectionId] = useState(null);
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [count, setCount] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);
  const [clickedNotifications, setClickedNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let initialAllReadValue;
  try {
    initialAllReadValue = JSON.parse(encryptStorage?.getItem('allRead'));
  } catch (e) {
    initialAllReadValue = false;
  }

  const [allRead, setAllRead] = useState(true);

  const hasRole = (rolesToCheck) => {
    for (const role of rolesToCheck) {
      if (roles.includes(role)) {
        return true;
      }
    }
    return false;
  };

  const handleSignOut = async () => {
    try {
      setHideTabs(true);
      navigate("/azure");
      await instance.logoutPopup();
      sessionStorage.clear();
      localStorage.clear();
      encryptStorage.clear();
    } catch (error) {
      console.error("Sign-out error:", error);
    }
  };

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if(userId) {
        const requestBody = {
          user_id: userId
        }
        try {
          const fetchPicture = await getUserProfile(requestBody);
          if(fetchPicture?.data && fetchPicture.data[0]) {
          setProfilePicture(fetchPicture?.data[0].profilePicture);
          }
        } catch (error) {
        //console.log('ERROR IN FETCHING PROFILE PICTURE: ', error);
      }}
  };

    fetchProfilePicture();
  }, [userId]); 

  useEffect(() => {
    if(userId) {
      loadNotification();
    }
  }, [userId, allRead])

  useEffect(() => {
    encryptStorage?.setItem('allRead', allRead);
  }, [allRead])

  useEffect(() => {
    const pathname = location.pathname;
    const selectPath = pathname.split("/");
    setSelectedPath(selectPath[1]);
    const timer = new IdleTimer({
      // EXPIRES AFTER 30 MINUTES
      timeout: 1800,
      onTimeout: () => {
        setTimeoutPopup(false);
        sessionStorage.clear();
        encryptStorage.clear();
        localStorage.clear();
        console.log("Session expired");
        // console.log("EXPIRED");
      },
      onExpired: () => { 
        console.log("Session was already expired");

        setTimeoutPopup(false);
        sessionStorage.clear();
        encryptStorage.clear();
        localStorage.clear();
      },
      });
    return () => {
      timer.cleanUp();
    };
  }, [location]);

  const handleNotificationClick = async () => {
    setShowPopUp(true);
  };
  
  const loadNotification = async () => {
    try {
      setIsLoading(true);

      const connection = new SignalR.HubConnectionBuilder()
        .withUrl(signalRAzureFunc)
        .withAutomaticReconnect([0, 1000, 5000, null])
        .configureLogging(SignalR.LogLevel.Information)
        .build();

      await connection.start();
      const connectionId = connection.connectionId;
      setUserConnectionId(connectionId);

      connection.on("newMessage", async (message) => {
        //console.log('NEW MESSAGE: ', message);
        try {
          setIsLoading(true);
          const response = await getNotifications({connectionId, userId});
          if (response.statusCode === 200) {
            setNotificationDetails(response.responseData.recordsets[0]);
            setCount(response.responseData.recordsets[0].length);
            let findisnotification = 1;
            response.responseData.recordset.map((item)=>{
                    item.is_active == 1 ? findisnotification = 0 : console.log("all read first");
                })
                if(findisnotification == 0)
                  {
                    setAllRead(false)
                  }
                
          } else {
            console.error('FAILED TO FETCH NOTIFICATIONS: ', response.statusCode);
          }
        } catch (error) {
          console.error('ERROR FETCHING NOTIFICATIONS: ', error);
        } finally {
          setIsLoading(false);
        }
      });

      connection.onclose(() => {
        //console.log('CONNECTION CLOSED');
      });

      const initialResponse = await getNotifications({connectionId, userId});
      if (initialResponse.statusCode === 200) {
        setNotificationDetails(initialResponse.responseData.recordsets[0]);
        setCount(initialResponse.responseData.recordsets[0].length);
                let findisnotification = 1;
                initialResponse.responseData.recordset.map((item)=>{
                    item.is_active == 1 ? findisnotification = 0 : console.log("all read second");
                })
                if(findisnotification == 0)
                  {
                    setAllRead(false)
                  }
                
      }
    } catch (error) {
      console.error("ERROR IN LOADING NOTIFICATION: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (notificationId, taskId, event) => {
    viewNotification(notificationId);

    navigate("/taskmanagement", {
      state: {
        viewTaskId: taskId,
        event,
      },
    });

    if (!clickedNotifications.includes(notificationId)) {
      setClickedNotifications([...clickedNotifications, notificationId]);
    }
  };

  function splitAndCapitalize(str) {
    str = str?.replace(/([A-Z])/g, ' $1');
    str = str?.charAt(0).toUpperCase() + str?.slice(1); 
    return str?.trim();
  }

  const bindNotification = () => {
    return notificationDetails.map((notification) => {
      // //console.log('NOTIFICATION: ', notification);
      const date = new Date(notification?.created_on || notification?.date_time);

      const formattedTime = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
     
      const key = (notification?.category_name === 'Compliance' && notification?.request_for === 'control created')   ? `${notification?.category_name} ${notification?.request_for}`   : `${notification?.category_name || notification?.category} ${notification?.notification_title || notification?.event_description}`;
      // //console.log('KEY: ', key);

      const descriptionTemplate = notificationLogs[key];
      // //console.log('DESCRIPTION TEMPLATE: ', descriptionTemplate);

      const description = descriptionTemplate
        ?.replace("<userName>", (notification?.employee_name || notification?.applied_on))
        ?.replace("<requestFor>", (notification?.request_for || notification?.event_description))
        ?.replace("<createdBy>", (notification?.created_by || notification?.applied_by))
        ?.replace("<applicationName>", notification?.application_name)
        ?.replace("<evidenceDocumentName>", notification?.request_for)
        ?.replace("<policyDocumentName>", notification?.request_for)
        ?.replace("<controlName>", notification?.notification_title)
        ?.replace("<appAdmin>", notification?.applied_by)
        ?.replace("<applicationName>", notification?.application_name)
      ;
      // //console.log('DESCRIPTION: ', description);
   
      return (
        <li className="px-3 py-3">
          <div
            className="d-flex align-items-center"
            onClick={() => {
              handleClick(
                notification?.notification_id || notification?.id,
                notification?.redirection_path,
                notification?.event
              );
            }}
          >
            <span className={`dot ${clickedNotifications.includes(notification?.notification_id || notification?.id) || (notification?.is_active === 0) || allRead ? 'grey-dot' : 'blue-dot'} me-2`}
            style={{ width: '4px', height: '4px', display: 'inline-block', borderRadius: '50%', flexShrink: '0' }} />
            <img src={profileUrl} alt="sample-icon" className="notification-prof"/>
            <div className="ms-3">
              <p className="font-12 primary-textcolor font-regular mb-1">
                <p className="font-10 font-regular d-flex align-items-center grey-light mb-0">
                  {description}
                </p>
              </p>
              <p className="font-10 font-regular d-flex align-items-center grey-light mb-0">
                {formattedTime}
                <span className="dot grey-dot me-2 ms-2" style={{ width: '4px', height: '4px', display: 'inline-block', borderRadius: '50%', flexShrink: '0' }}/>
                <span className="grey-light">{notification?.category_name ?? (notification?.category ? splitAndCapitalize(notification?.category) : "")}</span>
              </p>
            </div>
          </div>
        </li>
      );
    });
  };

  const viewNotification = async (notificationId = null, allRead) => {
    try {
      const data = {
        userId: userId,
        notificationId: notificationId,
        allRead: allRead,
      };

      let response = await readNotifications(data);
      if (response.statusCode === 200) {
        //console.log("SUCCESSFULLY VIEWED NOTIFICATION");
        let findisnotification = 1;
                response.responseData.recordset.map((item)=>{
                    item.is_active == 1 ? findisnotification = 0 : console.log("all read third");
                })
                if(findisnotification)
                  {
                    setAllRead(true)
                  }
      }
    } catch (error) {
      console.error("ERROR VIEWING NOTIFICATION: ", error);
    }
  };

  return (
    <>
      <div
        hidden={
          window.location.pathname !== "/" &&
            window.location.pathname !== "/azure" &&
            window.location.pathname !== "/config" &&
            window.location.pathname !== "/error" &&
            roles.length
            ? false
            : true
        }
      >
        <nav style={{ zIndex: 999 }} className="navbar navbar-expand-lg border-bottom bg-white py-0 px-3 position-fixed top-0 w-100">
          <div className="container-fluid">
            <a className="navbar-brand d-lg-flex d-none" style={{ cursor: "pointer" }}>
              <img
                className="logo"
                src="/images/agov-logo.svg"
                alt="Logo"
                onClick={() => roles.includes('Super Admin') || roles.includes('Manager') || roles.includes('User') || roles.includes('LMS Admin') || roles.includes('GRC Admin') || roles.includes('SOC Admin') ? navigate("/dashboard") : console.log("NOT ALLOWED TO DASHBOARD")}
              />
            </a>
            <div className="justify-content-between align-items-center word-break d-flex d-lg-none flex-row w-100 py-2">
              <div>
                <button
                  className="navbar-toggler px-0 float-start border-0 px-3 bg-white cust-mobile-notification"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand" style={{ cursor: "pointer" }}>
                  <img className="logo" src="/images/agov-logo.svg" alt="Logo"/>
                </a>
              </div>
              <div className="d-lg-none d-flex flex-row  align-items-center ">
                <div className="dropdown nav-item-link">
                  <button
                    className="btn dropdown-toggle btn-profile color-black lato-semibold font-16"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="profile-image"
                      src={profileUrl === '' || profileUrl === null || profileUrl === undefined ? profilepicture : profilePictureFromContext}
                      alt="profile image"
                    />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menuitems shadow-sm" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a className="dropdown-item font-regular font-14" style={{ cursor: "pointer" }} onClick={() => navigate("/userprofile")}>
                        <img src="/images/user-profile-icon.svg" alt="user-profile-icon"/>
                        User Profile
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item font-regular font-14 red-textcolor" style={{ cursor: "pointer" }}>
                        <img className src="/images/logout-icon.svg" alt="logout-icon"/>
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="collapse font-14 navbar-collapse mt-1 ms-3" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {hasRole(["Super Admin", "Manager", "User","SOC Admin","LMS Admin","GRC Admin"]) ? (
                  <li className="nav-item">
                    <a className={selectedPath === "dashboard"
                        ? "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2 active"
                        : "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/dashboard")}
                    >
                      Dashboard
                    </a>
                  </li>
                ) : null}
                {hasRole(["Super Admin", "App Admin", "Manager", "SOC Admin"]) ? (
                  <li className="nav-item">
                    <a className={selectedPath === "accessGovernance"
                        ? "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2 active"
                        : "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/accessGovernance")}
                    >
                      Access Governance
                    </a>
                  </li>
                ) : null}
                {hasRole(["Auditor"]) && !hasRole(["Manager", "App Admin", "Super Admin", "SOC Admin", "GRC Admin", "LMS Admin", "User"]) ? null : (
                  <li className="nav-item">
                    <a className={selectedPath === "lmsmanagement"
                      ? "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2 active"
                      : "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        hasRole(["LMS Admin", "Super Admin"]) 
                          ? "/lmsmanagement" 
                          : "/lmsmanagement/myLearnings"
                      );
                    }}
                    >
                      LMS
                    </a>
                  </li>
                )}
                {hasRole(["Super Admin", "GRC Admin", "Auditor"]) ? (
                  <li className="nav-item">
                    <a className={selectedPath === "compliance"
                          ? "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2 active"
                          : "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/compliance")}
                    >
                      Compliance
                    </a>
                  </li>
                ) : null}
                {hasRole(["SOC Admin", "Super Admin"]) ? (
                  <li className="nav-item">
                    <a className={selectedPath === "riskManagement"
                          ? "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2 active"
                          : "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/riskManagement")}
                    >
                      Risk Management
                    </a>
                  </li>
                ) : null}
                {hasRole(["Super Admin","LMS Admin","User","Manager","GRC Admin","SOC Admin"]) ? (
                  <li className="nav-item">
                    <a className={selectedPath === "task"
                          ? "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2 active"
                          : "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/taskmanagement");
                      }}
                    >
                      Tasks
                    </a>
                  </li>
                ) : null}
                {hasRole(["SOC Admin", "Super Admin", "App Admin", "Manager", "GRC Admin", "LMS Admin"]) ? (
                  <li className="nav-item">
                    <a className={selectedPath === "activitylogs"
                          ? "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2 active"
                          : "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/activitylogs");
                      }}
                    >
                      Reports
                    </a>
                  </li>
                ) : null}
                {hasRole(["Super Admin"]) ? (
                  <li className="nav-item">
                    <a className={selectedPath === "admin"
                          ? "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2 active"
                          : "nav-link font-14 font-medium py-3 px-0 mx-3 top-nav-link mobile-p-2"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/adminmanagement");
                      }}
                    >
                      Admin
                    </a>
                  </li>
                ) : null}
              </ul>
              <div className="d-lg-flex d-none flex-row  align-items-center ">
                {hasRole(["SOC Admin", "Super Admin", "app Admin", "Manager", "GRC Admin", "LMS Admin", "User"]) ? (
                  <div className="btn-group me-3 cursor-pointer position-relative">
                    <img
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      src="/images/notification-icon.svg"
                      alt="notification-icon"
                      className="header-profile position-relative"
                      onClick={() => {
                        handleNotificationClick();
                      }}
                    />
                    {count > 0 && !allRead ? (
                      <span className="position-absolute p-1 bg-danger rounded-circle dot-styling" />
                    ) : (
                      <span className="position-absolute p-1 bg-black rounded-circle dot-styling" />
                    )}
                    <span className="dropdown-menu notification-container shadow-sm border-0">
                      <div className="d-flex justify-content-between align-items-center px-4 py-1">
                        <span className=" width d-flex justify-content-between primary-textcolor font-bold font-16">
                          Notifications
                        </span>
                        <span
                          className="cursor-pointer font-12 font-medium secondary-textcolor"
                          onClick={() => {
                            setAllRead(true);
                            viewNotification(null, true);
                          }}
                          disabled={isLoading || allRead}
                          style={{
                            cursor: isLoading || allRead ? "not-allowed" : "pointer",
                            filter: isLoading || allRead ? "grayscale(80%)" : "none",
                            opacity: isLoading || allRead ? 0.5 : 1,
                          }}
                        >
                          Mark all as read
                        </span>
                      </div>
                      <ul className="list-unstyled my-4 mt-0 stack-table-scroll">
                        {isLoading ? (
                          <NotificationLoader />
                        ) : !notificationDetails || notificationDetails.length === 0 ? ( 
                          <NoNotificationFound data="No Notification Found" />
                         ) : (
                          bindNotification()
                        )}
                      </ul>
                    </span>
                  </div>
                ) : null}
                <div className="dropdown nav-item-link d-none d-lg-block" hidden={hidetabs}>
                  <button
                    className="btn dropdown-toggle btn-profile color-black lato-semibold font-16"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="profile-image"
                      src={profileUrl === '' || profileUrl === null || profileUrl === undefined ? profilepicture : profilePictureFromContext}
                      alt="profile pic"
                    />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menuitems shadow-sm" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a className="dropdown-item font-regular font-14"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if(location.pathname !== "/userprofile") {
                            navigate("/userprofile");
                          }
                        }}
                      >
                        <img src="/images/user-profile-icon.svg" alt="user-profile-icon"/>
                        User Profile
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item font-regular font-14 red-textcolor"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleSignOut();
                        }}
                      >
                        <img src="/images/logout-icon.svg" alt="logout-icon"/>
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div hidden={timeoutPopup} className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
        <div className="modal-dialog" hidden={hidetabs}>
          <div className="modal-content adjust-alert">
            <div className="text-center"></div>
            <div className="modal-body">
              <div className="col-md-12 mt-5 text-center">
                <img className="alert-pop" src="/images/delete-popup-icon.svg" alt="alert"/>
                <h5 className="alert mb-0">Alert!</h5>
              </div>
              <div className="col-md-12 text-center">
                <span className="alert-ms">
                  Your session has been Timed Out
                </span>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div className="col-md-12 mb-3 text-center">
                <a className="btn btn-primary mx-2 Revoke-btn" data-bs-dismiss="modal" onClick={() => handleSignOut()}>
                  Ok
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}