// PC_COM_RES_4 to PC_COM_RES_7 -> Import statements
import { useEffect, useState } from "react"
import { responsibilityOverViewApiResponse } from "../../Services/GRC/GrcService"
import { ProgressBarComponent } from "./progressBar"
import { PopUpComponent } from "./popUpComponent"
import { ResponsibilityPopUp } from "./ResponsibilityPopUp"
import NoRecordFound from "./dashboardNoRecordFound"
import { Loader } from "../Loader/Loader"
import { useNavigate } from "react-router-dom"

// PC_COM_RES_16 -> Component declaration
export function ResponsibilityOverView({ data }) {

    // PC_COM_RES_17 to PC_COM_RES_18 -> State variables creation
    const [respPayload, setRespPayload] = useState({
        frameworkId: 1,
        startDate: data.startDate,
        endDate: data.endDate,
        identity: 'Control',
        loadMore: 5
    })
    const [responsibilityOverViewData, setResponsibilityOverViewData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const navigate = useNavigate()

    // PC_COM_RES_19 to PC_COM_RES_20 -> useEffect declaration
    useEffect(() => {
        setRespPayload(prevPayload => ({
            ...prevPayload,
            startDate: data.startDate,
            endDate: data.endDate
        }));
    }, [data]);

    useEffect(() => {
        responsibility();
    }, [respPayload]);

    // PC_COM_RES_21 -> function declaration
    const responsibility = async () => {
        try {
            // PC_COM_RES_22 -> set state a variable isLoading and api call
            setIsLoading(true);
            //console.log(respPayload,"IN ResponseOverview")
            const result = await responsibilityOverViewApiResponse(respPayload)

            // PC_COM_RES_33 to PC_COM_RES_34 -> state variable updation whether statusCode is 200
            if (result.statusCode === 200) {
                //console.log(result?.responseData, "result.responseData");
                setResponsibilityOverViewData(result?.responseData)
                //console.log(result?.totalCount?.length, "total count in resp page")
                setTotalCount(result?.totalCount)
            }

            // PC_COM_RES_34 -> navigate to error page when statusCode in not equals to 200
            else {
                navigate('/error')
            }

            // PC_COM_RES_35 -> set state a variable isLoading
            setIsLoading(false);
        }

        // PC_COM_RES_36 -> navigate to error page
        catch (error) {
            navigate('/error');
        }
    }

    // PC_COM_RES_41 to PC_COM_RES_43 -> update a state variable respPayload while user changes an option
    const updateDropdownData = (event) => {
        setRespPayload(prevrespPayload => ({
            ...prevrespPayload,
            identity: event.target.value
        }))
    }

    // PC_COM_RES_46 to PC_COM_RES_52
    const togglePopUp = () => {
        setShowPopUp(!showPopUp)
    }

    // html part
    return (
        <div className="col-xl-4 col-lg-4 col-sm-12 col-md-12 mb-3">
            <div className="bg-white py-2 px-3 border-radius-8 three-col-widget rm-dashboard-card">
                <Loader isLoading={isLoading} />
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="font-14 font-medium primary-textcolor mb-0">
                        Responsibilities Overview</h3>
                    <div className="align-items-center d-flex gap-3">
                        <div className="d-flex">
                            <select className="form-select custom-form-control py-2 font-12 font-regular" onChange={(event) => { updateDropdownData(event) }}>
                                <option key='identity' value={'Control'}>Controls</option>
                                <option key='identity' value={'Policy'}>Policies</option>
                                <option key='identity' value={'Evidence'}>Evidences</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center">
                            {responsibilityOverViewData.length !== 0 && <img className="cursor-pointer" src="images/expand-icon.svg" alt="expand-icon" onClick={() => { togglePopUp() }} />}
                            {showPopUp && <ResponsibilityPopUp respData={responsibilityOverViewData} payload={respPayload} totalCount={totalCount} onClose={togglePopUp}></ResponsibilityPopUp>}
                        </div>
                    </div>
                </div>
                <div style={{height:"205px"}}>
                    {responsibilityOverViewData.length != 0 ? responsibilityOverViewData.map((ele, index) => index < 4  ? <ProgressBarComponent data={ele} /> : <></>) : <NoRecordFound data={"No " + respPayload.identity + " Assigned"}></NoRecordFound>}
                </div>
                {responsibilityOverViewData.length != 0 && <div className="align-items-center justify-content-center d-flex gap-2 mt-3" style={{height: "80px"}}>
                    <div className="align-items-baseline d-flex justify-content-between">
                        <span className="legends legend-completed me-1" />
                        <label className="me-2 font-12 grey-light">Completed</label>
                    </div>
                    <div className="align-items-baseline d-flex justify-content-between">
                        <span className="legends legend-medium me-1" />
                        <label className="me-2 font-12 grey-light">In
                            Progress</label>
                    </div>
                </div>}
            </div>
        </div>
    )
}