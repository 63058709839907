//PC_SR_1 to PC_SR_4
import React, { useState, useEffect, useContext } from 'react';
import { getRetentionConfig, insertRetentionConfig, updateRetentionConfig } from '../../Services/Admin/AdminService';
import { agovContext } from '../../Constant';
import { Loader } from '../Loader/Loader';

//PC_SR_5 to PC_SR_10
const SecurityRetentionConfig = () => {
  const { userId, employeeName, reportsToId } = useContext(agovContext);

  let retentionConfigObj = {
    retentionId: null,
    periodId: null,
    logRetentionPeriod: "",
    archiveDays:"",
    createdBy: employeeName,
    modifiedBy: employeeName,
    userId: userId,
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  };

  let retentionConfigErrorObj = {
    periodIdError: false,
    logRetentionPeriodError: false,
    archiveDaysError:false
  }

  const [logRetentionData, setLogRetentionData] = useState(retentionConfigObj);
  const [period, setPeriod] = useState([]);
  const [archiveDays, setArchiveDays]=useState("")
  const [errors, setErrors] = useState(retentionConfigErrorObj);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  //PC_SR_12 to PC_SR_15
  const handleInputChange = (e) => {

    const { name, value } = e.target;
    if (value.trim() !== '') {
      if (name === 'logRetentionPeriod') {
        setErrors((prevState) => ({
          ...prevState,
          logRetentionPeriodError: false,
        }));
      } else if (name === 'archiveDays') {
        setErrors((prevState) => ({
          ...prevState,
          archiveDaysError: false,
        }));
      }
    }
    if(name === 'logRetentionPeriod' && name ==='archiveDays'
     && isNaN(Number(value))) {
      return;
    }
    setLogRetentionData(prevState => ({
      ...prevState,
      [name]: value
    }));
    
  };
  //PC_SR_65 to PC_SR_72
  const validateLogRetentionConfig = () => {

    let isValid = true;
    let retentionConfigErrorObj = { ...errors };
    if (logRetentionData.logRetentionPeriod === "") {
      retentionConfigErrorObj.logRetentionPeriodError = true;
      isValid = false;
    }
    if (logRetentionData.periodId === "") {
      retentionConfigErrorObj.periodIdError = true;
      isValid = false;
    }
    if( logRetentionData.archiveDays > 30 ||  logRetentionData.archiveDays === "") {
      retentionConfigErrorObj.archiveDays = true;
      isValid = false;
  }
    setErrors(retentionConfigErrorObj);
    return isValid;
  }
  //PC_SR_58 to PC_SR_61
  const handleSave = () => {
    // //console.log(logRetentionData.retentionId,"handlesaveeeeee")
    if (logRetentionData.retentionId) {
      updateRetentionConfiguration();
    } else {
      insertRetentionConfiguration();
    }
  };
  //PC_SR_62 to PC_SR_65
  const handleCancel = () => {
    let retentionConfigObj = {
      periodId: "",
      logRetentionPeriod: "",
      archiveDays:""
    }
    setLogRetentionData(retentionConfigObj)
    setErrors(retentionConfigErrorObj)
  };

  function bindPeriod() {
    return (
      period.map((value, index) => {
        return (
          <option value={value.period_id}>{value.period_category}</option>
        )

      })
    )
  }
  
  //PC_SR_31 to PC_SR_44
  const fetchData = async () => {
    
    try {
      setIsLoading(true);
      let result = await getRetentionConfig();
      //console.log(result, "result of risk");
      if (result.statusCode === 200) {
        let editRetentionConfigObj = {
          retentionId: result.responseData.recordset[0].retention_id,
          periodId: result.responseData.recordset[0].period_id,
          logRetentionPeriod: result.responseData.recordset[0].log_retention_period,
          archiveDays:result.responseData.recordset[0].archive_days
        };
        setLogRetentionData(editRetentionConfigObj)
        setPeriod(result.responseData.recordsets[1]);
      setErrors(retentionConfigErrorObj)
        //console.log("RiskTask Configuration Information fetched successfully");
      } else {
        //console.log("Something went wrong!")
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  //PC_SR_19 to PC_SR_30
  const insertRetentionConfiguration = async () => {
    try {
      setIsLoading(true);
      if (validateLogRetentionConfig()) {
        const result = await insertRetentionConfig(logRetentionData);
        if (result.statusCode === 200) {
          //console.log("Log Retention Configuration Information inserted successfully");
        fetchData()
        } else {
          //console.log("Something went wrong!");
        }
      }
    }
    catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  //PC_SR_45 to PC_SR_57
  const updateRetentionConfiguration = async () => {
    try {
      setIsLoading(true);
      let retentionConfigObj = {
        retentionId: logRetentionData.retentionId,
        periodId: logRetentionData.periodId,
        logRetentionPeriod: logRetentionData.logRetentionPeriod,
        archiveDays:logRetentionData.archiveDays,
        createdBy: employeeName,
        modifiedBy: employeeName,
        userId: userId ? userId :'test123',
        managerId: reportsToId,
        appliedBy: employeeName,
        appliedOn: employeeName
      };
      setLogRetentionData(retentionConfigObj)
      
      if (validateLogRetentionConfig()) {
        let result = await updateRetentionConfig(retentionConfigObj);
        //console.log(result, "result of risk updation");
        if (result.statusCode === 200) {
          //console.log("RiskTask Configuration updated successfully");
        fetchData()
        } else {
          //console.log("Something went wrong!");
        }
        
      }
      else{
        //console.log("validation error ");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between mt-4 pt-2 align-items-center">
                <div className="col-md-8">
                  <h2 className="font-bold font-20 primary-textcolor">
                    Retention Config</h2>
                  <p className="font-14 grey-light mt-3">Configure log
                    retention settings within the application to
                    specify the duration and storage policies for
                    retaining system logs. Logs will be
                    automatically deleted after the specified
                    retention period.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="font-bold font-14 primary-textcolor mt-3" htmlFor="log-retention">Log
                Retention Period</label>
              <div className="col-md-12 d-flex align-items-center">
                <div className="col-md-6 d-flex gap-4">
                  <div className="col-md-7">
                    <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="log-retention"
                      name="logRetentionPeriod"
                      placeholder="Enter Days"
                      value={logRetentionData.logRetentionPeriod}
                      onChange={(e) => handleInputChange(e)} />
                    <h6>
                      {errors.logRetentionPeriodError? (
                        <span id="alertMessage" style={{color:"red", font:"small-caption"}}>
                          Enter log Retention Period
                        </span>) : null}
                    </h6>
                  </div>
                  <div className="col-md-4">
                    <select className="form-select form-control font-regular font-14 custom-form-control py-2" aria-label="Default select example"
                      id="log-retention"
                      name="periodId"
                      value={logRetentionData.periodId}
                      onChange={(e) => handleInputChange(e)}>
                      {bindPeriod()}
                      <h6>
                        {errors.periodIdError ? (
                          <span id="alertMessage" style={{color:"red", font:"small-caption"}}>
                            Choose Time Period
                          </span>) : null}
                      </h6>
                    </select>
                  </div>
                  
                </div>
                </div>
                <h2 className="font-bold font-20 primary-textcolor mt-4">
                    Archive</h2>
                    <p className="font-14 grey-light mt-3">Archived logs will be
                    stored in the database for a maximum of 30 days, as
                    specified by the user, before permanent deletion.
                    </p>
                    <div className="col-md-4">
                    <input type="text" className="form-control font-regular font-14 custom-form-control py-2 d-inline"
                     id="log-retention" placeholder="Enter Days" name="archiveDays"
                    value={logRetentionData.archiveDays}
                    onChange={(e)=> handleInputChange(e)}
                    />
                    <h6>
                      {errors.archiveDays ? (
                        <span id="alertMessage" style={{color:"red", font:"small-caption"}}>
                          Enter a archive days maximum of 30 days 
                        </span>) : null}
                    </h6>
                    </div>
            </div>
            {/* ends here  */}
            {/* log retention button  */}
            <div className="col-md-12 py-5">
              <div className="d-flex justify-content-end">
                <button type="button" className="primary-btn font-14 font-medium py-2 me-3  cursor-pointer" dismiss="modal" onClick={() => handleCancel()}>Cancel</button>
                <button type="button" className="btn dark-btn font-14 font-medium py-2  cursor-pointer" onClick={() => { handleSave() }}>Save</button>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  )
}

export { SecurityRetentionConfig }