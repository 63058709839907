//PC_AOC_1 to //PC_AOC_8
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAppOwnerConfig, insertAppOwnerConfig, bindEmailId, updateAppOwnerConfig, deleteAppOwnerConfig, getExportToExcelData } from '../../Services/Admin/AdminService';
import * as FileSaver from 'file-saver';
import { DeleteAdminPopUp } from './DeleteAdminPopUp';
import NoDataFound from './adminNoRecFound';
import { agovContext } from '../../Constant';
import { Loader } from '../Loader/Loader';
import ExcelJS from 'exceljs';
import Select from 'react-select';

//PC_AOC_9 to PC_AOC_35
const AppOwnerConfig = () => {
  const { userId, reportsToId, employeeName } = useContext(agovContext);

  let updateLoadMore = 10;
  let appOwnerDataObj = {
    employeeId: "",
    applicationId: "",
    createdBy: employeeName,
    userId: userId,
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  }

  const [applicationId, setApplicationId] = useState(null)

  let editConfigObj = {
    employeeId: "",
    applicationId: "",
    prevApplicationId: applicationId,
    userId: userId,
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  }

  let delObj = {
    appAdminId: "",
    userId: userId,
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  }
  const [appOwnerData, setAppOwnerData] = useState([]);
  const [orderColn, setOrderColn] = useState('employee_name ASC');
  const [count, setCount] = useState(0);
  const [loadMore, setLoadMore] = useState(updateLoadMore);
  const [searchText, setSearchText] = useState("");
  let appOwnerConfigObj = {
    sortColn: orderColn,
    searchValue: searchText,
    updateLoadMore: updateLoadMore
  }
  const [userNames, setUserNames] = useState([]);
  const [application, setApplication] = useState([]);
  const [appOwner, setAppOwner] = useState(appOwnerDataObj);
  const [updateAppOwner, setUpdateAppOwner] = useState(editConfigObj)
  const [appConfig, setAppConfig] = useState(appOwnerConfigObj);
  const [delPopUp, setDelPopUp] = useState(false);
  const [deleteData, setDeleteData] = useState(delObj)
  const [editEmployeeId, setEditEmployeeId] = useState(null)
  const [editApplicationId, setEditApplicationId] = useState(null)
  const [isEditing, setIsEditing] = useState(false);
  const [totalRec, setTotalRec] = useState(0)

  let appOwnerConfigErrorObj = {
    userNameError: false,
    applicationError: false
  }

  let appOwnerGridErrorObj = {
    userNameError: false,
    applicationError: false
  }

  const [errors, setErrors] = useState(appOwnerConfigErrorObj);
  const [mailId, setMailId] = useState('');
  const [mailRowId, setMailRowId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAppOwnerConfig();
  }, [orderColn, loadMore]);
  //PC_AOC_36 to PC_AOC_37
  const handleLoadMore = () => {
    setLoadMore(prevLoadMore => prevLoadMore + 10); // Increment loadMore by 5
  };
  //PC_AOC_38 to PC_AOC_39
  const handleSearch = () => {
    fetchAppOwnerConfig();
  };
  //PC_AOC_40 to //PC_AOC_42
  const handleEditClick = (value) => {
    setApplicationId(value.application_id)
    setEditEmployeeId(value.user_id);
    setEditApplicationId(value.application_id)
    setMailRowId(value.primary_email);

    const updatedAppOwnerDataError = appOwnerData.map(obj => {
      return { ...obj, userNameError: false, applicationError: false };
    });

    setAppOwnerData(updatedAppOwnerDataError);

    setIsEditing(true);
  };
  //PC_AOC_43 to //PC_AOC_44
  const handleTickClick = (index) => {
    // Perform actions for tick icon
    updateAppOwnerConfiguration(index);
  };
  //PC_AOC_45 to //PC_AOC_46
  const handleCrossClick = (index) => {
    // Perform actions for cross icon
    setIsEditing(false);

    let updatedAppOwnerData = [...appOwnerData];
    updatedAppOwnerData[index].userNameError = false;
    updatedAppOwnerData[index].applicationError = false;
    setAppOwnerData(updatedAppOwnerData);
  };
  //PC_AOC_47 to //PC_AOC_70
  const fetchAppOwnerConfig = async () => {
    let appOwnerConfigObj = appConfig
    appOwnerConfigObj.sortColn = orderColn
    appOwnerConfigObj.updateLoadMore = loadMore
    appOwnerConfigObj.searchValue = searchText
    try {
      setIsLoading(true);
      let result = await getAppOwnerConfig(appOwnerConfigObj);

      if (result.statusCode === 200) {
        let userName = result.responseData.employeeList;
        let application = result.responseData.applicationList;
        let gridData = result.responseData.gridData;

        let count = result.responseData.count[0].count;
        setAppOwnerData(gridData);
        setTotalRec(gridData.length)
        setUserNames(userName);
        setApplication(application);
        setCount(count)
      } else {

      }
    } catch (error) {

    }
    setIsLoading(false);
  };
  //PC_AOC_71 to //PC_AOC_85
  const fetchEmailId = async (id) => {
  
    try {
       
      setIsLoading(true);
      let result = await bindEmailId({ employeeId: id });
      if (result.statusCode === 200) {
        setMailId(result.responseData.recordset[0].primary_email);
      } else {

      }
    } catch (error) {
      console.error("Error fetching email ID:", error);
    }
    setIsLoading(false);
    //console.log(mailId,"poiuytrtyuioiuyhgjkiuyhgbjiuhy")
  };
   
  //console.log(mailId,"______________________________________")
  //PC_AOC_86 to //PC_AOC_100
  const fetchEmailRowId = async (id) => {
    try {
      setIsLoading(true);
      let result = await bindEmailId({ employeeId: id });
      if (result.statusCode === 200) {
        setMailRowId(result.responseData.recordset[0].primary_email);
      } else {

      }
    } catch (error) {
      console.error("Error fetching email ID:", error);
    }
    setIsLoading(false);
  };

  function bindUserName() {
    return userNames.map((value) => ({
      value: value.user_id,
      label: value.employee_name
    }));
  }

  function bindApplication(selectedAppId) {
    return (
      application.map((value, index) => {
        return (
          <option value={value.application_id} selected={value.application_id == selectedAppId ? true : false}
            name="applicationId">{value.application_name}</option>
        )
      })
    )
  }
  //PC_AOC_101 to //PC_AOC_104
  const handleOnChange = (selectedOption) => {
    // `selectedOption` is a single object
    const employeeId = selectedOption.value;

    // Update the state with the selected ID
    setAppOwner({ ...appOwner, employeeId });

    // Fetch email ID for the selected employee
    fetchEmailId(employeeId);
  };
  const handleOnChangeApplication = (e) => {
    const { name, value } = e.target;
  
    // Update the state with the selected application ID
    setAppOwner({ ...appOwner, [name]: value });
  };
  

  //PC_AOC_105 to //PC_AOC_108
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setUpdateAppOwner(prevState => ({
      ...prevState,
      employeeId: editEmployeeId,
      applicationId: value,
      prevApplicationId: applicationId
    }));
  };

  //PC_AOC_109 to //PC_AOC_111
  const handleDeletePopUp = (employeeId, applicationId, applicationName, userName) => {
    let delObj = {
      appAdminId: employeeId,
      employeeName: userName,
      applicationId: applicationId,
      applicationName: applicationName,
      userId: userId,
      managerId: reportsToId,
      appliedBy: employeeName,
      appliedOn: userName
    }
    setDeleteData(delObj)
    setDelPopUp(true);
  }
  //PC_AOC_112 to //PC_AOC_119
  const validateAppOwnerData = () => {
    let isValid = true;
    let appOwnerDataErrorObj = { ...errors };

    if (appOwner.employeeId === "") {
      appOwnerDataErrorObj.userNameError = true;
      isValid = false;
    }
    if (appOwner.applicationId === "") {
      appOwnerDataErrorObj.applicationError = true;
      isValid = false;
    }

    setErrors(appOwnerDataErrorObj);
    return isValid;
  };
  //PC_AOC_120 to //PC_AOC_127
  const validateGridData = (index) => {
    let isValid = true;
    let appOwnerGridErrorObj = [...appOwnerData];

    if (updateAppOwner.employeeId === "") {
      appOwnerGridErrorObj[index].userNameError = true;
      isValid = false;
    }
    if (updateAppOwner.applicationId === "") {
      appOwnerGridErrorObj[index].applicationError = true;
      isValid = false;
    }

    setAppOwnerData(appOwnerGridErrorObj);
    return isValid;
  };
  //PC_AOC_128 to //PC_AOC_143
  const insertAppOwner = async () => {

    try {
      setIsLoading(true);
      if (validateAppOwnerData()) {
        let result = await insertAppOwnerConfig(appOwner);
        if (result.statusCode == 200) {
          fetchAppOwnerConfig();
          setAppOwner(appOwnerDataObj)
          setMailId("")

        }
        else {

        }
      }
    }
    catch (error) {
      console.error('Error inserting app owner config:', error);
    }
    setIsLoading(false);
  };
  //PC_AOC_162 to //PC_AOC_177
  const updateAppOwnerConfiguration = async (index) => {
    try {
      setIsLoading(true);
      if (validateGridData(index)) {
        let result = await updateAppOwnerConfig(updateAppOwner);
        if (result.statusCode == 200) {
          setIsEditing(false);
          fetchAppOwnerConfig();
          setIsLoading(false)
        }
        else {

        }
      }
    }
    catch (error) {
      console.error('Error inserting app owner config:', error);
    }
    setIsLoading(false);
  };
  //PC_AOC_145 to //PC_AOC_160
  const deleteAppOwner = async (deleteData) => {
    try {
      setIsLoading(true);
      let result = await deleteAppOwnerConfig(deleteData);
      if (result.statusCode == 200) {
        fetchAppOwnerConfig();

      }
      else {

      }
    }
    catch (error) {
      console.log('Error inserting app owner config:', error);
    }
    setIsLoading(false);
  };
  //PC_AOC_178 to //PC_AOC_208
  const handleExportExcel = async () => {
    try {
      setIsLoading(true);
      let excelObj = {
        sortColn: orderColn,
        searchValue: searchText,
        userId: userId,
        managerId: reportsToId,
        appliedBy: employeeName,
        appliedOn: employeeName
      }
      let exportData = await getExportToExcelData(excelObj)
      exportData = exportData.responseData.recordsets[0];
      let excelData = exportData.map(data => [
        data.employee_name || '',
        data.primary_email || '',
        data.application_name || ''
      ]);
      let header = [['User Name', 'Email ID', 'Application Owner']]
      header = header.concat(excelData);
      let filename = "App_owner_config" + new Date().getFullYear() + '' + ((parseInt(new Date().getMonth()) + 1) < 10 ? '0' + (parseInt(new Date().getMonth()) + 1) : (parseInt(new Date().getMonth()) + 1)) + '' + (new Date().getDate() < 10 ? ('0' + new Date().getDate()) : new Date().getDate()) + ".xlsx";
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      let workbook = new ExcelJS.Workbook();
      let worksheet = workbook.addWorksheet('Application report');

      // Add header row
      worksheet.addRow(['User Name', 'Email ID', 'Application Owner']);

      // Add data rows
      excelData.forEach(data => {
        worksheet.addRow(data);
      });

      // Write workbook to buffer
      let buffer = await workbook.xlsx.writeBuffer();

      // Create Blob
      let data1 = new Blob([buffer], { type: fileType });

      // Save file
      FileSaver.saveAs(data1, filename);
    } catch (error) {
      console.log("Error in export excel:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function gridDynamicBind() {
    return (
      appOwnerData.map((value, index) => {
        return (
          <tr>
            <td className="font-12">
              <div className="d-flex align-items-center">
                {value.application_id === editApplicationId && isEditing ?
                  <select className="form-select cust-input-sty font-14 py-2 font-regular" aria-label="Default select example"
                    name="employeeId"
                    onChange={(e) => {
                      handleUpdate(e);
                       fetchEmailRowId(e.target.value)
                    }}>
                    <option value="">Select</option>
                    {bindUserName(value.user_id)}
                  </select> :
                  <>
                    <img src={value.profile_url} className="table-prof" />
                    <p className="font-13 font-medium secondary-textcolor mb-0 ms-2 cursor-pointer" onClick={() => {
                      navigate(`/userprofile`, { state: { value: { id: value.user_id, employeeid: value.employee_id } } })
                    }}>{value.employee_name}
                    </p>
                  </>
                }
              </div>
              <div>
                <h6 className="margin5Admin">
                  {value?.userNameError ? (
                    <span id="alertMessage" style={{ color: "red", font: "small-caption" }}>Choose UserName</span>
                  ) : null}
                </h6>
              </div>
            </td>
            <td className="font-13">{value.application_id === editApplicationId && isEditing ? mailRowId : value.primary_email}</td>
            <td className="font-13">
              {value.application_id === editApplicationId && isEditing ? (
                <select
                  className="form-select cust-input-sty font-14 py-2 font-regular"
                  aria-label="Default select example"
                  name="applicationId"
                  onChange={(e) => {
                    handleUpdate(e);
                  }}
                >
                  <option value="">Select</option>
                  {bindApplication(value.application_id)}
                </select>
              ) : (
                value.application_name
              )}
              <h6 className="margin5Admin">
                {value?.applicationError ? (
                  <span id="alertMessage" style={{ color: "red", font: "small-caption" }}>Choose Application</span>
                ) : null}
              </h6>
            </td>
            <td className="text-center">
              {value.application_id === editApplicationId && isEditing ? (
                <>
                  <a className="me-3" onClick={() => handleTickClick(index)}>
                    <img src="/images/tick-icon.svg" alt="tick-icon" />
                  </a>
                  <a className="me-3" onClick={() => handleCrossClick(index)}>
                    <img src="/images/cross-icon.svg" alt="cross-icon" />
                  </a>
                </>
              ) : (
                <a className="me-2" onClick={() => {
                  handleEditClick(value);
                }}>
                  <img src="/images/edit-icon.svg" alt="edit-icon" />
                </a>
              )}
              <a className="me-2"><img src="/images/delete-icon.svg" alt="edit-icon" onClick={() => {
                handleDeletePopUp(value.user_id, value.application_id, value.application_name, value.employee_name)
              }} /></a>
            </td>
          </tr>
        )
      }
      )
    )
  }
  const options = bindUserName();
  return (
    <>
      <div className="container-fluid mt-4 mt-custom">
        <div className="col-md-12">
          <div className="px-3 px-xl-5">
            <div className="d-flex justify-content-between mt-4">
              <h1 className="font-bold font-24 primary-textcolor">
                Admin Configurations
              </h1>
            </div>

            {/*tabs starts here*/}
            <div className="d-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style rounded-0"
                    id="information-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#information"
                    type="button"
                    role="tab"
                    aria-controls="information"
                    aria-selected="true"
                    onClick={() => navigate('/adminmanagement')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/information-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/information-icon.svg"
                        alt="information-icon"
                        className="me-2"
                      />
                    </span>
                    Information
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="permissions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#permissions"
                    type="button"
                    role="tab"
                    aria-controls="permissions"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/adminpermission')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/permission-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/permission-icon.svg"
                        alt="permissions-icon"
                        className="me-2"
                      />
                    </span>
                    Permissions
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="configurations-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#configurations"
                    type="button"
                    role="tab"
                    aria-controls="configurations"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/accessReview')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/key-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/key-icon.svg"
                        alt="configurations-icon"
                        className="me-2"
                      />
                      Configurations
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 active font-14 tab-style bold-text rounded-0"
                    id="app-owner-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#app-owner-config"
                    type="button"
                    role="tab"
                    aria-controls="app-owner-config"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/app-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/app-icon.svg"
                        alt="app-owner-config-icon"
                        className="me-2"
                      />
                      App Owner Config
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="security-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#security"
                    type="button"
                    role="tab"
                    aria-controls="security"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/securityConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/lock-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/lock-icon.svg"
                        alt="security-icon"
                        className="me-2"
                      />
                      Security
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="policy-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#policy-config"
                    type="button"
                    role="tab"
                    aria-controls="policy-config"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/policyConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/privacy-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/privacy-icon.svg"
                        alt="policy-config-icon"
                        className="me-2"
                      />
                      Policy Configuration
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="billing-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#billing"
                    type="button"
                    role="tab"
                    aria-controls="billing"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/billing')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/bill-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/bill-icon.svg"
                        alt="billing-icon"
                        className="me-2"
                      />
                      Billing
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            {/*tabs ends here*/}
            {/* App owner config tab starts here */}
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="font-medium font-24 primary-textcolor">
                  App Owner Config
                </h5>
                <p className="font-regular font-14 custom-grey-text">
                  Manage configurations of different applications used across the organization
                </p>
              </div>
              <div className="d-flex">
                <div className="input-group flex-nowrap search-group me-2">
                  <input type="search" className="form-control search-align"
                    placeholder="Search"
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    onKeyDownCapture={(e) => {
                      if (e.key === "Enter") {
                        handleSearch(e)
                      }
                    }}
                    onChange={(e) => { setSearchText(e.target.value) }}
                  />
                  <button className="input-group-text btn btn-search-custom" id="addon-wrapping">
                    <img src="/images/search-icon.svg" alt="search-icon" onClick={() => { handleSearch() }} />
                  </button>
                </div>
                <button className="advanced-filter-button medium-text customflip-btn  cursor-pointer" onClick={() => { handleExportExcel() }} type="button" id="dropdownMenuButton1" aria-expanded="false">
                  <img src="/images/excel-icon.svg" alt="Filter-icon" />
                </button>
              </div>
            </div>

            {/*App owner config table*/}
            <div className="table-responsive" style={{ overflowX: 'hidden' }} >
              <table className="table text-nowrap mb-0 custom-table-grid table-borderless mt-3">
                <thead>
                  <tr>
                    <th className="font-14 px-4 position-relative">Username
                      <span className="position-absolute">
                        <a className="sort-up position-absolute ms-2  cursor-pointer" onClick={() => setOrderColn("employee_name ASC")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2  cursor-pointer" onClick={() => setOrderColn("employee_name DESC")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                      </span>
                    </th>
                    <th className="font-14 position-relative">Email ID</th>
                    <th className="font-14 position-relative">Application
                      <span className="position-absolute">
                        <a className="sort-up position-absolute ms-2  cursor-pointer" onClick={() => setOrderColn("application_name ASC")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2  cursor-pointer" onClick={() => setOrderColn("application_name DESC")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                      </span>
                    </th>
                    <th className="font-14 position-relative text-center">Actions</th>
                  </tr>
                </thead>
                {count > 0 ? (
                  <tbody>
                    {/* Inline form  */}
                    <tr>
                      <td className="font-13">
                        <Select
                          options={options}
                          name="employeeId"
                          value={options.find(option => option.value === appOwner.employeeId)}
                          onChange={handleOnChange}
                          className="basic-single-select admin-container"
                          classNamePrefix="select"
                     
                        />
                        <h6 className="margin5Admin">
                          {errors.userNameError ? (
                            <div>
                              <span id="alertMessage" style={{ color: "red", font: "small-caption" }}>
                                Choose UserName
                              </span>
                            </div>) : null}
                        </h6>
                      </td>
                      <td className="font-13">
                        <input type="text" className="form-control custom-input-field" id="AdminInfoUrl" placeholder="Enter Email ID" value={mailId} disabled />
                      </td>
                      <td className="font-13">
                        <select className="form-select cust-input-sty font-14 py-2 font-regular" aria-label="Default select example"
                          name="applicationId"
                          value={appOwner.applicationId}
                          onChange={(e) => {
                            handleOnChangeApplication(e);
                          }}>
                          <option value="">Select</option>
                          {bindApplication()}
                        </select>
                        <h6 className="margin5Admin">
                          {errors.applicationError ? (
                            <span id="alertMessage" style={{ color: "red", font: "small-caption" }}>
                              Choose Application
                            </span>) : null}
                        </h6>
                      </td>
                      <td className="text-center">
                        <a className><img src="/images/plusicon-blue.svg" alt="Add-icon" onClick={() => insertAppOwner()} /></a>
                      </td>
                    </tr>
                    {gridDynamicBind()}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="font-13">
                        <Select
                          options={options}
                          name="employeeId"
                          value={options.find(option => option.value === appOwner.employeeId)}
                          onChange={handleOnChange}
                          className="basic-single-select"
                          classNamePrefix="select"
                        />
                        <h6 className="margin5Admin">
                          {errors.userNameError ? (
                            <div>
                              <span id="alertMessage" style={{ color: "red", font: "small-caption" }}>
                                Choose UserName
                              </span>
                            </div>) : null}
                        </h6>
                      </td>
                      <td className="font-13">
                        <input type="text" className="form-control custom-input-field" id="AdminInfoUrl" placeholder="Enter Email ID" value={mailId} disabled />
                      </td>
                      <td className="font-13">
                        <select className="form-select cust-input-sty font-14 py-2 font-regular" aria-label="Default select example"
                          name="applicationId"
                          value={appOwner.applicationId}
                          onChange={(e) => {
                            handleOnChangeApplication(e);
                          }}>
                          <option value="">Select</option>
                          {bindApplication()}
                        </select>
                        <h6 className="margin5Admin">
                          {errors.applicationError ? (
                            <span id="alertMessage" style={{ color: "red", font: "small-caption" }}>
                              Choose Application
                            </span>) : null}
                        </h6>
                      </td>
                      <td className="text-center">
                        <a className><img src="/images/plusicon-blue.svg" alt="Add-icon" onClick={() => insertAppOwner()} /></a>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        <NoDataFound />
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {
                delPopUp && (
                  <DeleteAdminPopUp handleDelete={deleteAppOwner} setDelPopUp={setDelPopUp} deleteData={deleteData} popUpHeader={"Remove User"}></DeleteAdminPopUp>
                )
              }
            </div>
            <div className="mt-3">
              <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{totalRec} </span>
                out of <span className="font-medium primary-textcolor">{count}</span> items</p>
            </div>
            <div className="text-center mt-5 mb-4">
              {totalRec < count && (
                <button className="primary-btn font-medium mb-5  cursor-pointer"
                  onClick={() => handleLoadMore()}>
                  Load More</button>
              )}
            </div>
            {/* App owner config table ends here */}
          </div>
        </div>
      </div >
      <Loader isLoading={isLoading} />
    </>
  )
}

export { AppOwnerConfig };