import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { agovContext } from '../../Constant';
import { getVideoDetails, getViewMediaDetails, postUserLmsProgress, trackUserLmsProgress } from '../../Services/LMS/LmsService';
import ReactPlayer from 'react-player';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Viewer, ZoomEvent, Worker } from '@react-pdf-viewer/core';
import ReadyQuizPopUp from './ReadyQuizPopUp';
import QuizQuestionsPopUp from './QuizQuestionsPopUp';
import QuizCompletionPopUp from './QuizCompletionPopUp';
import { Loader } from "../Loader/Loader";

const ViewMediaDetails = () => {

    /* PC_GVMDFE_NO_11 - PC_GVMDFE_NO_16
    * Initialzing const variables
    */
    const trackLmsUerProgressDetails = {
        'userId': "",
        'userName': "",
        'taskId': "",
        'campaignId': "",
        'courseId': "",
        'videoId': "",
        'videoWatchedProgress': 0
    };
    const videoEl = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, employeeName } = useContext(agovContext);
    const viewMediaConfig = location.state.value;


    /*
    * PC_GVMDFE_NO_16 - PC_GVMDFE_NO_26
    * Initialzing state variables
    */
    const [taskDetails, setTaskDetails] = useState({});
    const [viewMediaDetails, setViewMediaDetails] = useState({});
    const [seek, setSeek] = useState(true);
    const [mediaDetails, setMediaDetails] = useState([]);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [totalScoreResult, setTotalScoreResult] = useState(false);
    const [userLmsProgressTrackRequest, setUserLmsProgressTrackRequest] = useState(trackLmsUerProgressDetails);
    const [isReadyQuizPopupVisible, setIsReadyQuizPopupVisible] = useState(false);
    const [isQuizQuestionPopupVisible, setIsQuizQuestionPopupVisible] = useState(false);
    const [isQuizCompletionVisible, setIsQuizCompletionVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    /*
    * PC_GVMDFE_NO_27 - PC_GVMDFE_NO_28
    * Initialzing useEffect hook with empty dependencies
    */
    useEffect(() => {
        viewMediaPageLoad();
    }, []);

    /*
    * PC_GVMDFE_NO_29 - PC_GVMDFE_NO_63
    * Fetch all the videos on the initial page load.
    */
    const viewMediaPageLoad = async () => {
        setIsLoading(true);
        if (viewMediaConfig.taskDetailsId && viewMediaConfig.campaignId) {
            const req = {
                userId: userId,
                userName: employeeName,
                taskId: viewMediaConfig.taskDetailsId,
                campaignId: viewMediaConfig.campaignId,
                courseId: "",
                videoId: "",
                videoWatchedProgress: 0
            };
            setUserLmsProgressTrackRequest(req);

            const result = await trackUserLmsProgress(req);
            if (result.statusCode !== 200) {
                setIsLoading(false);
                navigate("/error");
            }
        }

        const mediaResults = await getViewMediaDetails(viewMediaConfig);
        if (mediaResults.statusCode !== 200) {
            setIsLoading(false);
            navigate("/error");
        }

        setTaskDetails(mediaResults.response.taskDetails);
        setViewMediaDetails(mediaResults.response.mediaDetails[0]);
        setMediaDetails(mediaResults.response.mediaDetails);
        setIsLoading(false);
    }

    /*
    * PC_GVMDFE_NO_65 - PC_GVMDFE_NO_67
    * Track the video watched progress when the video is paused
    */
    // const handlePause = async () => {
    //     if (taskDetails?.taskDetailsId && !viewMediaDetails.isVideoCompleted) {
    //         await handleTrackUserProgress();
    //     }
    // };

    /*
    * PC_GVMDFE_NO_87 - PC_GVMDFE_NO_91
    * Seek the video duration to the start when the video is dragged to the end of the video duration.
    */
    const seekPlayer = () => {
        if (seek && videoEl && videoEl.current) {
            videoEl.current.seekTo(0);
            setSeek(false);
        }
    };

    /*
    * PC_GVMDFE_NO_94 - PC_GVMDFE_NO_105
    * Track the video watched duration when the video is in progress
    */
    const handleProgress = (progress) => {
        if (videoEl.current) {
            const { playedSeconds } = progress;
            const totalDurationInSeconds = convertToSeconds(viewMediaDetails.videoTotalDuration);

            let watchedDurationPercentage = Math.round((playedSeconds / totalDurationInSeconds) * 100);

            setUserLmsProgressTrackRequest({ ...userLmsProgressTrackRequest, videoWatchedProgress: watchedDurationPercentage });
        }
    };

    /* 
    * PC_GVMDFE_NO_98 - PC_GVMDFE_NO_101
    * Function to convert time in the format "HH:MM:SS" to seconds
    */
    const convertToSeconds = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(":").map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    /*
    * PC_GVMDFE_NO_160 - PC_GVMDFE_NO_165
    * Handle if any one of the video is clicked to watch.
    */
    const handleMediaChange = async (media) => {
        setIsLoading(true);
        if (taskDetails?.taskDetailsId && !viewMediaDetails.isVideoCompleted) {
            await handleTrackUserProgress();
        }
        setIsLoading(false);
        setViewMediaDetails(media);
    };

    /*
    * PC_GVMDFE_NO_68 - PC_GVMDFE_NO_84
    * Track the video watched progress against the user
    */
    const handleTrackUserProgress = async () => {
        const videoCurrentProgress = Math.min(viewMediaDetails.isDocument ? 90 : Math.max(userLmsProgressTrackRequest.videoWatchedProgress, viewMediaDetails.videoWatchedProgress ?? 0), 90);

        const req = {
            userId: userId,
            userName: employeeName,
            taskId: taskDetails.taskDetailsId,
            campaignId: taskDetails.campaignId,
            courseId: taskDetails.courseId,
            videoId: viewMediaDetails.videoId,
            videoWatchedProgress: videoCurrentProgress
        };

        setUserLmsProgressTrackRequest(req);
        const result = await trackUserLmsProgress(req);

        if (result.statusCode !== 200) {
            navigate("/error");
        }
    }

    /*
    * PC_GVMDFE_NO_107 - PC_GVMDFE_NO_111
    * Handle if the user clicks on start quiz button.
    */
    const handleStartQuiz = async () => {
        setIsLoading(true);
        if (taskDetails?.taskDetailsId && !viewMediaDetails.isVideoCompleted) {
            await handleTrackUserProgress();
        }
        setIsLoading(false);
        setIsReadyQuizPopupVisible(true);
        setIsQuizCompletionVisible(false);
        setIsQuizQuestionPopupVisible(false);
    };

    /*
    * PC_GVMDFE_NO_113 - PC_GVMDFE_NO_132
    * Handle if user click on the start quiz button from the ready quiz popup.
    */
    const handleStartQuizQuestions = async () => {
        setIsReadyQuizPopupVisible(false);
        setIsLoading(true);
        const req = {
            videoId: viewMediaDetails.videoId,
            isShuffled: true
        };

        const quizQuestionResult = await getVideoDetails(req);
        if (quizQuestionResult.statusCode !== 200) {
            setIsLoading(false);
            navigate("/error");
        }
        setQuizQuestions(quizQuestionResult.response.questions);
        setIsLoading(false);
        setIsQuizQuestionPopupVisible(true);
    }

    /*
    * PC_GVMDFE_NO_134 - PC_GVMDFE_NO_158
    * Handle to post the user answered quiz completion status.
    */
    const handlePostQuizQuestions = async (totalScore) => {
        
        setIsQuizQuestionPopupVisible(false);
        setIsLoading(true);
        let isVideoCompleted = 0;
        let videoWatchedProgress = viewMediaDetails.videoWatchedProgress ?? 0;
        setTotalScoreResult({ obtainedScore: totalScore, isPass: false });
        if (totalScore >= viewMediaDetails.videoPassScore) {
            isVideoCompleted = 1;
            videoWatchedProgress = 100;
            setTotalScoreResult({ obtainedScore: totalScore, isPass: true });
        }

        const req = {
            userId: userId,
            userName: employeeName,
            taskDetailsId: taskDetails.taskDetailsId,
            campaignId: taskDetails.campaignId,
            campaignName: taskDetails.title,
            courseId: taskDetails.courseId,
            videoId: viewMediaDetails.videoId,
            videoWatchedProgress: videoWatchedProgress,
            numberOfAttempts: viewMediaDetails.numberOfAttempts + 1,
            userAnsweredScore: totalScore,
            isVideoCompleted: isVideoCompleted,
        };

        const result = await postUserLmsProgress(req);

        if (result.statusCode !== 200) {
            navigate("/error");
        }
        setIsLoading(false);
        setIsQuizCompletionVisible(true);
    };

    /*
    * PC_GVMDFE_NO_166 - PC_GVMDFE_NO_170
    * Generate the course videos details dynamically.
    */
    const generateCourseVideos = () => {
        return mediaDetails.map((media) => {
            return (
                <li className="mb-4 pointer" onClick={() => handleMediaChange(media)}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <span className="tips-thumnail d-flex">
                                <img
                                    src={media.videoThumbnail}
                                    className="w-100 image-cover"
                                    alt="sample-image"
                                />
                            </span>
                            <div className="ps-3 align-items-center align-middle">
                                <label className="font-medium font-14 primary-textcolor mb-2 d-block pointer">
                                    {media.videoName}
                                </label>
                                <span className="d-flex align-items-center">
                                    <img
                                        src="/images/timer-icon.svg"
                                        alt="timer-icon"
                                        className="me-1"
                                    />
                                    <span className="grey-light font-regular font-12">
                                        {formatVideoTime(media.videoTotalDuration)}
                                    </span>
                                </span>
                            </div>
                        </div>
                        {taskDetails.taskDetailsId ? <div className="wrapper">
                            <CircularProgressbar
                                className="counter"
                                value={media.videoWatchedProgress}
                                text={media.videoWatchedProgress ? media.videoWatchedProgress.toString() + "%" : "0 %"}
                                styles={buildStyles({
                                    textColor: "#000000",
                                    pathColor: "#31b14c",
                                    trailColor: "#f7f7f7"
                                })}
                            />
                        </div> : <></>}
                    </div>
                </li>
            )
        })
    }


    /*
    * PC_GVMDFE_NO_171 - PC_GVMDFE_NO_175
    * Format the video duration timing.
    */
    const formatVideoTime = (videoTime) => {
        const [hours, minutes, seconds] = videoTime.split(':');

        if (parseInt(hours) > 0) {
            return `${hours}:${minutes}`;
        } else {
            return `${minutes}:${seconds}`;
        }
    }

    /*
    * PC_GVMDFE_NO_176 - PC_GVMDFE_NO_183
    * Format the task due date
    */
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        return `${formattedMonth}/${formattedDay}/${year}`;
    };

    /*
    * PC_GVMDFE_NO_184 - PC_GVMDFE_NO_195
    * Format the total duration taken to complete the video timing.
    */
    const formatTime = (duration) => {
        let seconds = Math.abs(duration);
        let days = Math.floor(seconds / (24 * 3600));
        seconds %= (24 * 3600);
        let hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        let minutes = Math.floor(seconds / 60);
        seconds %= 60;

        if (days > 0) {
            return `${days} d ${hours} h`;
        } else if (hours > 0) {
            return `${hours} h ${minutes} mins`;
        } else {
            return `${minutes} mins`;
        }
    };

    const handleBackButton = async () => {
        setIsLoading(true);
        if (taskDetails?.taskDetailsId && !viewMediaDetails.isVideoCompleted) {
            await handleTrackUserProgress();
        }
        setIsLoading(false);
        navigate(-1);
    }


    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="container-fluid">
                <div className="col-md-12 px-5 my-5 mt-custom">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 my-3">
                            <h1 className="font-bold font-24 primary-textcolor mb-4">
                                <a className="text-decoration-none me-3 pointer" onClick={() => handleBackButton()}>
                                    <img src="/images/left-arrow.svg" alt="left-arrow" />
                                </a>
                                {taskDetails?.title ?? taskDetails?.courseName ?? viewMediaDetails?.videoName}
                            </h1>
                            <p className="font-14 font-regular data-color">
                                {taskDetails?.description ?? taskDetails?.courseName ?? ""}
                            </p>
                            <div className="d-block d-lg-flex justify-content-between">
                                <div className="d-flex">
                                    {taskDetails?.dueDate ? <span className="d-flex font-12 align-items-center me-3">
                                        <span>
                                            <img
                                                src="/images/calender-icon.svg"
                                                alt="calender-icon"
                                                className="me-2"
                                            />
                                        </span>
                                        <span className="font-regular grey-light me-1">Due Date:</span>
                                        <span className="font-medium primary-textcolor">{formatDate(taskDetails?.dueDate)}</span>
                                    </span> : <></>}
                                    {taskDetails.taskDetailsId && taskDetails?.numberOfAttempts ? <span className="d-flex font-12 align-items-center me-3">
                                        <span>
                                            <img
                                                src="/images/notes-icon.svg"
                                                alt="notes-icon"
                                                className="me-2"
                                            />
                                        </span>
                                        <span className="font-regular grey-light me-1">
                                            Number of attempts:
                                        </span>
                                        <span className="font-medium primary-textcolor">{taskDetails?.numberOfAttempts}</span>
                                    </span> : <></>}
                                    {taskDetails.taskDetailsId && taskDetails?.totalDurationTaken ? <span className="d-flex font-12 align-items-center me-3">
                                        <span>
                                            <img
                                                src="/images/clock-icon.svg"
                                                alt="clock-icon"
                                                className="me-2"
                                            />
                                        </span>
                                        <span className="font-regular grey-light me-1">
                                            Total Duration Taken:
                                        </span>
                                        <span className="font-medium primary-textcolor">{formatTime(taskDetails?.totalDurationTaken)}</span>
                                    </span> : <></>}
                                </div>
                                {taskDetails.taskDetailsId && !viewMediaDetails.isVideoCompleted ? <button
                                    type="button"
                                    className="font-14 font-medium dark-btn mt-3 mt-lg-0"
                                    onClick={() => handleStartQuiz()}
                                >
                                    <span>
                                        <img
                                            src="/images/quiz-icon.svg"
                                            alt="quiz-icon"
                                            className="me-2"
                                        />
                                    </span>
                                    Start Quiz
                                </button> : <></>}
                            </div>
                            {/* video player structure starts here */}
                            {!viewMediaDetails.isDocument ? <div className="my-4">
                                <ReactPlayer
                                    playsInline
                                    loop
                                    muted
                                    url={viewMediaDetails.videoBlobUrl}
                                    controls={true}
                                    onReady={seekPlayer}
                                    onProgress={handleProgress}
                                    // onPause={handlePause}
                                    ref={videoEl}
                                    style={{ width: "100%", height: "100%" }}
                                    className='react-player-width'
                                    config={{
                                        file: {
                                            attributes: {
                                                controlsList: 'nodownload',
                                            },
                                        },
                                    }}
                                />
                            </div> : <div className="my-4 p-5 c pdf-viewer">
                                <div className="pdf-view-scroll">
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                        <Viewer fileUrl={viewMediaDetails.videoBlobUrl} />
                                    </Worker>
                                </div>
                            </div>}
                            <h1 className="font-bold font-20 primary-textcolor mt-5 mb-4">
                                {viewMediaDetails.videoName}
                            </h1>
                            <p className="font-14 font-regular data-color">
                                {viewMediaDetails.videoDescription}
                            </p>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12  my-3">
                            <div className="border rounded-4 p-3 ">
                                <h1 className="font-bold font-20 primary-textcolor mt-1 mb-4">
                                    {taskDetails.courseName ?? "Media Library"}
                                </h1>
                                <ul className="list-unstyled">
                                    {generateCourseVideos()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Quiz Component */}
            {isReadyQuizPopupVisible && <ReadyQuizPopUp setIsReadyQuizVisisble={setIsReadyQuizPopupVisible} handleStartQuizQuestions={handleStartQuizQuestions}></ReadyQuizPopUp>}

            {/* Quiz Questions Component */}
            {isQuizQuestionPopupVisible && <QuizQuestionsPopUp setIsQuizQuestionsVisisble={setIsQuizQuestionPopupVisible} quizQuestions={quizQuestions} handlePostQuizQuestions={handlePostQuizQuestions}></QuizQuestionsPopUp>}

            {/* Quiz Completion Component */}
            {isQuizCompletionVisible && <QuizCompletionPopUp setIsQuizCompletionVisible={setIsQuizCompletionVisible} totalScoreResult={totalScoreResult}></QuizCompletionPopUp>}
        </>
    );
};

export default ViewMediaDetails;
