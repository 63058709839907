import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getOtherDocumentsGrid } from "../../Services/GRC/GrcService";
import ControlAuditStatus from "./ControlAuditStatus";
import { Loader } from "../Loader/Loader";
import NoDataFound from "../LMS/NoDataFound";
import RiskNoDataFound from "../Risk/RiskNoDataFound";
import { agovContext, encryptStorage } from '../../Constant';

const OtherDocumentsGrid = () => {
  // PC_OG_02
  // create the respective state variables
  const navigate = useNavigate();
  const location = useLocation();

  const [filterData, setFilterData] = useState({
    sortColumn: "od.modified_on",
    sortOrder: "desc",
    startDate: encryptStorage.getItem('commencer'),
    endDate: encryptStorage.getItem('fin'),
  });
  const [otherDocumentsGridData, setOtherDocumentsGridData] = useState([]);
  const [auditStatus, setAuditStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { roles } = useContext(agovContext);

  const isAdmin = roles.includes('Super Admin') || roles.includes('GRC Admin')


  let response;

  /**
 * PC_ED_05
 * The useEffect calls the API to get the data for the grid when ever the filterData changes
 */
  useEffect(() => {
    fetchOtherDocumentsGrid();
  }, [filterData]);

  /**
 * PC_ED_09
 * Get the grid data form the API and stores the data in the state variable
 */
  const fetchOtherDocumentsGrid = async () => {
    setIsLoading(true)
    try {
      response = await getOtherDocumentsGrid(filterData);
      setOtherDocumentsGridData(response.responseData.recordset);
    } catch (error) {
      //console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  };

  return (
    <div className="container-fluid mt-4 mt-custom">
      <Loader isLoading={isLoading} />
      <div className="col-md-12 px-lg-5">
        <div className="d-xl-flex d-block justify-content-between mt-4 mb-3">
          <h1 className="font-bold font-24 primary-textcolor d-flex  align-items-center mb-xl-0 mb-3">
            <a className="cursor-pointer" onClick={() => navigate(-1)}>
              <img src="/images/back-arrow.svg" alt="Back" className="me-3" />
            </a>{" "}
            <span>SOC 2</span>
          </h1>
          <div class="d-lg-flex d-block">
            <ControlAuditStatus setAuditStatus={setAuditStatus} isAuditor={!isAdmin} />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-12">
            {/*Compliance tab starts here*/}
            <div className="d-flex justify-content-between align-items-center ps-1 my-3 mb-4 custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item me-2" role="presentation">
                  <button
                    className="nav-link px-0 bold-text font-14 tab-style rounded-0"
                    id="Compliancecontrols-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Compliancecontrols"
                    type="button"
                    role="tab"
                    aria-controls="Compliancecontrols"
                    aria-selected="false"
                    onClick={() => navigate("/compliance/controls")}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/control-active-icon.svg"
                        className="me-2"
                      />{" "}
                      <img src="/images/control-icon.svg" className="me-2" />
                    </span>
                    Controls
                  </button>
                </li>
                <li className="nav-item ms-4 me-2" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="Compliancepolicies-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Compliancepolicies"
                    type="button"
                    role="tab"
                    aria-controls="Compliancepolicies"
                    aria-selected="false"
                    onClick={() => navigate('/compliance/policyGrid')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/policy-active-icon.svg"
                        className="me-2"
                      />{" "}
                      <img src="/images/policies-icon.svg" className="me-2" />{" "}
                    </span>
                    Policies
                  </button>
                </li>
                <li className="nav-item ms-4 me-2" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="Complianceevidences-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Complianceevidences"
                    type="button"
                    role="tab"
                    aria-controls="Complianceevidences"
                    aria-selected="false"
                    onClick={() => navigate('/compliance/evidenceGrid')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/evidence-active-icon.svg"
                        className="me-2"
                      />{" "}
                      <img src="/images/evidence-icon.svg" className="me-2" />{" "}
                    </span>
                    Evidences
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style active rounded-0"
                    id="Complianceotherdocs-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Complianceotherdocs"
                    type="button"
                    role="tab"
                    aria-controls="Complianceotherdocs"
                    aria-selected="true"
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/other-docs-active-icon.svg"
                        className="me-2"
                      />{" "}
                      <img src="/images/other-docs-icon.svg" className="me-2" />{" "}
                    </span>
                    Other Documents
                  </button>
                </li>
              </ul>
            </div>
            {/* Compliance tab ends here */}
          </div>
          <div className="col-md-12">
            <div>
              <div>
                <div className="col-md-12">
                  {/*Evidence table*/}
                  <h5 className="font-bold font-20 primary-textcolor">
                    Other Documents
                  </h5>
                  <div className="table-responsive">
                    {otherDocumentsGridData?.length ? <table className="table mb-0 custom-table-grid table-borderless mt-3">
                      <thead>
                        <tr>
                          <th className="font-14 px-4 position-relative">
                            Document Name
                            <span className="position-absolute">
                              <a
                                className="sort-up position-absolute ms-2 pointer"
                                onClick={() => setFilterData({ ...filterData, sortColumn: 'otherDocumentsName', sortOrder: 'asc' })}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </a>{" "}
                              <a
                                className="sort-down position-absolute ms-2 pointer"
                                onClick={() => setFilterData({ ...filterData, sortColumn: 'otherDocumentsName', sortOrder: 'desc' })}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </a>
                            </span>
                          </th>
                          <th className="font-14 position-relative">
                            Description
                          </th>
                          <th className="font-14 position-relative">
                            Last Uploaded
                            <span className="position-absolute">
                              <a
                                className="sort-up position-absolute ms-2 pointer"
                                onClick={() => setFilterData({ ...filterData, sortColumn: 'otherDocumentsUploadedOn', sortOrder: 'asc' })}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </a>{" "}
                              <a
                                className="sort-down position-absolute ms-2 pointer"
                                onClick={() => setFilterData({ ...filterData, sortColumn: 'otherDocumentsUploadedOn', sortOrder: 'desc' })}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </a>
                            </span>
                          </th>
                          <th className="font-14 position-relative">
                            Uploaded By
                            <span className="position-absolute">
                              <a
                                className="sort-up position-absolute ms-2 pointer"
                                onClick={() => setFilterData({ ...filterData, sortColumn: 'uploadedBy', sortOrder: 'asc' })}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </a>{" "}
                              <a
                                className="sort-down position-absolute ms-2 pointer"
                                onClick={() => setFilterData({ ...filterData, sortColumn: 'uploadedBy', sortOrder: 'desc' })}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </a>
                            </span>
                          </th>
                          <th className="font-14 position-relative text-center">
                            Attachment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {otherDocumentsGridData?.map((data) => (
                          <tr>
                            <td className="font-12 font-medium secondary-textcolor">
                              <a className="text-decoration-none pointer" style = {{cursor : 'pointer'}}onClick={() => navigate("/compliance/otherDocumentsDetails", {
                                state: {
                                  otherDocumentsId: data.otherDocumentsId,
                                },
                              })}>
                                {data?.otherDocumentsName}
                              </a>
                            </td>
                            <td className="font-12 wrap-text">
                              {data?.otherDocumentsDescription}
                            </td>
                            <td className="font-12">
                              {data?.otherDocumentsUploadedOn || 'Nill'}
                            </td>
                            <td className="font-12">
                              <div className="d-flex align-items-center">
                                {data?.uploadedBy ? <img
                                  src={data?.profileUrl}
                                  className="table-prof"
                                /> : null}
                                <p className="font-12 primary-textcolor mb-0 ms-2">
                                  {data?.uploadedBy || 'Nill'}
                                </p>
                              </div>
                            </td>
                            <td className="font-12 text-center">
                              <a className="pointer" onClick={() => navigate("/compliance/otherDocumentsDetails", {
                                state: {
                                  otherDocumentsId: data.otherDocumentsId,
                                },
                              })}>
                                <img
                                  src="/images/paper-clip.svg"
                                  alt="paper-clip"
                                />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table> : <RiskNoDataFound data={"No Documents Found"}/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherDocumentsGrid;
