// PC_COM_RES_POP_4 to PC_COM_RES_POP_8 -> import statements
import { ProgressBarComponent } from "./progressBar";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import { responsibilityOverViewApiResponse } from "../../Services/GRC/GrcService";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader/Loader";

// PC_COM_RES_POP_15 -> component declaration
export const ResponsibilityPopUp = ({ respData, payload, totalCount, onClose }) => {

  // PC_COM_RES_POP_16 to PC_COM_RES_POP_17 -> state variable creation
  const [popUpData, setPopUpData] = useState(respData)
  const [popUpLimit, setPopupLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // PC_COM_RES_POP_18 and PC_COM_RES_POP_19 -> useEffect declaration
  useEffect(() => {
    responsibilityOverViewPopUp()
  }, [popUpLimit, payload])

  // PC_COM_RES_POP_20 -> responsibilityOverViewPopUp function is triggered
  const responsibilityOverViewPopUp = async () => {
    try {

      // PC_COM_RES_POP_21 -> create an object popUpPayload and set state a variable isLoading as true
      setIsLoading(true);
      const popUpPayload = {
        frameworkId: payload.frameworkId,
        startDate: payload.startDate,
        endDate: payload.endDate,
        identity: payload.identity,
        loadMore: popUpLimit
      }

      // PC_COM_RES_POP_22 -> Declare a function call.
      const result = await responsibilityOverViewApiResponse(popUpPayload)

      // PC_COM_RES_POP_32 to PC_COM_RES_POP_33 -> state variable updation whether statusCode is 200
      if (result.statusCode === 200) {
        setPopUpData(result.responseData)
      }

      // PC_COM_RES_POP_33 -> navigate to error page when statusCode in not equals to 200
      else {
        navigate('/error');
      }
      setIsLoading(false)
    }

    // PC_COM_RES_POP_34 -> navigate to error page
    catch (error) {
      navigate('/error');
    }
  }

  // PC_COM_RES_POP_39 to PC_COM_RES_POP_41 -> handleScroll function is invoked
  const handleScroll = () => {
    //console.log("infinite scroll")
    setPopupLimit(popUpLimit + 10);
  }

  return (
    <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
      <Loader isLoading={isLoading} />
      <div className="col-md-12 my-2">
        {/* <div className="modal fade" id="staticBackdrop8" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between align-items-center p-4 border-0">
              <h5 className="modal-title font-14 font-medium primary-textcolor" id="staticBackdropLabel">
                Responsibility Overview</h5>
              <div className>
                <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
              </div>
            </div>
            <div className="modal-body px-4 pb-3 pt-0">

              <div id='scrollID9' className="security-popup" style={{height : '500px'}}>
                <InfiniteScroll
                  // debugger
                  dataLength={popUpData.length}
                  scrollThreshold={0.9}
                  hasMore={totalCount>popUpData.length}
                  scrollableTarget="scrollID9"
                  next={() => { handleScroll(); console.log("inisde log "); }}// Increment page when scrolling down
                >
                  {console.log(totalCount > popUpLimit, "^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")}
                  {popUpData?.map((ele) => <ProgressBarComponent data={ele}></ProgressBarComponent>)}
                </InfiniteScroll>
              </div>
              <div className="mt-3">
                <p className="font-12 grey-primary m-0">Showing <span className="font-medium primary-textcolor">{totalCount}</span> items</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}