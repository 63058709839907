import { Loader } from '../LoaderUMS';
import NoRecordFound from '../../dashboardNoRecordFound';
import DonutChartWithLegendNavigation from './DonutChartWithLegendNavigation';

const UserApplicationAccessHeader = ({ handleTabChange, data, roles, isSuperAdminAndManager, activeTab, isLoading }) => {
  const totalStatus = data?.reduce((total, status) => total + status.value, 0);

  const shouldRenderDonutChart = activeTab === 'myTask' ? roles === 'superAdmin' && isSuperAdminAndManager && totalStatus > 0 : activeTab === 'allTask' && totalStatus > 0;

  const isManagerWithNonCompliantData = roles === 'manager' && totalStatus > 0;

  return (
    <div className="py-2">
      {roles === 'superAdmin' && (
        <div className="d-flex justify-content-between align-items-center custom-tabs">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link px-0 font-14 tab-style rounded-0 d-flex align-items-center justify-content-center ${activeTab === 'myTask' ? 'active' : ''}`}
                id="appAccess-myTask-tab"
                data-bs-toggle="pill"
                data-bs-target="#appAccess-myTask"
                type="button"
                role="tab"
                aria-controls="appAccess-myTask"
                aria-selected={activeTab === 'myTask'}
                onClick={() => handleTabChange('myTask')}
              >
                <span className="tab-img d-flex align-items-center">
                  <img src="images/task-active.svg" alt="filled-icon" className="me-2" />
                  <img src="images/task-img.svg" alt="information-icon" className="me-2" />
                </span>
                My Task
              </button>
            </li>
            <li className="nav-item ms-4" role="presentation">
              <button
                className={`nav-link px-0 font-14 tab-style bold-text rounded-0 d-flex align-items-center justify-content-center ${activeTab === 'allTask' ? 'active' : ''}`}
                id="appAccess-allTask-tab"
                data-bs-toggle="pill"
                data-bs-target="#appAccess-allTask"
                type="button"
                role="tab"
                aria-controls="appAccess-allTask"
                aria-selected={activeTab === 'allTask'}
                onClick={() => handleTabChange('allTask')}
              >
                <span className="tab-img">
                  <img src="images/task-active.svg" alt="filled-icon" className="me-2" />
                  <img src="images/task-img.svg" alt="permissions-icon" className="me-2" />
                </span>
                All Task
              </button>
            </li>
          </ul>
        </div>
      )}
      <div className="tab-content px-2" id="myTabContent1" style={{ position: 'relative' }}>
        {isLoading && <Loader isLoading={isLoading} />}
        {isManagerWithNonCompliantData ? (
          <DonutChartWithLegendNavigation data={data} totalRequest={totalStatus} activeTab={activeTab} roles={roles} />
        ) : activeTab === 'myTask' ? (
          <div className={`tab-pane fade ${activeTab === 'myTask' ? 'show active' : ''}`} id="MyTasks" role="tabpanel" aria-labelledby="my-tasks-tab">
            {shouldRenderDonutChart ? (
              <DonutChartWithLegendNavigation data={data} totalRequest={totalStatus} activeTab={activeTab} roles={roles} />
            ) : (
              <NoRecordFound data={'No Application Request'} roles={roles} activeTab={activeTab} />
            )}
          </div>
        ) : (
          <div className={`tab-pane fade ${activeTab === 'allTask' ? 'show active' : ''}`} id="All-tasks-tab-1" role="tabpanel" aria-labelledby="all-tasks-tab1">
            {shouldRenderDonutChart ? (
              <DonutChartWithLegendNavigation data={data} totalRequest={totalStatus} activeTab={activeTab} roles={roles} />
            ) : (
              <NoRecordFound data={'No Application Request'} roles={roles} activeTab={activeTab} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserApplicationAccessHeader;
