import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { updateConfigureHeaders, PostData, updateData, postToggleButton, fetchConfigureHeaders, getPeriodicalAccessData } from '../../Services/Admin/AdminService';
import { ConfigureHeadersPopUp } from './ConfigureHeadersPopUp';
import { Select } from "antd";
import moment from "moment/moment";
import { Loader } from '../Loader/Loader';
import { encryptStorage, agovContext } from '../../Constant';

const AccessReview = () => {
  const navigate = useNavigate();
  let { userId, reportsToId, employeeName } = useContext(agovContext);
  let periodicAccessobj = {
    reviewRecurrence: '',
    approvalDuration: '',
    approvalLevelhierarchyLevel: 1,
    hierarchyLevel: '1',
    startDate: '',
    ifReviewerNotRespond: '',
    ifReviewerRevokeAction: '',
    noOfDaysFromStartDate: '',
    end_of_review_email: [],
    current_review_email: []
  }

  // configuration column starts here
  const [showPopUp, setShowPopUp] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [configHeaders, setConfigHeaders] = useState([]);
  const [access, setAccess] = useState([periodicAccessobj]);

  const handleConfigureColumn = () => {
    setShowPopUp(true);
    // handleSave()
  };

  const updateConfigHeaders = async (selectedColumns) => {
    setisLoading(true);
    try {
      let result = await updateConfigureHeaders({ uncheckedColumns: selectedColumns });
      //console.log(result, "result of update Configureheaders");
      if (result.statusCode === 200) {
        //console.log("Configuration Headers updated successfully");
      } else {
        setisLoading(false);
        console.error("Something went wrong!");
      }
      accessReviewPageLoad();
    }
    catch (error) {
      setisLoading(false);
      console.error("Error updating connector information:", error);
    }
    setisLoading(false);
  };
  // configuration column ends here

  //PC_3
  //state variables;

  //PC_4
  //state Variable to store the form data;

  /**
   * PC_5 Initialize the state variables
   */
  const [isValid, setIsValid] = useState(true);
  const [isValidApprovalDuration, setIsValidApprovalDuration] = useState(true);
  const [IsValidhierarchyLevel, setIsValidhierarchyLevel] = useState(true);

  const [options, setOptions] = useState([]);
  const [saveId, setSaveId] = useState('');
  const [bindDropdown, setbindDropdown] = useState([]);
  const [bindApprovalLevel, setbindApprovalLevel] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const [totalhierarchy, settotalhierarchy] = useState('');

  let [endOfReviewEmail, setEndOfReviewEmail] = useState([]);
  let [endOfCurrentEmail, setEndOfCurrentEmail] = useState([]);

  let [disableInputs, setDisableInputs] = useState(false);
  let [togglePopup, setTogglePopup] = useState(true);

  let [autoRevokePopUp, setAutoRevokePopUp] = useState(true);

  let [toggleButtonCheck, setToggleButtonCheck] = useState(false);
  let [toggleHighlight, setToggleHighlight] = useState(false);
  let [reviewRecurrenceHighlight, setReviewRecurrenceHighlight] = useState(false);


  const jwtToken = encryptStorage.getItem("jwtToken");
  const loginUserName = encryptStorage.getItem("username");

  let periodicAccessobjValidation = {
    reviewRecurrence: '',
    approvalDuration: '',
    approvalLevelhierarchyLevel: '',
    hierarchyLevel: '',
    startDate: '',
    ifReviewerNotRespond: '',
    ifReviewerRevokeAction: '',
    noOfDaysFromStartDate: '',
    endOfCurrentEmail: ''
  }

  let [validationMsg, setValidationMsg] = useState(periodicAccessobjValidation);
  /*
  *PC_AD_90
  *Create a variable state and store the instance of the useLocation()
  */
  const { state } = useLocation();
  /*
  *PC_9,PC_AD_91
  *The function adminHighlightion is called within the useEffect()
  */
  useEffect(() => {

    accessReviewPageLoad();

    adminHighlightion();
  }, []);

  const accessReviewPageLoad = async () => {
    await getConfigHeaders();
    await getDynamicValues();
  }

  const getConfigHeaders = async () => {
    try {
      let result = await fetchConfigureHeaders();
      let headersNames = result.responseData.recordsets[0].map(record => record.config_headers_name);

      //console.log(result.responseData.recordsets[0],"result of config header")
      // Set the configHeaders state variable with the headersNames
      setConfigHeaders(headersNames);
      setSelectedColumns(headersNames);
    } catch (error) {
      console.log("error in getConfigHeader:", error)
    }
  }

  /**
   * PC_AD_92,PC_AD_93
   * The function adminHighlightion is called from the useEffect
   * The value of the complienceType is checked from state.value is "is30DaysChagned", change the reviewRecurrenceHighlight to true
   * Within the setTimeOut function, change the value of state reviewRecurrenceHighlight to false with the parameter as 2000 i.e) 2seconds
   * The value of the value is checked from state is "isToggle", change the toggleHighlight to true
   * Within the setTimeOut function, change the value of state toggleHighlight to false with the parameter as 2000 i.e) 2seconds
   * The class "toggleOrReviewHighlight" will highlight the toggle or the review recurrence input field if the value of the  reviewRecurrenceHighlight or reviewRecurrenceHighlight is true
   */

  function adminHighlightion() {
    if (state?.value?.complienceType === "is30DaysChanged") {
      setReviewRecurrenceHighlight(true);
      setTimeout(() => {
        setReviewRecurrenceHighlight(false);
      }, 2000);
    }
    if (state?.value === "isToggle") {
      setToggleHighlight(true);
      setTimeout(() => {
        setToggleHighlight(false);
      }, 2000);
    }


  }

  //PC_10
  async function getDynamicValues() {
    //console.log("inside the fucntion ");
    // if (jwtToken != undefined && jwtToken != "") {
    /*PC_15
       response from api.js file is stored in  a variable getdata
    */
    setisLoading(true);
    let getdata = await getPeriodicalAccessData({ managerId: reportsToId });
    //console.log(getdata, "getdta of access review");
    let temparray = [];
    //console.log(getdata.response, "<><><><><><>");
    setDisableInputs(!(getdata.response.is_active));
    setToggleButtonCheck(getdata.response.is_active);

    for (let value of getdata.response.allEmail) {
      let maildata = {
        value: value,
        label: value
      }
      temparray.push(maildata)
    }
    setOptions(temparray);
    //PC_16
    //For binding the values in Form
    setbindDropdown(getdata.response.reviewActionMaster);
    // setbindEndType(getdata.response.endType);
    setbindApprovalLevel(getdata.response.escalationMaster);
    settotalhierarchy(getdata.organizationLevel)

    //console.log(getdata.response.escalationMaster, "inside the set of escalation<><>><><> ")
    //console.log(getdata.response.id, "saveId");
    // settotalhierarchy('2')
    if (getdata.response.id != '' && getdata.response.id != undefined) {
      setSaveId(getdata.response.id);
      access[0].reviewRecurrence = getdata.response.review_recurrence == null ? '' : getdata.response.review_recurrence;
      access[0].approvalDuration = getdata.response.approval_duration == null ? '' : getdata.response.approval_duration;
      access[0].approvalLevelhierarchyLevel = getdata.response.level_escalation_type
      access[0].hierarchyLevel = getdata.response.escalation == null ? '' : getdata.response.escalation;

      access[0].ifReviewerRevokeAction = getdata.response.reviewer_revoke_type == null ? 1 : getdata.response.reviewer_revoke_type;

      let dates = getdata.response.start_date.split('T');
      access[0].startDate = moment(dates[0]).format('YYYY-MM-DD');
      access[0].ifReviewerNotRespond = getdata.response.reviewer_action_type;

      access[0].noOfDaysFromStartDate = getdata.response.days_from_review == null ? '' : getdata.response.days_from_review;
      //for binding End of Review mail
      let endMailarray = [];
      for (let value of getdata.response.end_of_review_email) {
        endMailarray.push(value);
      }
      setEndOfReviewEmail(endMailarray);
      access[0].end_of_review_email = endMailarray;

      //for binding Current Review Status
      let currentStatusmail = [];
      for (let value of getdata.response.current_review_email) {
        currentStatusmail.push(value);
      }
      setEndOfCurrentEmail(currentStatusmail);
      access[0].current_review_email = currentStatusmail;
    }
    setisLoading(false);
    // } else {
    //   window.location.href = '/';
    // }
  }

  /*******
   * PC_AD_75
   *****/
  let startDATE = new Date();
  startDATE.setDate(startDATE.getDate() + 1);
  let periodicalAccessToggle = async (checkValue) => {
    var toggleReset = [...access];
    // PC_AD_83, PC_AD_84, PC_AD_85
    if (checkValue) {
      toggleReset[0]['reviewRecurrence'] = 30;
      toggleReset[0]['startDate'] = moment(startDATE).format('YYYY-MM-DD');
      toggleReset[0]['approvalDuration'] = 10;
      toggleReset[0]['approvalLevelhierarchyLevel'] = 2;
      toggleReset[0]['hierarchyLevel'] = 1;
      toggleReset[0]['ifReviewerNotRespond'] = 1;
      toggleReset[0]['ifReviewerRevokeAction'] = 1;
      toggleReset[0]['noOfDaysFromStartDate'] = "";
      toggleReset[0]['end_of_review_email'] = [];
      toggleReset[0]['current_review_email'] = [];
      setEndOfCurrentEmail([]);
      setEndOfReviewEmail([]);
      //PC_AD_88
      setAccess(toggleReset);
      setDisableInputs(false);
    } else {
      toggleReset[0]['reviewRecurrence'] = 0;
      toggleReset[0]['startDate'] = "";
      toggleReset[0]['approvalDuration'] = 0;
      toggleReset[0]['approvalLevelhierarchyLevel'] = 1;
      toggleReset[0]['hierarchyLevel'] = 0;
      toggleReset[0]['ifReviewerNotRespond'] = "";
      toggleReset[0]['ifReviewerRevokeAction'] = "";
      toggleReset[0]['noOfDaysFromStartDate'] = "";
      toggleReset[0]['end_of_review_email'] = [];
      toggleReset[0]['current_review_email'] = [];
      setEndOfCurrentEmail([]);
      setEndOfReviewEmail([]);
      //PC_AD_88
      setAccess(toggleReset);
      setDisableInputs(true);
    }
    setisLoading(true);
    // PC_AD_76
    let toggleUpdateResp = await postToggleButton({
      toggle: checkValue, modified_by: loginUserName, manager_id: reportsToId, userId: userId
    });
    // PC_AD_82
    if (toggleUpdateResp.status == 200) {
      if (checkValue) await SubmitClick(false);
    }
    setisLoading(false);
  }

  //PC_17
  //function that will be trigged while  Changing an fields in the form;
  function onChangeAccess(e) {
    try {
      let objValidation = {
        reviewRecurrence: '',
        approvalDuration: '',
        hierarchyLevel: '',
        startDate: '',
        ifReviewerNotRespond: '',
        noOfDaysFromStartDate: '',
        endOfCurrentEmail: '',
        approvalLevelhierarchyLevel: ''
      }
      let value = e?.target?.value;
      let name = e?.target?.name;
      let jsonData = [...access];
      //to check and replace the characters and special characters for the following fields;
      if (name == 'reviewRecurrence' || name == 'approvalDuration' || name == 'hierarchyLevel' || name == 'noOfDaysFromStartDate') {
        value = value.replace(/[^0-9 ]/g, "");
      }

      if (name == 'approvalDuration' && value.charAt(0) == 0) {
        value = value.slice(1);
      }

      if (name == 'hierarchyLevel' && value.charAt(0) == 0) {
        value = value.slice(1);
      }

      ///////////START HERE//////////////

      if (name == "reviewRecurrence" && access[0].approvalDuration == "" && access[0].hierarchyLevel == "") {
        jsonData[0]['reviewRecurrence'] = value;
      }

      if (name == "approvalDuration" && access[0].reviewRecurrence == "" && access[0].hierarchyLevel == "") {
        jsonData[0]['approvalDuration'] = value;
      }

      if (name == "hierarchyLevel" && access[0].approvalDuration == "" && access[0].reviewRecurrence == "") {
        jsonData[0]['hierarchyLevel'] = value;
      }

      if (name == "reviewRecurrence" && access[0].approvalDuration != "" && access[0].approvalLevelhierarchyLevel == "1") {
        if (access[0].approvalDuration >= parseInt(value)) {
          if (totalhierarchy >= parseInt(value)) {
            objValidation.reviewRecurrence = `Not a valid days for organization hierarchy ${totalhierarchy}`;
            value = '';
          } else
            objValidation.approvalDuration = `Approval duration should be less than review recurrence`;
          // value = '';
        } else {
          objValidation.approvalDuration = '';
        }
      }

      if (name == "reviewRecurrence" && access[0].approvalDuration == "" && access[0].approvalLevelhierarchyLevel == "1") {
        if (totalhierarchy >= parseInt(value)) {
          objValidation.reviewRecurrence = `Not a valid days for organization hierarchy ${totalhierarchy}`;
          // value = '';
        } else {
          objValidation.reviewRecurrence = '';
        }
      }

      if (name == "approvalDuration" && access[0].reviewRecurrence != "" && access[0].approvalLevelhierarchyLevel == "1") {
        let durationDays = parseInt(access[0].reviewRecurrence) / parseInt(totalhierarchy);
        if (durationDays <= parseInt(value)) {
          objValidation.approvalDuration = 'Please enter a valid days for approval duration';
          // value = '';
        }
        else if (access[0].reviewRecurrence <= parseInt(value)) {
          objValidation.approvalDuration = 'Approval duration should be less than review recurrence';
        } else {
          objValidation.approvalDuration = '';
        }
      }

      if (name == "approvalDuration" && access[0].reviewRecurrence != "" && access[0].hierarchyLevel == "" && access[0].approvalLevelhierarchyLevel == "2") {
        if (access[0].reviewRecurrence <= parseInt(value)) {
          objValidation.approvalDuration = 'Approval duration should be less than review recurrence';
        } else {
          objValidation.approvalDuration = '';
        }
      }

      if (name == "approvalDuration" && access[0].reviewRecurrence != "" && access[0].hierarchyLevel != "" && access[0].approvalLevelhierarchyLevel == "2") {
        if (((access[0].hierarchyLevel * parseInt(value))) >= access[0].reviewRecurrence) {
          jsonData[0]['approvalDuration'] = '';
          value = '';
        } else {
          jsonData[0]['approvalDuration'] = value;
          objValidation.hierarchyLevel = '';
        }
      }

      if (name == "hierarchyLevel" && access[0].approvalDuration != "" && access[0].reviewRecurrence != "" && access[0].approvalLevelhierarchyLevel == "2") {
        let calcValue = parseInt(access[0].reviewRecurrence / access[0].approvalDuration);
        if (calcValue >= (parseInt(value) + 1)) {
          jsonData[0]['hierarchyLevel'] = value;
          objValidation.hierarchyLevel = '';
        } else {
          if (value == '')
            objValidation.hierarchyLevel = 'Please enter a hierarchy level';
          else if (!(calcValue >= (parseInt(value) + 1)))
            objValidation.hierarchyLevel = 'Please enter the valid hierarchy level';
        }
      }

      if (name == "reviewRecurrence" && access[0].approvalDuration != "" && access[0].hierarchyLevel != "" && access[0].approvalLevelhierarchyLevel == "2") {
        if (((parseInt(access[0].hierarchyLevel) * parseInt(access[0].approvalDuration))) >= parseInt(value)) {
          objValidation.reviewRecurrence = 'Please enter the valid days for review recurrence';

          jsonData[0]['reviewRecurrence'] = '';
          // value = '';
        } else {
          jsonData[0]['reviewRecurrence'] = value;
          if (value == '')
            objValidation.reviewRecurrence = 'Please enter the days for review recurrence';
          else
            objValidation.reviewRecurrence = '';
        }
      }

      if (name == "reviewRecurrence" && access[0].approvalDuration != "" && access[0].hierarchyLevel == "" && access[0].approvalLevelhierarchyLevel == "2") {
        if (access[0].approvalDuration > value) {
          objValidation.reviewRecurrence = 'Please enter valid days for review recurrence';

          jsonData[0]['reviewRecurrence'] = '';
          value = '';
        } else {
          jsonData[0]['reviewRecurrence'] = value;
          if (value == '')
            objValidation.reviewRecurrence = 'Please enter the days for review recurrence';
          else
            objValidation.reviewRecurrence = '';
        }
      }

      if (name == "startDate") {
        if (value == "")
          objValidation.startDate = 'Please select a start date';
        else
          objValidation.startDate = '';
      }

      if (name == "ifReviewerNotRespond" && value == "")
        objValidation.ifReviewerNotRespond = 'Please select the action to perform';
      else
        objValidation.ifReviewerNotRespond = '';

      if (name == "noOfDaysFromStartDate" && value == "" && endOfCurrentEmail.length == 0) {
        objValidation.noOfDaysFromStartDate = '';
        objValidation.endOfCurrentEmail = '';
      }

      ///////////END HERE////////////////

      // Phase 2
      if (name == "approvalLevelhierarchyLevel") {
        if ((e.target.value) == '1') {
          jsonData[0][`hierarchyLevel`] = '1';
          objValidation.hierarchyLevel = '';
          objValidation.approvalLevelhierarchyLevel = `Your organization hierarchy is ${totalhierarchy}. Please configure the days according to the hierarchy`;
          jsonData[0][`approvalDuration`] = '';
          jsonData[0][`reviewRecurrence`] = '';
        }
      }

      jsonData[0][`${name}`] = value;
      setAccess(jsonData);

      validationMsg = objValidation;
      setValidationMsg(validationMsg);

    } catch (error) {
      console.log(error.message);
    }
  }

  //PC_21  when the save button is clicked
  //PC_31  when the Update button is clicked
  let validation = async () => {
    try {
      if (validationMsg.reviewRecurrence == "" && validationMsg.approvalDuration == '' && validationMsg.hierarchyLevel == '' && validationMsg.startDate == '' && validationMsg.ifReviewerNotRespond == '' && validationMsg.noOfDaysFromStartDate == "" && validationMsg.endOfCurrentEmail == "") {

        let objValidation = {
          reviewRecurrence: '',
          approvalDuration: '',
          hierarchyLevel: '',
          startDate: '',
          ifReviewerNotRespond: '',
          noOfDaysFromStartDate: '',
          endOfCurrentEmail: ''
        }

        //PC_22 
        //PC_32
        // If Review Recurrence field is empty
        if (String(access[0].reviewRecurrence).trim() == '') {
          objValidation.reviewRecurrence = 'Please enter the days for review recurrence';
        } else {
          objValidation.reviewRecurrence = '';
        }

        //If the Review Approval Duration field is empty
        if (String(access[0].approvalDuration).trim() == '')
          objValidation.approvalDuration = 'Please enter the approval duration days';
        else
          objValidation.approvalDuration = '';

        //For validating Review Recurrence should be greater than Review Aapproval Duration
        if (access[0].approvalDuration != '') {
          if (Number(access[0].reviewRecurrence) <= Number(access[0].approvalDuration)) {
            objValidation.approvalDuration = 'Approval duration should be less than review recurrence';
          } else {
            objValidation.approvalDuration = '';
          }
        }

        //If the Enter Hierarchy Level is empty
        if (access[0].approvalLevelhierarchyLevel == "2" && (String(access[0].hierarchyLevel).trim() == '' || (access[0].hierarchyLevel) == null))
          objValidation.hierarchyLevel = 'Please enter a hierarchy level';
        else
          objValidation.hierarchyLevel = '';

        // If Start Date field is empty
        if (access[0].startDate.trim() == '')
          objValidation.startDate = 'Please select the start date';
        else
          objValidation.startDate = '';

        //PC_23
        // If Reviewer doesnot Respond field is empty
        if (access[0].ifReviewerNotRespond == '')
          objValidation.ifReviewerNotRespond = 'Please select the action to perform';
        else
          objValidation.ifReviewerNotRespond = '';


        //If the number of days  is empty when the  current status reports is not empty
        if (String(access[0].noOfDaysFromStartDate) == "" && endOfCurrentEmail?.length != 0)
          objValidation.noOfDaysFromStartDate = 'Please enter the number of days';
        else
          objValidation.noOfDaysFromStartDate = '';
        //If the current status reports to mail is empty when the number of days is not empty
        if (String(access[0].noOfDaysFromStartDate).trim() != '' && access[0].noOfDaysFromStartDate != null && endOfCurrentEmail?.length == 0)
          objValidation.endOfCurrentEmail = 'Please select any of the user';
        else
          objValidation.endOfCurrentEmail = '';

        validationMsg = objValidation;
        setValidationMsg(validationMsg);

        if ((String(access[0].noOfDaysFromStartDate) != "" && endOfCurrentEmail.length == 0)) return false;
        else if ((String(access[0].noOfDaysFromStartDate) == "" && endOfCurrentEmail?.length != 0)) return false;

        if ((access[0].reviewRecurrence != "" && access[0].approvalDuration != '' && access[0].hierarchyLevel != '' && access[0].startDate != '' && access[0].ifReviewerNotRespond != '')) {
          if ((String(access[0].noOfDaysFromStartDate) != "" && endOfCurrentEmail.length != 0 && String(access[0].noOfDaysFromStartDate) != "null") || ((String(access[0].noOfDaysFromStartDate) == "" || String(access[0].noOfDaysFromStartDate) == "null") && endOfCurrentEmail.length == 0)) {

            if (Number(access[0].ifReviewerRevokeAction == 2)) {
              setAutoRevokePopUp(false)
            }
            else {
              await SubmitClick();
            }


          }

        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function SubmitClick(redirectKey = true) {
    //console.log(endOfReviewEmail, "Edndof review mail in submit click");
    let endarray = [];
    endOfReviewEmail.map((obj) => {
      endarray.push(obj);
    })

    let currentStatusarray = []
    endOfCurrentEmail.map((obj) => {
      currentStatusarray.push(obj);
    })
    // let manager_id = encryptStorage.getItem('employeeId');
    let manager_id = reportsToId
    let insertdata = {
      "review_recurrence": access[0].reviewRecurrence,
      "approval_duration": access[0].approvalDuration,
      "level_escalation_type": access[0].approvalLevelhierarchyLevel,
      "escalation": access[0].approvalLevelhierarchyLevel == '1' ? totalhierarchy : access[0].hierarchyLevel,
      "start_date": access[0].startDate,
      "reviewer_action_type": access[0].ifReviewerNotRespond,
      "reviewer_revoke_type": Number(access[0].ifReviewerRevokeAction),
      "days_from_review": access[0].noOfDaysFromStartDate,
      "end_of_review_email": endarray,
      "current_review_email": currentStatusarray,
      "created_by": employeeName,
      manager_id: manager_id,
      user_id: userId
    }

    let update = {
      "review_recurrence": access[0].reviewRecurrence,
      "approval_duration": access[0].approvalDuration,
      "level_escalation_type": access[0].approvalLevelhierarchyLevel,
      "escalation": access[0].approvalLevelhierarchyLevel == '1' ? totalhierarchy : access[0].hierarchyLevel,
      "start_date": access[0].startDate,
      "reviewer_action_type": access[0].ifReviewerNotRespond,
      "reviewer_revoke_type": Number(access[0].ifReviewerRevokeAction),
      "days_from_review": access[0].noOfDaysFromStartDate,
      "end_of_review_email": endarray,
      "current_review_email": currentStatusarray,
      "modified_by": employeeName,
      "id": saveId,
      manager_id: manager_id,
      user_id: userId
    }


    //PC_24,PC_25
    if (saveId == '') {
      setisLoading(true);
      let data = await PostData(insertdata);
     setisLoading(false)
      // setValidationMsg(periodicAccessobjValidation)
      //PC_30
      // if (redirectKey) navigate('/Dashboard');
    } else {
      //PC_34,//PC_35
      setisLoading(true);
      let data = await updateData(update);
      setisLoading(false)
      // setValidationMsg(periodicAccessobjValidation)
      //PC_40 Redirection to Home page
      // if (redirectKey) navigate('/Dashboard');
      // ResponseData: "Successfully Updated"
      // Success: true
    }
  }

  //PC_AD_116
  //Function used to set the state of endOfReviewEmail with list of values that are being given in the field "At end of review,send notification(s) to" 
  let handleMultiDropdown = (value) => {
    setEndOfReviewEmail(value);
  }
  //console.log(endOfReviewEmail, "endOf ReviewEmail");
  //PC_AD_117
  //Function used to set the state of endOfCurrentEmail with list of values that are being given in the field "Send current review status report to" 
  let handleMultiDropdownCurrentEmail = (value) => {
    setEndOfCurrentEmail(value);
  }
  //console.log(endOfCurrentEmail, "endOfCurrentEmail");
  //PC_AD_118 - PC_AD_121
  //This funciton is invoked when the user has selected auto revoke and clicked on submit. This funciton triggers pop up by setting the state of autoRevokePopip to true
  //Now get the values of access, destrucure and stote the value of the ifReviewerRevokeAction to 1 and set the Access state again.
  let resetIfReviewRevoke = () => {
    // //console.log("Inside resetIfReviewRevoke");
    setAutoRevokePopUp(true);
    let getResetData = [...access];
    getResetData[0]['ifReviewerRevokeAction'] = 1;
    setAccess(getResetData);

  }
  //end of review form

  return (
    <>
      <Loader isLoading={isLoading} />
      {/* <ReactTooltip /> */}
      <div className="container-fluid mt-4 mt-custom">
        <div className="col-md-12">
          <div className="px-3 px-xl-5">
            <div className="d-flex justify-content-between mt-4">
              <h1 className="font-bold font-24 primary-textcolor">
                Admin Configurations
              </h1>
            </div>
            {/* tabs starts here */}
            <div className="d-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link px-0  font-14 tab-style rounded-0" id="information-tab" data-bs-toggle="pill" data-bs-target="#information" type="button" role="tab" aria-controls="information" aria-selected="true" onClick={() => navigate('/adminmanagement')}>
                    <span className="tab-img"> <img src="/images/information-icon-filled.svg" alt="filled-icon" className="me-2" />
                      <img src="/images/information-icon.svg" alt="information-icon" className="me-2" /></span>Information</button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="permissions-tab" data-bs-toggle="pill" data-bs-target="#permissions" type="button" role="tab" aria-controls="permissions" aria-selected="false" tabIndex={-1} onClick={() => navigate('/adminmanagement/adminpermission')}>
                    <span className="tab-img"> <img src="/images/permission-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/permission-icon.svg" alt="permissions-icon" className="me-2" />
                    </span>Permissions</button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 active font-14 tab-style bold-text rounded-0" id="configurations-tab" data-bs-toggle="pill" data-bs-target="#configurations" type="button" role="tab" aria-controls="configurations" aria-selected="false" tabIndex={-1}>
                    <span className="tab-img"> <img src="/images/key-icon-filled.svg" alt="filled-icon" className="me-2" />
                      <img src="/images/key-icon.svg" alt="configurations-icon" className="me-2" />Configurations</span></button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="app-owner-config-tab" data-bs-toggle="pill" data-bs-target="#app-owner-config" type="button" role="tab" aria-controls="app-owner-config" aria-selected="false" tabIndex={-1} onClick={() => navigate('/adminmanagement/appOwnerConfig')}>
                    <span className="tab-img"> <img src="/images/app-icon-filled.svg" alt="filled-icon" className="me-2" />
                      <img src="/images/app-icon.svg" alt="app-owner-config-icon" className="me-2" />App Owner
                      Config</span></button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="security-tab" data-bs-toggle="pill" data-bs-target="#security" type="button" role="tab" aria-controls="security" aria-selected="false" tabIndex={-1} onClick={() => navigate('/adminmanagement/securityConfig')}>
                    <span className="tab-img"> <img src="/images/lock-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/lock-icon.svg" alt="security-icon" className="me-2" />Security</span></button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="policy-config-tab" data-bs-toggle="pill" data-bs-target="#policy-config" type="button" role="tab" aria-controls="policy-config" aria-selected="false" tabIndex={-1} onClick={() => navigate('/adminmanagement/policyConfig')}>
                    <span className="tab-img"> <img src="/images/privacy-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/privacy-icon.svg" alt="policy-config-icon" className="me-2" />Policy Configuration</span></button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="billing-tab" data-bs-toggle="pill" data-bs-target="#billing" type="button" role="tab" aria-controls="billing" aria-selected="false" tabIndex={-1}>
                    <span className="tab-img"> <img src="/images/bill-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/bill-icon.svg" alt="billing-icon" className="me-2" />Billing</span></button>
                </li>
              </ul>
            </div>
            {/* tab ends here */}
            <div className="row position-relative">
              <div className="tab-content" id="pills-tabContent">
                {/* Configurations tab starts here */}
                <div className="row justify-content-center">
                  <div className="col-md-9">
                    <div className="mb-4 px-3">
                      <div className="d-flex align-items-center">
                        <h2 className="font-medium font-24 me-3">Access Review</h2>
                        <div className="form-check form-switch align-items-center">
                          <input className="form-check-input" type="checkbox" id="AdminInfoStatus" checked={toggleButtonCheck} onChange={e => { setToggleButtonCheck(e.target.checked); if (!e.target.checked) { setTogglePopup(false) } else { periodicalAccessToggle(e.target.checked); } }} onClick={(e) => { console.log(e) }} />
                          <label className="form-check-label font-13 primary-textcolor" htmlFor="AdminInfoStatus">Active</label>
                        </div>
                      </div>
                      <p className="font-14 font-regular grey-secondary mt-3">Manage configurations,
                        integrations
                        and my organization views</p>
                    </div>
                    <div className="row mt-5">
                      <h3 className="font-medium font-20 active-textcolor mb-4 custom-form-padding">Review
                        Settings</h3>
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="review-recurrence" className="form-label font-14 font-medium">Review
                          Recurrence (in days)<span className="required">*</span>
                        </label>
                        <input type="text" className={`form-control custom-input-field  ${reviewRecurrenceHighlight == true ? "toggleOrReviewHighlight" : ""}`} id="review-recurrence"
                          name="reviewRecurrence"
                          value={access[0].reviewRecurrence} onChange={onChangeAccess} maxLength={3} disabled={disableInputs}
                          placeholder="Enter Days" />
                        <span id="alertMessage">{validationMsg.reviewRecurrence}</span>
                      </div>
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="review-approval" className="form-label font-14 font-medium">Review Approval
                          Duration (in days)<span className="required">*</span></label>
                        <input type="text" className="form-control custom-input-field" id="review-approval"
                          name="approvalDuration"
                          value={access[0].approvalDuration} onChange={onChangeAccess} maxLength={3} disabled={disableInputs}
                          placeholder="Enter Days" />
                        <span id="alertMessage">{validationMsg.approvalDuration}</span>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="StartDate" className="form-label text-black font-14 font-medium">Start
                          Date<span className="required">*</span></label>
                        <input type="date" className="form-control admin-custom-date custom-form-control font-14 py-2 font-regular" id="StartDate"
                          name="startDate"
                          min={startDATE?.toISOString()?.split("T")[0]} value={access[0].startDate} onChange={onChangeAccess} disabled={disableInputs} />
                        <span id="alertMessage">{validationMsg.startDate}</span>
                      </div>
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="approval" className="form-label font-14 font-medium d-block mb-3">
                          Approval Escalation Matrix
                          <span className="required">*</span></label>
                        {bindApprovalLevel.map((value, index) => {
                          return (
                            <>
                              <input
                                className="form-check-input custom-radio-input custom-input-field me-2"
                                type="radio"
                                name="approvalLevelhierarchyLevel"
                                id={value.name}
                                value={value.id}
                                onChange={(e)=>onChangeAccess(e)}
                                checked={Number(access[0].approvalLevelhierarchyLevel) === value.id}
                                disabled={disableInputs}
                              />
                              <label className="form-check-label mr-4">{value.name}</label>
                            </>
                          )
                        })}
                        <div>
                          <span id="alertMessage">{validationMsg.approvalLevelhierarchyLevel}</span>
                        </div>
                      </div>
                    </div>


                    <div className="row mt-4">
                      <div hidden={(access[0].approvalLevelhierarchyLevel == 2) ? false : true} className="col-md-6 custom-form-padding">
                        <label htmlFor="hierarchy" className="form-label font-14 font-medium">Enter
                          Hierarchy Level<span className="required">*</span></label>
                        <input type="number" className="form-control custom-input-field" id="hierarchy"
                          name="hierarchyLevel"
                          placeholder={1}
                          value={access[0].hierarchyLevel} onChange={onChangeAccess} maxLength={2} disabled={disableInputs} />
                        <span id="alertMessage">{validationMsg.hierarchyLevel}</span>
                      </div>
                    </div>
                    {/* Action Setting starts  */}
                    <div className="row mt-5">
                      <h3 className="font-medium font-20 active-textcolor mb-4 custom-form-padding">Action
                        Settings</h3>
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="review-not-res" className="form-label font-medium font-14 primary-textcolor d-block">If
                          reviewer’s don’t
                          respond<span className="required">*</span>
                        </label>
                        <input type="radio" className="form-check-input custom-radio-input custom-input-field me-2" id={1}
                          name="ifReviewerNotRespond"
                          value={1} onChange={onChangeAccess} checked={access[0].ifReviewerNotRespond == 1} disabled={disableInputs} defaultChecked />
                        <label htmlFor="revieweryesres" className="font-12 font-regular me-3">No Action</label>
                        <input type="radio" className="form-check-input custom-radio-input custom-input-field me-2" id={2}
                          name="ifReviewerNotRespond"
                          value={2} onChange={onChangeAccess} checked={access[0].ifReviewerNotRespond == 2} disabled={disableInputs} />
                        <label htmlFor="reviewernores" className="font-12 font-regular ">Auto Revoke</label>
                      </div>
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label font-14 font-medium d-block">If
                          reviewer’s don’t respond to highly
                          suspicious user’s</label>
                        <input type="radio" className="form-check-input custom-radio-input custom-input-field me-2"
                          name="ifReviewerRevokeAction"
                          id={1} value={1} onChange={onChangeAccess} checked={Number(access[0].ifReviewerRevokeAction) == 1} disabled={disableInputs} />
                        <label htmlFor="suspiciousyes" className="font-12 font-regular me-3">{"No Action"}</label>
                        <input type="radio" className="form-check-input custom-radio-input custom-input-field me-2"
                          name="ifReviewerRevokeAction"
                          id={2} value={2} onChange={onChangeAccess} checked={Number(access[0].ifReviewerRevokeAction) == 2} disabled={disableInputs} />
                        <label htmlFor="suspiciousno" className="font-12 font-regular ">{"Auto Revoke"}</label>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="end-review" className="form-label font-14 font-medium">At
                          the end of review, Send
                          notifications to
                        </label>
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Please select"
                          defaultValue={endOfReviewEmail}
                          value={endOfReviewEmail}
                          onChange={handleMultiDropdown}
                          options={options}
                          disabled={disableInputs}
                        />
                      </div>
                    </div>
                    {/* Action Setting ends Here  */}

                    
                    {/* Report SETTING STARTS HERE */}
                    <div className="row mt-5">
                      <h3 className="font-medium font-20 active-textcolor mb-4 custom-form-padding">Report
                        Settings</h3>
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="curr-review" className="form-label font-14 font-medium">Send current
                          review status report to</label>
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Please select"
                          defaultValue={endOfCurrentEmail}
                          value={endOfCurrentEmail}
                          onChange={handleMultiDropdownCurrentEmail}
                          options={options}
                          disabled={disableInputs}
                        />
                        <span id="alertMessage">{validationMsg.endOfCurrentEmail}</span>
                      </div>
                      <div className="col-md-6 custom-form-padding">
                        <label htmlFor="days-review" className="form-label font-14 font-medium">No. of days
                          from review start date</label>
                        <input id="days-review" type="number" className="form-control custom-input-field"
                          placeholder="Enter"
                          name="noOfDaysFromStartDate"
                          value={access[0].noOfDaysFromStartDate} onChange={onChangeAccess} maxLength={3} disabled={disableInputs} />
                        <span id="alertMessage">{validationMsg.noOfDaysFromStartDate}</span>
                      </div>
                    </div>
                    {/* configuration column starts */}
                    <div className="row mt-5">
                      {/* configure table button config */}
                      <div className="d-flex justify-content-between custom-form-padding align-items-center">
                        <div>
                          <h3 className="font-medium font-20 active-textcolor mb-4">Table personalization
                          </h3>
                          <p className="font-regular font-14 custom-grey-text">Manage columns & order of
                            columns
                            using configure, these columns would be reflected under My Organization
                          </p>
                        </div>
                        <div>
                          <button className="cust-filter-btn d-flex align-items-center" type="button" aria-expanded="true" onClick={() => { handleConfigureColumn() }}>
                            <img src="/images/settings-button-icon.svg" alt="filter-icon" className="filt-icon me-2" />Configure Columns
                          </button>
                        </div>
                      </div>
                      {/* configure table button config */}
                      <div className="col-md-12 mb-5 custom-form-padding">
                        {/* configure table starts here */}
                        <div className="table-responsive">
                          <table className="table text-nowrap mb-0 custom-table-grid table-borderless mt-3">
                            <thead>
                              <tr>
                                {configHeaders.map((column, index) => (
                                  <th key={index} className="font-14 position-relative">
                                    {column}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                          </table>
                        </div>
                        {/* configure table ends here */}
                      </div>
                    </div>
                    <div className="text-end mt-4 mb-5 px-3">
                      <button type="button" className="primary-btn font-14 font-medium me-2"
                      >
                        Cancel
                      </button>
                      <button type="button" className="dark-btn font-14 font-medium px-4"
                        onClick={() => { validation() }}>{(saveId == '' || saveId == undefined) ? "Save" : "Update"}
                      </button>
                    </div>
                    {/* configuration column ends */}
                  </div>
                </div>
                {/* Configurations tab ends here */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showPopUp &&
        <ConfigureHeadersPopUp setShowPopUp={setShowPopUp} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} updateConfigureHeaders={updateConfigHeaders}></ConfigureHeadersPopUp>

      }

      {/* toggle OFF Popup  Starts here  */}
      {/* Modal */}
      <div hidden={togglePopup} className={`modal ${!togglePopup ? "dull-background" : "transparent-background"}`} id="delete" tabindex="-1" aria-labelledby="exampleModalLabel"
        style={{display: setShowPopUp ? "block" : "none", backgroundColor: setShowPopUp ? "rgba(0, 0, 0, 0.6)" : "transparent" }}>
        <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
          <div className="modal-content">
            {/*modal content starts here*/}
            <div className="modal-header border-0 pb-2 px-4">
              <h5 className="modal-title font-bold" id="exampleModalLabel">
                <img
                  className="me-3"
                  src="/images/delete-popup-icon.svg"
                  alt="delete-icon"
                />
                Warning{" "}
              </h5>
              <button
                type="button"
                className="btn-close ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => { setTogglePopup(true); setToggleButtonCheck(!toggleButtonCheck) }}
              />
            </div>
            <div className="modal-body border-0 font-16 px-4">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <p className="font-16 font-regular mb-0">
                    <span className="font-medium">Turning off the Periodical Access Review will Reset
                      the Approval Cycle , Do you still want to turn off?</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 mb-3 px-4">
              <button
                type="button"
                className="dark-btn font-14 font-medium py-2 me-2"
                data-bs-dismiss="modal"
                onClick={() => { setTogglePopup(true); setToggleButtonCheck(!toggleButtonCheck) }}
              >
                No
              </button>
              <button
                type="button"
                className="btn revoke-btn font-14 font-medium py-2"
                onClick={() => { periodicalAccessToggle(toggleButtonCheck); setTogglePopup(true) }}
              >
                Yes
              </button>
            </div>
            {/*modal content ends here*/}
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* toggle OFF Popup Ends here  */}

      {/* AutoRevoke Popup  Starts here  */}
      {/* Modal */}
      <div hidden={autoRevokePopUp} className={`modal ${!autoRevokePopUp ? "dull-background" : "transparent-background"}`} id="delete" tabindex="-1" aria-labelledby="exampleModalLabel"
        style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
          <div className="modal-content">
            {/*modal content starts here*/}
            <div className="modal-header border-0 pb-2 px-4">
              <h5 className="modal-title font-bold" id="exampleModalLabel">
                <img
                  className="me-3"
                  src="/images/delete-popup-icon.svg"
                  alt="delete-icon"
                />
                Warning{" "}
              </h5>
              <button
                type="button"
                className="btn-close ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => { setTogglePopup(true); setToggleButtonCheck(!toggleButtonCheck) }}
              />
            </div>
            <div className="modal-body border-0 font-16 px-4">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <p className="font-16 font-regular mb-0">
                    <span className="alert-ms">Highly suspicious users will have their assigned </span>
                    <span className="alert-ms">applications auto-revoked during the first review </span>
                    <span className="alert-ms">cycle due to the Auto Revoke option being selected.</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 mb-3 px-4">
              <button
                type="button"
                className="dark-btn font-14 font-medium py-2 me-2"
                data-bs-dismiss="modal"
                onClick={() => { resetIfReviewRevoke() }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn revoke-btn font-14 font-medium py-2"
                onClick={async () => { setAutoRevokePopUp(true); await SubmitClick() }}
              >
                Save
              </button>
            </div>
            {/*modal content ends here*/}
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* AutoRevoke Popup  Ends here  */}

    </>
  )
}

export { AccessReview }