import { client } from "../../Client/Client";
import { backendUrl as URL, loginService, grcService, lmsService, governanceService, adminService } from "../../Constant";


/**
 * PC_CC_10 - PC_CC_18
 * Calls the getControlsGrid API and returns the values
 */
export const getControlsGrid = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.getControlsGrid,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
};

export const getVendorData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.getVendorDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
};

export const deleteVendorData = async (vendorId) => {
 

    let config = {
        method: 'POST',
        url: URL + grcService.deleteVendorData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: { vendorId } // Corrected parameter name here
    };

    let response = await client(config);
    return response;
};

export const getQuestionaryHistory = async (data) => {
  

    let config = {
        method: 'POST',
        url: URL + grcService.getQuestionaryHistory,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_160 - PC_CC_168
 * This API fetches the data of a particular control 
 */
export const getControlDetails = async (data) => {

    
    let config = {
        method: 'POST',
        url: URL + grcService.getControlDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_346 - PC_CC_354
 * This fetches all the data of the auditor comments  
 */
export const getAuditorComments = async (data) => {

    
    let config = {
        method: 'POST',
        url: URL + grcService.getAuditorComments,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_183 - PC_CC_192
 * This Api brings all the history related data according to a particular control
 */
export const getControlHistory = async (data) => {

    
    let config = {
        method: 'POST',
        url: URL + grcService.getControlHistory,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


export const getAuditCycleData = async (data) => {
    
    let config = {
        method: 'POST',
        url: URL + grcService.getAuditCycleData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deleteAuditCycle = async (data) => {
    
    let config = {
        method: 'PUT',
        url: URL + grcService.deleteAuditCycle,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAuditorDetails = async () => {

    let config = {
        method: 'POST',
        url: URL + grcService.getAuditorDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;
}

export const getStatusDetails = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.getStatusDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getRecurrenceData = async () => {

    let config = {
        method: 'POST',
        url: URL + grcService.getRecurrenceData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;
}

export const getExistingtimeline = async () => {

    let config = {
        method: 'POST',
        url: URL + grcService.getExistingtimeline,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;
}

export const addNewAuditCycle = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.addNewAuditCycle,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const addExistingAuditCycle = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.addExistingAuditCycle,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const addPastAuditCycle = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.addPastAuditCycle,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateAuditCycle = async (data) => {

    let config = {
        method: 'PUT',
        url: URL + grcService.updateAuditCycle,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const generateSasToken = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.generateSasToken,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getVendorFormData = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.getVendorFormData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateVendorDetails = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.updateVendorDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const insertVendorDetails = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.insertVendorDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * PC_CC_243 - PC_CC_251
 * This API call maps the policy or control or principle 
 */
export const mappingGRC = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.mappingGRC,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_266 - PC_CC_273
 * This API unmaps the control or policy or evidence
 */
export const unmappingGRC = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.unmappingGRC,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * PC_CC_208 - PC_CC_216
 * This API call brings all the date is required for assignpopup component
 */
export const assignPopup = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.assignPopup,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/*
* PC_CC_228 - PC_CC_236
* It fetches the data to be shown when a particular policy or evidence or control is clicked in the mapping Popup
*/
export const assignPopupDetails = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.assignPopupDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_315 - PC_CC_323
 * This APi call updates the status of a control whether it is active or inactive
 */
export const updateControlStatus = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.updateControlStatus,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_66 - PC_CC_74
 * This API call fetches the data of the add Control page when called
 */
export const controlPopupValues = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.controlPopupValues,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_26 - PC_CC_28
 * This fetches the data for showing the completed percentage at the top of header
 */
export const getSummaryDetails = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.getSummaryDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_439 - PC_CC_447
 * This creates an evidence or a policy when an auditor requests a evidence or policy 
 */
export const requestDocument = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.requestDocument,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * PC_CC_296 - PC_CC_304
 * This function updates the status of the particular control
 */
export const updateApprovalStatus = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.updateApprovalStatus,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_42 - PC_CC_50
 * This API will handle the data for export data
 */
export const getExportControls = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.getExportControls,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * PC_CC_140 - PC_CC_148, PC_CC_91 - PC_CC_98
 * This API will create a new control
 */
export const excelAddControl = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.excelAddControl,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * PC_CC_281 - PC_CC_289
 * This function update the owner of the control 
 */
export const updateControlOwner = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.updateControlOwner,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * PC_CC_397 - PC_CC_405
 * This API marks the audit as complete and uploads the report file 
 */
export const markAuditAsComplete = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.markAuditAsComplete,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/**
 * PC_CC_413- PC_CC_421
 * This API is used to mark an audit as incomplete
 */
export const markAuditAsInComplete = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.markAuditAsInComplete,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * PC_CC_375 - PC_CC_383
 * This API call updates the auditor comments
 */
export const insertAuditorComments = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.insertAuditorComments,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getQuestionaries = async () => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.getQuestionaries,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;
}

export const deleteSectionFromDB = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.deleteSectionFromDB,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deleteQuestionFromDB = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.deleteQuestionFormDB,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const postQuestionaries = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.postQuestionaries,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateQuestionToDB = async (data) => {

   
    let config = {
        method: 'POST',
        url: URL + grcService.updateQuestionToDB,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getEvidencesGrid = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.getEvidencesGrid,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const createEvidenceOrPolicy = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.createEvidenceOrPolicy,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getEvidenceDetails = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.getEvidenceDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const uploadEvidence = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.uploadEvidence,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateEvidenceRecurrence = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.updateEvidenceRecurrence,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateEvidencePause = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.updateEvidencePause,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateEvidenceActive = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.updateEvidenceActive,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deletePolicyOrEvidence = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.deletePolicyOrEvidence,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deleteParticularDocument = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.deleteParticularDocument,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getVendorQuestions = async (data) => {


    let config = {
        method: 'POST',
        url: URL + grcService.getVendorQuestions,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deleteVendorQuestionaries = async (data) => {


    let config = {
        method: 'POST',
        url: URL + grcService.deleteVendorQuestionaries,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getPoliciesGrid = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.getPoliciesGrid,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * GRC_SD_39 - GRC_SD_47
 * It inserts all the system description details to the database 
 */
export const insertSystemDescriptionDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.insertSystemDescriptionDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/**
 * GRC_SD_04 - GRC_SD_12
 * It fethces all the data of the system description to show the data
 */
export const systemDescriptionQuestions = async () => {
    let config = {
        method: 'POST',
        url: URL + grcService.systemDescriptionQuestions,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;
}

export const getPolicyDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.getPolicyDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const uploadPolicy = async (data) => {

    let config = {
        method: 'POST',
        url: URL + grcService.uploadPolicy,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getOtherDocumentsGrid = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.getOtherDocumentsGrid,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getOtherDocumentsDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.getOtherDocumentsDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deleteOtherDocuments = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.deleteOtherDocuments,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const uploadOtherDocuments = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.uploadOtherDocuments,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
export const insertVendorQuestions = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.insertVendorQuestions,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const uploadReport = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.uploadReport,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAuditorTimelines = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.getAuditorTimelines,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getUserLists = async () => {
    let config = {
        method: 'POST',
        url: URL + grcService.getUserLists,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;
}

export const deleteVendorContact = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.deleteVendorContact,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const insertLogs = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.insertLogs,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const downloadAuditEvidences = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.downloadAuditEvidences,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
};

export const insertLog = async (data) => {
    let config = {
        method: 'POST',
        url: URL + grcService.insertLog,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

// PC_COM_ACT_20 to PC_COM_ACT_28 
// It fetches a data for audit cycle timeline dropdown in GRC dashboard
export const auditCycleTimelineApiResponse = async (data) =>{
    let config = {
        method: 'POST',
        url: URL + grcService.getAuditCycleTimelines,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    //console.log(URL, "URL")
    //console.log(config.url)
    let response = await client(config);
    return response;
}

// PC_COM_GRC_24 to PC_COM_GRC_32 
// It fetches a data for control, policy and evidenes tiles in GRC dashboard
export const grcDashboardDetailsApiResponse = async (data) =>{
    //console.log(data)
    let config = {
        method: 'POST',
        url: URL + grcService.getGrcDashboardDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    
    let response = await client(config);
    return response;
}

// PC_COM_VI_24 to PC_COM_VI_32
// It fetches a data for vendor assessmnet piechart in GRC dahsboard
export const vendorAssessmentApiResponse = async () =>{
    let config = {
        method: 'POST',
        url: URL + grcService.getVendorInformation,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    
    let response = await client(config);
    return response;
}

// PC_COM_AS_RD_27 to PC_COM_AS_RD_35
// It fetches a data for auditor verification piechart and requested document piechart in GRC dashboard
export const auditorVerificationApiResponse = async (data) =>{
    let config = {
        method: 'POST',
        url: URL + grcService.auditorAndRequestedDocument,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    
    let response = await client(config);
    return response;
}

// PC_COM_RES_23 to PC_COM_RES_31
// It fetches a data for responsibility overview widget and pop-up in GRC Dashnoard
export const responsibilityOverViewApiResponse = async (data) =>{
    let config = {
        method: 'POST',
        url: URL + grcService.getResponsibilityOverView,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    
    let response = await client(config);
    //console.log(response, "responssibility response")
    return response;
}

// PC_COM_CS_19 to PC_COM_CS_23 
// It fetches a data for compliance summary widget in GRC dashboard
export const complianceSummaryApiResponse  = async (data) =>{
    let config = {
        method: 'POST',
        url: URL + grcService.getComplianceSummary,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    
    let response = await client(config);
    //console.log(response, "responssibility response")
    return response;
}

// PC_COM_RD_POP_17 to PC_COM_RD_POP_18
// It fetches a data for requested document pop up in GRC dashboard
export const requestedDocumentPopUpApiResponse  = async (data) =>{
    let config = {
        method: 'POST',
        url: URL + grcService.requestedDocumentPopUp,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    
    let response = await client(config);
    //console.log(response, "requested document api")
    return response;
}

// PC_COM_TS_16 and PC_COM_TS_22
// It fetches a data for requested document pop up in GRC dashboard
export const getTaskData  = async (data) =>{
    let config = {
        method: 'POST',
        url: URL + grcService.getTaskData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    
    let response = await client(config);
    //console.log(response, "requested document api")
    return response;
}


