import React, { useState } from 'react';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';

//PC_ARC_1 to PC_ARC_3
const ConfigureHeadersPopUp = (props) => {
    const { setShowPopUp, updateConfigureHeaders, selectedColumns, setSelectedColumns } = props
    //console.log(props, "props");

    const customizableColumn = ['Email ID', 'Department', 'Employee ID'];
    const toastMessage = {
        path: "",
        header: "",
        message: "",
        color: ""
    };

    const [checkedColumns, setCheckedColumns] = useState(selectedColumns);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastDetails, setToastDetails] = useState(toastMessage);

    //PC_ARC_4 to PC_ARC_8
    const handleCheckBoxChange = (checkedColumn) => {
        const updatedSelectedColumns = [...selectedColumns];
        const updatedCheckedColumns = [...checkedColumns];

        if (selectedColumns.includes(checkedColumn) && checkedColumns.includes(checkedColumn)) {
            // Remove the value if it already exists
            const index = updatedSelectedColumns.indexOf(checkedColumn);
            updatedSelectedColumns.splice(index, 1);
            updatedCheckedColumns.splice(index, 1);
        } else {
            // Push the value if it doesn't exist
            updatedSelectedColumns.push(checkedColumn);
            updatedCheckedColumns.push(checkedColumn);
        }

        setSelectedColumns(updatedSelectedColumns);
        setCheckedColumns(updatedCheckedColumns);
    };

    //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>selc", selectedColumns, ">>>>>>>>>>>>>>Check", checkedColumns);

    //PC_ARC_9 to PC_ARC_11
    const handleCancel = () => {
        // Reset the selectedColumns state to an empty array
        setSelectedColumns([]);
        // Close the popup
        setShowPopUp(false);
        setIsToastVisible(false);
    };
    //PC_ARC_12 to PC_ARC_16
    const handleSave = () => {
        if (selectedColumns.length <= 5) {
            let uncheckedColumns = customizableColumn.filter(column => !selectedColumns.includes(column));
            props.setSelectedColumns(selectedColumns);
            props.setShowPopUp(false);
            props.updateConfigureHeaders(uncheckedColumns);
            setIsToastVisible(false);
        }
        else {
            setToastDetails(prevState => ({
                ...prevState,
                path: "/images/error-toast-icon.svg",
                header: "Customizable headers error",
                message: "Choose only 2 customizable headers",
                color: "error-toast"
            }));
            setIsToastVisible(true);
        }
    };

    return (
        <>
            {/* <!-- Modal --> */}
            <div
                className={`modal ${setShowPopUp ? "dull-background" : "transparent-background"}`}
                id="add-audit"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                style={{ display: setShowPopUp ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content pt-3 px-2">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 pb-3 px-4 d-flex">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">
                                Customize Columns
                            </h5>
                            <button
                                type="button"
                                className="btn-close ms-auto cursor-pointer"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleCancel()}
                            />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="mb-4">
                                        <p className="font-regular font-12 primary-textcolor mb-4">
                                            Choose the columns and the order of the columns
                                        </p>
                                        <h6 className="font-medium font-15 mb-3">Default Columns</h6>
                                        <label className="border p-2 border-radius-4 ps-4 position-relative column-draggable cursor-grab mb-3 d-block">
                                            <span className="position-absolute column-grab border-end cursor-grab">
                                                <img
                                                    src="/images/column-draggable.svg"
                                                    alt="Grab"
                                                    className="column-grab-img"
                                                />
                                            </span>
                                            <div className="form-check ps-0">
                                                <input
                                                    className="form-check-input custom-checkbox mx-2"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="username"
                                                    checked
                                                    disabled
                                                />
                                                <label
                                                    className="form-check-label font-13 font-regular"
                                                    htmlFor="username"
                                                >
                                                    User Name
                                                </label>
                                            </div>
                                        </label>
                                        <label className="border p-2 border-radius-4 ps-4 position-relative column-draggable cursor-grab mb-3 d-block">
                                            <span className="position-absolute column-grab border-end cursor-grab">
                                                <img
                                                    src="/images/column-draggable.svg"
                                                    alt="Grab"
                                                    className=" column-grab-img"
                                                />
                                            </span>
                                            <div className="form-check ps-0">
                                                <input
                                                    className="form-check-input custom-checkbox mx-2"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="report"
                                                    checked
                                                    disabled
                                                />
                                                <label
                                                    className="form-check-label font-13 font-regular"
                                                    htmlFor="report"
                                                >
                                                    Reporting User Count
                                                </label>
                                            </div>
                                        </label>
                                        <label className="border p-2 border-radius-4 ps-4 position-relative column-draggable cursor-grab mb-3 d-block">
                                            <span className="position-absolute column-grab border-end cursor-grab">
                                                <img
                                                    src="/images/column-draggable.svg"
                                                    alt="Grab"
                                                    className=" column-grab-img"
                                                />
                                            </span>
                                            <div className="form-check ps-0">
                                                <input
                                                    className="form-check-input custom-checkbox mx-2"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="app-activity"
                                                    checked
                                                    disabled
                                                />
                                                <label
                                                    className="form-check-label font-13 font-regular"
                                                    htmlFor="app-activity"
                                                >
                                                    Application Activity
                                                </label>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="font-medium font-15 mb-3">Customizable Columns</h6>
                                        <label className="border p-2 border-radius-4 ps-4 position-relative column-draggable cursor-grab mb-3 d-block">
                                            <span className="position-absolute column-grab border-end cursor-grab">
                                                <img
                                                    src="/images/column-draggable.svg"
                                                    alt="Grab"
                                                    className=" column-grab-img"
                                                />
                                            </span>
                                            <div className="form-check ps-0">
                                                <input
                                                    className="form-check-input custom-checkbox mx-2"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="email-id"
                                                    onChange={() => handleCheckBoxChange('Email ID')}
                                                    checked={checkedColumns.includes('Email ID')}
                                                />
                                                <label
                                                    className="form-check-label font-13 font-regular"
                                                    htmlFor="email-id"
                                                >
                                                    Email ID
                                                </label>
                                            </div>
                                        </label>
                                        <label className="border p-2 border-radius-4 ps-4 position-relative column-draggable cursor-grab mb-3 d-block">
                                            <span className="position-absolute column-grab border-end cursor-grab">
                                                <img
                                                    src="/images/column-draggable.svg"
                                                    alt="Grab"
                                                    className=" column-grab-img"
                                                />
                                            </span>
                                            <div className="form-check ps-0">
                                                <input
                                                    className="form-check-input custom-checkbox mx-2"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="domain"
                                                    onChange={() => handleCheckBoxChange('Department')}
                                                    checked={checkedColumns.includes('Department')}
                                                />
                                                <label
                                                    className="form-check-label font-13 font-regular"
                                                    htmlFor="domain"
                                                >
                                                    Domain
                                                </label>
                                            </div>
                                        </label>
                                        <label className="border p-2 border-radius-4 ps-4 position-relative column-draggable cursor-grab mb-3 d-block">
                                            <span className="position-absolute column-grab border-end cursor-grab">
                                                <img
                                                    src="/images/column-draggable.svg"
                                                    alt="Grab"
                                                    className=" column-grab-img"
                                                />
                                            </span>
                                            <div className="form-check ps-0">
                                                <input
                                                    className="form-check-input custom-checkbox mx-2"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="employee-id"
                                                    onChange={() => handleCheckBoxChange('Employee ID')}
                                                    checked={checkedColumns.includes('Employee ID')}
                                                />
                                                <label
                                                    className="form-check-label font-13 font-regular"
                                                    htmlFor="employee-id"
                                                >
                                                    Employee ID
                                                </label>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 mt-2 mb-3 px-4">
                            <button
                                type="button"
                                className="primary-btn font-14 font-medium py-2 me-2 cursor-pointer"
                                data-bs-dismiss="modal"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </button>
                            <button type="button" className="dark-btn font-14 font-medium py-2 cursor-pointer" onClick={() => handleSave()}>
                                Save
                            </button>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            {isToastVisible && <ToastMessageComponent
                toastResponse={toastDetails}
                setIsShowToastMessage={setIsToastVisible}
            />}
        </>

    )
}
export { ConfigureHeadersPopUp }