import React, { useEffect, useState } from 'react';
import { encryptStorage } from '../../Constant';
import { assignPopup } from '../../Services/GRC/GrcService';
import { Loader } from '../Loader/Loader';
import AssignPopupDetails from './AssignPopupDetails';

const AssignPopupComponent = ({ popupName, identifier, mappingInformation, reRender, setReRender, mappingFrom }) => {


    const [popupValues, setPopupValues] = useState([]);
    const [isAdded, setIsAdded] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [searchPopup, setSearchPopup] = useState('');
    const [active, setActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    // const [popupReRender, setPopupReRender] = useState(true);


    /**
     *PC_CC_205 - PC_CC_221
     * The values are binded in the assign popup
     */
    useEffect(() => {
        if (identifier) {
            loadAssignPopup();
        }
        // setPopupValues(data)
    }, [identifier, searchPopup, mappingInformation, reRender])

    const loadAssignPopup = async () => {
        setIsLoading(true);
        try {
            let result = await assignPopup({ "identifier": identifier, "mappingId": mappingFrom == "controlMapping" ? mappingInformation?.controlDetails?.controlId : mappingFrom == "evidence" ? mappingInformation.evidenceId : mappingFrom == "policy" ? mappingInformation.policyId : '', "startDate": encryptStorage.getItem('commencer'), "endDate": encryptStorage.getItem('fin'), search: searchPopup });
            setPopupValues(result.responseData);
            if (selectedId == '') {
                setSelectedId(result.responseData[0]?.mappingId)
            }
        }
        catch (error) {
            //console.log(error, "error");
        }
        finally {
            setIsLoading(false);
        }

    }



    /**
     * PC_CC_219 - PC_CC_222
     * Binds the valeus of the popup
     */
    let bindPopupDetails = () => {
        return (
            popupValues?.map((popup, popupindex) => (
                <div className={`tab-custom-selected border-bottom font-regular font-12 py-4 ps-4 ${active == true && popupindex == 0 ? 'active' : ''}`} key={popup.mappingId} id={popup.mappingId} data-bs-toggle="pill" data-bs-target="#v-pills-info-security" role="tab" aria-controls="v-pills-info-security" aria-selected="false"
                    style={{ cursor: "pointer" }} onClick={() => { setIsAdded(popup.present === 1 ? true : false); setSelectedId(popup.mappingId) }}>
                    {popup.mappingName}
                    {popup.present === 1 ? <span className="font-10 font-medium green-textcolor border rounded-pill ms-2 px-3 py-2">Added</span> : null}
                </div>
            ))
        );
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="modal fade" id="add-policy" style={{ "z-index": 1 }} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header px-4 border-bottom">
                            <h5 className="modal-title font-bold font-20" id="exampleModalLabel">Add {popupName}</h5>
                            <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSelectedId('') }} />
                        </div>
                        <div className="modal-body border-0 font-16 px-4 py-0">
                            <div className="row">
                                <div className="col-md-6 border-end py-4">
                                    {/*search starts here*/}
                                    <div className="w-100 mb-4">
                                        <div className="input-group flex-nowrap search-group">
                                            <input type="search" className="form-control search-align" placeholder="Search" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => { setSearchPopup(e.target.value) }} />
                                            <button className="input-group-text btn btn-search-custom" id="addon-wrapping"><img src="/images/search-icon.svg" alt="search-icon" /></button>
                                        </div>
                                    </div>
                                    {/*search ends here*/}
                                    {/*left tabs starts here*/}
                                    <div className="w-100 mb-2">
                                        <h5 className="font-16 font-medium ">{popupName}</h5>
                                        <div className="nav flex-column nav-pills policy-container" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {bindPopupDetails()}
                                        </div>
                                    </div>
                                    {/*left tabs ends here*/}
                                </div>
                                <AssignPopupDetails identifier={identifier} isAdded={isAdded} setIsAdded={setIsAdded} mappingId={selectedId} mappingInformation={mappingInformation} reRender={reRender} setReRender={setReRender} mappingFrom={mappingFrom} />
                            </div>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
        </>

    )
}

export default AssignPopupComponent