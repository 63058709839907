import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getFileNameByBlobUrl, agovContext, encryptStorage, validateFileType, validatePolicyFileType, getFileIcon } from "../../Constant";
import { handleUpload } from '../../Configs/BlobContainer';
import { deleteOtherDocuments, generateSasToken, getOtherDocumentsDetails, uploadOtherDocuments } from "../../Services/GRC/GrcService";
import { Loader } from "../Loader/Loader";
import { saveAs } from "file-saver";
import DeletePopup from "./DeletePopup";
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";
import { format } from "date-fns";

const OtherDocumentsDetails = () => {
    // PC_OD_02
    // create the respective state variables
    const navigate = useNavigate()
    const location = useLocation();
    const otherDocumentsId = location?.state?.otherDocumentsId

    const [otherDocumentsDetails, setOtherDocumentsDetails] = useState([])
    const [fileSize, setFileSizes] = useState(null);
    const [fileName, setFileName] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [isToastVisible, setIsToastVisible] = useState(false)
    const [fileType, setFileType] = useState('')
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null
    })

    const { roles, userId } = useContext(agovContext);
    const isAdmin = roles.includes('Super Admin') || roles.includes('GRC Admin')
    const viewAsAuditor = encryptStorage.getItem('viewAsAuditor') == "true" ? true : false

    let response;

    /**
 * PC_ED_06
 * The useEffect calls the API to get the data for page if otherDocumentsId value is there
 */
    useEffect(() => {
        if (otherDocumentsId) {
            fetchOtherDocumentsDetails()
        }
        else {
            navigate('/compliance/otherDocumentsGrid')
        }
    }, []);

    /**
 * PC_ED_09
 * Make the API to get the details of the other document according to the otherDocumentID
 */
    const fetchOtherDocumentsDetails = async () => {
        setIsLoading(true)
        try {
            response = await getOtherDocumentsDetails({ otherDocumentsId })
            setOtherDocumentsDetails(response.responseData)
            fetchFileSizes(response.responseData.otherDocumentsDetails.documentLink)
            getFileName(response.responseData.otherDocumentsDetails.documentLink)
        }
        catch (error) {
            //console.log(error, "error")
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_ED_20
 * Upload the user inserted file in blob and insert the blob like in DB
 */
    const handleOtherDocumentsUpload = async (event) => {
        const validate = otherDocumentsDetails?.otherDocumentsDetails?.documentName === 'System Description' ? validatePolicyFileType(event) : validateFileType(event)
        const fileSizeCheck = (event.target.files[0].size/(1024 * 1024)).toFixed(2)

        if (validate && fileSizeCheck <= 50) {
            setIsLoading(true)
            try {
                const selectedFile = await handleUpload(event.target.files[0])
                await uploadOtherDocuments({ blobUrl: selectedFile, blobName: event.target.files[0].name, otherDocumentsId: otherDocumentsId, userId: userId })
                await fetchOtherDocumentsDetails()
            }
            catch (error) {
                //console.log(error, "error");
            }
            finally {
                setIsLoading(false)
            }
        }
        else {
            setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Invalid File', message: 'Please select a valid file', color: 'error-toast' })
            setIsToastVisible(true)
        }
    }

    /**
 * PC_ED_68
 * Delete the selected file in DB
 */
    const handleDelete = async () => {
        setIsLoading(true)
        try {
            setShowDeletePopup(false)
            await deleteOtherDocuments({ userId, otherDocumentsId })
            await fetchOtherDocumentsDetails();
        }
        catch (error) {
            //console.log(error, "error");
        }
        setIsLoading(false)
    }

    /**
 * PC_PD_55
 * Get the file size based on the file url
 */
    const fetchFileSizes = async (fileUrl) => {
        setIsLoading(true)
        try {
            let response = await generateSasToken({ resourceTypes: "o", permission: "r" });
            const sasToken = response.responseData;
            const url = fileUrl + sasToken
            response = await fetch(url, { method: 'HEAD' });
            if (response.ok) {
                const size = (response.headers.get('content-length') / (1024 * 1024)).toFixed(2);
                setFileSizes(size)
            } else {
                throw new Error('Unable to fetch file size. Status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching file size:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    /**
     * PC_PD_62
     * Get the file name by using the file URL
     */
    const getFileName = async (fileUrl) => {
        try {
            let response = await generateSasToken({ resourceTypes: "o", permission: "r" });
            const sasToken = response.responseData;
            const url = fileUrl + sasToken

            const constFileName = getFileNameByBlobUrl(url)
            setFileName(constFileName)
            fetchFileTypes(constFileName)
        }
        catch (error) {
            //console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_ED_53
 * Download the selected file
 */
    const handleDownload = async () => {
        setIsLoading(true)
        try {
        let fileUrl = '';
        let response = await generateSasToken({ resourceTypes: "o", permission: "r" });
        const sasToken = response.responseData;
        fileUrl = otherDocumentsDetails?.otherDocumentsDetails?.documentLink + sasToken

        const file = fileUrl.split('?')
        const fileExtension = getFileExtension(file[0]);
        const constFileName = getFileNameByBlobUrl(fileUrl)
        saveAs(fileUrl, constFileName)
        }
        catch (error) {
            //console.log(error,"error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_PD_71
 * Get the file type
 */
    const getFileExtension = (url) => {
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const extension = filename.split(".").pop();
        return extension;
    };

    /**
   * PC_PD_280
   * Open the selected file in new tab
   */
    const openFileInNewWindow = async (file, fileName) => {
        setIsLoading(true)
        try {
            response = await generateSasToken({ resourceTypes: "o", permission: "r" })
            const sasToken = response.responseData;
            const fileUrl = file + sasToken
            saveAs(fileUrl, fileName);
        }
        catch (error) {
            //console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    };

    /**
    * PC_PD_270
    * Close the delete popup
    */
    const handleCloseDeletePopup = () => {
        setShowDeletePopup(false)
    }

    const handleOpenDeletePopup = () => {
        setShowDeletePopup(true)
    }

    const fetchFileTypes = async (fileName) => {
        const extention = getFileIcon(fileName)
        setFileType(extention)
    };

    const formatTimeline = (date) => {
        return `${format(date, "MM/dd/yyyy")}`;
    };


    return (
        <div className="container-fluid mt-4 mt-custom">
            <Loader isLoading={isLoading} />
            {isToastVisible && <ToastMessageComponent
                toastResponse={toastMessage}
                setIsShowToastMessage={setIsToastVisible}
            />}
            <div className="col-md-12 px-5">
                {showDeletePopup ? <DeletePopup onClose={handleCloseDeletePopup} onDelete={handleDelete} heading='Delete Other Document' subHeading={null} content='Are you sure you want to delete this Document? This action will permanently remove the selected other document.' /> : null}
                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <img src="/images/left-arrow.svg" alt="back" className="me-3 pointer" style = {{cursor : 'pointer'}}onClick={() => navigate(-1)} />
                        <h1 className="font-bold font-24 primary-textcolor mb-0">
                            {otherDocumentsDetails?.otherDocumentsDetails?.documentName}
                        </h1>
                    </div>
                </div>
                {!otherDocumentsDetails?.otherDocumentsDetails?.documentLink && isAdmin ? <div className="col-md-12">
                    <div className="upload-files mt-4 p-4">
                        <div className="col-md-12 col-sm-12 p-0">
                            <span className="upload-btn-wrapper upload-wrapper d-block text-center">
                                <img src="/images/upload-btn-icon.svg" alt="dropfiles" className="drop-file text-center mb-2" />
                                <span className="font-regular font-13 d-block text-center primary-textcolor mb-3">
                                    Drag and drop, or select a file
                                </span>
                                <span className="text-center upload-btn-wrapper mb-3">
                                    <button type="button" className="cust-filter-btn font-14  font-medium primary-color upload-btn ">
                                        <input disabled={viewAsAuditor} type="file" onChange={(e) => handleOtherDocumentsUpload(e)} />
                                        <img src="/images/upload.svg" alt="upload" className="me-2" />Upload
                                    </button>
                                </span>
                                <span className="font-regular font-10 d-block text-center position-relative grey-light">
                                    Upload files up to 50 MB of the following types: .docx, .pdf {otherDocumentsDetails?.otherDocumentsDetails?.documentName != 'System Description' ? '.jpg, .png, .webp, .xlsx, .csv, .txt' : null}
                                </span>
                                <input type="file" name="myfile" className="cursor-pointer cust-file" id="OrganizationLogo" />
                            </span>
                        </div>
                    </div>
                </div> :
                    <div className="col-md-12 mt-4">
                        <div>
                            <p className="font-16 font-medium">Files(1)</p>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="d-inline-flex justify-content-between align-items-center border border-radius-8 p-3">
                                <div className="d-flex align-items-center">
                                    <span className="course-thumbnail d-flex">
                                        <img src={fileType} className="w-100" alt="Thumbnail" />
                                    </span>
                                    <div className="ps-3 align-items-center vertical-align-middle">
                                        <span className="font-medium font-12 primary-textcolor d-block">{fileName}</span>
                                        <span className="grey-light font-regular font-12">{fileSize}MB</span>
                                    </div>
                                </div>
                                <div className="btn-group dropend ms-5">
                                    <button className="btn border-0 p-0 align-top" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="/images/kebab-right.svg" alt="kebab-icon" className=" align-top pt-1" />
                                    </button>
                                    <ul className="dropdown-menu custom-dropdown-style custom-dropdown-policy shadow border-0 p-2">
                                        <li>
                                            <span className="dropdown-item  font-14 d-flex align-items-center font-regular primary-textcolor custom-dropdown-li py-2 px-2 cursor-pointer" onClick={() => handleDownload()}>
                                                <img src="/images/download-light-blue-icon.svg" alt="download-icon" className="me-2" />Download
                                            </span>
                                        </li>
                                        {isAdmin && !viewAsAuditor ? <li>
                                            <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-2 custom-dropdown-li cursor-pointer" onClick={() => handleOpenDeletePopup()}>
                                                <img src="/images/delete-icon.svg" alt="eye-icon" className="me-2" />Delete
                                            </span>
                                        </li> : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>}
                {isAdmin && otherDocumentsDetails?.otherDocumentsDetails?.documentName === 'System Description' ? <div className="col-md-12">
                    <div className="template-container p-4 mt-4">
                        <div className=" d-flex justify-content-between align-items-center">
                            <div>
                                <div className="d-flex align-items-center mb-3"><img src="/images/info-icon-unfilled.svg" className="me-2" />
                                    <p className="font-16 font-medium primary-textcolor mb-0">Generate a System Description</p>
                                </div>
                                <p className="font-13 font-regular primary-textcolor ps-4 mb-0">Use our template guide to generate a system description document.</p>
                            </div>
                            <div>
                                <button type="button" className="cust-filter-btn font-14 font-regular primary-color upload-btn" onClick={() => navigate('/compliance/SystemDescription')}>
                                    Open Template
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <div className="col-md-12 mt-4">
                    <label htmlFor="CompanyName" className="form-label font-medium font-14 primary-textcolor mb-1">
                        Description
                    </label>
                    <p className="font-12 font-regular mb-2 data-color mb-3">
                        {otherDocumentsDetails?.otherDocumentsDetails?.documentDescription}
                    </p>
                </div>
                {/* Accordion structure starts here */}
                <ul className="list-unstyled ps-0">
                    <li className=" py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="p-0 d-flex align-items-center border-0 shadow-none orgtodo-accbutton-custom bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#versionHistory" aria-expanded="false" aria-controls="versionHistory">
                                <span className="font-16 font-medium mb-0 primary-textcolor ms-3">Version History
                                    <img src="/images/info-icon.svg" className="ms-1" alt="Information" data-bs-toggle="tooltip" data-bs-placement="top" title data-bs-original-title="download" aria-label="download" /></span>
                            </button>
                        </div>
                        <div id="versionHistory" className="accordion-collapse table-parent-accordion collapse pt-3">
                            <div className="rounded-4 cust-acc-box-shadow py-2">
                                <div className="cus-tab-border violet-tab-bg py-1 px-4">
                                    <div className="col-md-12 mb-3">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap mb-0 table-borderless mt-3">
                                                <thead>
                                                    <tr>
                                                        <th className="font-14 font-medium position-relative">Document Name</th>
                                                        <th className="font-14 font-medium position-relative">Uploader Name</th>
                                                        {/* <th class="font-14 font-medium position-relative">Audit Timeline</th> */}
                                                        <th className="font-14 font-medium position-relative">Uploaded Date</th>
                                                        <th className="font-14 font-medium position-relative">Approver Name</th>
                                                        <th className="font-14 font-medium position-relative">Approved Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {otherDocumentsDetails?.otherDocumentsHistory?.map((data) =>
                                                        <tr>
                                                            <td className="font-12 font-regular secondary-textcolor cursor-pointer" style={{ color: "blue" }} onClick={() => openFileInNewWindow(data?.OtherDocumentsLink, data?.DocumentName)}>{data?.DocumentName}</td>
                                                            <td className="font-12 font-regular">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={data?.ProfileURL} className="table-prof" />
                                                                    <p className="font-12 primary-textcolor mb-0 ms-2">{data?.EmployeeName}</p>
                                                                </div>
                                                            </td>
                                                            {/* <td class="font-12 font-regular">Jan, 2024 - May, 2024</td> */}
                                                            <td className="font-12 font-regular">{formatTimeline(data?.UploadDate)}</td>
                                                            <td className="font-12 font-regular">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={data?.ProfileURL} className="table-prof" />
                                                                    <p className="font-12 primary-textcolor mb-0 ms-2">{data?.EmployeeName}</p>
                                                                </div></td>
                                                            <td className="font-12 font-regular">{formatTimeline(data?.UploadDate)}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                {/* Accordion structure ends here */}
            </div>
        </div>
    )
}

export default OtherDocumentsDetails;