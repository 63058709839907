import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllUserDetails } from '../../Services/LMS/LmsService'
const TotalUserDetailsPopUp = (Props) => {

    const { onClose, selectedUserRequest } = Props

    const apiRequest = {
        campaignId: selectedUserRequest,
        limit: 10,
        sortOrder: 'desc'
    };

    //PS_TU_FE_1.8 
    const [userDetailsRequest, setUserDetailsRequest] = useState(apiRequest);
    const [userDetails, setUserDetails] = useState([]);
    const [totalUserCount, setTotalUserCount] = useState(0);
    const [hasMoreLoad, setHasMoreLoad] = useState(false);

    //PS_TU_FE_1.9
    useEffect(() => {
        fetchUserDetails()
    }, [userDetailsRequest])

    //PS_TU_FE_1.11 
    const fetchUserDetails = async () => {
        const result = await getAllUserDetails(userDetailsRequest);
        setUserDetails(result.response)
        setTotalUserCount(result.totalResultCount)
        setHasMoreLoad(result.totalResultCount > userDetailsRequest.limit ? true : false)
    }

    //PS_TU_FE_1.27 to 1.29 To bind the user details into grid 
    const bindData = () => {
        return userDetails.map((item, index) => (

            <tr key={index}>
                <td className="font-12 font-regular">
                    <span className="user-profile me-2"><img src={item.userProfile} alt="user-profile-icon" className="user-image" /></span>{item.userName}
                </td>
                <td className="font-12 font-regular">{item.userEmail}</td>
            </tr>
        ));
    };

    //PS_CG_FE_1.35 To handle cancel icon
    const handleClickCancelButton = () => {
        onClose(false);
    }

    //PS_CG_FE_1.39 to 1.44  To handle sorting on total user pop up
    const handleColumnSort = (sortOrder) => {
        setUserDetailsRequest(prevState => ({
            ...prevState,
            sortOrder: sortOrder
        }));
    };

    return (
        <>
            <div className="modal " id="total-audit" tabIndex={-1} aria-labelledby="exampleModalLabel" data-bs-backdrop="static" style={{ display: "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered total-user-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">Total Users</h5>
                            <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClickCancelButton()} />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="col-md-12">
                                <div className="stack-table-scroll" id="LMSUsers">
                                    <InfiniteScroll
                                        dataLength={userDetails.length}
                                        next={() => {
                                            setUserDetailsRequest(prevState => ({
                                                ...prevState,
                                                limit: prevState.limit + 10
                                            }));
                                        }}
                                        hasMore={hasMoreLoad}
                                        scrollThreshold={0.9}
                                        scrollableTarget="LMSUsers"
                                    >
                                        <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                            <thead className="sticky-top">
                                                <tr>
                                                    <th className="font-14 px-4 py-3 position-relative">User Name
                                                        <span className="position-absolute">
                                                            <span className="sort-up position-absolute ms-2" onClick={() => handleColumnSort('asc')}>
                                                                <img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" />
                                                            </span>
                                                            <span className="sort-down position-absolute ms-2" onClick={() => handleColumnSort('desc')}>
                                                                <img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" />
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th className="font-14 position-relative px-4 py-2">Email ID
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bindData()}
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                </div>
                                <p className="font-12 grey-primary mt-3">Showing <span className="font-medium primary-textcolor">{totalUserCount}  </span>
                                    items</p>
                            </div>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </>
    )
}
export default TotalUserDetailsPopUp