import React, { useState, useContext } from 'react'
import { getExportControls, markAuditAsInComplete } from '../../Services/GRC/GrcService';
import { encryptStorage } from '../../Constant';
import { saveAs } from 'file-saver';
import { agovContext } from '../../Constant'
import ControlAuditStatus from './ControlAuditStatus';
import CompleteAuditPopup from './CompleteAuditPopup';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import ExcelJS from 'exceljs';

const ControlHeader = ({ setSearchControl, setHideControlInfo, setHideAddControlPage, setHideControlsGrid, setViewAsAuditor, viewAsAuditor, isAuditor, filteredData }) => {


    /**
     * PC_CC_25
     * create the required state variables 
     */
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const [search, setSearch] = useState('');
    const [auditStatus, setAuditStatus] = useState('');
    const [reRender, setReRender] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    /**
     * PC_CC_31 -  PC_CC_34
     * Search function that triggers useEffect and renders the data 
     */
    const handleSearch = () => {
        setSearchControl(search);
        setHideControlInfo(true);
        setHideAddControlPage(true);
        setHideControlsGrid(false);
    }


    /**
     * PC_CC_38 - PC_CC_56
     *  This function downloads the list of controls when the the export controls is clicked
     */
    const handleExport = async () => {
        //console.log(filteredData, "fl")
        setIsLoading(true);
        let result = await getExportControls({ "startDate": encryptStorage.getItem('commencer'), "endDate": encryptStorage.getItem('fin'), auditCycleID: encryptStorage.getItem('identifiant'), userId: userId, filter : filteredData });
        const dataToExport = result?.responseData.map(item => ({
            controlId: item.control_identity,
            controlName: item.control_name,
            controlDescription: item.control_description,
            controlOwner: item.employee_name ? item.employee_name : '',
            controlFrameworkMappings: item.mapped_principles,
            controlEvidenceStatus: item.evidence_status
        }));

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        const headers = ['controlId', 'controlName', 'controlDescription', 'controlOwner', 'controlFrameworkMappings', 'controlEvidenceStatus'];
        worksheet.addRow(headers);

        dataToExport.forEach(data => {
            const row = [];
            headers.forEach(header => {
                row.push(data[header] || '');
            });
            worksheet.addRow(row);
        });

        const excelBuffer = await workbook.xlsx.writeBuffer();
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(data, 'AGov-Controls.xlsx');
        setIsLoading(false);
    };

    /**
     * PC_CC_412 - PC_CC_423
     * This function marks the audit as incomplete
     */
    const changeAuditStatus = async () => {
        try {
            setIsLoading(true)
            let result = await markAuditAsInComplete({ auditCycleId: encryptStorage.getItem('identifiant') })
            // if (result?.statusCode == 200) {
            //     setTimeout(() => {
            //         navigate(0);
            //     }, 3000);
            // }
            navigate(0);
        }
        catch(error) {
            //console.log(error,"error");
        }
        finally {
            setIsLoading(false)
        }

    }

    return (
        <div className="d-lg-flex d-block">
            <ControlAuditStatus setAuditStatus={setAuditStatus} isAuditor={isAuditor} />
            <div className="d-flex">
                <div className="input-group flex-nowrap search-group me-2">
                    <input
                        type="search"
                        className="form-control search-align"
                        placeholder="Search Controls"
                        aria-label="search"
                        aria-describedby="addon-wrapping"
                        onChange={(e) => { setSearch(e.target.value) }}
                        onKeyDownCapture={(e) => {
                            if (e.key == "Enter") {
                                handleSearch()
                            }
                        }}
                    />
                    <button
                        className="input-group-text btn btn-search-custom"
                        id="addon-wrapping" onClick={() => { handleSearch() }}
                    >
                        <img src="/images/search-icon.svg" alt="search-icon" />
                    </button>
                </div>

                {/* PC_CC_386 - PC_CC_388
                Popup opens when the complete audit button is clicked
                 */}
                {isAuditor == true ?
                    auditStatus == 'in progress' ?
                        <button type="button" className="green-btn font-14 font-regular px-3" data-bs-toggle="modal" data-bs-target="#complete-audit">
                            <img src="/images/check-icon.svg" class="me-2" />
                            Complete Audit
                        </button>
                        :
                        auditStatus == 'completed' ?
                            <button type="button" className="green-btn font-14 font-regular px-3" onClick={() => { changeAuditStatus() }}>
                                Mark Audit As Incomplete
                            </button>
                            : ''
                    : ''}

                {/* PC_CC_35 - PC_CC_37 
                shows the hide Export controls 
                 */}
                <div className="btn-group dropend border border-radius-4" style={{ marginLeft: "5px" }}>                    <button
                        className="btn border-0 p-0 align-top mb-1"
                        type="button"
                        id="dropdownMenuButton3"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // style={{"marginRight" : "4px"}}
                         style={{ width: '24px' }} 
                    >
                        <img
                            src="/images/kebab-icon.svg"
                            alt="kebab-icon"
                            className="align-centre pt-1"
                           
                        />
                    </button>
                    <ul className="dropdown-menu custom-dropdown-sty custom-dropdown-compliance shadow border-0 p-2">
                        <li onClick={handleExport}>
                            <span className="dropdown-item  font-14 d-flex align-items-center font-regular primary-textcolor custom-dropdown-li cursor-pointer py-2 px-3">
                                <img
                                    src="/images/download-icon-black.svg"
                                    alt="download-icon"
                                    className="me-2"
                                />
                                Export Controls
                            </span>
                        </li>
                        {/* PC_CC_57 - PC_CC_60
                        The view as auditor feature will be enable
                         */}
                        <li hidden={isAuditor} onClick={() => { encryptStorage.setItem('viewAsAuditor', !viewAsAuditor); setViewAsAuditor(!viewAsAuditor); }}>
                            <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-3 cursor-pointer custom-dropdown-li">
                                <img
                                    src="/images/eye-icon.svg"
                                    alt="eye-icon"
                                    className="me-2"
                                />{" "}
                                View as Auditor
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <CompleteAuditPopup />
            <Loader isLoading={isLoading} />
        </div>
    )
}

export default ControlHeader