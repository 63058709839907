//PC_COM_CS_4 to PC_COM_CS_6 --> Import statements  
import { useEffect, useState } from "react"
import { complianceSummaryApiResponse } from "../../Services/GRC/GrcService"
import { PopUpComponent } from "./popUpComponent"
import { Loader } from "../Loader/Loader"
import { useNavigate } from "react-router-dom"
// PC_COM_CS_13 -> function declaration
export function ComplianceSummary({ data }) {

  // PC_COM_CS_14 and PC_COM_CS_15 -> state variables creation
  const [complianceSummaryData, setComplianceSummaryData] = useState({})
  const [showPopUp, setShowPopUp] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // PC_COM_CS_16 -> useEffect declaration
  useEffect(() => {
    complianceSummaryWidget()
  }, [data])

  // PC_COM_CS_17 -> function declaration
  const complianceSummaryWidget = async () => {
    try {

      // PC_COM_CS_18 -> set state a variable isLoading as true and Api function call
      setIsLoading(true);
      const result = await complianceSummaryApiResponse(data)

      // PC_COM_CS_25 -> state variable updation whether statusCode is equal to 200
      if (result.statusCode === 200) {
        setComplianceSummaryData(result.resonseData)
        //console.log(result, "compliance summary")
      }

      // PC_COM_CS_27 -> navigate to error page when statusCode in not equals to 200
      else {
        navigate('/error');
      }
      // PC_COM_CS_26 -> set state a variable isLoading
      setIsLoading(false);
    }

    // PC_COM_CS_27 -> navigate to error page
    catch (error) {
      navigate('/error');
    }
  }

  // PC_COM_CS_31 to PC_COM_CS_35 -> set state a variable showPopUp as true while clicking expand button 
  // PC_COM_CS_37 and PC_COM_CS_38 -> set state a variable showPopUp as false while clicking a close button in pop-up
  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  // html part
  return (
    <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3">
      <div className="dashboard-carousel p-3 border-radius-8 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <Loader isLoading={isLoading} />
          <h3 className="font-14 font-medium primary-textcolor mb-0">
            Compliance Summary</h3>
          <img className="cursor-pointer" src="images/expand-icon.svg" onClick={() => togglePopUp()} />
          {showPopUp && <PopUpComponent data={complianceSummaryData} onClose={togglePopUp}></PopUpComponent>}
        </div>
        <div id="security-carousel" className="carousel slide mt-4" data-bs-ride="carousel">
          <div className="carousel-indicators custom-carousel-indicators align-items-center">
            <button className="carousel-control-prev align-items-end custom-carousel-control" type="button" data-bs-target="#security-carousel" data-bs-slide="prev">
              <span className="carousel-control-prev-icon carousel-arrow-indicators" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
            <button type="button" data-bs-target="#security-carousel" data-bs-slide-to={1} aria-label="Slide 2" />
            <button type="button" data-bs-target="#security-carousel" data-bs-slide-to={2} aria-label="Slide 3" />
            <button type="button" data-bs-target="#security-carousel" data-bs-slide-to={3} aria-label="Slide 4" />
            <button type="button" data-bs-target="#security-carousel" data-bs-slide-to={4} aria-label="Slide 5" />
            <button className="carousel-control-next align-items-end custom-carousel-control" type="button" data-bs-target="#security-carousel" data-bs-slide="next">
              <span className="carousel-control-next-icon carousel-arrow-indicators" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div className="carousel-inner carousel-inner-height">
            <div className="carousel-item active">
              <p className="font-medium font-40 secondary-textcolor">
                {complianceSummaryData.controlNotMapped}</p>
              <p className="font-medium font-12 mt-5">Controls
                without Policies/Evidence
                mapped</p>
              <p className="font-12 primary-textcolor line-height-24 w-75">
                Discover controls lacking corresponding
                policies or evidence for
                compliance assurance.</p>
            </div>
            <div className="carousel-item">
              <p className="font-medium font-40 secondary-textcolor">
                {complianceSummaryData.controlNotAssigned}</p>
              <p className="font-medium font-12 mt-5">Controls Not Assigned</p>
              <p className="font-12 primary-textcolor line-height-24 w-75">
                Discover the controls that are not assigned to any users, indicating potential gaps in monitoring and oversight.
              </p>
            </div>
            <div className="carousel-item">
              <p className="font-medium font-40 secondary-textcolor">
                {complianceSummaryData.commentsCount}</p>
              <p className="font-medium font-12 mt-5">Comments mentioned by Auditor</p>
              <p className="font-12 primary-textcolor line-height-24 w-75">
              Discover the comments that have been mentioned by the auditor, which may require additional attention or clarification.</p>
            </div>
            <div className="carousel-item">
              <p className="font-medium font-40 secondary-textcolor">
                {complianceSummaryData.policyEvidenceOverDue}</p>
              <p className="font-medium font-12 mt-5">Policy/Evidence pending post duedate</p>
              <p className="font-12 primary-textcolor line-height-24 w-75">
              Discover the policy/evidence submissions that are pending and have exceeded their due date, potentially impacting compliance or audit readiness.</p>
            </div>
            <div className="carousel-item">
              <p className="font-medium font-40 secondary-textcolor">
                {complianceSummaryData.policyWithoutRevision}</p>
              <p className="font-medium font-12 mt-5">Policies without revision</p>
              <p className="font-12 primary-textcolor line-height-24 w-75">
                Discover the policies that have not undergone revisions, which may indicate a lack of updates or changes to align with evolving regulations or standards.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}