// PC_AI_1 to PC_AI_9
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import {
  insertAdminInformation,
  getAdminInformation,
  updateAdminInformation,
} from "../../Services/Admin/AdminService";
import { handleUpload } from "../../Configs/BlobContainer";
import UploadedMediaSection from "../LMS/UploadedMediaSection";
import UploadMediaSection from "../LMS/UploadMediaSection";
import { agovContext, getFileDetails } from "../../Constant";
import { Loader } from "../Loader/Loader";
import { isObject } from "lodash";

import moment from "moment-timezone";

const { Option } = Select;

// PC_AI_10 to PC_AI_20
const AdminInformation = () => {
  const { userId, reportsToId, employeeName } = useContext(agovContext);

  let adminInformationObj = {
    orgId: null,
    companyName: "",
    companyUrl: "",
    phoneNumber: "",
    domainName: [],
    companyLogo: "",
    companyAddress: "",
    timeZones: "",
    fromTime: "",
    toTime: "",
    createdBy: employeeName,
    modifiedBy: employeeName,
    managerId: reportsToId,
    managerName: employeeName,
    userId: userId,
  };

  let adminInformationErrorObj = {
    companyNameError: false,
    urlError: false,
    urlRegexError: false,
    phoneNumberError: false,
    phoneNumberRegexError: false,
    domainNameError: false,
    statusError: false,
    companyLogoError: false,
    companyLogoRegexError: false,
    companyAddressError: false,
    fromTimeError: false,
    toTimeError: false,
    timeZones: false,
  };
  const [adminInfo, setAdminInfo] = useState(adminInformationObj);
  const [errors, setErrors] = useState(adminInformationErrorObj);
  const [typedData, setTypedData] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [logoImageData, setLogoImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  // PC_AI_21
  useEffect(() => {
    editAdminInfo();
    const zones = moment.tz.names().map((zone) => {
      // Get the actual offset using the moment-timezone functionality
      const offset = moment.tz(zone).utcOffset();
      // Format offset hours and minutes
      const offsetHours = Math.floor(Math.abs(offset) / 60);
      const offsetMinutes = Math.abs(offset) % 60;
      // Combine zone with formatted offset for display
      const offsetLabel = `UTC${offset >= 0 ? "+" : "-"}${offsetHours
        .toString()
        .padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`;
      return {
        name: zone,
        label: `${offsetLabel} ${zone}`,
      };
    });
    setTimeZones(zones);
  }, []);

  const navigate = useNavigate();

  //PC_AI_22 to PC_AI_37
  const handleInputChange = (e) => {
    let adminInformationErrorObj = { ...errors };
    // if (e.target.name === 'phoneNumber') {
    //     let contactRegex = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
    //     if (contactRegex.test(e.target.value)) {
    //         adminInformationErrorObj.phoneNumberRegexError = false;
    //     } else {
    //         adminInformationErrorObj.phoneNumberRegexError = true;
    //     }
    // }
    if (e.target.name === "companyUrl") {
      let urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      if (urlRegex.test(e.target.value)) {
        adminInformationErrorObj.urlRegexError = false;
      } else {
        adminInformationErrorObj.urlRegexError = true;
      }
    }

    let value =
      e.target.name !== "status" ? e.target.value : e.target.checked ? 1 : 0;

    if (e.target.name === "companyName") {
      let nameRegex =  /^[A-Za-z0-9 ]+$/;
      if (nameRegex.test(e.target.value) || !e.target.value) {
        value = e.target.value;
      } else {
        value = adminInfo.companyName;
      }
    }

    if (e.target.name === "phoneNumber") {
      let numberRegex = /^[0-9\-+]+$/;
      if (numberRegex.test(e.target.value) || !e.target.value) {
        value = e.target.value;
        if (!e.target.value)
          adminInformationErrorObj.phoneNumberRegexError = true;
        else adminInformationErrorObj.phoneNumberRegexError = false;
      } else {
        value = adminInfo.phoneNumber;
        adminInformationErrorObj.phoneNumberRegexError = false;
      }
    }

    if (e.target.name === "domainName") {
      //console.log(e.target.value);
      //console.log(e.target.value.includes("."), "target value");
      // Check if the value contains at least one dot
      if (e.target.value.every((domain) => domain.includes("."))) {
        value = e.target.value;
      } else {
        value = adminInfo.domainName;
      }
    }

    if (e.target.name === "timeZones") {
      //console.log(e.target.value);
      value = e.target.value;
    }

    setAdminInfo({ ...adminInfo, [e.target.name]: value });
    setErrors(adminInformationErrorObj);
    //console.log(adminInfo, "adminInfo");
  };

  //PC_AI_43 to PC_AI_55
  const insertAdminInfo = async (companyUrl) => {
    try {
      setIsLoading(true);
      let companyLogoBlob = companyUrl;
      setAdminInfo((prevState) => ({
        ...prevState,
        companyLogo: companyLogoBlob, // Assuming 'result' contains the file URL or data
      }));
      let result = await insertAdminInformation(adminInfo);
      if (result.statusCode === 200) {
        //console.log(result.responseData.orgId);
        setOrganizationId(result.responseData.orgId);
        //console.log("Admin Information added successfully");
      } else {
        //console.log("Something went wrong!");
      }
    } catch (error) {
      console.error("Error editing admin information:", error);
      //console.log("Something went wrong!");
    }
    setIsLoading(false);
  };

  //PC_AI_56 toPC_AI_72
  const editAdminInfo = async () => {
    try {
      setIsLoading(true);
      let result = await getAdminInformation();
      //console.log(result, "editAdminInfo");
      if (result.statusCode === 200) {
        try {
          const mediaData = await getFileDetails(
            result.responseData.orgData.company_logo
          );
          setLogoImageData(mediaData);
        } catch (error) {
          console.error("Error fetching media data:", error);
        }
        // Map response data to options
        const options = result.responseData.domainName.map((item) => ({
          label: item.domain_name,
          value: item.domain_name,
        }));
        // Set admin info state
        let editAdminInformationObj = {
          orgId: result.responseData.orgData.organization_id,
          companyName: result.responseData.orgData.company_name,
          companyUrl: result.responseData.orgData.company_url,
          phoneNumber: result.responseData.orgData.phone_no,
          timeZones: result.responseData.orgData.time_zone,
          domainName: result.responseData.domainName.map(
            (item) => item.domain_name
          ),
          companyLogo: result.responseData.orgData.company_logo,
          companyAddress: result.responseData.orgData.company_address,
          fromTime: result.responseData.orgData.from_time,
          toTime: result.responseData.orgData.to_time,
        };
        // Set isEditing flag based on whether orgId exists
        setIsEditing(!!editAdminInformationObj.orgId);
        setAdminInfo(editAdminInformationObj);
        setTypedData(options); // Set options state
      } else {
        //console.log("Something went wrong!");
      }
    } catch (error) {
      console.error("Error editing admin information:", error);
      //console.log("Something went wrong!");
    }
    setIsLoading(false);
  };

  const handleDeleteLogoImage = () => {
    setLogoImageData(null);
    setAdminInfo({ ...adminInfo, companyLogo: "" });
  };

  const handleLogoChange = (event) => {
    const image = event.target.files[0];
    setErrors(adminInformationErrorObj);
    const updatedError = { ...errors };
    updatedError.companyLogoError = "";

    const imageExtension = image.name.split(".").pop().toLowerCase();
    const imageSizeInMB = (image.size / (1024 * 1024)).toFixed(2);
    const allowedExtensions = ["jpg", "jpeg", "png"];

    if (!allowedExtensions.includes(imageExtension)) {
      updatedError.companyLogoError =
        "Invalid file format. Please upload a valid file (e.g., JPEG, PNG, JPG)";
      setErrors(updatedError);
      setLogoImageData(null);
      setAdminInfo({ ...adminInfo, companyLogo: "" });
      return;
    }
    // Check file size (max size in bytes, here 5MB)
    const maxFileSize = 5 * 1024 * 1024;
    if (image.size > maxFileSize) {
      updatedError.companyLogoError = "Image size exceeds the limit";
      setErrors(updatedError);
      setLogoImageData(null);
      setAdminInfo({ ...adminInfo, companyLogo: "" });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const uploadedImage = e.target.result;
      setLogoImageData({
        name: image.name,
        size: imageSizeInMB,
        extension: imageExtension,
        src: uploadedImage,
      });
    };
    reader.readAsDataURL(image);
    setAdminInfo({ ...adminInfo, companyLogo: image });
    setErrors(updatedError);
  };

  //PC_AI_73 to PC_AI_87
  const updateAdminInfo = async (companyLogoUrl) => {
    try {
      setIsLoading(true);
      let companyLogoBlobUrl = companyLogoUrl;
      let updateAdminInfoObj = {
        orgId: adminInfo.orgId,
        companyName: adminInfo.companyName,
        companyUrl: adminInfo.companyUrl,
        phoneNumber: adminInfo.phoneNumber,
        domainName: adminInfo.domainName,
        timeZones: adminInfo.timeZones,
        companyLogo: companyLogoBlobUrl,
        companyAddress: adminInfo.companyAddress,
        fromTime: adminInfo.fromTime,
        toTime: adminInfo.toTime,
        modifiedBy: employeeName,
        userId: userId,
        managerId: reportsToId,
        appliedBy: employeeName,
        appliedOn: employeeName,
      };
      setAdminInfo(updateAdminInfoObj);
      let result = await updateAdminInformation(updateAdminInfoObj);
      if (result.statusCode === 200) {
        setErrors(adminInformationErrorObj);
        //console.log("Admin Information updated successfully");
      } else {
        //console.log("Something went wrong!");
      }
    } catch (error) {
      console.error("Error editing admin information:", error);
      //console.log("Something went wrong!");
    }
    setIsLoading(false);
  };

  //PC_AI_88 to PC_AI_110
  const validateInformationForm = () => {
    let isValid = true;
    let errorObj = { ...errors };
    if (adminInfo.companyName === "") {
      errorObj.companyNameError = true;
      isValid = false;
    }
    if (adminInfo.companyUrl === "") {
      errorObj.urlError = true;
      isValid = false;
    }
    if (adminInfo.phoneNumber === "") {
      errorObj.phoneNumberError = true;
      isValid = false;
    }
    if (adminInfo.domainName.length === 0) {
      errorObj.domainNameError = true;
      isValid = false;
    }
    if (adminInfo.timeZones === null) {
      errorObj.statusError = true;
      isValid = false;
    }
    if (adminInfo.companyLogo === "") {
      errorObj.companyLogoError = true;
      isValid = false;
    }
    if (adminInfo.companyAddress === "") {
      errorObj.companyAddressError = true;
      isValid = false;
    }
    if (adminInfo.fromTime === "") {
      errorObj.fromTimeError = true;
      isValid = false;
    }
    if (adminInfo.toTime === "") {
      errorObj.toTimeError = true;
      isValid = false;
    }
    setErrors(errorObj);
    return isValid;
  };
  //PC_AI_111 to PC_AI_113
  const handleClear = async () => {
    let adminInformationObj = {
      companyName: "",
      companyUrl: "",
      phoneNumber: "",
      domainName: [],
      timeZones: "",
      companyLogo: "",
      companyAddress: "",
      fromTime: "",
      toTime: "",
    };
    setAdminInfo(adminInformationObj);
    setLogoImageData(null);
  };

  //PC_AI_114 to PC_AI_120
  const handleSaveButtonClick = async () => {
    setIsLoading(true);
    let result;
    if (isObject(adminInfo.companyLogo)) {
      result = await handleUpload(adminInfo.companyLogo);
    } else if (adminInfo.companyLogo !== "") {
      result = adminInfo.companyLogo.split("?")[0];
    }

    if (validateInformationForm()) {
      if (isEditing) {
        await updateAdminInfo(result);
      } else {
        await insertAdminInfo(result);
      }
    }
    setIsLoading(false);
  };

  // const handleTimeZoneChange = (event) => {
  //     setSelectedTimeZone(event.target.value);
  //     handleInputChange(event)
  //   };

  return (
    <>
      <div className="container-fluid mt-4 mt-custom">
        <div className="col-md-12">
          <div className="px-3 px-xl-5">
            <div className="d-flex justify-content-between mt-4">
              <h1 className="font-bold font-24 primary-textcolor">
                Admin Configurations
              </h1>
            </div>
            {/* tabs starts here */}
            <div className="d-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link px-0 active font-14 tab-style rounded-0"
                    id="information-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#information"
                    type="button"
                    role="tab"
                    aria-controls="information"
                    aria-selected="true"
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/information-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/information-icon.svg"
                        alt="information-icon"
                        className="me-2"
                      />
                    </span>
                    Information
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="permissions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#permissions"
                    type="button"
                    role="tab"
                    aria-controls="permissions"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate("./userPermission")}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/permission-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/permission-icon.svg"
                        alt="permissions-icon"
                        className="me-2"
                      />
                    </span>
                    Permissions
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="configurations-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#configurations"
                    type="button"
                    role="tab"
                    aria-controls="configurations"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate("./accessReview")}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/key-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/key-icon.svg"
                        alt="configurations-icon"
                        className="me-2"
                      />
                      Configurations
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="app-owner-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#app-owner-config"
                    type="button"
                    role="tab"
                    aria-controls="app-owner-config"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate("./appOwnerConfig")}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/app-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/app-icon.svg"
                        alt="app-owner-config-icon"
                        className="me-2"
                      />
                      App Owner Config
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="security-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#security"
                    type="button"
                    role="tab"
                    aria-controls="security"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate("./securityConfig")}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/lock-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/lock-icon.svg"
                        alt="security-icon"
                        className="me-2"
                      />
                      Security
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="policy-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#policy-config"
                    type="button"
                    role="tab"
                    aria-controls="policy-config"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate("./policyConfig")}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/privacy-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/privacy-icon.svg"
                        alt="policy-config-icon"
                        className="me-2"
                      />
                      Policy Configuration
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="billing-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#billing"
                    type="button"
                    role="tab"
                    aria-controls="billing"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate("./billing")}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/bill-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/bill-icon.svg"
                        alt="billing-icon"
                        className="me-2"
                      />
                      Billing
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            {/* tab ends here */}
            <div className="row position-relative">
              <div className="tab-content" id="pills-tabContent">
                {/* Information tab starts here */}
                <div
                  className="tab-pane fade show active mt-3"
                  id="information"
                  role="tabpanel"
                  aria-labelledby="information-tab"
                >
                  <div className="row justify-content-center">
                    <div className="col-md-9">
                      <div className="px-3">
                        <h2 className="font-medium font-24 mb-3">
                          Information
                        </h2>
                        <p className="font-14 font-regular custom-grey-text">
                          Complete general information about your business and
                          its physical location to inform policies and satisfy
                          compliance requirements.
                        </p>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 custom-form-padding">
                          <label
                            htmlFor="AdminInfoCompanyName"
                            className="form-label font-14 font-medium"
                          >
                            Company Name
                          </label>
                          <input
                            type="text"
                            className="form-control custom-input-field"
                            id="AdminInfoCompanyName"
                            name="companyName"
                            maxLength={200}
                            placeholder="Enter Display Name"
                            value={adminInfo.companyName}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          <h6 className="margin5Admin">
                            {errors.companyNameError ? (
                              <span id="alertMessage" style={{color:"red",font:"small-caption"}}>Enter Company Name</span>
                            ) : null}
                          </h6>
                        </div>
                        <div className="col-md-6 custom-form-padding">
                          <label
                            htmlFor="AdminInfoUrl"
                            className="form-label font-14 font-medium"
                          >
                            URL
                          </label>
                          <input
                            type="url"
                            className="form-control custom-input-field"
                            id="AdminInfoUrl"
                            name="companyUrl"
                            placeholder="https://"
                            value={adminInfo.companyUrl}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          <h6 className="margin5Admin">
                            {errors.urlError ? (
                              <span id="alertMessage" style={{color:"red",font:"small-caption"}}>Enter Url</span>
                            ) : errors.urlRegexError ? (
                              <span id="alertMessage" style={{color:"red",font:"small-caption"}}>Enter Valid Url</span>
                            ) : null}
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6 custom-form-padding">
                          <label
                            htmlFor="AdminInfoPhoneNumber"
                            className="form-label font-14 font-medium"
                          >
                            Phone Number
                          </label>
                          <input
                            type="tel"
                            className="form-control custom-input-field"
                            id="AdminInfoPhoneNumber"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            value={adminInfo.phoneNumber}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          <h6 className="margin5Admin">
                            {errors.phoneNumberError ? (
                              <span id="alertMessage" style={{color:"red",font:"small-caption"}}>Enter Phone Number</span>
                            ) : errors.phoneNumberRegexError ? (
                              <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                                Enter Valid Phone Number
                              </span>
                            ) : null}
                          </h6>
                        </div>
                        <div className="col-md-6 custom-form-padding">
                          <label
                            htmlFor="AdminInfoManagedDomain"
                            className="form-label font-14 font-medium"
                          >
                            Managed Domains
                          </label>
                          <div className="select-container">
                          <Select
  mode="tags"
  style={{ width: "100%", overflowY: "auto" }}
  onChange={(value) => {
    // Check if the first element is being removed
    if (value.length < adminInfo.domainName.length && adminInfo.domainName[0] !== value[0]) {
      // If the first element is not in the new value, prevent the change
      return;
    }
    handleInputChange({
      target: { name: "domainName", value },
    });
  }}
  tokenSeparators={[","]}
  value={adminInfo.domainName}
  placeholder="Enter Domain"
>
  {adminInfo.domainName.map((domain) => (
    <Select.Option key={domain} value={domain}>
      {domain}
    </Select.Option>
  ))}
</Select>
                            <h6 className="margin5Admin">
                              {errors.domainNameError ? (
                                <span id="alertMessage" style={{color:"red",font:"small-caption"}}>Choose Domain</span>
                              ) : null}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        {/* <div className="col-md-6 custom-form-padding">
                                                    <label htmlFor="AdminInfoStatus" className="form-label font-14 font-medium">Status</label>
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox" id="AdminInfoStatus" name="status" onChange={(e) => { handleInputChange(e) }} checked={adminInfo.status === 1} />
                                                        <label className="form-check-label font-13 primary-textcolor" htmlFor="AdminInfoStatus">Active</label>
                                                        <h6 className = "margin5Admin">
                                                            {errors.statusError ? (
                                                                <span id="alertMessage">
                                                                    Please make the status Active
                                                                </span>) : null}
                                                        </h6>
                                                    </div>
                                                </div>  Replacing Company Address Instead of the below status */}
                        <div className="col-md-6 custom-form-padding">
                          <label
                            htmlFor="companyAddress"
                            className="form-label font-14 font-medium"
                          >
                            Company Address
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              maxHeight: "4.5rem",
                            }}
                          >
                            <textarea
                              className="form-control custom-input-field no-resize"
                              name="companyAddress"
                              style={{ flex: "1", overflow: "hidden" }}
                              rows={3}
                              placeholder="Enter Company Address"
                              defaultValue={""}
                              value={adminInfo.companyAddress}
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                            />
                          </div>
                          <h6 className="margin5Admin">
                            {errors.companyAddressError ? (
                              <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                                Enter the company Address
                              </span>
                            ) : null}
                          </h6>
                        </div>
                        <div className="col-md-6 custom-form-padding">
                          <label
                            htmlFor="AdminInfoCompanyLogo"
                            className="form-label font-medium font-14 primary-textcolor"
                          >
                            Company Logo
                          </label>
                          <span className="upload-btn-wrapper me-2 d-block cursor-pointer ">
                            {logoImageData ? (
                              <UploadedMediaSection
                                mediaData={logoImageData}
                                onDelete={handleDeleteLogoImage}
                                isMedia={false}
                              />
                            ) : (
                              <>
                                <UploadMediaSection
                                  handleUpload={handleLogoChange}
                                  allowedExtensions=".jpg, .png, .jpeg"
                                  error={errors.companyLogoError}
                                />
                                <span class="font-12 grey-secondary">
                                  Upload a JPEG, PNG, or JPG file.
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-4">
                        {/* <div className="col-md-6 custom-form-padding">
                                                    <label htmlFor="timeZoneSelect" className="form-label font-14 font-medium">
                                                        Time Zone
                                                    </label>
                                                    <select
                                                        id="timeZoneSelect"
                                                        className="form-select cust-input-sty font-14 py-2 font-regular"
                                                        aria-label="Default select example"
                                                        value={selectedTimeZone}
                                                        onChange={handleTimeZoneChange}
                                                    >
                                                        <option value="" disabled>
                                                        Select
                                                        </option>
                                                        {timeZones.map((zone, index) => (
                                                        <option key={index} value={zone.name} name="timeZones"  onChange={(e) => { handleInputChange(e) }}>
                                                            {zone.label}
                                                            
                                                        </option>
                                                        ))}
                                                    </select>
                                               </div> */}

                        <div className="col-md-6 custom-form-padding">
                          <label
                            htmlFor="timeZoneSelect"
                            className="form-label font-14 font-medium"
                          >
                            Time Zone
                          </label>
                          <select
                            id="timeZoneSelect"
                            className="form-select cust-input-sty font-14 py-2 font-regular"
                            aria-label="Time Zone Select"
                            value={adminInfo.timeZones}
                            name="timeZones" // This `name` value must match the object property used in `handleInputChange` function
                            onChange={handleInputChange}
                          >
                            {adminInfo.timeZones != null ?
                            <option value="" disabled>
                            {adminInfo.timeZones}
                          </option>:
                            <option value="" disabled>
                              Select
                            </option>}
                            {timeZones.map((zone, index) => (
                              <option key={index} value={zone.label}>
                                {zone.label}
                              </option>
                            ))}
                          </select>
                          <h6 className="margin5Admin">
                            {errors.timeZones ? (
                              <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                               Please Select Time Zone
                              </span>
                            ) : null}
                          </h6>
                        </div>

                        <div className="col-md-6 custom-form-padding">
                          <label
                            htmlFor="business-hours"
                            className="form-label font-14 font-medium"
                          >
                            Business Hours
                          </label>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="time"
                                id="business-hours"
                                name="fromTime"
                                className="cursor-pointer upload-btn-input form-control"
                                value={adminInfo.fromTime}
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                              />
                              <h6 className="margin5Admin">
                                {errors.fromTimeError ? (
                                  <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                                    Enter the buisness hours
                                  </span>
                                ) : null}
                              </h6>
                            </div>
                            <div className="col-md-6">
                              <input
                                type="time"
                                id="business-hours"
                                name="toTime"
                                className="cursor-pointer upload-btn-input form-control"
                                value={adminInfo.toTime}
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                              />
                              <h6 className="margin5Admin">
                                {errors.toTimeError ? (
                                  <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                                    Enter the buisness hours
                                  </span>
                                ) : null}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="text-end mt-4 mb-2 px-4 mb-5">
                          <button
                            type="button"
                            className="primary-btn font-14 font-medium me-2 cursor-pointer"
                            onClick={() => {
                              handleClear();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="dark-btn font-14 font-medium px-4 cursor-pointer"
                            onClick={() => {
                              handleSaveButtonClick();
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Information tab ends here */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
};

export { AdminInformation };
