import { client } from "../../Client/Client";
import { backendUrl as URL, riskService } from "../../Constant";
/**
 * In `Each Methods()`, Declare an object variable `config` with keys as `method`, `url`, `headers`, and `data`, and initialize its value accordingly.
 * Using await call the `Client()` method and pass the argument as `config
 * Store the returned data in the variable `response`and Return the response 
 */

export const getExportExternalDomain = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getExportExternalDomain,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
//PS_SP_11 - PS_SP_14 the sevice with the content type and payload will be handled here

export const getExternalDomain = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getExternalDomains,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAllUsersInfo = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getAllUsersInfo,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getUserDepartment = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getUserDepartment,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAllDeviceEmployee = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getAllDeviceEmployee,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAllDevicesInfo = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getAllDevicesInfo,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAllApplicationsInfo = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getAllApplicationsInfo,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAllUsersExcel = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getAllUsersExcel,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
export const getAllApplicationsExcel = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getAllApplicationsExcel,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getRiskTilesInfo = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getRiskTilesInfo,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getRiskSecurityRecommendations = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getRiskSecurityRecommendations,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getRiskTaskTrends = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getRiskTaskTrends,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getHeatMapInfo = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getHeatMapInfo,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getRiskTrends = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getRiskTrends,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getRiskEvents = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getRiskEvents,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getRiskDetailsByFilter = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getRiskDetailsByFilter,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAllDevicesExcel = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getAllDevicesExcel,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
export const getExportEventLogs = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getExportEventLogs,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
export const getEventLogsData = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getEventLogsData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
export const getSubcategoryData = async (data) => {
    let config = {
        method: 'POST',
        url: URL + riskService.getSubcategoryData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}




