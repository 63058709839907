/**
 * AG_PC_LF_01 - AG_PC_LF_03
 * Import statements for this Component
 */
import React from "react";
import { useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

/**
 * AG_PC_LF_04 - AG_PC_LF_07
 * Export functions and variable declarations
 */
export default function Newlogin() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const accounts = instance.getAllAccounts();
    const navigate = useNavigate();


    useEffect(() => {
        //console.log(accounts, "accounts>>>>>");
    }, [isAuthenticated]);

    /**
     * AG_PC_LF_08 - AG_PC_LF_12 
     * Let the user to sign in the application
     */
    const handleSignIn = async () => {

        // Check if the user is already authenticated
        if (isAuthenticated) {
            navigate("/loginAuth"); // Navigate to authenticated route
            return;
        }

        // If not authenticated, initiate login
        try {
            await instance.loginPopup();
            navigate("/loginAuth");
        } catch (err) {
            //console.log(err.message, "Error in newLogin.JSX");
            navigate('/azure');
        }

    };
    return (

        <>
            {/* Login page starts here */}
            <>
                <meta charSet="UTF-8" />
                <title>Login</title>
                {/* Login page starts here */}
                <div className="container-fluid">
                    <div className="row ">
                        {/*Left container starts here */}
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 ">
                            <div className="row justify-content-center">
                                <div className="col-md-12 px-4 pt-4">
                                    <span>
                                        <img
                                            src="/images/agov-logo.svg"
                                            alt="agov-logo-login"
                                            className="login-logo-sty mb-3"
                                        />
                                    </span>
                                </div>
                                <div className="col-md-11 col-sm-12 pt-0 pb-0 p-5">
                                    <div className="login-content-height w-100 d-flex justify-content-center">
                                        <div className="form-signup my-auto w-75">
                                            <h5 className="mb-2 font-24 primary-textcolor font-medium">
                                                Sign In
                                            </h5>
                                            <p className="mb-5  font-16 primary-textcolor font-regular">
                                                Securely Sign In, governably gain your SOC Certificate.
                                            </p>
                                            <div className="d-block mb-5">
                                                <button
                                                    type="button"
                                                    className="dark-btn w-md-50 w-100 mb-3 mb-md-0 font-14 font-medium px-4" onClick={() => handleSignIn()}
                                                >
                                                    <span>
                                                        <img
                                                            src="images/lg-microsoft-icon.svg"
                                                            alt="lg-microsoft-icon"
                                                            className="me-2"
                                                        />
                                                    </span>{" "}
                                                    Sign In with Microsoft
                                                </button>
                                                {/* <button
                                                    type="button"
                                                    className="secondary-login-btn w-md-50 w-100 font-14 font-medium px-4 py-2 mt-4"
                                                    disabled
                                                >
                                                    <span>
                                                        <img
                                                            src="images/lg-okta-icon.svg"
                                                            alt="lg-okta-icon"
                                                            className="me-2"
                                                        />
                                                    </span>{" "}
                                                    Sign In with Okta
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* login footer starts here */}
                            <div className="p-0 fixed-bottom">
                                <div className="text-center footer-bg login-custom-width p-2 w-50">
                                    <span className=" font-regular font-18 mb-0 ">
                                        Don't have an account yet?{" "}
                                        <a className="text-decoration-none font-medium pointer"  style={{cursor: "pointer"}} onClick={()=> navigate('/')}>
                                            Register
                                        </a>
                                    </span>
                                </div>
                            </div>
                            {/* login footer ends here */}
                        </div>
                        {/*Left container ends here */}
                        {/*Right container starts here */}
                        <div className="px-4 py-3 col-lg-12 col-xl-6 right-container-login">
                            <div className="login-bg-height login-bg-img w-100 h-100 p-5">
                                <img src="images/open-quote-img.svg" alt="open-quote-img" />
                                <p className="font-16 font-regular color-white mt-4">
                                    <span className="font-medium lh-lg">Unlock Security: </span>Attain
                                    Your SOC Certificate with Confidence!
                                </p>
                            </div>
                        </div>
                        {/*Right container ends here */}
                    </div>
                </div>
                {/* Login page ends here */}
            </>

            {/* Login page ends here */}
        </>
    )
}