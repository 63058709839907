/**PS_AP_01 - Importing of required Constants */
import React, { useState, useEffect, useContext } from 'react';
// import { agovDetails } from './constants';
import AddZero from './AddZeroComponent';
import { getAllApplicationsInfo } from '../../Services/Risk/RiskService';
import { getAllApplicationsExcel } from '../../Services/Risk/RiskService';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import { useLocation } from 'react-router-dom';
import NoDataFound from '../LMS/NoDataFound';
import RiskNoDataFound from './RiskNoDataFound';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';



function AllApplicationInfo() {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const riskApplicationStatsInfo = (state && state.riskApplicationStatsInfo) || false;
    const nonComplaintApplications = state?.NonComplaint
    const { roles, userId } = useContext(agovContext);





    /**PS_AP_04 - State Variables declaration */
    const [allApplicationInfo, setAllApplicationInfo] = useState([]);
    const [allApplicationExcelInfo, setAllApplicationExcelInfo] = useState([]);
    const [showApplicationFilterPopup, setshowApplicationFilterPopup] = useState(false);
    const [applicationCategoryFilter, setApplicationCategoryFilter] = useState('');
    const [limit, setLimit] = useState(10);
    const [sortKey, setSortKey] = useState('applicationName');
    const [sortOrder, setSortOrder] = useState('asc');
    const [applicationSearch, setApplicationSearch] = useState('');
    /**PS_AP_05 Creation of App Category Array */
    const [appCategoryArr] = useState(['Newly Discovered', 'Sanctioned', 'Unsanctioned','Non Complaint']);
    const [applicationTotalCount, setApplicationTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false)



    useEffect(() => {
        // Triggered on page load
        getAllApplicationData();
    }, [limit, sortKey, sortOrder]);

    /**PS_AP_09 - Declaration of Async function to fetch Application Data */
    const getAllApplicationData = async (cancelFlag) => {
        setIsLoading(true)


        let body;
        /**PS_AP_41,42 - checking for riskApplications */
        try {
            body = {
                "categoryFilterName": nonComplaintApplications == "Non Complaint" ? nonComplaintApplications : cancelFlag ? '' : applicationCategoryFilter,
                "applicationRiskInfo": riskApplicationStatsInfo,
                "searchKey": applicationSearch.trim(),
                "sortKey": sortKey,
                "sortOrder": sortOrder,
                "limit": limit,
                "userRoles": roles
            }
            /**PS_AP_20 - Storage of the response in a variable */
            const response = await getAllApplicationsInfo(body);
            if (response.statusCode === 200) {
                setAllApplicationInfo(response.responseData.applicationData);
                setApplicationTotalCount(response.responseData.totalCount)
                setIsLoading(false)

            }
        } catch (error) {
            console.error('Error fetching application info:', error);
            setIsLoading(false)
        }
    };


    // useEffect(() => {
    //     getAllApplicationData();
    // }, [limit, sortKey, sortOrder]);

    /**PS_AP_30 - the function to handle the Sort */
    const handleSortClicked = (columnName, order) => {
        setSortKey(columnName);
        setSortOrder(order);
    };



    /**PS_AP_32 - function to handle the search */
    const handleSearchClick = async (emptySearch) => {

        if (emptySearch) {
            setApplicationSearch('');
            await getAllApplicationData();
            return
        }
        getAllApplicationData();
    }

    /**PS_AP_34 - Function to handle the load more */
    const handleLoadMore = () => {
        if (applicationTotalCount > allApplicationInfo.length) {
            setLimit(prevLimit => prevLimit + 10);
        }
    };

    /**PS_AP_37 - Function to handle the clear filter */
    const handleCancelFilter = () => {
        setApplicationCategoryFilter('')
        setshowApplicationFilterPopup(false);
        getAllApplicationData(true)
    };

    const handleApplyFilter = () => {
        /**PS_AP_28 - check whether `applicationCategoryFilter` has a value and it's inside the `appCategoryArr`  */
        if (applicationCategoryFilter && appCategoryArr.includes(applicationCategoryFilter)) {
            getAllApplicationData();
        }
    };


    /**PS_AE_25 - Function to handle the Excel Download */
    const handleExportExcel = async () => {
        setIsLoading(true);

        const body = {
            "categoryFilterName": nonComplaintApplications == "Non Complaint" ? nonComplaintApplications :applicationCategoryFilter,
            "applicationRiskInfo": riskApplicationStatsInfo,
            "searchKey": applicationSearch.trim(),
            "sortKey": sortKey,
            "sortOrder": sortOrder,
            "userRoles": roles,
            "userId": userId
        };

        try {
            const response = await getAllApplicationsExcel(body);

            if (response && response.statusCode === 200) {
            /** PS_AE_28 - Loop Through Excel Data: For each item in the `excelData`: we extract relevant fields and push them into the `exportToExcel` array. */
                setAllApplicationExcelInfo(response.responseData);
                setIsLoading(false);

                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Application Logs');

                // Define headers
                worksheet.columns = [
                    { header: 'Application Name', key: 'applicationName' },
                    { header: 'User Count', key: 'userCount' },
                    { header: 'Category', key: 'category' },
                    { header: 'Risk Severity', key: 'riskSeverity' }
                ];

                // Add data rows
                response.responseData.forEach(app => {
                    worksheet.addRow({
                        applicationName: app.applicationName,
                        userCount: app.userCount,
                        category: app.category,
                        riskSeverity: app.riskSeverity
                    });
                });

                // Generate Excel file
                const excelBuffer = await workbook.xlsx.writeBuffer();
                const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

                let fileName="AGov-applicationReport-" +
                new Date().getFullYear() +
                "/" +
                (parseInt(new Date().getMonth()) + 1 < 10
                  ? "0" + (parseInt(new Date().getMonth()) + 1)
                  : parseInt(new Date().getMonth() + 1)) +
                "/" +
                (new Date().getDate() < 10
                  ? "0" + new Date().getDate()
                  : new Date().getDate()) + ".xlsx";
                // Save Excel file
                FileSaver.saveAs(data, fileName);
            }
        } catch (error) {
            console.error("Error exporting Excel:", error);
            setIsLoading(false);
        }
    };

    //render Function
    const renderAllApplications = (responseData) => {
        return (
            <tbody>
                {responseData.map((app, index) => (
                    <tr key={index}>
                        <td className="font-12">
                            <div className="d-flex align-items-center">
                                <img src={app.applicationUrl ? app.applicationUrl : "/images/my-app-active.svg"} className="table-logo" alt={app.applicationName} />
                                <p className="font-12 primary-textcolor mb-0 ms-2">{app.applicationName}</p>
                            </div>
                        </td>
                        <td className="font-12 text-end counter-padding">{app.userCount}</td>
                        <td className="font-12">{app.category}</td>
                        <td className="font-12">{app.riskSeverity}</td>
                    </tr>
                ))}
            </tbody>
        );
    }


    //mockData
    const responseData = [
        {
            "applicationName": "Unsanctioned App 1",
            "userCount": 10,
            "applicationUrl": "https://example.com/unsanctionedapp1.png",
            "riskSeverity": "High",
            "category": "Unsanctioned"
        },
        {
            "applicationName": "Sanctioned App 1",
            "userCount": 20,
            "applicationUrl": "https://example.com/sanctionedapp1.png",
            "riskSeverity": "Medium",
            "category": "Sanctioned"
        },
        {
            "applicationName": "Newly Discovered App 1",
            "userCount": 5,
            "applicationUrl": "https://example.com/newlydiscoveredapp1.png",
            "riskSeverity": "Low",
            "category": "Newly Discovered"
        }
    ]

    return (
        <>
            <Loader isLoading={isLoading} />     <div>
                <div className="container-fluid mt-4 mt-custom">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between mt-4 px-5">
                            <h1 className="font-bold font-24 primary-textcolor">
                                Risk Management
                            </h1>
                        </div>
                        {/*tabs starts here*/}
                        <div className="d-flex justify-content-between align-items-center my-3 px-5 custom-tabs">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link px-0 bold-text font-14 tab-style rounded-0" id="overview-tab" data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="false" onClick={() => { navigate('/riskManagement') }}>
                                        <span className="tab-img"> <img src="/images/dashboard-icon-filled.svg" alt="filled-icon" className="me-2" />
                                            <img src="/images/dashboard-icon.svg" alt="overview-icon" className="me-2" /></span>Overview</button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-users-tab" data-bs-toggle="pill" data-bs-target="#all-users" type="button" role="tab" aria-controls="all-users" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allUsers') }}>
                                        <span className="tab-img"> <img src="/images/people-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/people-icon.svg" alt="all-users-icon" className="me-2" />
                                        </span>All Users</button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style active rounded-0" id="all-apps-tab" data-bs-toggle="pill" data-bs-target="#all-apps" type="button" role="tab" aria-controls="all-apps" aria-selected="true">
                                        <span className="tab-img"> <img src="/images/app-icon-filled.svg" alt="filled-icon" className="me-2" />
                                            <img src="/images/app-icon.svg" alt="all-apps-icon" className="me-2" />All
                                            Applications</span></button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-devices-tab" data-bs-toggle="pill" data-bs-target="#all-devices" type="button" role="tab" aria-controls="all-devices" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allDevices') }}>
                                        <span className="tab-img"> <img src="/images/device-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/device-icon.svg" alt="all-devices-icon" className="me-2" />All Devices</span></button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="event-logs-tab" data-bs-toggle="pill" data-bs-target="#event-logs" type="button" role="tab" aria-controls="event-logs" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/eventLogs') }}>
                                        <span className="tab-img"> <img src="/images/document-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/document-icon.svg" alt="event-logs-icon" className="me-2" />Event Logs</span></button>
                                </li>
                            </ul>
                        </div>
                        {/*tabs ends here*/}
                        <div className="row position-relative">
                            <div className="tab-content px-0" id="pills-tabContent">
                                {/*All apps tab*/}
                                <div className="tab-pane active show px-5 ms-2 mt-3" id="all-apps" role="tabpanel" aria-labelledby="all-apps-tab">
                                    <ul className="nav position-absolute end-0 custom-filter-tab me-5">
                                        <li>
                                            <div className="input-group flex-nowrap search-group me-2">
                                                {/**PS_AP_31 - invoking the handleSearchClick based on user Click or Enter  */}
                                                <input type="text" className="form-control search-align" placeholder="Search" aria-label="Username" aria-describedby="addon-wrapping"
                                                    value={applicationSearch} onChange={(e) => setApplicationSearch(e.target.value)} onKeyDownCapture={(e) => {
                                                        if (e.key == "Enter") {
                                                            handleSearchClick(false)
                                                        }
                                                    }} />
                                                <button className="input-group-text btn btn-search-custom" id="addon-wrapping" onClick={() => handleSearchClick(false)}><img src="/images/search-icon.svg" alt="search-icon" /></button>
                                            </div>
                                        </li>
                                        <button className="cust-filter-btn d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                        </button>
                                        <div className="dropdown-menu custom-filter border-0">
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                                    <button type="button" className="btn-close font-12" aria-label="Close" />
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-12 font-medium">Category
                                                            </label>
                                                            {/**PS_AP_26 - set the application type filter category */}
                                                            <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example" onChange={e => { setApplicationCategoryFilter(e.target.value) }} value={applicationCategoryFilter || (nonComplaintApplications === 'Non Complaint' ? 'Non Complaint' : '')}>
                                                                <option value=''>Select</option>
                                                                {appCategoryArr.map((category, index) => (
                                                                    <option key={index} value={category} >{category}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-end mt-4 mb-2">
                                                    <button type="button" className="primary-btn font-14 font-medium me-2" onClick={handleCancelFilter}>
                                                        Clear
                                                    </button>
                                                    {/**PS_AP_27 If the user clicks on the apply Button, the `applicationCategoryFilter` has a value */}
                                                    <button type="button" className="dark-btn font-14 font-medium px-4" onClick={handleApplyFilter} disabled={applicationCategoryFilter ? false : true}>
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="advanced-filter-button ms-2 medium-text customflip-btn" type="button" id="dropdownMenuButton1" aria-expanded="false" onClick={handleExportExcel} style={allApplicationInfo.length > 0? {cursor: "pointer",filter:"none"}:{cursor: "not-allowed" ,filter:"grayscale(80%)"}}>
                                            <img src="/images/excel-icon.svg" alt="Filter-icon" />
                                        </button>
                                    </ul>
                                    {/*All apps table*/}
                                    <h5 className="font-bold font-20 primary-textcolor">
                                        All Applications
                                    </h5>
                                    <div className={allApplicationInfo.length > 0 ? 'table-responsive' : ''}>
                                        {allApplicationInfo.length > 0 ? (<table className="table text-nowrap mb-0 custom-table-grid table-borderless mt-3">
                                            <thead>
                                                <tr>
                                                    <th className="font-14 px-4 position-relative">Application Name
                                                        <span className="position-absolute">
                                                            <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("applicationName", "asc")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("applicationName", "desc")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                    <th className="font-14 position-relative text-end counter-padding">User Count
                                                        <span className="position-absolute">
                                                            <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("userCount", "asc")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("userCount", "desc")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                    <th className="font-14 position-relative">Category
                                                        <span className="position-absolute">
                                                            <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("category", "asc")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("category", "desc")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                    <th className="font-14 position-relative">Risk Severity
                                                        <span className="position-absolute">
                                                            <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("riskSeverity", "asc")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("riskSeverity", "desc")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {renderAllApplications(allApplicationInfo)}
                                        </table>
                                        ) : (<RiskNoDataFound  data={"No Application Found"}/>)}
                                    </div>
                                    <div className="mt-3">
                                        <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor"> <AddZero number={allApplicationInfo.length} ></AddZero> </span>
                                            out of <span className="font-medium primary-textcolor"> <AddZero number={applicationTotalCount}></AddZero></span> items</p>
                                    </div>
                                    <div className="text-center mt-3">
                                        {/**PS_AP_36 - handle the visibility of load more button */}
                                        {applicationTotalCount > allApplicationInfo?.length && (
                                            <button className="primary-btn font-medium cursor-pointer" onClick={() => handleLoadMore()}>Load More</button>
                                        )}
                                    </div>
                                </div>
                                {/*All apps table*/}

                            </div>
                        </div>
                        {/*Event documents table*/}
                    </div>
                </div>

            </div>
        </>

    );
}

export default AllApplicationInfo;
