import React, { useEffect, useState, useContext } from 'react'
import { getAuditorComments, insertAuditorComments } from '../../Services/GRC/GrcService'
import { handleUpload } from '../../Configs/BlobContainer';
import { agovContext } from '../../Constant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../Loader/Loader';

const ControlAuditComments = ({ controlInformation }) => {
  const [page, setPage] = useState(15);
  const [controlAuditComments, setControlAuditComments] = useState([]);
  const [comments, setComments] = useState('');
  const [uploadedImage, setUploadedImage] = useState('');
  const [image, setImage] = useState('');
  const [hasMorePage, setHasMorePage] = useState(true);
  const [renderComponent, setRenderComponent] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { employeeId, employeeName, roles, userId , profileUrl} = useContext(agovContext);



  /**
   * PC_CC_344 -  PC_CC_355
   * Fetches the data of audit for a particular control
   */
  useEffect(() => {
    loadAuditComments();
  }, [page, renderComponent])


  const loadAuditComments = async () => {
    setIsLoading(true);
    let controlComments = await getAuditorComments({
      "controlIdentity": controlInformation.controlDetails.controlIdentity,
      "page": page
    })
    let result = controlComments?.responseData?.auditCount?.length / page;
    setHasMorePage(result <= 1 ? false : true)

    setControlAuditComments(controlComments?.responseData?.auditComments)
    setIsLoading(false);
  }

  /**
   * PC_CC_374 - PC_CC_385
   * The auditor comments will be posted in the database and will show immediately
   */
  const handleSubmit = async () => {
    setIsLoading(true);
    let blobLink = '';
    if (uploadedImage != '') {
      blobLink = await handleUpload(uploadedImage);
    }
    let insertAuditorCommentsConfig = {
      controlId: controlInformation.controlDetails.controlId,
      auditorComment: comments,
      auditorCommentBlobLink: blobLink,
      userId: userId,
      controlIdentity: controlInformation.controlDetails.controlIdentity,
      controlName: controlInformation.controlDetails.controlName
    }
    let result = await insertAuditorComments(insertAuditorCommentsConfig);
    setIsLoading(false);
    setComments('');
    setImage('');
    setUploadedImage('');
    setRenderComponent(!(renderComponent))
  }

  /**
   * PC_CC_364 - PC_CC_370 
   * This function handles the upload of the image 
   */
  const handleImageUpload = (event) => {

    const file = event.target.files[0];
    const validImageExtensions = ['.jpeg', '.jpg', '.png', '.webp'];
    const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
    if (!validImageExtensions.includes(fileExtension)) {
      setErrorMessage('Invalid file type. Please upload a .jpg, .jpeg, .png, or .webp image.');
      return;
    }
    else {
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
      setUploadedImage(file);;
      setErrorMessage('')
    }
  }


/**
 * PC_CC_371 - PC_CC_373
 * This function removes the uploaded image
 */
  const handleImageRemove = () => {
    setUploadedImage('');
    setImage('');
  }

  /**
   * PC_CC_355 - PC_CC_360
   * This function binds the auditor comments to the component 
   */
  const bindAuditComments = () => {
    return (
      controlAuditComments.length > 0 ?
        controlAuditComments.map((auditComment, index) => (
          <li key={index} className="mb-5">
            <div className="d-flex align-items-center">
              <img src={auditComment.profileUrl} alt="sample-profile" className="compliance-li-logo" />
              <div className="ms-3">
                <p className="font-12 primary-textcolor font-regular mb-1">{auditComment.auditorComment}</p>
                <p className="font-10 font-regular grey-light mb-0">{new Date(auditComment.createdOn).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</p>
                {auditComment.auditorCommentLink[0] != '?' ?
                  <img src={auditComment.auditorCommentLink} alt='commentImage' height={100} width={100} /> : ''}
              </div>
            </div>
          </li>
        ))
        :
        <p>No comments available.</p>
    );
  }

  return (
    <>
      <div id="scrollIDGRC02" className="scrollable-content" style={{
        maxHeight: "300px",
        overflowY: "auto"
      }}>
        <InfiniteScroll
          dataLength={controlAuditComments.length}
          next={() => {
            setPage((previousLimit) => (previousLimit + 15))
          }}
          hasMore={hasMorePage}
          scrollThreshold={0.9}
          scrollableTarget="scrollIDGRC02"
        >
          <ul className="list-unstyled ps-0 mb-4">
            <li className="mb-5">
              <div className="col-md-12 col-sm-12">
                <div className="d-flex mb-3 align-items-center">
                  <img src={profileUrl} className="comp-comment-prof" />
                  <div className="container ">
                    <div className="row">
                      <div className="col-md-6">
                        <form>
                          <div className="form-group">
                            <div className="custom-text-area">
                              {/* PC_CC_361 - PC_CC_363
                              This onChange set states the variable  comment 
                              */}
                              <textarea className="form-control" id="comment" rows={1} placeholder="Add a Comment..." defaultValue={""} value={comments} onChange={(e) => { setComments(e.target.value) }} />
                              <p class="font-13 font-regular required-text mt-2 mb-2" >{errorMessage}</p>
                              <div className="image-and-button-container mt-3">
                                {image || image != '' ?
                                  <div className="uploaded-image-container" style={{ display: 'flex' }}>
                                    <img src={image} className="uploaded-image" id="uploadedImage" width={150} height={150} />
                                    <span className="deselect-image" id="deselectImage" style={{ display: image == '' ? 'none' : 'block', cursor: 'pointer' }} onClick={() => handleImageRemove()}>×</span>
                                  </div> : ''}
                                <div className="button-container text-end">
                                  <div className="add-image-button">
                                    <label className="btn btn-outline-secondary">
                                      <img src="/images/upload-photo.svg" width="30px" />
                                      <input type="file" id="imageInput" onChange={(e) => { handleImageUpload(e) }} style={{ display: 'none' }} />
                                    </label>
                                  </div>
                                  <button type="button" className="btn btn-primary dark-btn submit-button ms-2" onClick={() => { handleSubmit() }}>Submit</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div></li>
            {bindAuditComments()}
          </ul>
        </InfiniteScroll >
      </div >
      <Loader isLoading={isLoading} />
    </>
  )
}
export default ControlAuditComments