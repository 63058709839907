import CryptoJS, { AES, enc } from 'crypto-js';
import { EncryptStorage } from "encrypt-storage";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { createContext } from "react";
import { voga12, voga23 } from './Configs/logValues';
import { generateSasToken } from "./Services/GRC/GrcService";
import { getSubscriptionDetails, handleBuySubcription } from './Services/Registration/RegistrationService';


export const agovContext = createContext();
export const encryptStorage = new EncryptStorage("jhvklrgdjldfflsdbfajnlksvdbsfalksdbsglvkfbdvnsalsdlnsvassvjan", {
    stateManagementUse: true,
    storageType: "localStorage",
});
export const loginCredientials = {
    tenantId: decomposeDet(process.env.REACT_APP_CLIENT_TENANT_ID),
    clientId: decomposeDet(process.env.REACT_APP_CLIENT_ID)
};
export const backendUrl = decomposeDet(process.env.REACT_APP_BACKEND_URL);
export const blobStorageAccountName = decomposeDet(process.env.REACT_APP_BLOB_STORAGE_ACCOUNT_NAME);
export const blobContainerName = decomposeDet(process.env.REACT_APP_BLOB_CONTAINER_NAME);
export const defaultThumbnailBlobUrl = decomposeDet(process.env.REACT_APP_DEFAULT_THUMBNAIL_BLOBURL);
export const sampleAssigneeDetailsTemplateBlobUrl = decomposeDet(process.env.REACT_APP_ASSIGNE_DETAILS_BLOBURL);

export const signalRAzureFunc = decomposeDet(process.env.REACT_APP_AZURE_FUNC_SIGNALR_ENDPOINT)


export const governanceService = {
    getGovernanceUser: "/agl/getGovernanceUser",
    showRiskTag: "/agl/getRiskTag",
    closeRiskTag: "/agl/updateRiskTag",
    getExportToExcelData: "/agl/getExportToExcelData",
    getAccessNotification: "/agl/accessNotification",
    getAppData: "/agl/getAppData",
    getAccordionData: "/agl/getAccordianData",
    getFilterAppData: "/agl/filterAppData",
    getExportToExcelApp: "/agl/getExportExcelApp",
    getApprovalStatusData: "/agl/getApprovalStatusData",
    getLogsData: "/agl/getLogsData",
    revokeAccess: "/agl/revokeAccess",
    approveAccess: "/agl/approveAccess",
    getUserProfile: "/agl/getUserProfile",
    getActivityLogs: "/agl/getActivityLogs",
    getOrgDetails: "/agl/getOrgDetails",
    notificationClick: "/agl/notificationClick",
    uploadProfile: "/agl/uploadProfile",
    getTileData:"/agl/tileData",
    getTaskData:"/agl/TaskData",
    getCampaignsData:"/agl/campaignData",
    getLMSData:"/agl/LMSData",
    getUsersNonCompliantDetails: "/agl/getUsersNonCompliantDetails",
    getUsersAccessDetails: "/agl/getUsersAccessDetails",
    getRiskCount: "/agl/getRiskCount",
};

export const loginService = {
    validateJWT: "/login/validateJWT",
    getEmployeeDetails: "/login/getEmployeeDetails",
    getConnector: "/login/getConnector",
    mandatoryDataCheck: "/login/mandatoryDataCheck",
    checkUserLogin : "/login/checkUserLogin"
};

export const adminService = {
    updateRoles: '/admin/updateRoles',
    getUserRoles: '/admin/getUserRoles',
    insertAdminInformation: '/admin/insertAdminInformation',
    getAdminInformation: '/admin/getAdminInformation',
    updateAdminInformation: '/admin/updateAdminInformation',
    getAdminPermission: '/admin/getAdminPermission',
    insertPolicyConfig: '/admin/insertPolicyConfig',
    getPolicyConfig: '/admin/getPolicyConfig',
    getPolicyDynamicData: '/admin/getPolicyDynamicData',
    generateSasToken: '/admin/generateSasToken',
    getAppOwnerConfig: '/admin/getAppOwnerConfig',
    bindEmailId: '/admin/bindEmailId',
    insertAppOwnerConfig: '/admin/insertAppOwnerConfig',
    updateAppOwnerConfig: '/admin/updateAppOwnerConfig',
    deleteAppOwnerConfig: '/admin/deleteAppOwnerConfig',
    getExportToExcelData: '/admin/getExportToExcelData',
    insertIpConfig: '/admin/insertIpConfig',
    updateIpConfig: '/admin/updateIpConfig',
    getIpConfig: '/admin/getIpConfig',
    deleteIpConfig: '/admin/deleteIpConfig',
    
    getConnectorData: '/admin/getConnectorData',
    updateConnectorData: '/admin/updateConnectorData',
    insertConnectorData: '/admin/insertConnectorData',// Adding admins service for dashboard phase 
    deleteConnectorData:'/admin/deleteConnectorData', //Adding for Disconnect in Connector
    getSlaConfig: '/admin/getSlaConfig',
    updateSlaConfig: '/admin/updateSlaConfig',
    getRiskTaskConfig: '/admin/getRiskTaskConfig',
    insertRiskTaskConfig: '/admin/insertRiskTaskConfig',
    updateRiskTaskConfig: '/admin/updateRiskTaskConfig',
    getRetentionConfig: '/admin/getRetentionConfig',
    insertRetentionConfig: '/admin/insertRetentionConfig',
    updateRetentionConfig: '/admin/updateRetentionConfig',
    updateConfigureHeaders: '/admin/updateConfigureHeaders',
    getPeriodicalAccessData: '/admin/getPeriodicalAccess',
    updateAdminData: '/admin/updatePeriodicalAccess',
    postAdminData: '/admin/postPeriodicalAccess',
    postToggleButton: '/admin/adminToggleChange',
    getNotifications: '/admin/getNotification',
    readNotifications: '/admin/readNotification',
    fetchConfigureHeaders: '/admin/fetchConfigureHeaders',
    getBillingDetails: '/admin/getBillingDetails',
    fetchUserDetails: '/admin/fetchUserDetails',
    countDetails: '/admin/countDetails',
}

export const grcService = {
    // Dashboard 
    getAuditCycleTimelines : "/grc/getAuditCycleTimelines",
    requestedDocumentPopUp : "/grc/requestedDocumentPopUp", 
    getComplianceSummary : "/grc/getComplianceSummary", 
    getResponsibilityOverView : "/grc/getResponsibilityOverView",
    auditorAndRequestedDocument : "/grc/auditorAndRequestedDocument",
    getVendorInformation : "/grc/getVendorInformation", 
    getGrcDashboardDetails : "/grc/getGrcDashboardDetails",
    getTaskData : "/grc/getTaskData",
    // Dashboard routes ends here
    getPolicyDetails: "/grc/getPolicyDetails",
    getControlsGrid: "/grc/getControlsGrid",
    getControlDetails: "/grc/getControlDetails",
    getAuditorComments: "/grc/getAuditorComments",
    getControlHistory: "/grc/getControlHistory",
    getAuditCycleData: "/grc/getAuditCycle",
    getAuditorDetails: "/grc/getAuditorDetails",
    deleteAuditCycle: "/grc/deleteAuditCycle",
    addNewAuditCycle: "/grc/addNewAuditCycle",
    getExistingtimeline: "/grc/getExistingtimeline",
    addExistingAuditCycle: "/grc/addExistingAuditCycle",
    updateAuditCycle: "/grc/updateAuditCycle",
    generateSasToken: "/grc/generateSasToken",
    addPastAuditCycle: "/grc/addPastAuditCycle",
    getEvidencesGrid: "/grc/getEvidencesGrid",
    getStatusDetails: "/grc/getStatusDetails",
    getRecurrenceData: "/grc/getRecurrenceData",
    createEvidenceOrPolicy: "/grc/createEvidenceOrPolicy",
    getEvidenceDetails: "/grc/getEvidenceDetails",
    uploadEvidence: "/grc/uploadEvidence",
    unmappingGRC: "/grc/unmappingGRC",
    mappingGRC: "/grc/mappingGRC",
    assignPopup: "/grc/assignPopup",
    assignPopupDetails: "/grc/assignPopupDetails",
    updateControlStatus: "/grc/updateControlStatus",
    controlPopupValues: "/grc/controlPopupValues",
    getSummaryDetails: "/grc/getSummaryDetails",
    requestDocument: "/grc/requestDocument",
    updateApprovalStatus: "/grc/updateApprovalStatus",
    getExportControls: "/grc/getExportControls",
    excelAddControl: "/grc/excelAddControl",
    updateEvidenceRecurrence: "/grc/updateEvidenceRecurrence",
    updateEvidencePause: "/grc/updateEvidencePause",
    updateEvidenceActive: "/grc/updateEvidenceActive",
    deletePolicyOrEvidence: "/grc/deletePolicyOrEvidence",
    deleteParticularDocument: "/grc/deleteParticularDocument",
    getPoliciesGrid: "/grc/getPoliciesGrid",
    updateControlOwner: "/grc/updateControlOwner",
    markAuditAsComplete: "/grc/markAuditAsComplete",
    markAuditAsInComplete: "/grc/markAuditAsInComplete",
    insertAuditorComments: "/grc/insertAuditorComments",
    insertSystemDescriptionDetails: "/grc/insertSystemDescriptionDetails",
    systemDescriptionQuestions: "/grc/systemDescriptionQuestions",
    uploadPolicy: "/grc/uploadPolicy",
    getOtherDocumentsGrid: "/grc/getOtherDocumentsGrid",
    getOtherDocumentsDetails: "/grc/getOtherDocumentsDetails",
    deleteOtherDocuments: "/grc/deleteOtherDocuments",
    uploadOtherDocuments: "/grc/uploadOtherDocuments",
    uploadReport: "/grc/uploadReport",
    getAuditorTimelines: "/grc/getAuditorTimelines",
    getUserLists: "/grc/getUserLists",
    downloadAuditEvidences: "/grc/downloadAuditEvidences",
    insertLog: "/grc/insertLog",

    // Vendor Path Starts here
    getVendorDetails: "/grc/getVendorDetails",
    deleteVendorData: "/grc/deleteVendorDetails",
    getQuestionaryHistory: "/grc/getQuestionaryHistory",
    getVendorFormData: "/grc/getVendorFormData",
    insertVendorDetails: "/grc/insertVendorDetails",
    updateVendorDetails: "/grc/updateVendorDetails",
    getQuestionaries: "/grc/getQuestionaries",
    deleteSectionFromDB: "/grc/deleteSectionFromDB",
    deleteQuestionFormDB: "/grc/deleteQuestionFromDB",
    updateQuestionToDB: "/grc/updateQuestionToDB",
    getVendorQuestions: "/grc/getVendorQuestions",
    deleteVendorQuestionaries: "/grc/deleteVendorQuestionaries",
    insertVendorQuestions: "/grc/insertVendorQuestions",
    postQuestionaries: "/grc/postQuestionaries",
    deleteVendorContact: "/grc/deleteVendorContact",
    insertLogs: "/grc/insertLogs",
    // Vendor Path Starts here
};

export const taskService = {
    getAssignedTask: "/task/getAssignedTask",
    getMyRequestTask: "/task/getMyRequestTask",
    getTaskDynamicData: "/task/getTaskDynamicData",
    getViewTask: "/task/getViewTask",
    getTaskSource: "/task/getTaskSource",
    getTaskType: "/task/getTaskType",
    getTaskDependency: "/task/getTaskDependency",
    getTaskAssignee: "/task/getTaskAssignee",
    createTask: "/task/createTask",
    updateTask: "/task/updateTask",
    exportTaskDetails: "/task/exportTaskDetails",
    createSubtask: "/task/createSubtask",
    addComments: "/task/addComments",
    downloadPolicyTemplate: "/task/downloadPolicyTemplate",
    getAllTask: "/task/getAllTask",
    approveDocument: "/task/approveDocument",
    generateSasToken: '/task/generateSasToken',
    getDirectReporteesTask: '/task/getDirectReporteesTask'
};
export const registrationService = {
    getHeadCountOptions: "/registration/getHeadCountOptions",
    postRegisterDetails: "/registration/postRegisterDetails",
    verifyAccount : "/registration/verifyAccount",
    getSubscriptionDetails: "/registration/getSubscriptionDetails",
    getSessionIdForStripe:"/registration/create-stripe-checkout-session",
    generatePaymentId : "/registration/generateStripePaymentId",
    regenerateOTP : '/registration/regenerateOTP',
    createDynamicDb: '/registration/createDatabase'
}

export const lmsService = {
    getMyAssignedCampaign: "/lms/getMyAssignedCampaigns",
    getCourseDetails: "/lms/getAllCourseDetails",
    getCourseType: "/lms/getCourseType",
    getVideosByType: "/lms/getVideosByType",
    postCourseDetails: "/lms/postCourseDetails",
    updateCourseDetails: "/lms/editCourseDetails",
    getCampaignDetails: "/lms/getCampaignDetails",
    deleteCampaignDetails: "/lms/deleteCampaignDetails",
    getVideoDetails: "/lms/getVideoDetails",
    postVideoDetails: "/lms/postVideoDetails",
    generateSasToken: "/lms/generateSasToken",
    getDataForCourseId: "/lms/getDataForCourseId",
    getMediaDetails: "/lms/getMediaDetails",
    getCourseByMediaType: "/lms/getCourseByMediaType",
    postAddOnCourseDetails: "/lms/postAddOnCourseDetails",
    getUserDetails: "/lms/getAllUserDetails",
    trackUserLmsProgress: "/lms/trackUserLmsProgress",
    postUserLmsProgress: "/lms/postUserLmsProgress",
    getViewMediaDetails: "/lms/getViewMediaDetails",
    getAllRecurrenceConditions: "/lms/getAllRecurrenceConditions",
    getAllCourses: "/lms/getAllCourses",
    getAllPriorityDetails: "/lms/getAllPriorityDetails",
    getTaskAssigneeDetails: "/lms/getTaskAssigneeDetails",
    deleteCourse: "/lms/deleteCourseDetails",
    validateAssigneeDetails: "/lms/validateAssigneeDetails",
    postCampaignDetails: "/lms/postCampaignDetails",
    validateJWT: "/admin/validateJWT",
    // dashboard route points
    getTileData:"/lms/tile",
    getActiveCampaign:"/lms/activecampaign",
    getupcomingCampaigns:"/lms/upcomingcampaigns",
     getmultiTabInfo:"/lms/multitab",
     getExportExcelData:"/lms/expoertexcel",
     getUserLearningInfo:"/lms/userlearninginfo",
     getuserPopupInfo:"/lms/userlearninginfopopup",
     getCampaignDetailsss:"/lms/getCampaignDetails"
};

export const riskService = {
    getAllUsersInfo: "/risk/getAllUsersInfo",
    getUserDepartment: "/risk/getUserDepartment",
    getAllDeviceEmployee: "/risk/getAllDeviceEmployee",
    getAllDevicesInfo: "/risk/getAllDevicesInfo",
    getAllApplicationsInfo: "/risk/getAllApplicationsInfo",
    getAllUsersExcel: "/risk/getAllUsersExcel",
    getAllApplicationsExcel: "/risk/getAllApplicationsExcel",
    getRiskTilesInfo: "/risk/getRiskTilesDetails",
    getRiskSecurityRecommendations: "/risk/getRiskSecurityRecommendations",
    getRiskTaskTrends: "/risk/getRiskTaskTrends",
    getHeatMapInfo: "/risk/getHeatMapInfo",
    getRiskTrends: "/risk/getRiskTrends",
    getRiskEvents: "/risk/getRiskEvents",
    getRiskDetailsByFilter: "/risk/getRiskDetailsByFilter",
    getAllDevicesExcel: "/risk/getAllDevicesExcel",
    getExportEventLogs: "/risk/getExportEventLogs",
    getEventLogsData: "/risk/getEventLogsData",
    getExternalDomains: "/risk/externalDomains",
    getExportExternalDomain: "/risk/exportExternalDomains",
    getSubcategoryData: "/risk/getSubcategoryData"
};

export const blobURlDownload = async (arrayBuffer) => {
    const zip = new JSZip();
    const control = zip.folder("control");
    const category = control.file("Excel.png", arrayBuffer, { binary: true });
    // Generate the zip file
    zip.generateAsync({ type: "blob" }).then((content) => {
        // Save the zip file
        saveAs(content, "exported_data.zip");
    });
};

export const abbreviateNumber = (num) => {
    const absNum = Math.abs(num);
    const sign = Math.sign(num);

    if (absNum < 1000) {
        return num.toString();
    }
    
    if (absNum < 1000000) {
        const rounded = (absNum / 1000).toFixed(2);
        if (rounded.endsWith('.00')) {
            return sign * parseInt(rounded) + 'K';
        }
        return sign * rounded + 'K';
    }
    
    const rounded = (absNum / 1000000).toFixed(2);
    if (rounded.endsWith('.00')) {
        return sign * parseInt(rounded) + 'M';
    }
    return sign * rounded + 'M';
}

export const areArraysSame = async (arr1, arr2) => {
    const sortedArr1 = arr1.sort();
    const sortedArr2 = arr2.sort();
    console.log("Sorted responseDataRoles:", sortedArr1);
    console.log("Sorted successRoles:", sortedArr2);
    return sortedArr1.length === sortedArr2.length && sortedArr1.every((element, index) => element === sortedArr2[index]);
}


export const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "-"; // Return an empty string if dateTimeString is null or undefined

    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour =
        hour % 12 === 0 ? "12" : (hour % 12).toString().padStart(2, "0");


    return `${day}/${month}/${year} ${formattedHour}:${minute}${ampm}`;
}


//Get the formatted file name by blob url.
export const getFileNameByBlobUrl = (value) => {
    let fileName = value.split("/").pop();

    if (fileName.includes("?")) {
        fileName = fileName.split("?")[0];
    }

    if (fileName.includes("-iiiii-")) {
        fileName = fileName.split("-iiiii-").pop();
    }

    if (fileName.includes("###")) {
        fileName = fileName.split("###").pop();
    }

    fileName = decodeURIComponent(fileName);

    return fileName;
};

export const downloadTemplate = async () => {
    let sasToken = await generateSasToken({
        resourceTypes: "o",
        permission: "r",
    });
    sasToken = sasToken?.responseData;
    let fileUrl = sampleAssigneeDetailsTemplateBlobUrl + sasToken;
    saveAs(fileUrl, "TaskAssigneeTemplate.xlsx");
};

export const validateFileType = (event) => {
    const allowedTypes = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "image/jpeg", // .jpg
        "application/pdf", // .pdf
        "image/png", // .png
        "image/webp", // .webp
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "text/csv", // .csv
        "text/plain", // .txt
    ];
    const file = event.target.files[0];
    if (file) {
        const fileType = file.type;
        const fileExtension = file.name.split(".").pop().toLowerCase();

        // Check if the file type or extension is allowed
        if (
            allowedTypes.includes(fileType) ||
            allowedTypes.includes(`.${fileExtension}`)
        ) {
            return true;
        }
        return false;
    }
};

export const validatePolicyFileType = (event) => {
    const allowedTypes = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "application/pdf", // .pdf
    ];
    const file = event.target.files[0];
    if (file) {
        const fileType = file.type;
        const fileExtension = file.name.split(".").pop().toLowerCase();

        // Check if the file type or extension is allowed
        if (
            allowedTypes.includes(fileType) ||
            allowedTypes.includes(`.${fileExtension}`)
        ) {
            return true;
        }
        return false
    }
};


/*
* PC_PVDFE_NO_46 - PC_PVDFE_NO_53
* Get the file size, extension, name, src from the blob URL.
*/
export const getFileDetails = async (blobURL) => {
    try {
        const response = await fetch(blobURL, { method: "HEAD" });

        if (response.ok) {
            const fileSize = (
                response.headers.get("content-length") /
                (1024 * 1024)
            ).toFixed(2);
            const fileName = getFileNameByBlobUrl(blobURL);
            const fileExtension = fileName.split(".").pop().toLowerCase();
            const src = !["mp4", "pdf"].includes(fileExtension)
                ? blobURL
                : fileExtension === "pdf"
                    ? "/images/pdf-icon.svg"
                    : "/images/mp4-icon.svg";

            return {
                name: fileName,
                size: fileSize,
                extension: fileExtension,
                src: src,
            };
        } else {
            console.error("Failed to retrieve file size. Status:", response.status);
        }
    } catch (error) {
        console.error("Error:", error.message);
    }
};


export let notificationLogs = {
    "LMS Task Assigned":"<userName> have assigned an LMS Campaign of <requestFor>",
    "Access Request taskCreated": "<createdBy> has raised a request for the application <requestFor>.",
    "Access Request taskUpdated": "<createdBy> has updated a request for the application <requestFor>.",
    "Access Request commentAdded": "<userName> has added a comment for <requestFor>.",
    "Policy complianceSubTaskCreated": "Task has been created for the <requestFor> Policy by <userName>.",
    "Policy subTaskUpdated": "Task has been updated for the <requestFor> Policy by <userName>.",
    "Evidence subTaskUpdated": "Task has been updated for the <requestFor> Evidence by <userName>.",
    "Evidence documentApproved": "Task has been approved for the <requestFor> Evidence by <userName>.",
    "Policy documentApproved": "Task has been approved for the <requestFor> Policy by <userName>.",
    "Evidence complianceSubTaskCreated": "Task has been created for the <requestFor> Evidence by <userName>.",
    "Policy complianceSubTaskCompleted": "<userName> has uploaded the <requestFor> Policy.",
    "Evidence complianceSubTaskCompleted": "<userName> has uploaded the <requestFor> Evidence.",
    "Evidence taskCreated": "<userName> has been assigned with evidence <requestFor>.",
    "Evidence taskUpdated": "<userName> has been assigned with evidence <requestFor>.",
    "LMS taskUpdated": "<userName> has been assigned with LMS <requestFor>.",
    "Evidence subtaskCreated": "<userName> has been assigned with evidence <requestFor>.",
    "Policy complianceTaskApproved": "<userName> has approved the submitted policy document <requestFor>.",
    "Policy taskUpdated": "<userName> has updated the policy <requestFor>.",
    "Compliance taskUpdated": "<userName> has updated the policy <requestFor>.",
    "Compliance controlOwnerAdded": "<userName> made you as control owner for <requestFor> control.",
    "Policy documentApproved": "<userName> has approved the policy <requestFor>.",
    "Policy taskCreated": "<userName> has been assigned with policy <requestFor>.",
    "Policy subtaskCreated": "<userName> has been assigned with policy <requestFor>.",
    "Policy commentsAdded": "<createdBy> added comments in <requestFor>.",
    "Access Request commentsAdded": "<createdBy> added comments in <requestFor>.",
    "Compliance commentsAdded": "<createdBy> added comments in <requestFor>.",
    "LMS commentsAdded": "<createdBy> added comments in <requestFor>.",
    "Evidence commentsAdded": "<createdBy> added comments in <requestFor>.",
    "Evidence complianceTaskApproved": "<User Name> has approved the submitted evidence <Evidence Document Name>.",
    "LMS taskAssigned": "<userName> have assigned an LMS Campaign of <requestFor>.",
    "LMS taskCreated": "<userName> have assigned an LMS Campaign of <requestFor>.",
    "LMS taskCommentAdded": "<userName> has added a comment against the <requestFor>.",
    "Risk taskCreated": "Risk Task <requestFor> has been created by the <userName>.",
    "Risk taskAssigned": "<userName> have assigned a Risk Task of <requestFor>.",
    "Risk riskSubtaskCreated": "A Task has been created by <createdBy> to investigate the <requestFor> risk activity performed by you.",
    "Risk subTaskCommentAdded": "<userName> has added a comment against the <requestFor>.",
    "appManagement Application Access Approved": "<appAdmin> has approved the request for the application <applicationName>.",
    "appManagement Application Access Revoke": "<appAdmin> has revoked the request for the application <applicationName>.",
    "Compliance policyUpload": "<userName> has approved the submitted policy document <policyDocumentName>.",
    "Compliance evidenceUpload": "<userName> has approved the submitted evidence document <evidenceDocumentName>.",
    "Compliance auditorComment": "<userName> has added a comment against the controller id <requestFor>.",
    "Policy uploadDocument": "<userName> had uploaded the policy document for <policyDocumentName>.",
    "Evidence uploadDocument": "<userName> had uploaded the evidence document for <evidenceDocumentName>.",
    "Risk taskUpdated": "A Task has been created by <createdBy> to investigate the <requestFor> Risk Activity you have performed.",
    "Compliance control created": "<userName> had added the control <controlName>.",
    "appManagement New Application Assigned" : "<applicationName> has been assigned to <userName>"
};

export const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
        case "pdf":
            return "/images/pdf-icon.svg";
        case "doc":
        case "docx":
            return "/images/document-icon-filled.svg";
        case "jpg":
        case "jpeg":
            return "/images/image-icon.png";
        case "png":
            return "/images/image-icon.png";
        case "webp":
            return "/images/image-icon.png";
        case "xlsx":
            return "/images/excel-icon.svg";
        case "csv":
            return "/images/excel-icon.svg";
        case "txt":
            return "/images/document-icon.svg";
        default:
            return "/images/document-icon.svg";
    }
};



export function encryptDataFunction(data) {
    let ivHex = voga12;
    let keyHex = voga23;

    let generateRandom = () => {
        let randomNumber = Math.floor(Math.random() * 15) + 1;
        let formattedNumber = randomNumber.toString().padStart(2, '0');
        return formattedNumber;
    };

    let random = generateRandom();
    let secretKeyStr = keyHex;
    let ivKeyStr = ivHex;
    let secretKey = secretKeyStr;
    let ivKey = ivKeyStr;
    const jsonData = JSON.stringify(data);
    const encryptedData = AES.encrypt(jsonData, secretKey, {
        iv: ivKey,
    }).toString();
    let encryptedData2 = encryptedData + random.toString();
    return encryptedData2;
}

export function decryptData(encrypt) {
    try {
        let ivHex = voga12;
        let keyHex = voga23;
        const iv = ivHex;
        const key = keyHex;
        let encryptedData = encrypt.slice(0, -2);
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
        const decryptedObject = JSON.parse(decryptedText);
        return decryptedObject;
    }
    catch (error) {
        console.log('Error during decryption:', error);
        throw error; // Rethrow to handle the error elsewhere or return null/undefined or error message.
    }
}

export function decomposeDet(encrypt) {
    try {
        let ivHex = voga12;
        let keyHex = voga23;
        const iv = ivHex;
        const key = keyHex;
        let encryptedData = encrypt.slice(0, -2);
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
        const decryptedObject = JSON.parse(decryptedText);
        return decryptedObject;
    }
    catch (error) {
        console.log('Error during decryption:', error);
        throw error; // Rethrow to handle the error elsewhere or return null/undefined or error message.
    }
}




export const convertDuration = (duration) => {
    const durationParts = duration.split(':');
    const hours = parseInt(durationParts[0]);
    const minutes = parseInt(durationParts[1]);
    const seconds = parseInt(durationParts[2]);

    if (hours > 0) {
        return `${hours} hr${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
        return `${minutes} min${minutes > 1 ? 's' : ''}`;
    } else {
        return `${seconds} sec${seconds > 1 ? 's' : ''}`;
    }
};


export const formatCommentsDate = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours < 12 ? 'AM' : 'PM';
    const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour time
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const currentDate = new Date();
    const isToday = date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear();

    if (isToday) {
        return `Today ${formattedHours}:${formattedMinutes}${period}`;
    } else {
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        return `${month} ${day} ${formattedHours}:${formattedMinutes}${period}`;
    }
};

