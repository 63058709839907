import { client } from "../../Client/Client";
import { backendUrl as URL, loginService, } from "../../Constant";


export const getUserRoleDetails = async (data) => {

    //console.log(data, "dataIngetUserrOLE");

    let config = {
        method: 'POST',
        url: URL + loginService.getUserRole,
        headers: {
            'Content-Type': 'application/json'
        },  
        data: data
    }
    let response = await client(config);
    return response;
}

export const getEmployeeDetails = async (data) => {


    //console.log({ URL: URL, Service: loginService.getEmployeeDetails },"URL check");

    let config = {
        method: 'POST',
        url: URL + loginService.getEmployeeDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }


    //console.log(config, "Config in getEmployeeDetails");


    let response = await client(config);
    return response;
}
export const validateJWT = async (data) => {

    let config = {
        method: 'POST',
        url: URL + loginService.validateJWT,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
export const getConnectors = async (data) => {

    let config = {
        method: 'POST',
        url: URL + loginService.getConnector,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const mandatoryDataCheck = async () => {

    let config = {
        method: 'POST',
        url: URL + loginService.mandatoryDataCheck,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;
}

export const checkUserLogin =async (data) =>{
    const config = {
      url: URL + loginService.checkUserLogin,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data:data
    };
    let response = await client(config)
    return response;
  }