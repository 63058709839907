import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NoDataFound from './NoDataFound';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts'
import NoRecordsPage from './NoRecordsPage';
import { Loader } from '../LMS/LMSLoader';

import { agovContext,abbreviateNumber } from '../../Constant';
import { gettileAPIdata, getActiveCampaignApiData, getUpcomingCampaignApiData, getmultiInfoAPIdata, getExportExcelApidata, getUserInfoAPIdata } from '../../Services/LMS/LmsService';
import NoRecordFound from '../Dashboard/dashboardNoRecordFound';
import PopUpDashBoard from './PopUpDashBoard';




const MultiTab = () => {
  const { userId, roles } = useContext(agovContext);

  const multiTabPayload = {
    "userId": userId,
    "tab": "due",
    "sortColumn": "Username",
    "sortOrder": "ASC",
    "riskSortColumn": 'taskPriority',
    "riskSortOrder": "DESC",
    "limit": 5
  }


  const [userDetailsRequest, setUserDetailsRequest] = useState(multiTabPayload)
  // state variable for multiaTabaInfo PC_multiTab_09 to PC_multiTab_12n


  const [multiTabInfo, setMultiTabInfo] = useState([])
  const [multiTabCount, setMultiTabCount] = useState([])
  const [totalCount,setTotalCount]=useState([])
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tabName, setTabName] = useState("")
  const [activeTab, setActiveTab] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // a onClick function   PC_multiTab_49 to PC_multiTab_51
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };


  // THIS FUNCTION FETCHES THE TAB DATA   PC_multiTab_18 to PC_multiTab_43


  const fetchMultiTabInfo = async () => {
    
    setIsLoading(true)
    try {
      const response = await getmultiInfoAPIdata(userDetailsRequest);
      // //console.log('Response:', response); // Log the entire response object

      if (response && response.statusCode === 200) {
        const data = response.responseData;
        // //console.log('Response Data:', data); // Log the responseData
        setMultiTabInfo(data);
        setMultiTabCount(response.count);
        setTotalCount(response.tabCount)
      } else if (response && response.statusCode === 400) {
        // Error handling logic goes here
        console.error('Error fetching upComingCampaign data: ', response.statusCode);
      } else {
        // Handle other status codes or undefined response
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error fetching multiInfo data:', error);
    } finally {
      setIsLoading(false)
    }

  };

  // PC_multiTab_53 this function is to handle sorting

  const handleColumnSort = (columnName) => {
    // Toggle sortOrder if sorting the same column, otherwise set to 'Asc'
    const newSortOrder = columnName === userDetailsRequest.sortColumn && userDetailsRequest.sortOrder === 'DESC' ? 'ASC' : 'DESC';
    setUserDetailsRequest({ ...userDetailsRequest, sortColumn: columnName, sortOrder: newSortOrder });
  };
  const riskhandleColumnSort = (columnName) => {
    // Toggle sortOrder if sorting the same column, otherwise set to 'Asc'
    const newSortOrder = columnName === userDetailsRequest.riskSortColumn && userDetailsRequest.riskSortOrder === 'DESC' ? 'ASC' : 'DESC';
    setUserDetailsRequest({ ...userDetailsRequest, riskSortColumn: columnName, riskSortOrder: newSortOrder });
  };


  // useeffect to call the api initally PC_multiTab_16

  useEffect(() => {


    fetchMultiTabInfo();

    // This function is invoked when the component is mounted
  }, [userDetailsRequest]);

  // an emitter function to close the popup
  const onCloseEmitterEvent = () => {
    setIsPopupOpen(false)
  }





  const onTabClick = (event) => {
    // const newTab = event.target.value;
    const newTab = event
    //console.log(newTab);

    setUserDetailsRequest((prevRequest) => ({
      ...prevRequest,
      tab: newTab,
    }));

    handleTabClick(newTab);
  };
  //console.log("tabhandleClick", activeTab);













  return (
    <>
      <div className="bg-white py-2 px-3 border-radius-8 rm-dashboard-card" style={{ position: 'relative' }}>
        <Loader isLoading={isLoading} />
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h3 className="font-14 font-medium primary-textcolor mb-0">User Based On</h3>
            <div className="d-flex justify-content-between align-items-center ps-1 custom-tabs ms-2">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* dueStart here */}
                  <button className="nav-link px-0 active font-14 tab-style rounded-0" value="due" id="due-tab" data-bs-toggle="pill" data-bs-target="#due" type="button" role="tab" aria-controls="information" aria-selected="true" onClick={() => onTabClick('due')}>
                    <span className="tab-img"> <img src="images/media-roll-active.svg" alt="filled-icon" className="me-2" /> <img src="images/media-roll-inactive.svg" alt="information-icon" className="me-2" /></span>Due
                    <span className="badges-blue border-radius-8 ms-1 font-12">{abbreviateNumber(multiTabCount?.dueCount)}</span></button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" value="overDue" id="overdue-tab" data-bs-toggle="pill" data-bs-target="#overdue" type="button" role="tab" aria-controls="permissions" aria-selected="false" tabIndex={-1} onClick={() => onTabClick('overDue')}>
                    <span className="tab-img"> <img src="images/media-roll-active.svg" alt="filled-icon" className="me-2" /> <img src="images/media-roll-inactive.svg" alt="permissions-icon" className="me-2" /></span>Overdue
                    <span className="badges-blue border-radius-8 ms-1 font-12">{abbreviateNumber(multiTabCount?.overdueCount)}</span></button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" value="risk" id="risk-tab" data-bs-toggle="pill" data-bs-target="#risk" type="button" role="tab" aria-controls="permissions" aria-selected="false" tabIndex={-1} onClick={() => onTabClick('risk')}>
                    <span className="tab-img"> <img src="images/risk-active-filled.svg" alt="filled-icon" className="me-2" /> <img src="images/risk-inactive-nonfilled.svg" alt="permissions-icon" className="me-2" /></span>Risk
                    <span className="badges-blue border-radius-8 ms-1 font-12">{abbreviateNumber(multiTabCount?.riskCount)}</span></button>
                </li>
              </ul>
            </div>
          </div>
          <div className="align-items-center d-flex gap-3">
            <div className="d-flex align-items-center">
              {totalCount!== 0 ? (
                <>
                  <img
                    className="cursor-pointer"
                    src="images/expand-icon.svg"
                    alt="expand-icon"
                    onClick={() => setIsPopupOpen(!isPopupOpen)}
                  />
                  {isPopupOpen && (
                    <PopUpDashBoard
                      Props={activeTab || 'due'}
                      onClose={onCloseEmitterEvent}
                    />
                  )}
                </>
              ) : null}

              {/* {isPopupOpen && <PopUpDashBoard Props={activeTab || 'due'} onClose={onCloseEmitterEvent} />} */}



            </div>
          </div>
        </div>
        <div className="py-2 px-2">

          <div className="tab-content" id="myTabContent">


            <div className="tab-pane fade show active" id="due" role="tabpanel" aria-labelledby="due-tab">
              {/* due  */}
              {multiTabInfo.length === 0 ? (
                <NoRecordFound data={"No campaigns Due "} />
              ) : (
                <table className="table text-nowrap mb-0  custom-table-grid table-borderless dashboard-table">
                  <thead>
                    <tr>
                      <th className="font-14 px-4 position-relative">Username
                        <span className="position-absolute cursor-pointer" onClick={() => handleColumnSort('Username')}>
                          <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" />
                          <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" />
                        </span>
                      </th>
                      <th className="font-14 position-relative">
                        Campaigns
                        <span className="position-absolute cursor-pointer" onClick={() => handleColumnSort('campaigns')}>
                          <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" />
                          <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" />
                        </span>
                      </th>
                      <th className="font-14 position-relative text-end">Days
                        <span className="position-absolute cursor-pointer" onClick={() => handleColumnSort('campaign_duration')}>
                          <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" />
                          <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" />
                        </span>
                      </th>
                    </tr>

                  </thead>

                  <tbody>
                    {multiTabInfo.map(task => (
                      <tr className="font-12">
                        <td className="font-12">
                          <div className="d-flex align-items-center">
                            <img src={task?.profileImages} className="table-prof" alt="Profile" />
                            <p className="font-12 font-regular primary-textcolor mb-0 ms-2">
                              {task?.Username}
                            </p>
                          </div>
                        </td>
                        <td className="font-12 font-regular primary-textcolor">
                          <span className='multiTab-column'>
                            {task?.campaigns}
                          </span>
                        </td>
                        <td className="font-12 font-regular primary-textcolor" style={{ textAlign: 'right' }}>
                          <span >
                            {task?.dueDays}
                          </span>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>)}

            </div>


            <div className="tab-pane fade" id="overdue" role="tabpanel" aria-labelledby="overdue-tab">
              {/* overDue ends here */}
              {/* Risk tab content */}
              {multiTabInfo.length === 0 ? (
                <NoRecordFound data={"No campaigns OverDue "} />
              ) : (
                <table className="table text-nowrap mb-0  custom-table-grid table-borderless dashboard-table">
                  <thead>
                    <tr>
                      <th className="font-14 px-4 position-relative" >Username
                        <span className="position-absolute cursor-pointer" onClick={() => handleColumnSort('Username')}>
                          <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" />
                          <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" />
                        </span>
                      </th>
                      <th className="font-14 position-relative">
                        Campaigns
                        <span className="position-absolute cursor-pointer" onClick={() => handleColumnSort('campaigns')}>
                          <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" />
                          <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" />
                        </span>
                      </th>
                      <th className="font-14 position-relative text-end" >Days
                        <span className="position-absolute cursor-pointer" onClick={() => handleColumnSort('overdueDays')}>
                          <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" />
                          <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" />
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {multiTabInfo.map(task => (
                      <tr className="font-12">
                        <td className="font-12">
                          <div className="d-flex align-items-center">
                            <img src={task?.profileImages} className="table-prof" alt="Profile" />
                            <p className="font-12 font-regular primary-textcolor mb-0 ms-2">
                              {task?.Username}
                            </p>

                          </div>
                        </td>
                        <td className="font-12 font-regular primary-textcolor">
                          <span className='multiTab-column'>
                            {task?.campaigns}
                          </span>
                        </td>
                        <td className="font-12 font-regular primary-textcolor" style={{ textAlign: 'right' }}>
                          <span >
                            {task?.overdueDays}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>



            <div className="tab-pane fade" id="risk" role="tabpanel" aria-labelledby="risk-tab">
              {/* Risk tab content */}
              {multiTabInfo.length === 0 ? (
                <NoRecordFound data={"No risk Users "} />
              ) : (
                <table className="table text-nowrap mb-0  custom-table-grid table-borderless dashboard-table">
                  <thead>
                    <tr>
                      <th className="font-14 px-4 position-relative" onClick={() => riskhandleColumnSort('Username')}>
                        Username
                        <span className="position-absolute cursor-pointer">
                          <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" />
                          <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" />
                        </span>
                      </th>
                      <th className="font-14 position-relative" onClick={() => riskhandleColumnSort('taskPriority')}>
                        Risk Severity
                        <span className="position-absolute cursor-pointer">
                          <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" />
                          <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" />
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {multiTabInfo.map(task => (
                      <tr key={task.taskDetailsId}>
                        <td className="font-12">
                          <div className="d-flex align-items-center">
                            <img src={task?.profileImages} className="table-prof" alt="Profile" />
                            <p className="font-12 font-regular primary-textcolor mb-0 ms-2">

                              {task?.Username}
                            </p>
                          </div>
                        </td>
                        <td className="font-12 font-medium text-left">
                          <span className={`batch-${task?.riskSeverity === 'Low' ? 'low' : task?.riskSeverity === 'Medium' ? 'medium' : 'high'}`}>
                            {task?.riskSeverity}
                          </span>
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </table>
              )}
            </div>

          </div>

        </div>
      </div>



    </>
  )
}

export default MultiTab