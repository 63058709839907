import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { agovContext } from "../../../Constant";
import { getTileData } from "../../../Services/Agov/governanceService";
import { Loader } from "../../Loader/Loader";
import {
    getApprovalStatusData,
  } from "../../../Services/Agov/governanceService";
  import moment from "moment";

const SuperAdminTileData = (TileData)=>{


    //   tileData state variables starts
// pc_0.5 set stateing all the state variables.
  const [totalLMSData, setTotalLMSData] = useState(0)
  const [dueCount, setDueCount] = useState(0)
  const [overDueCount, setOverDueCount] = useState(0)
  const [totalOtherTask, setTotalOtherTask] = useState(0)
  const [completedTaskCount, setCompletedTaskCount] = useState(0)
  const [pendingTaskCount, setPendingTaskCount] = useState(0)
  const [accessRequest, setAccessRequest] = useState(0)
  const [accessReview, setAccessReview] = useState(0)
  const [isLoading,setIsLoading] = useState(false)
  const [reviewStatus, setReviewStatus] = useState(null);
//   tileData state variables ends

//PC_07 
// TO get the required user information from localstorage using useContext
let { employeeName, userId, managerId, roles, employeeId, employeeValue} = useContext(agovContext);

//PS_06 Utilizing useEffect() for fetchting data initially
useEffect(()=>{
    onInitialPageLoad()
    fetchPeriodicalAccessStatus()
},[])

let navigate = useNavigate()  

  //PC_07-16
  //function onIntialPageLoad triggres and make api call to fetch the data\
//and set states the response in state variables and bind them in HTML.
async function onInitialPageLoad(){
        //   Tile Data fetching starts
        
        let payload = {
            userId: userId,
            userRole: roles,
            managerId: managerId,
            employeeId: employeeId
          }
          setIsLoading(false)
          const tileResponse = await getTileData(payload)
      
          if(tileResponse && tileResponse.statusCode === 200)
          {
              if(tileResponse.responseData === null || tileResponse.responseData === undefined)
                  {
                      setTotalLMSData(0)
                      setDueCount(0)
                      setOverDueCount(0)
                      setTotalOtherTask(0)
                      setAccessRequest(0)
                      setAccessReview(0)
                  }
                  else{
                      setTotalLMSData(tileResponse.responseData.LmsTask.totalTaskCount)
                      setDueCount(tileResponse.responseData.LmsTask.due)
                      setOverDueCount(tileResponse.responseData.LmsTask.overDue)
                      setTotalOtherTask(tileResponse.responseData.otherTileTask.totalTaskCount)
                      setAccessRequest(tileResponse.responseData.accessRequest)
                      setAccessReview(tileResponse.responseData.accessReview)
                  }
          }
          else
          {
              setTotalLMSData(0)
              setDueCount(0)
              setOverDueCount(0)
              setTotalOtherTask(0)
              setAccessRequest(0)
              setAccessReview(0)
              navigate('/error');
          }
          setIsLoading(false)
}
const fetchPeriodicalAccessStatus = async () => {
    const response = await getApprovalStatusData({
      id: userId,
      date: moment().format("YYYY-MM-DD"),
    });

    if (response?.statusCode === 200) {
      setReviewStatus(response.message);
    }
  };

    return(
            
            <div className="col-md-12 col-sm-12 col-lg-12 mt-4">
                {/* <Loader isLoading={isLoading} /> */}
            <div className="row rm-dashboard-card bg-white p-3 margin-tile">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="font-14 font-medium primary-textcolor mb-0">Tasks
                    </h3>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 border-end">
                    <div className="bg-white p-3 ps-0 font-medium">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <img src="images/todo-list.svg" className="img-size" alt="Users Icon" />
                                <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">Application Access
                                    Review
                                </h3>
                            </div>
                            <div className="d-inline">
                                <span className="primary-textcolor font-32">
                                {reviewStatus === "AccessOff"
                                ? 0
                                : reviewStatus === "AccessOn"
                                ? accessReview
                                : 0}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 border-end">
                    <div className="bg-white p-3 font-medium">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <img src="images/file-dashboard.svg" className="img-size" alt="Users Icon" />
                                <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">
                                    Application Access Request</h3>
                            </div>
                            <div className="d-inline">
                                <span className="primary-textcolor font-32">{accessRequest}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 border-end">
                    <div className="bg-white p-3 font-medium">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                <img src="images/training-task.svg" className="img-size" alt="Users Icon" />
                                <div className="d-inline">
                                    <h3 className="d-inline primary-textcolor font-14 ms-2">Active Learning Task
                                    </h3>
                                    <div className="d-flex justify-content-between ms-2">
                                        <div className="d-inline">
                                            <span className="grey-light font-11">Due</span>
                                            <span className="primary-textcolor font-13 ms-1">{dueCount}</span>
                                        </div>
                                        <div className="d-inline ms-2">
                                            <span className="grey-light font-11">Overdue</span>
                                            <span className="primary-textcolor font-13 ms-1">{overDueCount}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-inline">
                                <span className="primary-textcolor font-32">{totalLMSData}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3">
                    <div className="bg-white p-3 font-medium">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <img src="images/teams-kpi.svg" className="img-size" alt="Users Icon" />
                                <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">Others
                                </h3>
                            </div>
                            <div className="d-inline">
                                <span className="primary-textcolor font-32">{totalOtherTask}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div> 
    )
}

export default SuperAdminTileData;