
import moment from "moment";
import { BlockBlobClient } from "@azure/storage-blob";
import { v4 as uuid } from "uuid";
import { generateSasToken } from "../Services/GRC/GrcService";
import { blobStorageAccountName, blobContainerName } from "../Constant";


export const handleUpload = async (selectedFile) => {
    const containerName = blobContainerName;
    const storageAccountName = blobStorageAccountName;

    if (!selectedFile) {
        alert("Please select a file first.");
        return;
    }
    let instance = moment().format("DDHHMMmmYYYYss");
    instance += uuid() + "-iiiii-";
    instance += selectedFile.name;
    const blobName = instance;

    let response = await generateSasToken({
        resourceTypes: "o",
        permission: "w",
    });

    const sasToken = response.responseData;
    //console.log(sasToken, "sasToken");
    const blobService = new BlockBlobClient(
        `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blobName}${sasToken}`
    );

    const fileSize = selectedFile.size;
    const chunkSize = 4 * 1024 * 1024;
    let offset = 0;
    const blockList = [];

    while (offset < fileSize) {
        const remainingSize = fileSize - offset;
        const chunk = selectedFile.slice(
            offset,
            offset + Math.min(chunkSize, remainingSize)
        );
        const blockNumber = Math.floor(offset / chunkSize) + 1; // Calculate block number
        const paddedBlockNumber = String(blockNumber).padStart(6, "0"); // Pad block number
        const blockId = btoa(`block-${paddedBlockNumber}`); // Generate block ID with padded block number
        try {
            await blobService.stageBlock(blockId, chunk, chunk.size);
            //console.log("Result: Success");
            blockList.push(blockId);
        } catch (error) {
            console.log("Error:", error);
        }
        offset += chunkSize;
    }

    try {
        await blobService.commitBlockList(blockList);
        //console.log("Block list committed successfully");
    } catch (error) {
        console.log("Error committing block list:", error);
    }
    let blobUrl = blobService.url;
    blobUrl = blobUrl.split("?");
    //console.log(blobUrl[0], "blobUrl[0]");
    return blobUrl[0];
};
