export const Loader = ({ isLoading }) => {
    if (isLoading) {
      return (
        <div className="tab-content px-2" style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "white",
          zIndex: 995,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width:700,
          marginLeft:-13,
          marginTop:-80,
          height:379
          
        }}>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="loader spin">
              <div className="spin__blocker"></div>
              <div className="spin__bottom-left"></div>
              <div className="spin__bottom-right"></div>
              <div className="spin__top-left"></div>
            </div>
            <h3 className="mt-4 font-24 font-medium">Loading...</h3>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };