//PC_SIC_1 to PC_SIC_1 0
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIpConfig, insertIpConfig, updateIpConfig, deleteIpConfig } from '../../Services/Admin/AdminService';
import { SecurityConnector } from './SecurityConnector';
import { SecuritySlaConfig } from './SecuritySlaConfig';
import { SecurityRiskTaskConfig } from './SecurityRiskTaskConfig';
import { SecurityRetentionConfig } from './SecurityRetentionConfig';
import { DeleteAdminPopUp } from './DeleteAdminPopUp';
import { agovContext } from '../../Constant';
import NoDataFound from '../LMS/NoDataFound';
import { Loader } from '../Loader/Loader';

//PC_SIC_11 to  PC_SIC_20
const SecurityIpConfig = () => {
  const navigate = useNavigate();
  const { userId, employeeName, reportsToId } = useContext(agovContext);

  let updateLoadMore = 15;
  let ipConfigDataObj = {
    ipId: null,
    ipName: "",
    ipAddressRange: "",
    category: "",
    createdBy: employeeName,
    modifiedBy: employeeName,
    userId: userId,
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  }
  let clearObj = {
    ipName: "",
    ipAddressRange: "",
    category: ""
  }

  const [ipConfig, setIpConfig] = useState(ipConfigDataObj)
  const [ipConfigData, setIpConfigData] = useState([]);
  const [count, setCount] = useState(0);
  const [orderColn, setOrderColn] = useState('created_on DESC');
  const [loadMore, setLoadMore] = useState(updateLoadMore);
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState([]);

  const [hideIpConfig, setHideIpConfig] = useState(true)
  const [hideSlaConfig, setHideSlaConfig] = useState(false)
  const [hideConnetorConfig, setHideConnectorConfig] = useState(false)
  const [hideRiskConfig, setHideRiskConfig] = useState(false)
  const [hideRetentionConfig, setHideRetentionConfig] = useState(false)
  const [delPopUp, setDelPopUp] = useState(false)
  const [totalRec, setTotalRec] = useState(0)
  const [isLoading, setIsLoading] = useState(false);

  let ipConfigErrorObj = {
    ipNameError: false,
    ipRangeError: false,
    ipRangeRegexError: false,
    categoryError: false
  }
  let ipGridErrorObj = {
    ipIdError: false,
    ipNameError: false,
    ipRangeError: false,
    ipRangeRegexError: false,
    categoryError: false
  }

  let delObj = {
    ipId: null,
    ipName: "",
    ipAddressRange:null,
    userId: userId,
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  }

  const [errors, setErrors] = useState(ipConfigErrorObj);
  const [errorGrid, setErrorGrid] = useState(ipGridErrorObj);
  const [editIpId, setEditIpId] = useState(null)
  const [deleteData, setDeleteData] = useState(delObj)
  const [isEditing, setIsEditing] = useState(false);

  let ipConfigObj = {
    sortColn: orderColn,
    searchValue: searchText,
    updateLoadMore: loadMore
  }
  const [ipObj, setIpObj] = useState(ipConfigObj)
  let editConfigObj = {
    ipId: "",
    ipName: "",
    ipAddressRange: "",
    category: "",
    modifiedBy: employeeName
  }

  const [updateIpConfigData, setUpdateIpConfigData] = useState(editConfigObj)

  useEffect(() => {
    fetchIpConfig();
  }, [loadMore, orderColn]);

  const handleLoadMore = () => {
    setLoadMore(prevLoadMore => prevLoadMore + 5); // Increment loadMore by 5
  };

  const handleSearch = () => {
    fetchIpConfig();
  };

  const handleIpTab = () => {
    setHideSlaConfig(false);
    setHideConnectorConfig(false);
    setHideRiskConfig(false);
    setHideRetentionConfig(false);
    setHideIpConfig(true);
  }

  const handleSlaTab = () => {
    setHideSlaConfig(true);
    setHideConnectorConfig(false);
    setHideRiskConfig(false);
    setHideRetentionConfig(false);
    setHideIpConfig(false);
  }

  const handleConnectorTab = () => {
    setHideSlaConfig(false);
    setHideConnectorConfig(true);
    setHideRiskConfig(false);
    setHideRetentionConfig(false);
    setHideIpConfig(false);
  }

  const handleRetentionTab = () => {
    setHideSlaConfig(false);
    setHideConnectorConfig(false);
    setHideRiskConfig(false);
    setHideRetentionConfig(true);
    setHideIpConfig(false);
  }

  const handleRiskTaskTab = () => {
    setHideSlaConfig(false);
    setHideConnectorConfig(false);
    setHideRiskConfig(true);
    setHideRetentionConfig(false);
    setHideIpConfig(false);
  }

  const handleEditClick = (value) => {
    setEditIpId(value.ip_id);
    setUpdateIpConfigData({
      ...updateIpConfigData,
      ipId: value.ip_id,
      ipName: value.ip_name,
      ipAddressRange: value.ip_address_range,
      category: value.category,
    })

    const updatedIpConfigDataError = ipConfigData.map(obj => {
      return { ...obj, ipIdError: false, ipNameError: false, ipRangeError: false, categoryError: false };
    });

    setIpConfigData(updatedIpConfigDataError);

    setIsEditing(true);
  };

  const handleTickClick = (index) => {
    // Perform actions for tick icon
    handleUpdate(index);
  };

  const handleCrossClick = (index) => {
    // Perform actions for cross icon
    setIsEditing(false);
    setErrorGrid(ipGridErrorObj)

    let updatedIpConfigData = [...ipConfigData];

    updatedIpConfigData[index].ipIdError = false;
    updatedIpConfigData[index].ipNameError = false;
    updatedIpConfigData[index].ipRangeError = false;
    updatedIpConfigData[index].categoryError = false;
    setIpConfig(updatedIpConfigData);
  };

  //gridData binding
  //PC_SIC_25 to PC_SIC_41
  const fetchIpConfig = async () => {
    let ipval = {
      sortColn: orderColn,
      searchValue: searchText,
      updateLoadMore: loadMore
    }
    let ipConfigObj = ipval
    ipConfigObj.updateLoadMore = loadMore
    ipConfigObj.searchValue = searchText
    try {
      setIsLoading(true);
      let result = await getIpConfig(ipConfigObj);
      if (result.statusCode === 200) {
        setIpConfigData(result.responseData.recordsets[0]);
        setTotalRec(result.responseData.recordsets[0].length)
        setCategory(result.responseData.recordsets[1]);
        setCount(result.responseData.recordsets[2][0].count);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  function bindCategory(selectedCategory) {
    //console.log(selectedCategory, "selecetedCategory");
    return (
      category.map((value, index) => {
        return (
          <option value={value.category} selected={value.category == selectedCategory ? true : false}
            name="category">{value.category}</option>
        )

      })
    )
  }
  //PC_SIC_42 to PC_SIC_47
  const handleInputChange = (e) => {
    let ipConfigErrorObj = { ...errors }
    if (e.target.name === 'ipAddressRange') {
      const rangeRegex = /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$/
      const rangeAllowRegex = /^[0-9.\/]+$/
      if(!rangeAllowRegex.test(e.target.value) && e.target.value) e.target.value = ipConfig.ipAddressRange
      if (rangeRegex.test(e.target.value)) {
        ipConfigErrorObj.ipRangeRegexError = false;
      } else {
        ipConfigErrorObj.ipRangeRegexError = true;
      }
    }
    const { name, value } = e.target;
    setIpConfig(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(ipConfigErrorObj);
  };

  const handleDeletePopUp = (ipId, ipName ,ipAddressRange) => {
    //console.log(ipAddressRange)
    let delObj = {
      ipId: ipId,
      ipName: ipName,
      ipAddressRange: ipAddressRange,
      userId: userId,
      managerId: reportsToId,
      appliedBy: employeeName,
      appliedOn: employeeName
    }
    setDeleteData(delObj)
    setDelPopUp(true);
  }

  const handleUpdateChange = (e) => {
    let ipGridErrorObj = { ...errorGrid }
    if (e.target.name === 'ipAddressRange') {
      let rangeRegex = /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$/
      if (rangeRegex.test(e.target.value)) {
        ipGridErrorObj.ipRangeRegexError = false;
      } else {
        ipGridErrorObj.ipRangeRegexError = true;
      }
    }
    const { name, value } = e.target;
    setUpdateIpConfigData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrorGrid(ipGridErrorObj);
  };
  //PC_SIC_48 to PC_SIC_61
  const handleInsert = async () => {
    try {
      setIsLoading(true);
      if (validateIpConfigData()) {
        let result = await insertIpConfig(ipConfig);
        if (result.statusCode == 200) {
          fetchIpConfig();
          setIpConfig(clearObj)
          //console.log(" Ip Config inserted successfully");
        }
        else {
          //console.log("Something went wrong!");
        }
      }
    }
    catch (error) {
      console.error('Error inserting Ipconfig:', error);
    }
    setIsLoading(false);
  };
  //PC_SIC_95 to PC_SIC_103
  const validateIpConfigData = () => {

    let isValid = true;
    let ipConfigErrorObj = { ...errors };
    let rangeRegex = /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$/

    if (ipConfig.ipName === "") {
      ipConfigErrorObj.ipNameError = true;
      isValid = false;
    }

    if (ipConfig.ipAddressRange === "") {
      ipConfigErrorObj.ipRangeError = true;
      isValid = false;
    }

    if (ipConfig.category === "") {
      ipConfigErrorObj.categoryError = true;
      isValid = false;
    }

    if (!rangeRegex.test(ipConfig.ipAddressRange)) {
      ipConfigErrorObj.ipRangeRegexError = true;
      isValid = false
    }

    setErrors(ipConfigErrorObj);
    return isValid;
  };
  //PC_SIC_104 to  PC_SIC_114
  const validateGridData = (index) => {

    let isValid = true;
    let ipGridErrorObj = [...ipConfigData];

    if (updateIpConfigData.ipId === "") {
      ipGridErrorObj[index].ipIdError = true;
      isValid = false;
    }
    if (updateIpConfigData.ipName === "") {
      ipGridErrorObj[index].ipNameError = true;
      isValid = false;
    }
    if (updateIpConfigData.ipAddressRange === "") {
      ipGridErrorObj[index].ipRangeError = true;
      isValid = false;
    }

    if (updateIpConfigData.category === "") {
      ipGridErrorObj[index].categoryError = true;
      isValid = false;
    }

    setIpConfigData(ipGridErrorObj);
    return isValid;
  };
  //PC_SIC_62 to PC_SIC_77
  const handleDelete = async (deleteData) => {
    try {
      setIsLoading(true);
      let result = await deleteIpConfig(deleteData);
      if (result.statusCode === 200) {
        //console.log(result, "result of delete");
        fetchIpConfig();
        //console.log('IP Configuration Information fetched successfully');
      } else {
        //console.log('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  //PC_SIC_79 to PC_SIC_94
  const handleUpdate = async (index) => {
    try {
      setIsLoading(true);
      if (validateGridData(index)) {
        let result = await updateIpConfig(updateIpConfigData);
        if (result.statusCode === 200) {
          setIsEditing(false);
          fetchIpConfig();
          //console.log('IP Configuration Information fetched successfully');
        } else {
          //console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  function gridDynamicBind() {
    return (
      ipConfigData.map((value, index) => {
        //console.log(value, "value");
        return (
          <tr>
            <td className="font-14 font-regular">
              {value.ip_id === editIpId && isEditing ? (
                <input type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  name="ipName"
                  value={updateIpConfigData.ipName ?? value.ip_name}
                  onChange={(e) => {
                    handleUpdateChange(e);
                  }}
                  placeholder="Enter Range Name" />
              ) : value.ip_name}
              <h6>
                {value?.ipNameError ? (
                  <span id="alertMessage" style={{color:"red",font:"small-caption"}}>Enter IpName</span>
                ) : null}
              </h6>
            </td>
            <td className="font-14 font-regular">
              {value.ip_id === editIpId && isEditing ? (
                <input type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  name="ipAddressRange"
                  value={updateIpConfigData.ipAddressRange ?? value.ip_address_range}
                  onChange={(e) => {
                    handleUpdateChange(e);
                  }}
                  placeholder="Eg: 192.168.1.1/32" />
              ) : value.ip_address_range}
              <h6>
                {(value.ip_id === editIpId && isEditing && value?.ipRangeError) ? (
                  <span id="alertMessage" style={{color:"red",font:"small-caption"}}>Enter Ip Range</span>
                ) : value.ip_id === editIpId && isEditing && errorGrid.ipRangeRegexError ? (<span id="alertMessage">Enter Valid Ip Address</span>) : null}
              </h6>
            </td>
            <td className="font-14 font-regular">
              {value.ip_id === editIpId && isEditing ? (
                <select className="form-select form-control font-regular font-14 custom-form-control py-2" aria-label="Default select example" id="country"
                  name="category"
                  onChange={(e) => {
                    handleUpdateChange(e);
                  }}>
                  <option value="">Select</option>
                  {bindCategory(value.category)}
                </select>
              ) : (
                value.category
              )}
              <h6>
                {value?.categoryError ? (
                  <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                    Choose Category
                  </span>) : null}
              </h6>
            </td>
            <td className="text-center">
              {value.ip_id === editIpId && isEditing ? (
                <>
                  <a className="me-3" onClick={() => { handleTickClick(index) }}>
                    <img src="/images/tick-icon.svg" alt="tick-icon" />
                  </a>
                  <a className="me-3" onClick={() => handleCrossClick(index)}>
                    <img src="/images/cross-icon.svg" alt="cross-icon" />
                  </a>
                </>
              ) : (
                <a className="me-2">
                  <img src="/images/edit-icon.svg" alt="edit-icon" onClick={() => {
                    handleEditClick(value);
                  }} />
                </a>
              )}
              <a className="me-2"><img src="/images/delete-icon.svg" alt="edit-icon" onClick={() => {
                handleDeletePopUp(value.ip_id,value.ip_name,value.ip_address_range)
              }} /></a>
            </td>
          </tr>
        )
      }
      )
    )
  }


  return (
    <>
      <div className="container-fluid mt-4 mt-custom">
        <div className="col-md-12">
          <div className="px-3 px-xl-5">
            <div className="d-flex justify-content-between mt-4">
              <h1 className="font-bold font-24 primary-textcolor">
                Admin Configurations
              </h1>
            </div>
            {/*tabs starts here*/}
            <div className="d-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link px-0  font-14 tab-style rounded-0"
                    id="information-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#information"
                    type="button"
                    role="tab"
                    aria-controls="information"
                    aria-selected="true"
                    onClick={() => navigate('/adminmanagement')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/information-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/information-icon.svg"
                        alt="information-icon"
                        className="me-2"
                      />
                    </span>
                    Information
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="permissions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#permissions"
                    type="button"
                    role="tab"
                    aria-controls="permissions"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/userPermission')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/permission-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/permission-icon.svg"
                        alt="permissions-icon"
                        className="me-2"
                      />
                    </span>
                    Permissions
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="configurations-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#configurations"
                    type="button"
                    role="tab"
                    aria-controls="configurations"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/accessReview')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/key-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/key-icon.svg"
                        alt="configurations-icon"
                        className="me-2"
                      />
                      Configurations
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="app-owner-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#app-owner-config"
                    type="button"
                    role="tab"
                    aria-controls="app-owner-config"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/appOwnerConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/app-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/app-icon.svg"
                        alt="app-owner-config-icon"
                        className="me-2"
                      />
                      App Owner Config
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 active font-14 tab-style bold-text rounded-0"
                    id="security-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#security"
                    type="button"
                    role="tab"
                    aria-controls="security"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/lock-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/lock-icon.svg"
                        alt="security-icon"
                        className="me-2"
                      />
                      Security
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="policy-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#policy-config"
                    type="button"
                    role="tab"
                    aria-controls="policy-config"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/policyConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/privacy-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/privacy-icon.svg"
                        alt="policy-config-icon"
                        className="me-2"
                      />
                      Policy Configuration
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="billing-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#billing"
                    type="button"
                    role="tab"
                    aria-controls="billing"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/billing')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/bill-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/bill-icon.svg"
                        alt="billing-icon"
                        className="me-2"
                      />
                      Billing
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            {/*tabs ends here*/}

            {/* Security tab starts here */}
            <div className="row mb-5">
              <div className="col-md-12">
                <h2 className="font-bold font-24">Security</h2>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex align-items-start security-ip-tab">
                      <div className="col-md-3 nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" onClick={() => { handleIpTab() }}>IP
                          Config</button>
                        <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { handleSlaTab() }}>SLA
                          Config</button>
                        <button className="nav-link" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false" onClick={() => { handleRetentionTab() }}>Data
                          Retention</button>
                        <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false" onClick={() => { handleConnectorTab() }}>Connectors</button>
                        <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false" onClick={() => handleRiskTaskTab()}>Risk Task Config</button>
                      </div>

                      {/* Security Ip Config */}
                      {hideIpConfig && (
                        <div className="col-md-9 tab-content ps-5" id="v-pills-tabContent">
                          <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex={0}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="d-flex justify-content-between mt-4 align-items-center">
                                  <div className="col-md">
                                    <h1 className="font-bold font-20 primary-textcolor">IP
                                      Config</h1>
                                    <p className="font-14 grey-light mt-3">Efficiently
                                      categorize and manage IP addresses for optimized
                                      network organization and administration</p>
                                  </div>
                                  <div className="col-md d-flex justify-content-end">
                                    <div className="input-group flex-nowrap search-group me-2">
                                      <input type="search" className="form-control search-align"
                                        placeholder="Search"
                                        aria-label="Username"
                                        aria-describedby="addon-wrapping"
                                        onKeyDownCapture={(e) => {
                                          if (e.key === "Enter") {
                                            handleSearch(e)
                                          }
                                        }}
                                        onChange={(e) => { setSearchText(e.target.value) }} />
                                      <button className="input-group-text btn btn-search-custom" id="addon-wrapping">
                                        <img src="/images/search-icon.svg" alt="search-icon" onClick={() => { handleSearch() }} />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="table-responsive" style={{ overflowX: 'hidden' }}>
                                  <table className="table mb-0 custom-table-grid table-borderless mt-3">
                                    <thead>
                                      <tr>
                                        <th className="font-14 px-4 position-relative">
                                          Name<span className="position-absolute">
                                            <a className="sort-up position-absolute ms-2" onClick={() => { setOrderColn("ip_name ASC") }}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2" onClick={() => setOrderColn("ip_name DESC")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a></span>
                                        </th>
                                        <th className="font-14 position-relative">IP Address
                                          Range<span className="position-absolute"><a className="sort-up position-absolute ms-2" onClick={() => { setOrderColn("ip_address_range ASC") }}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2" onClick={() => setOrderColn("ip_address_range DESC")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a></span>
                                        </th>
                                        <th className="font-14 position-relative">
                                          Category<span className="position-absolute"><a className="sort-up position-absolute ms-2" onClick={() => setOrderColn("category ASC")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2" onClick={() => { setOrderColn("category DESC") }}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a></span>
                                        </th>
                                        <th className="font-14 text-center">Action</th>
                                      </tr>
                                    </thead>
                                    {count >= 0 ? (
                                      <tbody>
                                        <tr>
                                          <td><input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                                            name="ipName"
                                            value={ipConfig.ipName}
                                            onChange={(e) => {
                                              handleInputChange(e);
                                            }}
                                            placeholder="Enter Range Name" />
                                            <h6>
                                              {errors.ipNameError ? (
                                                <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                                                  Enter Ip Name
                                                </span>) : null}
                                            </h6>
                                          </td>
                                          <td><input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                                            name="ipAddressRange"
                                            value={ipConfig.ipAddressRange}
                                            onChange={(e) => {
                                              handleInputChange(e);
                                            }}
                                            placeholder="Eg: 192.168.1.1/32" />
                                            <h6>
                                              {errors.ipRangeError ? (
                                                <span id="alertMessage" style={{color:"red",font:"small-caption"}}>Enter Ip Range</span>
                                              ) : errors.ipRangeRegexError ? (<span id="alertMessage">Enter Valid Ip Address</span>) : null}
                                            </h6>
                                          </td>
                                          <td>
                                            <select className="form-select form-control font-regular font-14 custom-form-control py-2" aria-label="Default select example" id="country"
                                              name="category"
                                              value={ipConfig.category}
                                              onChange={(e) => {
                                                handleInputChange(e);
                                              }}
                                            >
                                              <option value="">Select</option>
                                              {bindCategory()}
                                            </select>
                                            <h6>
                                              {errors.categoryError ? (
                                                <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                                                  Choose Category
                                                </span>) : null}
                                            </h6>
                                          </td>
                                          <td className="text-center"><a ><img src="/images/add-icon-line.svg" alt="add-icon" onClick={() => handleInsert()} /></a></td>
                                        </tr>
                                        {gridDynamicBind()}
                                      </tbody>
                                    ) : (
                                      <tr>
                                        <td colSpan={4} style={{ textAlign: 'center' }}>
                                          <NoDataFound />
                                        </td>
                                      </tr>
                                    )}
                                  </table>
                                  {
                                    delPopUp && (
                                      <DeleteAdminPopUp handleDelete={handleDelete} setDelPopUp={setDelPopUp} deleteData={deleteData}></DeleteAdminPopUp>
                                    )
                                  }
                                </div>
                                <div className="mt-3">
                                  <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{totalRec} </span>
                                    out of <span className="font-medium primary-textcolor">{count}</span> items</p>
                                </div>
                                <div className="text-center mt-5 mb-4">
                                  {totalRec < count && (
                                    <button className="primary-btn font-medium mb-5  cursor-pointer"
                                      onClick={handleLoadMore}>
                                      Load More</button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Security Ip Config */}

                      {/* Connector Config*/}
                      {hideConnetorConfig && (
                        <SecurityConnector></SecurityConnector>
                      )}

                      {hideSlaConfig && (
                        <SecuritySlaConfig></SecuritySlaConfig>
                      )}

                      {hideRiskConfig && (
                        <SecurityRiskTaskConfig></SecurityRiskTaskConfig>
                      )}

                      {hideRetentionConfig && (
                        <SecurityRetentionConfig></SecurityRetentionConfig>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  )
}

export { SecurityIpConfig }