import moment from "moment";
import { useEffect, useState } from "react";
import {
  getApprovalStatusData,
  getUsersAccessDetails,
} from "../../../../Services/Agov/governanceService";
import UserApplicationAccessHeader from "./UserApplicationAccessHeader";
import { getConnectorData } from "../../../../Services/Admin/AdminService";

const UserApplicationAccessOverview = ({roles, userId, managerId, isSuperAdminAndManager}) => {
  const [accessStatus, setAccessStatus] = useState([]);
  const [reviewStatus, setReviewStatus] = useState(null);
  const [activeTab, setActiveTab] = useState("myTask");
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveBadge, setIsRemoveBadge] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {        ;
        await fetchPeriodicalAccessStatus();
        await fetchUsersAccessDetails();
        await fetchConnectorsData();
      } catch(error) {
        //console.log('API Error!!!');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [activeTab]);

  const fetchPeriodicalAccessStatus = async () => {
    const response = await getApprovalStatusData({
      id: userId,
      date: moment().format("YYYY-MM-DD"),
    });

    if (response?.statusCode === 200) {
      setReviewStatus(response.message);
    }
  };

  const fetchUsersAccessDetails = async () => {
    const payload = {
      managerId,
      activeTab,
      roles,
      isSuperAdminAndManager,
    };

    const response = await getUsersAccessDetails(payload);

    if (response?.statusCode === 200) {
      const result = response?.result?.status || {};
      const transformDataForRechart = Object.keys(result).map((key) => ({
        name: key,
        value: result[key],
      }));

      setAccessStatus(transformDataForRechart);
    }
  };

  const fetchConnectorsData = async () => {
    const response = await getConnectorData();

    if (response.responseData === "newLogin") {
      setIsRemoveBadge(true);
    }
  }


  const filterDataForLegends = (reviewStatus, accessStatus) => {
    if (!accessStatus) return [];

    const accessStatusBasedOnReviewStatus =
      reviewStatus === "AccessOff"
        ? ["approved", "noActionTaken"]
        : ["approved", "awaitingApproval"];

    return accessStatus
      ?.filter((access) =>
        accessStatusBasedOnReviewStatus.includes(access.name)
      )
      .map((access) => ({
        name:
          access.name === "approved"
            ? "Approved"
            : access.name === "noActionTaken"
            ? "No Action Taken"
            : "Awaiting Approval",
        value: access.value,
      }));
  };

  const pieChartLegends = filterDataForLegends(reviewStatus, accessStatus);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3">
      <div className="bg-white p-3 border-radius-8 rm-dashboard-card">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-14 font-medium primary-textcolor mb-0">
            User Application Access
          </h3>
          {!isRemoveBadge && (
          <div className="align-items-center d-flex gap-2">
            <span className="badges-blue border-radius-8 ms-2 font-10">
              <img src="images/key.svg" className="me-1" />
              {reviewStatus === "AccessOff"
                ? "Review Completed"
                : reviewStatus === "AccessOn"
                ? "During the Review"
                : "Loading..."}
            </span>
          </div>
          )}
        </div>
        <UserApplicationAccessHeader handleTabChange={handleTabChange} data={pieChartLegends} roles={roles} isSuperAdminAndManager={isSuperAdminAndManager} activeTab={activeTab} isLoading={isLoading}/>
      </div>
    </div>
  );
};

export default UserApplicationAccessOverview;