//Import the required Hooks,Pcakages, services from LogServices 

import { useContext, useEffect, useState } from "react";
import { getAppData } from "../../Services/Agov/governanceService";
import { useLocation, useNavigate } from "react-router";
import { getFilterAppData } from "../../Services/Agov/governanceService";
import { getExportToExcelApp } from "../../Services/Agov/governanceService";
import * as FileSaver from 'file-saver';
import moment from "moment";
import { Loader } from '../Loader/Loader';
import { agovContext } from "../../Constant";
import ExcelJS from 'exceljs';
import NoRecordFoundForAG from "./NoRecordFoundForAG";

//initiating the Activity Logs 
function ApplicationGrid() {

    const { userId } = useContext(agovContext);

    let filterObj = {
        managedBy: "",
        department: "",
        location: "",
        status: "",
        fromDate: "",
        toDate: ""
    }

    const [isLoading, setIsLoading] = useState(false)
    let [gridData, setGridData] = useState([]);
    let [nextPage, setNextPage] = useState(10);
    let [searchValue, setSearchValue] = useState("");
    const [gridCount, setGridCount] = useState(0);
    let [noData, setNoData] = useState(0);
    let [managedBy, setMangedBy] = useState([])
    let [department, setDepartment] = useState([])
    let [filterLocation, setFilterLocation] = useState([])
    let [approvalStatus, setApprovalStatus] = useState([])
    let [showfilter, setShowFilter] = useState(false);
    let [filter, setFilter] = useState(filterObj)
    const [sortColumnName, setSortColumnName] = useState('b.employee_name asc');
    let [searchValueConstant, setSearchValueConstant] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    const selectedAppName = location.state?.value?.id;
    const selectedAppUrl = location.state?.value?.appUrl;

    //UseEffect Function Triggers
    useEffect(() => {
        loadApplicationGrid()
    }, [nextPage, sortColumnName]);

    /**
       * PC_AG_32 -Load Application Grid  function performs the inital 
       */
    const loadApplicationGrid = async () => {
        let gridvalue = []
        try {

            setIsLoading(true)
            let loadObj = {
                sort: sortColumnName,
                page: nextPage,
                applicationName: selectedAppName,
                search: searchValue,
                filterData: filter
            }
            let appResponse = await getAppData(loadObj)

            setGridData(appResponse.response[0])
            setGridCount(appResponse.count[0].totalCount)
            gridvalue = appResponse.response
            setFilter(filterObj = {
                managedBy: "",
                department: "",
                location: "",
                status: "",
                fromDate: "",
                toDate: ""
            })
            setShowFilter(false)
            setIsLoading(false)
        }

        catch {
            setIsLoading(false)
        }
    }

      useEffect(() => {
        fetchFilterData();
    }, []);

    const fetchFilterData = async () => {
        try {
            let filterObj = {
               userId,
            }
            let filterResult = await getFilterAppData(filterObj)
            setMangedBy(filterResult?.response[0]);
            setDepartment(filterResult?.response[1]);
            setFilterLocation(filterResult?.response[2]);
            setApprovalStatus(filterResult?.response[3]);
        } catch (error) {
            console.error("Error fetching filter data:", error);
        }
    }

    const isDisabled = gridData.length === 0;

    // format Date and Time 
    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString || dateTimeString == undefined) return "-"; // Return an empty string if dateTimeString is null or undefined

        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        const hour = date.getHours().toString().padStart(2, "0");
        const minute = date.getMinutes().toString().padStart(2, "0");
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour =
            hour % 12 === 0 ? "12" : (hour % 12).toString().padStart(2, "0");

        return (
            <span>
                {day}/{month}/{year} {formattedHour}:{minute} {ampm}
            </span>
        );
    };

    //Binding the Filter Data  in the Filter Pop Up  
    const bindApplicationGrid = () => {

        if (gridData.length == 0) {
            return (
                <tr>
                    <td colSpan={6}>
                        <NoRecordFoundForAG />
                    </td>
                </tr>
            )
        }
        else {
            return gridData?.map((obj, index) => {
                return (
                    <tr style={{cursor:"default"}}>
                        <td>
                            <div className="d-flex align-items-center" style={{cursor: "pointer"}}>
                                <img src={obj.profileUrl} className="table-prof" />
                                <p className="font-12 font-medium secondary-textcolor mb-0 ms-2"
                                  onClick={() => navigate(`/userprofile`, { state: { value: { id: obj.user_id, employeeid: obj.employee_id, navigatedFromAppGrid: true } } }) }>{obj.employee_name}</p>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                            {obj.manager_name !== null ? (
                                <>
                                <img src={obj.managerProfileUrl} className="table-prof" />
                                <p className="font-12 primary-textcolor mb-0 ms-2">{obj.manager_name}</p>
                                </>
                            ) : (
                                <p className="font-12 primary-textcolor mb-0">-</p>
                            )}
                            </div>
                        </td>
                        <td className="font-12">
                            {obj.employee_domain === null ? "-" : obj.employee_domain}
                        </td>
                        <td className="font-12">
                            {formatDateTime(obj.application_login_date)}
                        </td>
                        <td className="font-12">

                            <div className="tooltip-container">
                                <img src={obj.network == "External" ? "/images/home.svg" : obj.network == "Internal" ? "/images/business-and-trade.svg" : "-"}
                                
                                    className="me-2" data-bs-toggle="tooltip" data-bs-placement="bottom" />{obj.location}
                                <div className="tooltip">{obj.network || '-'}</div>
                            </div>

                        </td>
                        <td className="font-12">
                            <span className={
                                obj.approval_status == "Application Assigned" || obj.approval_status == "Application Approved" ? "green-dot me-2" :
                                    obj.approval_status == "Awaiting Confirmation" ? "orange-dot me-2" :
                                        obj.approval_status == "Not Approved" ? "red-dot me-2" : "-"
                            }></span>
                            {obj.approval_status == "Not Approved" ? "No Action" :obj.approval_status}
                        </td>
                    </tr>
                )
            })
        }
    }

    // let onHandleSearch = async (ob) => { setSearchValue(ob?.target?.value?.trim()); }

    /**
  * PC_AG_138-PC_AG_141 handleSearch Function gets invoked
  */
    let handleSearch = async () => {

        gridData = [];
        setGridData(gridData)
        setGridCount(0);
        nextPage = 10;
        setNextPage(nextPage);
        setSearchValueConstant(searchValue);

        await loadApplicationGrid(nextPage);
    }
    /**
     * PC_AG_69 handle Filter Data function gets invoked 
     * 
     */
 
    const handleFilterData = () => {
        setShowFilter(true);
    };

    let bindMangedBy = () => {
        return managedBy?.map((obj, index) => {
            return (
                <option key={index} value={obj.manager_name}>{obj.manager_name == "" || obj.manager_name == null || obj.manager_name == undefined ? "-" : obj.manager_name}</option>

            )
        })
    }

    let bindDepartment = () => {
        return department?.map((obj, index) => {
            return (
                <option key={index} value={obj.employee_domain}>{obj.employee_domain == "" || obj.employee_domain == null || obj.employee_domain == undefined ? "-" : obj.employee_domain}</option>

            )
        })
    }

    let bindFilterLocation = () => {
        return filterLocation?.map((obj, index) => {

            return (
                <option key={index} value={obj.location}>{obj.location == "" || obj.location == "NULL" || obj.location == undefined ? "Select" : obj.location}</option>

            )
        })
    }
    let bindApprovalStatus = () => {
        return approvalStatus?.map((obj, index) => {
            return (
                <option key={index} value={obj.approval_status}>{obj.approval_status}</option>

            )
        })
    }
    /**
     * 
     * PC_AG_84 Handle Filter Dunction gets Triggered 
     */
    const handleFilter = (event) => {
        setFilter({ ...filter, [event.target.name]: event.target.value });
    }


    const handleApply = () => {
    
        loadApplicationGrid();
        setFilter(filterObj = {
            managedBy: "",
            department: "",
            location: "",
            status: "",
            fromDate: "",
            toDate: ""
        })
        setShowFilter(false)
    };

    const handleClear =()=>{
        setFilter(filterObj = {
            managedBy: "",
            department: "",
            location: "",
            status: "",
            fromDate: "",
            toDate: ""
        })
    }





    //PC_EXG_13 - PC_EXG_53  handle Export Excel Function defined with the Respective Payload and Api Call
    let handleExportExcel = async () => {
        setIsLoading(true);
        try {
            if (gridCount != 0) {
                let excelObj = {
                    id: userId,
                    applicationName: selectedAppName,
                    search: searchValue,
                    sort: sortColumnName,
                    filterData: filter
                };
                let exportData = await getExportToExcelApp(excelObj);
                let user = exportData.response;
                let excelData = [];

                for (var i = 0; i < user.length; i++) {
                    let val = [
                        user[i].employee_name,
                        user[i].primary_email == null ? "-" : user[i].primary_email,
                        user[i].employee_domain == null ? "-" : user[i].employee_domain,
                        user[i].manager_name == null ? "-" : user[i].manager_name,
                        selectedAppName,
                        user[i].application_login_date == null ? "-" : moment(user[i].application_login_date).format('L') + " " + moment(user[i].application_login_date).format('LT'),
                        user[i].browser,
                        user[i].location,
                        user[i].ip_address
                    ];
                    excelData.push(val);
                }

                let header = [['Employee Name', 'Employee Email', 'Department', 'Manager Name', 'Application Name', 'Last Login Date & Time', "Browser", "Location", "IP Address"]];
                header = header.concat(excelData);

                let filename = "AGov-Application-accessReport-"+
                new Date().getFullYear() +
                "/" +
                (parseInt(new Date().getMonth()) + 1 < 10
                  ? "0" + (parseInt(new Date().getMonth()) + 1)
                  : parseInt(new Date().getMonth() + 1)) +
                "/" +
                (new Date().getDate() < 10
                  ? "0" + new Date().getDate()
                  : new Date().getDate()) + '.xlsx';
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

                // Create a new Excel workbook
                let workbook = new ExcelJS.Workbook();
                let worksheet = workbook.addWorksheet('Application report');

                worksheet.columns = [
                    { width: 20 },  // Employee Name
                    { width: 30 },  // Employee Email
                    { width: 15 },  // Department
                    { width: 20 },  // Manager Name
                    { width: 20 },  // Application Name
                    { width: 25 },  // Last Login Date & Time
                    { width: 15 },  // Browser
                    { width: 15 },  // Location
                    { width: 15 }   // IP Address
                ];

                // Add data to worksheet
                header.forEach(row => {
                    worksheet.addRow(row);
                });

                // Write workbook to buffer
                let buffer = await workbook.xlsx.writeBuffer();

                // Create Blob
                let dataBlob = new Blob([buffer], { type: fileType });

                // Save file
                FileSaver.saveAs(dataBlob, filename);
            }
        } catch (error) {
            console.error("Error exporting to Excel:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    return (
        <>
            <Loader isLoading={isLoading} />

            <div className="mt-custom">
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="stylesheet" type="text/Css" href="css/agov.css" />
                <link rel="stylesheet" type="text/css" href="css/bootstrap.css" />

                <title>Access Governance - My Applications Grid</title>
                <div className="container-fluid">
                    <div className="col-md-12 px-2 px-md-3 px-lg-5 mt-5">
                        <div className="d-sm-flex align-items-center justify-content-between mt-4">
                            {/* Application Name */}
                            <div className="mb-3 mb-md-0">
                                <button className="border-0 bg-transparent p-0" onClick={() => navigate('/accessGovernance')}><img src="/images/left-arrow.svg" className="me-3" /></button>
                                <img src="/images/my-app-active.svg" className="me-2" />
                                <span className="font-medium font-18 primary-textcolor" style={{cursor: "default"}}>{selectedAppName}</span>
                            </div>
                            {/* Application Name */}
                            {/* Search and Filter  */}
                            <ul className="nav">
                                <li>
                                    <div className='input-group flex-nowrap search-group me-2 ms-2' style={{padding: "1px"}}>
                                        <input type="search" onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearch(e)
                                            }
                                        }} onChange={(e) => { setSearchValue(e.target.value) }}
                                            className="form-control search-align" placeholder="Search" maxLength={250} aria-label="Username" aria-describedby="addon-wrapping" />
                                        <button className="input-group-text btn btn-search-custom" onClick={() => handleSearch()} id="addon-wrapping"><img src="/images/search-icon.svg" alt="search-icon" /></button>
                                    </div>
                                </li>
                                <li>
                                    <button className={showfilter ? `cust-filter-btn d-flex align-items-center me-2 ms-2` : `cust-filter-btn d-flex align-items-center me-2 ms-2 show`} onClick={() => handleFilterData()} type="button">
                                        <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                    </button>
                                    {showfilter ? <div className={showfilter ? `custom-filter-container custom-filter border-0` : `custom-filter-container custom-filter border-0 show`}>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                                <button type="button" className="btn-close font-12" onClick={() => setShowFilter(false)} />
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">Managed by</label>
                                                        <select className="form-select cust-input-sty font-14 font-regular cursor-pointer" aria-label="Default select example" name='managedBy'
                                                            value={filter.managedBy} onChange={(e) => { handleFilter(e) }}>
                                                            <option selected>Select</option>
                                                            {bindMangedBy()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">Department</label>
                                                        <select className="form-select cust-input-sty font-14 font-regular" aria-label="Default select example" name='department'
                                                            value={filter.department} onChange={(e) => { handleFilter(e) }}>
                                                            <option selected>Select</option>
                                                            {bindDepartment()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">Location</label>
                                                        <select className="form-select cust-input-sty font-14 font-regular" aria-label="Default select example" name='location'
                                                            value={filter.location} onChange={(e) => { handleFilter(e) }}>
                                                            <option selected>Select</option>
                                                            {bindFilterLocation()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">Status</label>
                                                        <select className="form-select cust-input-sty font-14 font-regular" aria-label="Default select example" name='status'
                                                            value={filter.status} onChange={(e) => { handleFilter(e) }}>
                                                            <option selected>Select</option>
                                                            {bindApprovalStatus()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <h5 className="font-14 font-bold text-black mt-3">Date Range</h5>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="StartDate" className="form-label text-black font-12 font-medium">From</label>
                                                        <input type="date" className="form-control cust-input-sty font-12 font-regular" id="StartDate"
                                                            value={filter.fromDate} name='fromDate' onChange={(e) => { handleFilter(e) }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="endDate" className="form-label text-black font-12 font-medium">To</label>
                                                        <input type="date" className="form-control cust-input-sty font-12 font-regular" id="endDate"
                                                            value={filter.toDate} name='toDate' onChange={(e) => { handleFilter(e) }} />
                                                    </div>
                                                </div>
                                            </div>
                                                <button type="button" className="primary-btn font-14 font-medium me-2"
                                                    onClick={handleClear}>
                                                    Clear
                                                </button>
                                                <button type="button" className="dark-btn font-14 font-medium px-4"
                                                    disabled={!(filter.managedBy || filter.department || filter.location || filter.fromDate || filter.toDate || filter.status)}
                                                    onClick={() => { handleApply() }}>
                                                    Apply
                                                </button>
                                            </div>
                                    </div> : null}
                                </li>
                                <button className={`advanced-filter-button ms-2 me-2 medium-text customflip-btn`} type="button" onClick={() => {!isDisabled && handleExportExcel()}} aria-expanded="false">
                                    <img src="/images/excel-icon.svg" alt="Filter-icon" />
                                </button>
                            </ul>
                            {/* Search and Filter Ends */}
                        </div>
                        <h2 className="font-20 font-medium mt-4">Application Users</h2>
                        <div className="table-responsive">
                            <table className="table text-nowrap mb-0 custom-table-grid table-borderless mt-3">
                                <thead style={{cursor: "default"}}>
                                    <tr>
                                        <th className="font-14 position-relative">
                                            User Name
                                            <a onClick={() => { setSortColumnName('b.employee_name asc') }} className="agl-sort-Up position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
                                            </a>
                                            <a onClick={() => { setSortColumnName('b.employee_name desc') }} className="agl-sort-Down  position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
                                            </a>
                                        </th>
                                        <th className="font-14 position-relative">
                                            Managed by
                                            <a onClick={() => { setSortColumnName('b.manager_name asc') }} className="agl-sort-Up position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
                                            </a>
                                            <a onClick={() => { setSortColumnName('b.manager_name desc') }} className="agl-sort-Down position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
                                            </a>
                                        </th>
                                        <th className="font-14 position-relative">
                                            Department
                                            <a onClick={() => { setSortColumnName('b.employee_domain asc') }} className="agl-sort-Up position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
                                            </a>
                                            <a onClick={() => { setSortColumnName('b.employee_domain desc') }} className="agl-sort-Down position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
                                            </a>
                                        </th>
                                        <th className="font-14 position-relative">
                                            Last login
                                            <a onClick={() => { setSortColumnName('a.application_login_date asc') }} className="agl-sort-Up position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
                                            </a>
                                            <a onClick={() => { setSortColumnName('a.application_login_date desc') }} className="agl-sort-Down position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
                                            </a>
                                        </th>
                                        <th className="font-14 position-relative">
                                            Location
                                            <a onClick={() => { setSortColumnName('a.location asc') }} className="agl-sort-Up position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
                                            </a>
                                            <a onClick={() => { setSortColumnName('a.location desc') }} className="agl-sort-Down position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
                                            </a>
                                        </th>
                                        <th className="font-14 position-relative">
                                            Status
                                            <a onClick={() => { setSortColumnName('c.approval_status asc') }} className="agl-sort-Up position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
                                            </a>
                                            <a onClick={() => { setSortColumnName('c.approval_status') }} className="agl-sort-Down position-absolute ms-2">
                                                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bindApplicationGrid()}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3">
                            <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{gridData.length}</span> out of
                                <span className="font-medium primary-textcolor"> {gridCount}</span> items
                            </p>
                        </div>
                        {gridCount > gridData?.length && (
                            <div className="text-center mb-5">
                                <button className="primary-btn font-medium" onClick={() => setNextPage(nextPage + 10)}>Load More</button>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default ApplicationGrid
