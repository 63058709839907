export const Loader = ({ isLoading }) => {
    if (isLoading) {
      return (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 995,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 450,
          marginLeft:13
        }}>

          <div className="d-flex align-items-center justify-content-center flex-column">
          <div className="loader spin"> {/* Scaled down to 60% of original size */}
            <div className="spin__blocker"></div>
            <div className="spin__bottom-left"></div>
            <div className="spin__bottom-right"></div>
            <div className="spin__top-left"></div>
          </div>
          <h3 className="mt-2 font-16 font-medium">Loading...</h3> {/* Reduced font size and margin */}
        </div>
        </div>
        
      );
    } else {
      return null;
    }
  };