import React, { useState, useEffect,useContext } from 'react';
import { getUserInfoPopupAPIdata } from '../../Services/LMS/LmsService';
import InfiniteScroll from 'react-infinite-scroll-component';
import { agovContext } from '../../Constant';
import { Loader } from '../Loader/Loader';

const UserLearningDashBoardPopup = ({ Props, onClose,eventValue }) => {
  //console.log(Props, "prospValueee");
  const { userId, roles } = useContext(agovContext);
  const [userDetailsRequest, setUserDetailsRequest] = useState({
    userId: userId,
    limit: 5,
    sortColumn: 'Username',
    sortOrder: 'ASC',
    status: Props,
    reqDuration:eventValue
  });
  // state variables  PC_userPopUp_09 to  PC_userPopUp_12

  const [userDetails, setUserDetails] = useState([]);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [hasMoreLoad, setHasMoreLoad] = useState(false);
   const [isLoading, setIsLoading] = useState(false); 
//PC_userPopUp_13  use effect to call the pi initally 

  useEffect(() => {
    fetchUserPopUpDetails();
  }, [userDetailsRequest, Props]);

//PC_userPopUp_16  this function fetches the api data 

  const fetchUserPopUpDetails = async () => {
    setIsLoading(true)
    try {
      const response = await getUserInfoPopupAPIdata(userDetailsRequest);
      // setUserDetails([]);
      if (response.status && response.statusCode === 200) {
        let data = response.responseData;
        // setUserDetails(prevDetails => [...prevDetails, ...data]);
        setUserDetails(data)
        setTotalUserCount(response.popupCount);
        let totalCount=response.popupCount
        
        //console.log(userDetails.length + data.length < response.popupCount, "asdfasdf");
        setHasMoreLoad(totalCount > userDetailsRequest.limit ? true : false);
       
      } else {
        console.error('Error fetching data:', response.statusCode);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setIsLoading(false)}
  };

  

// this function is used for tsorting the grid PC_userPopUp_44  to PC_userPopUp_50

  const handleColumnSort = (columnName) => {
    const newSortOrder = columnName === userDetailsRequest.sortColumn && userDetailsRequest.sortOrder === 'DESC' ? 'ASC' : 'DESC';
    setUserDetailsRequest({
      ...userDetailsRequest,
      sortColumn: columnName,
      sortOrder: newSortOrder,
      limit: 5  // Reset the limit when sorting changes
    });
    setUserDetails([]);  // Clear existing data to load new sorted data
  };
// this function is used to increase the limit 
  const fetchMoreData = () => {
    setUserDetailsRequest(prevRequest => ({
      ...prevRequest,
      limit: prevRequest.limit + 5
    }));
  };

  return (
    <div className="modal fade show" id="staticBackdrop1" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }} tabIndex={-1}  aria-labelledby="staticBackdropLabel">
       <Loader isLoading={isLoading} />
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content custom-popup-width">
      <div className="modal-header d-flex justify-content-between align-items-center pb-2 pt-3 pe-2 border-0">
        <h5 className="modal-title font-14 font-medium primary-textcolor" id="staticBackdropLabel">
          User Learning Information
        </h5>
        <div className="d-flex flex-row align-items-center">
          <button type="button" className="btn btn-close me-3" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
        </div>
      </div>
      <div className="modal-body px-3 pb-4 pt-0" id="LMSUsers">
        <div className="table-scroll" id="scrollableDiv" style={{ maxHeight: '300px' }}>
          <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
            <thead>
              <tr className="sticky-top" style={{ width: '100%' }}>
                <th className="font-12 px-4 position-relative font-medium">
                  Username
                  <span className="position-absolute">
                    <a href="#" onClick={() => handleColumnSort('Username')} className="sort-up position-absolute ms-2">
                      <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" />
                    </a>
                    <a href="#" onClick={() => handleColumnSort('Username')} className="sort-down position-absolute ms-2">
                      <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" />
                    </a>
                  </span>
                </th>
                <th className="font-12 position-relative font-medium">
                  Campaigns
                  <span className="position-absolute">
                    <a href="#" onClick={() => handleColumnSort('campaignName')} className="sort-up position-absolute ms-2">
                      <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" />
                    </a>
                    <a href="#" onClick={() => handleColumnSort('campaignName')} className="sort-down position-absolute ms-2">
                      <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" />
                    </a>
                  </span>
                </th>
                <th className="font-12 position-relative font-medium">
                  End Date
                  <span className="position-absolute">
                    <a href="#" onClick={() => handleColumnSort('campaignEndDate')} className="sort-up position-absolute ms-2">
                      <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" />
                    </a>
                    <a href="#" onClick={() => handleColumnSort('campaignEndDate')} className="sort-down position-absolute ms-2">
                      <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" />
                    </a>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {userDetails.map((user, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <img src={user?.profileImages} className="table-prof" />
                      <p className="font-12 font-regular primary-textcolor mb-0 ms-2">{user.Username}</p>
                    </div>
                  </td>
                  <td className="font-12 font-regular primary-textcolor"><span className="text-truncate">{user.CampaignName}</span></td>
                  <td className="font-12 font-regular primary-textcolor">{new Date(user.campaignEndDate).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <InfiniteScroll
          dataLength={userDetails.length}
          next={fetchMoreData}
          hasMore={hasMoreLoad}
          scrollThreshold={0.9}
          scrollableTarget="scrollableDiv"
        >
          <div className="mt-3">
            <p className="font-12 grey-primary m-0">
              Showing <span className="font-medium primary-textcolor">{totalUserCount}</span> items
            </p>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  </div>
</div>
  );
};

export default UserLearningDashBoardPopup;
