//PC_APC_1 to PC_APC_4
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPolicyConfig, insertPolicyConfig, getPolicyDynamicData } from '../../Services/Admin/AdminService';
import { agovContext } from '../../Constant';
import { Loader } from '../Loader/Loader';

//PC_APC_5 to PC_APC_16
const PolicyConfigForm = () => {
  const navigate = useNavigate();
  const { userId, employeeName, reportsToId } = useContext(agovContext);

  let policyConfigObj = {
    policyConfigId: null,
    eventTitle: "",
    eventDescription: "",
    policyTitle: "",
    userId: userId,
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  }

  const [policyConfig, setPolicyConfig] = useState(policyConfigObj);
  const [openIndex, setOpenIndex] = useState(null);
  const [data, setData] = useState([])
  const [disableValue, setDisableValue] = useState(false)
  const [showPolicyTitle, setShowPolicyTitle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const riskTypes = [...new Set(data.map(item => item.risk_type))];

  const [errors, setErrors] = useState({
    policyConfigIdError: false,
    policyTitleError: false
  });

  useEffect(() => {
    policyDynamicBind();
  }, [])
  //PC_APC_51 to PC_APC_66
  const policyDynamicBind = async () => {
    try {
      setIsLoading(true);
      let result = await getPolicyDynamicData();
      if (result.statusCode === 200) {
        let riskData = result.responseData.recordsets[0];
        setData(riskData);
        console.log("Policy Configuration fetched successfully");
      } else {
        console.log("Something went wrong!");
      }
    }
    catch (error) {
      console.error("Error in formDynamicBind", error);
    }
    setIsLoading(false);
  }
  //PC_APC_33 to PC_APC_50
  const fetchPolicyConfig = async (selectedRiskType, selectedEventName) => {
    try {
      setIsLoading(true);
      let result = await getPolicyConfig({ riskType: selectedRiskType, eventName: selectedEventName });
      if (result.statusCode === 200) {
        let getPolicyConfigObj = {
          policyConfigId: result.responseData.recordsets[0][0].policy_config_id,
          eventTitle: result.responseData.recordsets[0][0].event_title,
          eventDescription: result.responseData.recordsets[0][0].event_description,
          policyEvent: result.responseData.recordsets[0][0].policy_event,
          policyTitle: result.responseData.recordsets[0][0].policy_title,
          userId: userId
        }
        setPolicyConfig(getPolicyConfigObj);
        if (getPolicyConfigObj.policyConfigId) {
          setDisableValue(true)
        }
        //console.log("Policy Configuration fetched successfully");
      } else {
        console.log("Something went wrong!");
      }
    }
    catch (error) {
      console.error("Error editing admin information:", error);
      //console.log("Something went wrong!");
    }
    setIsLoading(false);
  }
  //PC_APC_20 to PC_APC_32
  const createPolicyConfig = async () => {
    try {
      setIsLoading(true);
      const result = await insertPolicyConfig(policyConfig);
      if (result.statusCode === 200) {
        console.log("Admin Information added successfully");
      } else {
        console.log("Something went wrong!");
      }
    } catch (error) {
      console.error("Error inserting policy configuration:", error);
    }
    setIsLoading(false);
  }
  //PC_APC_17 to PC_APC_19
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPolicyConfig(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRadioChange = (e) => {
    setShowPolicyTitle(e.target.value === 'option1'); // Show Policy Title if "Yes" is selected
    if (e.target.value === 'option2') {
      setShowPolicyTitle(false)
      // Reset policyTitle when "No" is selected
      setPolicyConfig({ policyTitle: '' });
    }
  };
  //PC_APC_68 to PC_APC_74
  const validatePolicyConfigForm = () => {
    let isValid = true;
    const errorObj = { ...errors };
    if (policyConfig.policyConfigId === "") {
      errorObj.policyConfigIdError = true;
      isValid = false
    }
    if (policyConfig.policyTitle === "") {
      errorObj.policyTitleError = true;
      isValid = false;
    }

    setErrors(errorObj);
    return isValid;
  };
  //PC_APC_75 to PC_APC_78
  const handleConnectButton = () => {
    if (validatePolicyConfigForm()) {
      createPolicyConfig()
    }
  };
  //PC_APC_79 to PC_APC_80
  const handleCancel = () => {
    setPolicyConfig(policyConfigObj)
  };

  return (
    <>
      <div className="container-fluid mt-4 mt-custom">
        <div className="col-md-12">
          <div className="px-3 px-xl-5">
            <div className="d-flex justify-content-between mt-4">
              <h1 className="font-bold font-24 primary-textcolor">
                Admin Configurations
              </h1>
            </div>
            {/*tabs starts here*/}
            <div className="d-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link px-0  font-14 tab-style rounded-0"
                    id="information-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#information"
                    type="button"
                    role="tab"
                    aria-controls="information"
                    aria-selected="true"
                    onClick={() => navigate('/adminmanagement')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/information-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/information-icon.svg"
                        alt="information-icon"
                        className="me-2"
                      />
                    </span>
                    Information
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="permissions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#permissions"
                    type="button"
                    role="tab"
                    aria-controls="permissions"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/userPermission')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/permission-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/permission-icon.svg"
                        alt="permissions-icon"
                        className="me-2"
                      />
                    </span>
                    Permissions
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="configurations-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#configurations"
                    type="button"
                    role="tab"
                    aria-controls="configurations"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/accessReview')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/key-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/key-icon.svg"
                        alt="configurations-icon"
                        className="me-2"
                      />
                      Configurations
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="app-owner-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#app-owner-config"
                    type="button"
                    role="tab"
                    aria-controls="app-owner-config"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/appOwnerConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/app-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/app-icon.svg"
                        alt="app-owner-config-icon"
                        className="me-2"
                      />
                      App Owner Config
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="security-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#security"
                    type="button"
                    role="tab"
                    aria-controls="security"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/securityConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/lock-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/lock-icon.svg"
                        alt="security-icon"
                        className="me-2"
                      />
                      Security
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 active font-14 tab-style bold-text rounded-0"
                    id="policy-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#policy-config"
                    type="button"
                    role="tab"
                    aria-controls="policy-config"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/privacy-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/privacy-icon.svg"
                        alt="policy-config-icon"
                        className="me-2"
                      />
                      Policy Configuration
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="billing-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#billing"
                    type="button"
                    role="tab"
                    aria-controls="billing"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/billing')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/bill-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/bill-icon.svg"
                        alt="billing-icon"
                        className="me-2"
                      />
                      Billing
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            {/*tabs ends here*/}
            <div className="tab-pane mt-3 active show" id="policy-config" role="tabpanel" aria-labelledby="policy-config-tab">
              <div className="my-3 d-flex align-items-center justify-content-between">
                <h1 className="font-medium font-24 primary-textcolor">
                  Policy Configuration
                </h1>
                <span className="d-flex align-items-center">
                  <span className="d-flex me-3 font-12 font-regular align-items-center grey-light">
                    <span className="admin-legent-sty admin-legent-green me-2" /> Configured
                  </span>
                  <span className="d-flex font-12 font-regular align-items-center grey-light">
                    <span className="admin-legent-sty admin-legent-orange me-2" /> Not
                    Configured
                  </span>
                </span>
              </div>
              <div className="row g-5">
                <div className="col-xl-3 pe-0">
                  <ul className="list-unstyled d-xl-block ps-0">
                    {riskTypes.map((riskType, index) => (
                      <li className="border-bottom py-3" key={index}>
                        <div className="d-flex align-items-center justify-content-between px-3">
                          <h5 className="font-16 font-medium mb-0 primary-textcolor">{riskType}</h5>
                          <button
                            className={`p-0 border-0 shadow-none admin-accbutton-custom bg-transparent ${openIndex === index ? '' : 'collapsed'} `}
                            type="button"
                            data-bs-toggle="collapse"
                            onClick={() => setOpenIndex(openIndex === index ? null : index)}
                            aria-expanded={openIndex === index}
                            aria-controls={`acc${riskType}`}
                            data-bs-target={`#acc${riskType}`}>
                            </button>
                        </div>
                        <div id={`acc${riskType}`}
                          className={`accordion-collapse table-parent-accordion collapse ${openIndex === index ? 'show' : ''} pt-3`}>
                          <div className="d-flex align-items-start">
                            <div className="nav flex-column nav-pills w-100" id="v-pills-tab"
                              role="tablist" aria-orientation="vertical">
                              {data.filter(item => item.risk_type === riskType).map((item, index) => (
                                <button
                                  className={`nav-link Admin-sidenav-btn font-14 cus-tab-border d-flex align-items-center mb-2 ${item.policy_title === '' ? 'orange-tab-bg' : 'green-tab-bg'}`}
                                  id={`${item.event_name}-tab`} data-bs-toggle="pill"
                                  data-bs-target={`#${item.event_name}`} type="button" role="tab"
                                  aria-controls={`${item.event_name}`} aria-selected="false" key={index}
                                  onClick={() => fetchPolicyConfig(riskType, item.event_name)}>
                                  {item.event_name}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {/* mobile responsive structure starts here */}
                  <div className="d-flex d-xl-none">
                    <div className="col-md-6 mt-3 pe-4">
                      <label htmlFor="Policy" className="form-label font-medium font-14 primary-textcolor mb-1">
                        Policy
                      </label>
                      <select className="form-select cust-input-sty font-14 py-2 font-regular" id="Policy" aria-label="Default select example">
                        <option selected>Application</option>
                        <option value={1}>Email</option>
                      </select>
                    </div>
                    <div className="col-md-6 mt-3 px-3">
                      <label htmlFor="Type" className="form-label font-medium font-14 primary-textcolor mb-1">
                        Type
                      </label>
                      <select className="form-select cust-input-sty font-14 py-2 font-regular" id="Type" aria-label="Default select example">
                        <option selected>Select</option>
                        <option value={1} onClick={() => { }}>Mass Download</option>
                        <option value={2}>Mass Delete</option>
                        <option value={3}>High Volume application</option>
                      </select>
                    </div>
                  </div>
                  {/* mobile responsive structure ends here */}
                </div>
                <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 res-left-border admin-rightcontainer-hig">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div className="tab-pane fade show active" id="MassDownload" role="tabpanel" aria-labelledby="MassDownload-tab">
                      <h1 className="font-medium font-20 primary-textcolor my-3">Configure Event </h1>
                      <div className="row">
                        <div className="col-md-6 mb-3 mt-2">
                          <label htmlFor="Title" className="form-label font-medium font-14 primary-textcolor mb-1">
                            Title <span className="required-text">*</span>
                          </label>
                          <input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                            id="Title"
                            name="eventTitle"
                            value={policyConfig.eventTitle}
                            onChange={(e) => { handleInputChange(e) }}
                            disabled={disableValue}
                            placeholder="Enter Title" />
                        </div>
                        <div className="col-md-12 my-3">
                          <label htmlFor="Description" className="form-label font-medium font-14 primary-textcolor mb-1">
                            Description <span className="required-text">*</span>
                          </label>
                          <textarea rows={4} className="form-control resize-none font-regular font-14 custom-form-control py-2"
                            id="Description"
                            name="eventDescription"
                            value={policyConfig.eventDescription}
                            onChange={(e) => { handleInputChange(e) }}
                            disabled={disableValue}
                            placeholder="Enter Description"
                            defaultValue={""} />
                        </div>
                        <h1 className="font-medium font-20 primary-textcolor mt-4 mb-3">Policy Title
                        </h1>
                        <div className="col-md-12 mb-3 mt-2">
                          <label className="form-label font-medium font-14 primary-textcolor mb-2">
                            Do you have <span className="font-bold">{policyConfig.policyTitle}</span> policy
                            configured in the tenant?
                          </label>
                          <div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-radio-input" type="radio" name="admineventname" id="admineventnameyes"
                                value="option1"
                                defaultChecked
                                onChange={handleRadioChange} />
                              <label className="form-check-label" htmlFor="admineventnameyes">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-radio-input" type="radio" name="admineventname" id="admineventnameno"
                                value="option2"
                                onChange={handleRadioChange} />
                              <label className="form-check-label" htmlFor="admineventnameno">No</label>
                            </div>
                          </div>
                        </div>
                        {showPolicyTitle && (
                          <div className="col-md-6 my-3">
                            <label htmlFor="PolicyTitle" className="form-label font-medium font-14 primary-textcolor mb-1">
                              Policy Title<span className="required-text">*</span>
                            </label>
                            <input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                              id="PolicyTitle"
                              placeholder="Enter Title"
                              name="policyTitle"
                              value={policyConfig.policyTitle}
                              onChange={(e) => { handleInputChange(e) }} />
                            <h6 className = "margin5Admin">
                              {errors.policyTitleError ? (
                                <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                                  Enter PolicyTitle
                                </span>) : null}
                            </h6>
                          </div>
                        )}
                        <div className="col-md-12">
                          {showPolicyTitle && (
                            <p className="font-12 font-regular primary-textcolor">
                              <span className="font-medium primary-color">Note:</span> Please confirm
                              that the policy title set in the cloud app
                              security matches the intended title. Any discrepancy could impact
                              the
                              risk events  console.logs.
                            </p>
                          )}
                          {!showPolicyTitle && (
                            <p className="font-12 font-regular primary-textcolor">
                              <span className="font-medium primary-color">Note:</span> Note: Please
                              create your policy in the cloud apps portal. 
                              <a className="font-medium text-decoration-none" href='https://portal.cloudappsecurity.com/#/policy'> portal.cloudappsecurity.com/#/policy</a>
                            </p>
                          )}
                        </div>
                        <div className="d-flex justify-content-end my-5">
                          <button type="button" className="font-14 primary-btn font-medium"
                            onClick={() => handleCancel()}>
                            Cancel
                          </button>
                          <button type="button" className="font-14 dark-btn font-medium ms-3"
                            onClick={() => handleConnectButton()}>
                            Connect
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="MassDelete" role="tabpanel" aria-labelledby="MassDelete-tab">2</div>
                    <div className="tab-pane fade" id="HighVolumeapplication" role="tabpanel" aria-labelledby="HighVolumeapplication-tab">3</div>
                  </div>
                  <div className="tab-content" id="v-pills2-tabContent">
                    <div className="tab-pane fade" id="BulkEmaildelete" role="tabpanel" aria-labelledby="BulkEmaildelete-tab">
                      4
                    </div>
                    <div className="tab-pane fade" id="SensitiveSharedUser" role="tabpanel" aria-labelledby="SensitiveSharedUser-tab">5</div>
                  </div>
                </div>
              </div>
              {/* Policy configuration tab ends here */}
            </div>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  )
}

export { PolicyConfigForm }

