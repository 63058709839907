const PopupLoader = ({ isLoading }) => {
    if (isLoading) {
      return (
        <div>
          <div className="d-flex align-items-center justify-content-center flex-column" style={{ width: "350px", height: "388px" }}>
            <div className="loader spin" style={{marginLeft:"110px"}}>
              <div className="spin__blocker"></div>
              <div className="spin__bottom-left"></div>
              <div className="spin__bottom-right"></div>
              <div className="spin__top-left"></div>
            </div>
            <h3 className="mt-4 font-24 font-medium"style={{marginLeft:"120px"}}>Loading...</h3>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  
  export default PopupLoader;