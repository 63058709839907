import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { agovContext } from "../../../Constant";
import { getCampaignData } from "../../../Services/Agov/governanceService";
import { Loader } from "../../Loader/Loader";
import NoDataScreen from "./NoDataScreen";
import NoRecordFound from '../dashboardNoRecordFound';
const MyLearnings = (LearningData) => {

    //My learnings state variables begin
    //PS_CAM_05 create a state variables with initial data
    const [campaignDetails, setCampaignsDetails] = useState([])
    const [progressDetails, setProgressDetails] = useState([])
    const [TotalCampaigns, setTotalCampaigns] = useState(0)
    const [noDataFound, setNodataFound] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    //My learnings state variables ends

    //PS_CAM_07
    // TO get the required user information from localstorage using useContext
    let { employeeName, userId, managerId, roles, employeeId, employeeValue } = useContext(agovContext);
    


    // PS_CAM_06 
    // PS_TD_06 Set up useEffect with dependencies to fetch data in initial page load
    useEffect(() => {
        onInitialPageLoad()
    }, [noDataFound])



    // PS_cam_07 - 13 Making an Api call by passing the payload data and set stating the response in state variable 
    async function onInitialPageLoad() {

        let payload = {
            userId: userId
        }

        //Mylearnings Fetching starts
        setIsLoading(true)
        const MyLearningsReaponse = await getCampaignData(payload)
        if (MyLearningsReaponse && MyLearningsReaponse.statusCode == 200) {
            if (MyLearningsReaponse.campaignResponse.length == 0) {
                setNodataFound(true)
            }
            else {
                setNodataFound(false)
                setTotalCampaigns(MyLearningsReaponse.campaignResponse.length)
                setCampaignsDetails(MyLearningsReaponse.campaignResponse)
                setProgressDetails(MyLearningsReaponse.progressResponse)
            }
        }
        else {
            setNodataFound(true)
            navigate('/error');
        }
        setIsLoading(false)
        //Mylearnings Fetching ends
    }





    // PS_CAM_17,18
    // NAviagte to view media page when user clicks on any campaign 
    function onNavigateViewMedia(taskId, campaignId) {
        navigate('/lmsmanagement/viewmedia',
            { state: { value: { courseId: "", videoId: "", campaignId: campaignId, taskDetailsId: taskId } } })
    }


    //PS_CAM_16  bind the campaign details to the UI elements using the map function alone with the progressDetails.
    campaignDetails?.map((campaign) => {
        let TotalProgress = 0;
        progressDetails.map((progress) => {

            if (campaign.taskDetailsId == progress.task_details_id) {
                TotalProgress = Math.floor((progress.summed_max_video_watched_progress / (progress.total_video_count * 100)) * 100)
            }
        })
        campaign.progress = TotalProgress
    })

    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1
    };


    // PS_Cam_14 
    // binding the data with the help of slider
    return (
        <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mb-3 ">
            <Loader isLoading={isLoading} />
            <div className="bg-white py-2 px-3 border-radius-8 position-relative rm-dashboard-card">
                <div className="d-flex justify-content-between align-items-center ">
                    <h3 className="font-14 font-medium primary-textcolor mb-0 mt-2">My Learnings <span className="batch-count ms-2">{!noDataFound ? TotalCampaigns : 0}</span></h3>
                </div>
                {!noDataFound ? (
                    <>
                        {TotalCampaigns > 4 ? (
                            <Slider {...settings} className='custom-slick mt-5'>
                                {campaignDetails?.map((campaign) => (
                                    <div className="gap-3 custom-stepper align-items-center h-100" key={campaign.campaignId}>
                                        <div className="custom-slide h-100">
                                            <div className="card border-0 h-100">
                                                <span className="card-bg-image d-flex mb-5 user-db-image">
                                                    <img
                                                        src={campaign.campaignThumbnail}
                                                        alt="lms-learning-image"
                                                        className="card-image-cover w-100" />
                                                </span>
                                                <a href=""
                                                    className="card-body text-decoration-none learning-card-pos p-0 w-100 user-db-body" onClick={() => { onNavigateViewMedia(campaign.taskDetailsId, campaign.campaignId) }}>
                                                    <div className="mx-2 learning-card p-4">
                                                        <h5 className="font-14 font-medium primary-textcolor">
                                                            
                                                            {campaign.campaignName.split(' ').slice(0, 5).join(' ') + '...'}
                                                        </h5>
                                                        <p className="font-12 font-regular mb-0 grey-light mb-2 linehig">
                                                            {campaign.campaignDescription.split(' ').slice(0, 6).join(' ') + '...'}
                                                        </p>
                                                        <div className="mt-auto">
                                                            <span className="d-flex align-items-center">
                                                                <span className="ms-1 grey-primary font-12 font-regular">
                                                                    <img src="images/clock-icon.svg" alt="clock-icon" />
                                                                    {campaign.campaignDuration}
                                                                </span>
                                                                <span className="ms-3 grey-primary font-12 font-regular d-flex">
                                                                    <img src="images/calender-icon.svg" alt="calender-icon" width={'20px'} height={'20px'} />
                                                                    <span className="ms-1 grey-primary font-12 font-regular">{campaign.campaignEndDate}</span>
                                                                </span>
                                                            </span>
                                                            <div className="position-relative mt-3">
                                                                <div className="lms-progress progress">
                                                                    <div className="lms-progress-bar progress-bar" role="progressbar" style={{ width: campaign.progress + '%' }} aria-valuenow={campaign.percentageComplete} aria-valuemin={0} aria-valuemax={100}>
                                                                        <span className="progress-label">{campaign.progress}%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="mt-5 d-flex flex-wrap">
                                {campaignDetails?.map((campaign) => (
                                    <div className="gap-3 custom-stepper align-items-center h-100" key={campaign.campaignId} style={{ flex: '0 0 calc(25% - 15px)' }}>
                                        <div className="custom-slide h-100">
                                            <div className="card border-0 h-100">
                                                <span className="card-bg-image d-flex mb-5 user-db-image">
                                                    <img
                                                        src={campaign.campaignThumbnail}
                                                        alt="lms-learning-image"
                                                        className="card-image-cover w-100" />
                                                </span>
                                                <a href=""
                                                    className="card-body text-decoration-none learning-card-pos p-0 w-100 user-db-body" onClick={() => { onNavigateViewMedia(campaign.taskDetailsId, campaign.campaignId) }}>
                                                    <div className="mx-2 learning-card p-4">
                                                        <h5 className="font-14 font-medium primary-textcolor">
                                                        {campaign.campaignName.split(' ').slice(0, 5).join(' ') + '...'}
                                                        </h5>
                                                        <p className="font-12 font-regular mb-0 grey-light mb-2 linehig">
                                                        {campaign.campaignDescription.split(' ').slice(0, 6).join(' ') + '...'}
                                                        </p>
                                                        <div className="mt-auto">
                                                            <span className="d-flex align-items-center">
                                                                <span className="ms-1 grey-primary font-12 font-regular">
                                                                    <img src="images/clock-icon.svg" alt="clock-icon" />
                                                                    {campaign.campaignDuration}
                                                                </span>
                                                                <span className="ms-3 grey-primary font-12 font-regular d-flex">
                                                                    <img src="images/calender-icon.svg" alt="calender-icon" width={'20px'} height={'20px'} />
                                                                    <span className="ms-1 grey-primary font-12 font-regular">{campaign.campaignEndDate}</span>
                                                                </span>
                                                            </span>
                                                            <div className="position-relative mt-3">
                                                                <div className="lms-progress progress">
                                                                    <div className="lms-progress-bar progress-bar" role="progressbar" style={{ width: campaign.progress + '%' }} aria-valuenow={campaign.percentageComplete} aria-valuemin={0} aria-valuemax={100}>
                                                                        <span className="progress-label">{campaign.progress}%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                ) : (
                    <NoRecordFound data={"No Course Assigned"} />
                )}
            </div>
        </div>
    );
    
}

export default MyLearnings;