import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { agovContext } from "../../Constant";
import {
  exportTaskDetails,
  getAllTasks,
  getAssignedTask,
  getDirectReporteesTask,
  getMyRequestTask,
} from "../../Services/Task/TaskService";
import { Loader } from "../Loader/Loader";
import NotFound from "../NotFound/NotFound";
import SuccessToastMessage from "./SuccessToastMessage";
import TaskForm from "./TaskForm";

export default function TaskGrid() {
  // PC_TG_07 Create required variables
  let { employeeId, employeeName, roles, userId } = useContext(agovContext);
  const Navigate = useNavigate();
  
  // TG_FE_PC_01
  const { state } = useLocation();

  let filterData = {
    fromDate: state?.fromDate ? state?.fromDate?.split("T")[0] : "",
    toDate: state?.toDate ? state?.toDate?.split("T")[0] : "",
    assignedTo: "",
    categoryId: state?.categoryId || "",
    statusId: "",
    taskId : state?.taskId || ""
  };
  let navigatedTaskTab = state?.tasktab ? state?.tasktab : state?.event === "Approve" ? "myRequest" : "assignedToMe"
  
  //PC_TG_08 Create required state variables
  const [sort, setSort] = useState("d.created_on desc");
  const [taskDetails, setTaskDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(filterData);
  const [loadmore, setLoadmore] = useState(10);
  const [isFilterPopup, setIsFilterPopup] = useState(false);
  const [taskTab, setTaskTab] = useState(navigatedTaskTab);
  const [isCreateTask, setIsCreateTask] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessToastMessage, setIsSuccessToastMessage] = useState();
  const [toastMessage, setToastMessage] = useState();
  const [isViewTask, setIsViewTask] = useState(false);
  const [taskId, setTaskId] = useState(
    state?.viewTaskId ? state?.viewTaskId : "" ||
    state?.taskId ? state?.taskId : ""
  );
  const [isUpdateTask, setIsUpdateTask] = useState(false);
  const [onClear, setOnClear] = useState(false)

  /**
   * PC_TG_10
   * useEffect will be triggered in the pageload and based on the dependency
   * loadmore, sort, taskTab
   */
  useEffect(() => {
    if (taskTab) {
      loadTaskDetails();
    }
  }, [loadmore, sort, taskTab, onClear]);

  /**
   * PC_TG_11 - PC_TG_57, TG_FE_PC_03 TO TG_FE_PC_07
   * loadTaskDetails() method is used to fetch the task grid details based on the
   * clicked tab
   */
  const loadTaskDetails = async () => {
    setIsLoading(true);
    let taskConfig = {
      userId: userId,
      employeeId: employeeId,
      sort: sort,
      search: search,
      loadmore: loadmore,
      filter: filter,
    };
    setIsFilterPopup(false);
    let response;

    if (taskTab === "assignedToMe") {
      response = await getAssignedTask(taskConfig);
      setTaskDetails(response?.data);
    }

    if (taskTab === "myRequest") {
      response = await getMyRequestTask(taskConfig);
      setTaskDetails(response?.data);
    }

    if (taskTab === "directReportees") {
      response = await getDirectReporteesTask(taskConfig);
      setTaskDetails(response?.data);
    }

    if (taskTab === "allTasks") {
      response = await getAllTasks(taskConfig);
      if(response.data.length!=0){
        setTaskDetails(response?.data);
      }
      else{
        console.log(response, "error")
      }
    }
    setIsLoading(false);
    if(state?.taskId){
      setIsViewTask(true);
    }
    
  };

  /**
   * PC_TG_91 - PC_TG_92
   * In handleFilter() method, store the filter value in the onchange of the filter fields
   */
  const handleFilter = (event) => {
    event.target.value =
      event.target.value == "Select" ? "" : event.target.value;
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  /**
   * PC_TG_96 - PC_TG_120
   * In handleExportToExcel() method, make an API call to fetch the respective data to
   * bind in the excel file
   */
  const handleExportToExcel = async () => {
    try {
      setIsLoading(true);

      let exportConfig = {
        userId: userId,
        employeeId: employeeId,
        taskTab: taskTab,
        sort: sort,
        search: search,
        filter: filter,
      };

      let excelData = await exportTaskDetails(exportConfig);
      excelData = excelData.data;

      let header =
        taskTab === "assignedToMe"
          ? [
              "Task ID",
              "Title",
              "Start Date",
              "End Date",
              "Category",
              "Priority",
              "Status",
            ]
          : taskTab === "myRequest"
          ? [
              "Task ID",
              "Title",
              "Start Date",
              "End Date",
              "Category",
              "Priority",
              "Status",
            ]
          : taskTab === "directReportees"
          ? [
              "Task ID",
              "Title",
              "Start Date",
              "End Date",
              "Assigned To",
              "Category",
              "Priority",
              "Status",
            ]
          : taskTab === "allTasks"
          ? [
              "Task ID",
              "Title",
              "Start Date",
              "End Date",
              "Assigned To",
              "Category",
              "Priority",
              "Status",
            ]
          : [
              "Task ID",
              "Title",
              "Start Date",
              "End Date",
              "Category",
              "Priority",
              "Status",
            ];

      header = [header, ...excelData];

      let fileNameAssignedTask = 'AGov-assignedTask-'+
      new Date().getFullYear() +
      "/" +
      (parseInt(new Date().getMonth()) + 1 < 10
        ? "0" + (parseInt(new Date().getMonth()) + 1)
        : parseInt(new Date().getMonth() + 1)) +
      "/" +
      (new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate());

    let fileNameCreatedTask = 'AGov-createdTask-'+
        new Date().getFullYear() +
        "/" +
        (parseInt(new Date().getMonth()) + 1 < 10
          ? "0" + (parseInt(new Date().getMonth()) + 1)
          : parseInt(new Date().getMonth() + 1)) +
        "/" +
        (new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate());

    let fileNameReporteesTask = 'AGov-reporteesTask-'+
        new Date().getFullYear() +
        "/" +
        (parseInt(new Date().getMonth()) + 1 < 10
          ? "0" + (parseInt(new Date().getMonth()) + 1)
          : parseInt(new Date().getMonth() + 1)) +
        "/" +
        (new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate());

    let fileNameTaskReport = 'AGov-taskReport-'+
        new Date().getFullYear() +
        "/" +
        (parseInt(new Date().getMonth()) + 1 < 10
          ? "0" + (parseInt(new Date().getMonth()) + 1)
          : parseInt(new Date().getMonth() + 1)) +
        "/" +
        (new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate());

      

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Task Details");

      // Set header row
      header.forEach((row) => {
        worksheet.addRow(row);
      });

      // Generate Excel file
      const excelBuffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([excelBuffer], { type: fileType });

      // Save Excel file
      taskTab === "assignedToMe"? FileSaver.saveAs(data, fileNameAssignedTask + fileExtension):
      taskTab === "myRequest"? FileSaver.saveAs(data, fileNameCreatedTask + fileExtension):
      taskTab === "directReportees"?FileSaver.saveAs(data, fileNameReporteesTask + fileExtension):
      FileSaver.saveAs(data, fileNameTaskReport + fileExtension)

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const abbreviateNumber = (num) => {
    if (Math.abs(num) < 999) {
        return num.toString();
    }
    if (Math.abs(num) < 999999) {
        if (num % 1000 === 0) {
            return (Math.sign(num) * (Math.abs(num) / 1000)) + 'K';
        } else {
            return (Math.sign(num) * (Math.abs(num) / 1000).toFixed(3)) + 'K';
        }
    }
    return (Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1)) + 'M';
}

  /**
   * PC_TG_59
   * In bindTaskDetails() method, all the four tab task grid details will be binded
   */
  const bindTaskDetails = () => {
    if (taskDetails?.taskDetails?.length > 0) {
      return taskDetails?.taskDetails?.map((value) => {
        return (
          <tr>
            <td
              className="font-12 font-medium cursor-pointer"
              value={value?.taskId}
              onClick={(e) => {
                setTaskId(value?.taskId);
                setIsViewTask(true);
                setIsCreateTask(false);
                setIsUpdateTask(false);
              }}
            >
              <a className="text-decoration-none">{value?.taskId}</a>
            </td>
            {taskTab == "assignedToMe" || taskTab == "myRequest" ? (
              <td className="font-12">{value?.title}</td>
            ) : (
              <td className="font-12">
                <div className="text-align-center">
                  <p className="font-12 mb-2">{value?.title}</p>
                  <p className="font-10 grey-light d-flex align-items-center mb-0">
                    Created by
                    <span className="grey-dot list-pointer mb-0" />
                    {value?.createdBy}
                  </p>
                </div>
              </td>
            )}
            <td className="font-12">
              {moment(value?.startDate).format("MM-DD-YYYY")}
            </td>
            <td className="font-12">
              {moment(value?.endDate).format("MM-DD-YYYY")}
            </td>
            {taskTab == "allTasks" || taskTab == "directReportees" ? (
              <td>
                <div className="d-flex align-items-center">
                  {value.assignedTo ? (
                    <img src={value.profileUrl} class="table-prof" />
                  ) : (
                    <></>
                  )}
                  <p className="font-12 primary-textcolor mb-0 ms-2">
                    {value.assignedTo ? value.assignedTo : "-"}
                  </p>
                </div>
              </td>
            ) : (
              <></>
            )}
            <td className="font-12">{value?.categoryName}</td>
            <td className="font-12">{value?.taskPriority}</td>
            <td className="font-12">
              <span
                className={
                  value?.taskStatus === "Open"
                    ? "green-dot me-2"
                    : value?.taskStatus === "In Progress"
                    ? "orange-dot me-2"
                    : value?.taskStatus === "Awaiting Confirmation"
                    ? "blue-dot me-2"
                    : "grey-dot me-2"
                }
              />
              {value?.taskStatus}
            </td>
            {taskTab == "myRequest" ? (
              <td className="text-center">
                <a style={{ cursor: "pointer" }} className="me-2">
                  <img
                    src="images/edit-icon.svg"
                    alt="edit-icon"
                    onClick={() => {
                      setTaskId(value?.taskId);
                      setIsViewTask(false);
                      setIsCreateTask(false);
                      setIsUpdateTask(true);
                    }}
                  />
                </a>
              </td>
            ) : (
              <></>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td
            colSpan={
              taskTab == "assignedToMe" || taskTab == "myRequest" ? 7 : 8
            }
          >
            <NotFound />
          </td>
        </tr>
      );
    }
  };

  /**
   * PC_TG_60
   * bindFilterCategory() method is used to bind the category dropdown data in the
   * filter popup
   */
  const bindFilterCategory = () => {
    return taskDetails?.filterCategory?.map((value) => {
      return <option value={value?.categoryId}>{value?.categoryName}</option>;
    });
  };

  /**
   * PC_TG_61
   * bindFilterStatus() method is used to bind the status dropdown data in the
   * filter popup
   */
  const bindFilterStatus = () => {
    return taskDetails?.filterStatus?.map((value) => {
      return <option value={value?.taskStatusId}>{value?.taskStatus}</option>;
    });
  };

  /**
   * PC_TG_61
   * bindFilterAssignee() method is used to bind the assigned to dropdown data in the
   * filter popup
   */
  const bindFilterAssignee = () => {
    return taskDetails?.assignedTo?.map((value) => {
      return <option value={value?.userId}>{value?.employeeName}</option>;
    });
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <title>Tasks</title>
      <div className={"container-fluid mt-4 mt-custom"}>
        <div className="col-md-12 px-5">
          <div className="d-lg-flex d-block justify-content-between align-items-center mt-4">
            <h1 className="font-bold font-24 primary-textcolor mb-lg-0 mb-3">
              Manage Tasks
            </h1>
            {/* Assigned tab filter starts here */}
            <ul className="nav justify-content-end">
              <li>
                <div className="input-group flex-nowrap search-group me-2">
                  <input
                    type="search"
                    className="form-control search-align"
                    placeholder="Search"
                    aria-label="search"
                    aria-describedby="addon-wrapping"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      e.key === "Enter" ? loadTaskDetails() : <></>;
                    }}
                  />
                  <button
                    className="input-group-text btn btn-search-custom"
                    id="addon-wrapping"
                  >
                    <img
                      src="/images/search-icon.svg"
                      alt="search-icon"
                      onClick={() => {
                        loadTaskDetails();
                      }}
                    />
                  </button>
                </div>
              </li>
              <li className="ms-2">
                <button
                  className="cust-filter-btn d-flex align-items-center show"
                  type="button"
                  aria-expanded="true"
                  onClick={() => {
                    setIsFilterPopup(!isFilterPopup);
                  }}
                >
                  <img
                    src="images/filter-icon.svg"
                    alt="filter-icon"
                    className="filt-icon me-2"
                  />
                  Filter
                </button>
                {isFilterPopup ? (
                  <div className="dropdown-menu filter-right custom-filter border-0 show">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="font-18 font-bold text-black mb-0">
                          Advanced Filter
                        </h5>
                        <button
                          type="button"
                          className="btn-close font-12"
                          aria-label="Close"
                          onClick={() => {
                            setIsFilterPopup(false);
                          }}
                        />
                      </div>
                      <div className="row mt-3">
                        {taskTab != "myRequest" && taskTab != "assignedToMe" ? (
                          <div className="col-md-6 col-sm-12">
                            <div className="mb-3">
                              <label
                                className="form-label text-black font-12 font-medium"
                                name="AssignedTo"
                              >
                                Assigned To
                              </label>
                              <select
                                className="form-select cust-input-sty font-12 font-regular"
                                aria-label="Default select example"
                                name="assignedTo"
                                value={filter.assignedTo}
                                onChange={(e) => {
                                  handleFilter(e);
                                }}
                              >
                                <option selected>Select</option>
                                {bindFilterAssignee()}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label text-black font-12 font-medium">
                              Category
                            </label>
                            <select
                              className="form-select cust-input-sty font-12 font-regular"
                              aria-label="Default select example"
                              name="categoryId"
                              value={filter.categoryId}
                              onChange={(e) => {
                                handleFilter(e);
                              }}
                            >
                              <option selected>Select</option>
                              {bindFilterCategory()}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label text-black font-12 font-medium">
                              Status
                            </label>
                            <select
                              className="form-select cust-input-sty font-12 font-regular"
                              aria-label="Default select example"
                              name="statusId"
                              value={filter.statusId}
                              onChange={(e) => {
                                handleFilter(e);
                              }}
                            >
                              <option selected>Select</option>
                              {bindFilterStatus()}
                            </select>
                          </div>
                        </div>
                        <h5 className="font-14 font-bold text-black mt-3">
                          Date Range
                        </h5>
                        <div className="col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label
                              htmlFor="StartDate"
                              className="form-label text-black font-12 font-medium"
                            >
                              From
                            </label>
                            <input
                              type="date"
                              className="form-control custom-date cust-input-sty font-12 font-regular"
                              id="StartDate"
                              value={filter.fromDate}
                              name="fromDate"
                              onChange={(e) => {
                                handleFilter(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label
                              htmlFor="endDate"
                              className="form-label text-black font-12 font-medium"
                            >
                              To
                            </label>
                            <input
                              type="date"
                              className="form-control custom-date cust-input-sty font-12 font-regular"
                              id="endDate"
                              value={filter.toDate}
                              name="toDate"
                              onChange={(e) => {
                                handleFilter(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-end mt-4 mb-2">
                        <button
                          type="button"
                          className="primary-btn font-14 font-medium me-2"
                          onClick={() => {
                            filterData = {
                              fromDate: "",
                              toDate: "",
                              assignedTo: "",
                              categoryId: "",
                              statusId: "",
                            };
                            setFilter(filterData);
                            setIsFilterPopup(false);
                            setLoadmore(10);
                            setOnClear(!onClear);
                          }}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className={`dark-btn font-14 font-medium px-4`}
                          onClick={() => {
                            setLoadmore(10);
                            loadTaskDetails();
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </li>
              <button
                className="advanced-filter-button ms-2 medium-text customflip-btn"
                type="button"
                aria-expanded="false"
                onClick={() => {
                  if(taskDetails?.taskCount > 0)
                    handleExportToExcel();
                }}
                style={taskDetails?.taskCount > 0? {cursor: "pointer",filter:"none"}:{cursor: "not-allowed" ,filter:"grayscale(80%)"}}
              >
                <img src="images/excel-icon.svg" alt="Filter-icon" />
              </button>
              <li className="ms-2">
                <button
                  className="create-task-button medium-text customflip-btn"
                  type="button"
                  id="offcanvasLmsBtn"
                  aria-expanded="false"
                  aria-controls="offcanvasLms"
                  onClick={() => {
                    setIsViewTask(false);
                    setTaskId("");
                    setIsCreateTask(true);
                  }}
                >
                  <img
                    src="images/Plus-icon.svg"
                    alt="Plus-icon"
                    className="plus-icon me-2"
                  />
                  Create Task
                </button>
              </li>
            </ul>
          </div>
          {/*tabs starts here*/}
          <div className="d-flex justify-content-between align-items-center ps-1 custom-tabs">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={
                    taskTab == "assignedToMe"
                      ? "nav-link px-0 active font-14 tab-style rounded-0"
                      : "nav-link px-0 font-14 tab-style rounded-0"
                  }
                  id="assigned-to-me-tab"
                  data-bs-toggle="pill"
                  type="button"
                  role="tab"
                  aria-controls="assigned-to-me"
                  aria-selected="true"
                  onClick={() => {
                    setTaskTab("assignedToMe");
                    setFilter(filterData);
                    setLoadmore(10);
                    setSearch("");
                    setSort("d.created_on desc");
                  }}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="images/user-edit-icon.svg"
                      alt="filled-icon"
                      className="me-2"
                    />
                    <img
                      src="images/user-edit-icon-filled.svg"
                      alt="assigned-to-me-icon"
                      className="me-2"
                    />
                  </span>
                  Assigned to Me
                </button>
              </li>
              <li className="nav-item ms-4" role="presentation">
                <button
                  className={
                    taskTab == "myRequest"
                      ? "nav-link px-0 active font-14 tab-style rounded-0 active"
                      : "nav-link px-0 font-14 tab-style rounded-0"
                  }
                  id="my-requests-tab"
                  data-bs-toggle="pill"
                  type="button"
                  role="tab"
                  aria-controls="my-requests"
                  aria-selected="false"
                  tabIndex={-1}
                  onClick={() => {
                    setTaskTab("myRequest");
                    setFilter(filterData);
                    setLoadmore(10);
                    setSearch("");
                    setSort("d.created_on desc");
                  }}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="images/ticket-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />{" "}
                    <img
                      src="images/ticket-icon.svg"
                      alt="my-requests-icon"
                      className="me-2"
                    />
                  </span>
                  My Requests
                </button>
              </li>
              {roles.includes("Manager") ? (
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className={
                      taskTab == "directReportees"
                        ? "nav-link px-0 active font-14 tab-style rounded-0 active"
                        : "nav-link px-0 font-14 tab-style rounded-0"
                    }
                    id="direct-reportees-tab"
                    data-bs-toggle="pill"
                    type="button"
                    role="tab"
                    aria-controls="direct-reportees"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => {
                      setTaskTab("directReportees");
                      setFilter(filterData);
                      setLoadmore(10);
                      setSearch("");
                      setSort("d.created_on desc");
                    }}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="images/people-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="images/people-icon.svg"
                        alt="direct-reportees-icon"
                        className="me-2"
                      />
                      Direct Reportees
                    </span>
                  </button>
                </li>
              ) : (
                <></>
              )}
              {roles.includes("Super Admin") ||
              roles.includes("LMS Admin") ||
              roles.includes("SOC Admin") ||
              roles.includes("GRC Admin") ? (
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className={
                      taskTab == "allTasks"
                        ? "nav-link px-0 active font-14 tab-style rounded-0 active"
                        : "nav-link px-0 font-14 tab-style rounded-0"
                    }
                    id="all-tasks-tab"
                    data-bs-toggle="pill"
                    type="button"
                    role="tab"
                    aria-controls="all-tasks"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => {
                      setTaskTab("allTasks");
                      setFilter(filterData);
                      setLoadmore(10);
                      setSearch("");
                      setSort("d.created_on desc");
                    }}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="images/ticket-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="images/ticket-icon.svg"
                        alt="all-tasks-icon"
                        className="me-2"
                      />
                      All Tasks
                    </span>
                  </button>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
          {/*tabs ends here*/}
          <div className="row position-relative">
            <div className="tab-content" id="pills-tabContent">
              {/*Assigned to me tab*/}
              <div
                className="tab-pane fade show active"
                id="assigned-to-me"
                role="tabpanel"
                aria-labelledby="assigned-to-me-tab"
              >
                {/*Assigned to me table*/}
                <div className="table-responsive">
                  <table className="table text-nowrap mb-0 custom-table-grid table-borderless mt-3">
                    <thead>
                      <tr>
                        <th className="font-14 px-4 position-relative">
                          Task ID
                          <span className="position-absolute">
                            <a
                              onClick={() => {
                                setSort("d.task_id asc");
                              }}
                              className="sort-up position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-up.svg"
                                alt="sort-up-icon"
                              />
                            </a>{" "}
                            <a
                              onClick={() => {
                                setSort("d.task_id desc");
                              }}
                              className="sort-down position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-down.svg"
                                alt="sort-down-icon"
                              />
                            </a>
                          </span>
                        </th>
                        <th className="font-14 position-relative">
                          Title
                          <span className="position-absolute">
                            <a
                              onClick={() => {
                                setSort("d.title asc");
                              }}
                              className="sort-up position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-up.svg"
                                alt="sort-up-icon"
                              />
                            </a>{" "}
                            <a
                              onClick={() => {
                                setSort("d.title desc");
                              }}
                              className="sort-down position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-down.svg"
                                alt="sort-down-icon"
                              />
                            </a>
                          </span>
                        </th>
                        <th className="font-14 position-relative">
                          Start Date
                          <span className="position-absolute">
                            <a
                              onClick={() => {
                                setSort("d.start_date asc");
                              }}
                              className="sort-up position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-up.svg"
                                alt="sort-up-icon"
                              />
                            </a>{" "}
                            <a
                              onClick={() => {
                                setSort("d.start_date desc");
                              }}
                              className="sort-down position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-down.svg"
                                alt="sort-down-icon"
                              />
                            </a>
                          </span>
                        </th>
                        <th className="font-14 position-relative">
                          End Date
                          <span className="position-absolute">
                            <a
                              onClick={() => {
                                setSort("d.end_date asc");
                              }}
                              className="sort-up position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-up.svg"
                                alt="sort-up-icon"
                              />
                            </a>{" "}
                            <a
                              onClick={() => {
                                setSort("d.end_date desc");
                              }}
                              className="sort-down position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-down.svg"
                                alt="sort-down-icon"
                              />
                            </a>
                          </span>
                        </th>
                        {taskTab == "directReportees" ||
                        taskTab == "allTasks" ? (
                          <th className="font-14 position-relative">
                            Assigned to
                            <span className="position-absolute">
                              <a
                                onClick={() => {
                                  setSort("tu.employee_name asc");
                                }}
                                className="sort-up position-absolute ms-2"
                              >
                                <img
                                  className="sort-img-size cursor-pointer"
                                  src="images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </a>{" "}
                              <a
                                onClick={() => {
                                  setSort("tu.employee_name desc");
                                }}
                                className="sort-down position-absolute ms-2"
                              >
                                <img
                                  className="sort-img-size cursor-pointer"
                                  src="images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </a>
                            </span>
                          </th>
                        ) : (
                          <></>
                        )}
                        <th className="font-14 position-relative">
                          Category
                          <span className="position-absolute">
                            <a
                              onClick={() => {
                                setSort("tc.category_name asc");
                              }}
                              className="sort-up position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-up.svg"
                                alt="sort-up-icon"
                              />
                            </a>{" "}
                            <a
                              onClick={() => {
                                setSort("tc.category_name desc");
                              }}
                              className="sort-down position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-down.svg"
                                alt="sort-down-icon"
                              />
                            </a>
                          </span>
                        </th>
                        <th className="font-14 position-relative">
                          Priority
                          <span className="position-absolute">
                            <a
                              onClick={() => {
                                setSort("tp.task_priority asc");
                              }}
                              className="sort-up position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-up.svg"
                                alt="sort-up-icon"
                              />
                            </a>{" "}
                            <a
                              onClick={() => {
                                setSort("tp.task_priority desc");
                              }}
                              className="sort-down position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-down.svg"
                                alt="sort-down-icon"
                              />
                            </a>
                          </span>
                        </th>
                        <th className="font-14 position-relative">
                          Status
                          <span className="position-absolute">
                            <a
                              onClick={() => {
                                setSort("ts.task_status asc");
                              }}
                              className="sort-up position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-up.svg"
                                alt="sort-up-icon"
                              />
                            </a>{" "}
                            <a
                              onClick={() => {
                                setSort("ts.task_status desc");
                              }}
                              className="sort-down position-absolute ms-2"
                            >
                              <img
                                className="sort-img-size cursor-pointer"
                                src="images/sort-down.svg"
                                alt="sort-down-icon"
                              />
                            </a>
                          </span>
                        </th>
                        {taskTab == "myRequest" ? (
                          <th className="font-14 text-center">Action</th>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </thead>
                    <tbody>{bindTaskDetails()}</tbody>
                  </table>
                </div>
                {taskDetails?.taskDetails?.length > 0 ? (
                  <p className="font-12 grey-primary">
                    Showing{" "}
                    <span className="font-medium primary-textcolor">
                      {taskDetails?.taskCount}{" "}
                    </span>
                    out of{" "}
                    <span className="font-medium primary-textcolor">
                      {taskDetails?.totalTaskCount}
                    </span>{" "}
                    items
                  </p>
                ) : (
                  <></>
                )}
                {taskDetails?.taskDetails?.length > 0 ? (
                  <div className="text-center mt-5">
                    <button
                      className={
                        taskDetails?.taskCount >= taskDetails?.totalTaskCount
                          ? "primary-btn font-medium fade mb-2"
                          : "primary-btn font-medium mb-2"
                      }
                      onClick={() => {
                        taskDetails?.taskCount >=
                        taskDetails?.totalTaskCount ? (
                          <></>
                        ) : (
                          setLoadmore(loadmore + 10)
                        );
                      }}
                      disabled={
                        taskDetails?.taskCount >= taskDetails?.totalTaskCount
                          ? true
                          : false
                      }
                    >
                      Load More
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCreateTask || isViewTask || isUpdateTask ? (
        <TaskForm
          props={{
            isCreateTask: isCreateTask,
            setIsCreateTask: setIsCreateTask,
            isSuccessToastMessage: isSuccessToastMessage,
            setIsSuccessToastMessage: setIsSuccessToastMessage,
            toastMessage: toastMessage,
            setToastMessage: setToastMessage,
            taskId: state?.taskId ? state.taskId : taskId,
            isViewTask: isViewTask,
            setIsViewTask: setIsViewTask,
            isUpdateTask: isUpdateTask,
            setIsUpdateTask: setIsUpdateTask,
            loadTaskDetails: loadTaskDetails,
            taskTab:taskTab
          }}
        />
      ) : (
        <></>
      )}
      <SuccessToastMessage
        props={{
          isSuccessToastMessage: isSuccessToastMessage,
          setIsSuccessToastMessage: setIsSuccessToastMessage,
          toastMessage: toastMessage,
          setToastMessage: setToastMessage,
        }}
      />
      {isCreateTask || isViewTask || isUpdateTask ? (
        <div class="modal-backdrop fade show"></div>
      ) : (
        <></>
      )}
    </>
  );
}
