import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import { agovContext,abbreviateNumber } from "../../../Constant";
import { getTaskData } from "../../../Services/Agov/governanceService";
import { Loader } from '../widgets/Loader';
import NoRecordFound from '../dashboardNoRecordFound';

const TaskSummary = () => {
    //PC_TD_07
    // TO get the required user information from localstorage using useContext
    let { employeeName, userId, managerId, roles, employeeId, employeeValue } = useContext(agovContext);
    
    let userRole = ''
    if (roles.includes('Super Admin')) {
        userRole = 'Super Admin'
    }
    else if (roles.includes('Manager')) {
        userRole = 'Manager'
    }
    else {
        userRole = 'User'
    }
    // PS_TD_05 create the state variable with initial values
    const [reqTaskDuration, setReqTaskDuration] = useState("Monthly");
    const [dateTimeColumnName, setDateTimeColumnName] = useState('start_date');
    const [totalTaskData, setTotalTaskData] = useState(0);
    const [allTaskData, setAllTaskData] = useState([])
    const [transformData, setTransformData] = useState([])
    const [userTaskSelection, setUserTaskSelection] = useState(roles.includes("Super Admin") || roles.includes("Manager") ? "Team's Task" : "MyTask");
    const [noDataFound, setNodataFound] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    //   task Data state variables ends
    const navigate = useNavigate()



    //   PS_TD_18
    // to change the format of the response data to Diaplay it in chart by invoking the function
    useEffect(() => {
        setTransformData(taskTransformingData(allTaskData))
    }, [allTaskData]);

    // PS_TD_06 Set up useEffect with dependencies to fetch data in initial page load
    useEffect(() => {
        onInitialPageLoad();

    }, [userTaskSelection, reqTaskDuration]);

    //PS_TD_09 -PS_TD_20
    //function onIntialTaskPageLoad triggres and make api call when user intract with the application to fetch the data 
    // and set states the response in state variables and bind them in HTML.
    async function onInitialPageLoad() {

        let payload = {
            userId: userId,
            userRole: roles,
            employeeId: employeeId,
            userSelection: userTaskSelection,
            reqTaskDuration: reqTaskDuration,
            managerId: employeeId,
            dateTimeColumnName: dateTimeColumnName
        }

        setIsLoading(true)
        const TaskResponse = await getTaskData(payload)


        if (TaskResponse && TaskResponse.statusCode === 200) {

            if (TaskResponse.responseData.totalTaskCount != 0) {

                setNodataFound(false)
                setAllTaskData(TaskResponse.responseData || []);
                setTotalTaskData(TaskResponse.responseData.totalTaskCount || 0)
                
            }
            else {
                setNodataFound(true)
                setAllTaskData(TaskResponse.responseData || []);
                setTotalTaskData(TaskResponse.responseData.totalTaskCount || 0)
            }

        }
        else {
            setNodataFound(true)
            navigate('/error');
            
        }
        setIsLoading(false)
    }

    //PS_TD_25 makes a rediection to differnt page based on the tabvalue with the help of use navigate.
        function taskNavigation() {
            let tabvalue = ''
            
            if (userTaskSelection === 'MyTask') {
                tabvalue = "assignedToMe"
            }
            else if (userTaskSelection === "Team's Task") {
                tabvalue = "directReportees"
            }
            else if (userTaskSelection === "AllTask") {
                tabvalue = "allTasks"
            }
            
            let fromDate = ''
            if(roles.includes("Super Admin") || roles.includes("Manager"))
                {
                    if(reqTaskDuration=="Today")
                    {
                        fromDate = new Date();
                    }
                    else if(reqTaskDuration == "Weekly")
                    {
                        
                        fromDate =  new Date();
                        fromDate.setDate(fromDate.getDate() - 7);
                    }
                    else if(reqTaskDuration == "Monthly")
                    {
                        fromDate =  new Date();
                        fromDate.setMonth(fromDate.getMonth() - 1);
                    }
                    else if(reqTaskDuration == "Quaterly")
                    {
                        fromDate =  new Date();
                        fromDate.setMonth(fromDate.getMonth() - 3);
                    }
                    fromDate=fromDate.toISOString();
                }
            

                    navigate('/taskmanagement', {
                        state: {
                            tasktab: tabvalue,
                            fromDate:fromDate,
                        }
                    })
                
                
            

        }

    // PS_TD_21
    // Set state the varable reqTaskDuration when user changes the value in dropdown and fetch the data accordingly
    function OnHandleTaskDropDown(value) {

        setReqTaskDuration(value)
        setNodataFound(false)
    }


    //PS_TD_23
    // Set state the varable userTaskSelection when user switched the tab and fetch the data accordingly.
    function OnHandleTaskTabSwitch(value) {
        setUserTaskSelection(value)
    }

    //PS-TD-18 here the function triggers to transform the response data which can be binded in the chart
    const taskTransformingData = (taskTrendData) => {
        const transformedData = Object.keys(taskTrendData).filter((each) => each != 'totalTaskCount').map((key) => {
            return {
                name: key,
                YetToStart: taskTrendData[key].YetToStart,
                inProgress: taskTrendData[key].inProgress,
                Completed: taskTrendData[key].Completed
            };
        });
        return transformedData
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
                
                <div className="custom-tooltip-Dashboard" >
                <p className="labeltitle">{label}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.color }}>
                            {entry.name}: {entry.value}
                        </p>
                    ))}
                </div>
                </>
            );
        }
    
        return null;
    };

    

    return (
        <>
            {userRole == "User" ?
                <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3 pe-4" style={{ position: 'relative' }}>
                    <Loader isLoading={isLoading} />
                    <div className="bg-white py-2 px-3 border-radius-8 ">
                        <div className={noDataFound?"d-flex justify-content-between align-items-center mt-1":"d-flex justify-content-between align-items-center mt-3"}>
                            <h3 className="font-14 font-medium primary-textcolor mb-0">Task Summary <span className="badges-blue border-radius-8 ms-2 font-12">{!noDataFound ? abbreviateNumber(totalTaskData) : 0}</span></h3>
                            <div className="align-items-center d-flex gap-3">
                                <div className="d-flex align-items-center">
                                    {!noDataFound ? <a href="" className="font-12 secondary-textcolor font-medium text-decoration-none" onClick={() => { totalTaskData != 0 ? taskNavigation() :console.log("No Records")  }}>View
                                        All <img src="images/right-arw-blue.svg" alt="right-arrow" /></a> : <p className="font-12 font-medium text-decoration-none disableView" style={{cursor:"not-allowed"}}>View All </p>}
                                </div>
                            </div>
                        </div>
                        {!noDataFound ? <div>
                            <div className="d-flex py-4 px-2 mt-4">
                                <ResponsiveContainer width={'100%'} height={210}>
                                    <BarChart
                                        data={transformData}
                                        margin={{
                                            top: 10,
                                            right: 10,
                                            left: 10,
                                            bottom: 10,
                                        }}
                                        barCategoryGap={20} // This creates a fixed 5px gap
                                        barGap={5} // This creates a fixed 5px gap between stacks within the same category
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" scale="none" padding={{ left: 120, right: 120 }}/>
                                        <YAxis />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar
                                            dataKey="YetToStart"
                                            stackId="a"
                                            fill="#78DC8E"
                                            name="Open"
                                            barSize={20} // Adjust the width of the bars here
                                            radius={[3, 3, 3, 3]}
                                        />
                                        <Bar
                                            dataKey="inProgress"
                                            stackId="a"
                                            fill="#FACC9E"
                                            name="In Progress"
                                            barSize={20} // Adjust the width of the bars here
                                            radius={[3, 3, 3, 3]}
                                        />
                                        <Bar
                                            dataKey="Completed"
                                            stackId="a"
                                            fill="#ced4da"
                                            name="Closed"
                                            barSize={20} // Adjust the width of the bars here
                                            radius={[3, 3, 3, 3]}
                                        />


                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="align-items-center justify-content-center d-flex gap-2">
                                <div className="align-items-baseline d-flex">
                                    <span className="legends legend-completed me-1" />
                                    <label className="me-2 font-12 grey-light">Open</label>
                                </div>
                                <div className="align-items-baseline d-flex">
                                    <span className="legends legend-medium me-1" />
                                    <label className="me-2 font-12 grey-light">In Progress</label>
                                </div>
                                <div className="align-items-baseline d-flex">
                                    <span className="legends legend-open me-1" />
                                    <label className="me-2 font-12 grey-light">Closed</label>
                                </div>
                            </div>
                        </div> : <NoRecordFound data={"No Task Assigned"} />}

                    </div>
                </div>


                : <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3"style={{ position: 'relative' }}>
                    <Loader isLoading={isLoading} />
                    <div className="bg-white py-2 px-3 border-radius-8 rm-dashboard-card">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="align-items-center d-flex gap-2">
                                <h3 className="font-14 font-medium primary-textcolor mb-0">
                                    Task Summary<span className="badges-blue border-radius-8 ms-2 font-13">{!noDataFound ? abbreviateNumber(totalTaskData) : 0}</span></h3>
                            </div>
                            <div className="align-items-center d-flex gap-3">
                                {userRole === 'User' ? "" : <div className="d-flex">
                                    <select className="form-select custom-form-control py-2 font-12 font-regular" onChange={(e) => { OnHandleTaskDropDown(e.target.value) }}>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Today">Today</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Quaterly">Quaterly</option>
                                        
                                    </select>
                                </div>}
                                <div className="d-flex align-items-center"  >
                                    {noDataFound?<p className="font-12 font-medium text-decoration-none disableView" style={{cursor:"not-allowed"}} >View All </p>:
                                    <a href="" className="font-12 secondary-textcolor font-medium cursor-pointer text-decoration-none" onClick={() => { totalTaskData != 0 ? taskNavigation() : console.log("No Records") }}
                                    >View All <img src="images/right-arw-blue.svg" alt="right-arrow" /></a>}
                                </div>

                            </div>
                        </div>

                        {userRole === 'User' ? "" : <div className="d-flex justify-content-between align-items-center custom-tabs">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link px-0 active font-14 tab-style rounded-0 d-flex align-items-center justify-content-center" id="team-tasks-tab" data-bs-toggle="pill" data-bs-target="#TeamTasks" type="button" role="tab" aria-controls="information" aria-selected="true" onClick={() => OnHandleTaskTabSwitch("Team's Task")}>
                                        <span className="tab-img d-flex align-items-center"> <img src="images/my-team-active.svg" alt="filled-icon" className="me-2" />
                                            <img src="images/my-team.svg" alt="information-icon" className="me-2" />
                                        </span>Team’s Tasks
                                    </button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0 d-flex align-items-center justify-content-center" id="my-tasks-tab1" data-bs-toggle="pill" data-bs-target="#MyTasks1" type="button" role="tab" aria-controls="permissions" aria-selected="false" tabIndex={-1} onClick={() => OnHandleTaskTabSwitch("MyTask")}>
                                        <span className="tab-img d-flex align-items-center"> <img src="images/user-edit-icon.svg" alt="filled-icon" className="me-2" /> <img src="images/user-edit-icon-filled.svg" alt="permissions-icon" className="me-2" />
                                        </span>My Task
                                    </button>
                                </li>
                                {roles.includes('Super Admin') && <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0 d-flex align-items-center justify-content-center" id="all-tasks-tab2" data-bs-toggle="pill" data-bs-target="#All-tasks-tab-2" type="button" role="tab" aria-controls="permissions" aria-selected="false" tabIndex={-1} onClick={() => OnHandleTaskTabSwitch("AllTask")}>
                                        <span className="tab-img d-flex align-items-center"> <img src="images/my-team-active.svg" alt="filled-icon" className="me-2" /> <img src="images/my-team.svg" alt="permissions-icon" className="me-2" />
                                        </span>All Tasks
                                    </button>
                                </li>}

                            </ul>
                        </div>}
                        {!noDataFound ? <div className="tab-content" id="myTabContent">
                            {/* Team's task Starts */}
                            <div className={`tab-pane fade ${userTaskSelection == "Team's Task" ? "show active" : ""}`} id="TeamTasks" role="tabpanel" aria-labelledby="team-tasks-tab">
                                <div className="d-flex py-2 px-2">
                                    <ResponsiveContainer width={'100%'} height={235}>
                                        <BarChart
                                            data={transformData}
                                            margin={{
                                                top: 10,
                                                right: 10,
                                                left: 10,
                                                bottom: 10,
                                            }}
                                            barCategoryGap={20} // This creates a fixed 5px gap
                                            barGap={5} // This creates a fixed 5px gap between stacks within the same category
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" scale="none" padding={{ left: 120, right: 120 }}/>
                                            <YAxis />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Bar
                                                dataKey="Completed"
                                                stackId="a"
                                                fill="#78DC8E"
                                                name="Completed"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                                
                                            />
                                            <Bar
                                                dataKey="inProgress"
                                                stackId="a"
                                                fill="#FACC9E"
                                                name="In Progress"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                                
                                            />
                                            <Bar
                                                dataKey="YetToStart"
                                                stackId="a"
                                                fill="#ced4da"
                                                name="Yet to Start"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                                
                                            />


                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="align-items-center justify-content-center d-flex gap-2 mt-2">
                                    <div className="align-items-baseline d-flex">
                                        <span className="legends legend-completed me-1" />
                                        <label className="me-2 font-10 grey-light">Completed</label>
                                    </div>
                                    <div className="align-items-baseline d-flex">
                                        <span className="legends legend-medium me-1" />
                                        <label className="me-2 font-10 grey-light">In Progress</label>
                                    </div>
                                    <div className="align-items-baseline d-flex">
                                        <span class="legends legend-open me-1" />
                                        <label className="me-2 font-10 grey-light">Yet to Start</label>
                                    </div>
                                </div>
                            </div>
                            {/* Team's task ends */}

                            {/* My task Starts */}
                            <div className={`tab-pane fade ${userTaskSelection == "MyTask" ? "show active" : ""}`} id="MyTasks1" role="tabpanel" aria-labelledby="my-tasks-tab1">
                                <div className="d-flex py-2 px-2">
                                    <ResponsiveContainer width={'100%'} height={235}>
                                        <BarChart
                                            data={transformData}
                                            margin={{
                                                top: 10,
                                                right: 10,
                                                left: 10,
                                                bottom: 10,
                                            }}
                                            barCategoryGap={20} // This creates a fixed 5px gap
                                            barGap={5} // This creates a fixed 5px gap between stacks within the same category
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" scale="none" padding={{ left: 120, right: 120 }}/>
                                            <YAxis />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Bar
                                                dataKey="Completed"
                                                stackId="a"
                                                fill="#78DC8E"
                                                name="Completed"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                            />
                                            <Bar
                                                dataKey="inProgress"
                                                stackId="a"
                                                fill="#FACC9E"
                                                name="In Progress"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                            />
                                            <Bar
                                                dataKey="YetToStart"
                                                stackId="a"
                                                fill="#ced4da"
                                                name="Yet to Start"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                            />


                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="align-items-center justify-content-center d-flex gap-2 mt-2">
                                    <div className="align-items-baseline d-flex">
                                        <span className="legends legend-completed me-1" />
                                        <label className="me-2 font-10 grey-light">Completed</label>
                                    </div>
                                    <div className="align-items-baseline d-flex">
                                        <span className="legends legend-medium me-1" />
                                        <label className="me-2 font-10 grey-light">In Progress</label>
                                    </div>
                                    <div className="align-items-baseline d-flex">
                                        <span className="legends legend-open me-1" />
                                        <label className="me-2 font-10 grey-light">Yet to Start</label>
                                    </div>
                                </div>
                            </div>
                            {/* My task ends */}

                            {/* All task Starts */}
                            <div className={`tab-pane fade ${userTaskSelection == "AllTask" ? "show active" : ""}`} id="All-tasks-tab-2" role="tabpanel" aria-labelledby="all-tasks-tab2">
                                <div className="d-flex py-2 px-2">
                                    <ResponsiveContainer width={'100%'} height={235}>
                                        <BarChart
                                            data={transformData}
                                            margin={{
                                                top: 10,
                                                right: 10,
                                                left: 10,
                                                bottom: 10,
                                            }}
                                            barCategoryGap={20} // This creates a fixed 5px gap
                                            barGap={20} // This creates a fixed 5px gap between stacks within the same category
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" scale="none" padding={{ left: 120, right: 120 }}/>
                                            <YAxis />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Bar
                                                dataKey="Completed"
                                                stackId="a"
                                                fill="#78DC8E"
                                                name="Completed"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                            />
                                            <Bar
                                                dataKey="inProgress"
                                                stackId="a"
                                                fill="#FACC9E"
                                                name="In Progress"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                            />
                                            <Bar
                                                dataKey="YetToStart"
                                                stackId="a"
                                                fill="#ced4da"
                                                name="Yet to Start"
                                                barSize={20} // Adjust the width of the bars here
                                                radius={[3, 3, 3, 3]}
                                            />


                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="align-items-center justify-content-center d-flex gap-2 mt-2">
                                    <div className="align-items-baseline d-flex">
                                        <span className="legends legend-completed me-1" />
                                        <label className="me-2 font-10 grey-light">Completed</label>
                                    </div>
                                    <div className="align-items-baseline d-flex">
                                        <span className="legends legend-medium me-1" />
                                        <label className="me-2 font-10 grey-light">In Progress</label>
                                    </div>
                                    <div className="align-items-baseline d-flex">
                                        <span className="legends legend-open me-1" />
                                        <label className="me-2 font-10 grey-light">Yet to Start</label>
                                    </div>
                                </div>
                            </div>
                            {/* All task ends */}
                        </div> : <NoRecordFound data={"No Task Assigned"} />}
                    </div>
                </div>
            }

        </>
    )
}

export default TaskSummary;