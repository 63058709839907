/**
 * Defining the Const Object of the logConst
 */
export let logConst = {
    hackingActivity: {
        divColor: "",
        firstname: "applied_by",
        divStatement1: " has performed the ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Hacking Activity",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        lastname: "",
        logtype: "Hacking Activity",
        Category: "category",
        assignedOrPerform: "Performed By:",
    },
    exportExcel: {
        divColor: "",
        firstname: "applied_by",
        divStatement1: " has exported the  ",
        divStatement2: " data",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Export Excel Data",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        lastname: "",
        logtype: "Export Excel",
        Category: "category",
        assignedOrPerform: "Performed By:",
    },
    auditAssigned: {
        divColor: "row log-card Complaince-log",
        firstname: "",
        divStatement1: "Audit Period has been scheduled by",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Audit period Assigned",
        subEvent: "",
        subEventName: "",
        event: "event",
        lastname: "applied_by",
        dateTime: "date_time",
        logtype: "Compliance",
        Category: "category",
        assignedOrPerform: "Performed By:",
    },
    controlAdded: {
        divColor: "row log-card Complaince-log",
        divStatement1: "A new control",
        firstname: "",
        divStatement2: " has been added by",
        assignedBy: "",
        performedBy: "applied_by",
        description: " New Control Added",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        lastname: "applied_by",
        dateTime: "date_time",
        logtype: "Compliance",
        Category: "category",
        assignedOrPerform: "Performed By:",
    },
    controlOwnerAssigned: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_on",
        divStatement1: " has been assigned as the control owner for the ",
        divStatement2: " control",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Control Owner Assigned",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        lastname: "",
        dateTime: "date_time",
        Category: "category",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    policyMap: {
        divColor: "row log-card Complaince-log",
        firstname: "",
        divStatement1: "The",
        divStatement2: " policy has been mapped to the",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Policy Mapping",
        subEvent: "event_description",
        subEventName: "sub_event",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    evidenceMap: {
        divColor: "row log-card Complaince-log",
        firstname: "",
        divStatement1: "The",
        divStatement2: " evidence has been mapped to the",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Evidence Mapping",
        subEvent: "event_description",
        subEventName: "sub_event",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    controlMap: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has been mapped to the control",
        divStatement2: " to the principle",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Control Mapping",
        subEvent: "event_description",
        subEventName: "sub_event",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    controlUnMap: {
        divColor: "row log-card Complaince-log",
        firstname: "",
        divStatement1: "The",
        divStatement2: " control has been unmapped by",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Control Unmapping",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_by",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    policyUpload: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has uploaded a policy document for ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Policy Upload",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    evidenceUpload: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has uploaded an evidence document for ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Evidence Upload",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    policyDownload: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has download the policy",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Policy Download",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    policyCreation: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has created a policy",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Policy Creation",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    evidenceCreation: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has created a evidence",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Evidence Creation",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    evidenceDeletion: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has deleted the evidence",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Evidence Deletion",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    commentsAdded: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has added a comment to the ",
        divStatement2: " task",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Auditor Added Comments",
        subEvent: "sub_event",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    sysDescGenerated: {
        divColor: "row log-card Complaince-log",
        firstname: "",
        divStatement1: "A system description has been generated",
        divStatement2: "",
        assignedBy: "",
        performedBy:"applied_by",
        description: "System Description Generation",
        subEvent: "",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    controlActive: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has made the control ",
        divStatement2: " active",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Control Active",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    controlInactive: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has made the ",
        divStatement2: " inactive",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Control Inactive",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    evidenceActive: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has made the  ",
        divStatement2: " evidence active",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Evidence Active",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    evidenceInActive: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has made the  ",
        divStatement2: " evidence Inactive",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Evidence Inactive",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    evidenceReccurenceChanage: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has changed the recurrence of the ",
        divStatement2: " evidence ",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Evidence Recurrence Changed",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    controlCompletion: {
        divColor: "row log-card Complaince-log",
        firstname: "",
        divStatement1: "The ",
        divStatement2: " control has been successfully completed ",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Control Completion",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "",
    },
    evidenceDownload: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has downloaded the Evidence ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Evidence Download",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    vendorAdded: {
        divColor: "row log-card Complaince-log",
        firstname: "applied_by",
        divStatement1: " has successfully added the details for ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Vendor Added",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    reportUpload: {
        divColor: "row log-card Complaince-log",
        firstname: "event_description",
        divStatement1: " report has uploaded by the  ",
        divStatement2: " , from Audit History",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Report Upload",
        subEvent: "applied_by",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    reportImport: {
        divColor: "row log-card Complaince-log",
        firstname: "event_description",
        divStatement1: " report has Imported by the ",
        divStatement2: " , from Audit History",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Report Upload",
        subEvent: "applied_by",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    reportDownload: {
        divColor: "row log-card Complaince-log",
        firstname: "event_description",
        divStatement1: " report has downloaded by the ",
        divStatement2: " , from Audit History",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Report Download",
        subEvent: "applied_by",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Compliance",
        assignedOrPerform: "Performed By:",
    },
    videoAdded: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has added a  ",
        divStatement2: " video.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Video Added",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Performed By:",
    },
    videoEdited: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has edited the  ",
        divStatement2: " video.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Video Edited",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Performed By:",
    },
    documentAdded: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has added a",
        divStatement2: " document.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Document Added",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Performed By:",
    },
    documentEdited: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has edited the",
        divStatement2: " document.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Document Edited",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Performed By:",
    },
    courseCreated: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has created the ",
        divStatement2: " course.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Course Created",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Performed By:",
    },
    courseEdited: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has edited the ",
        divStatement2: " course.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Course Edited",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Performed By:",
    },
    courseDeleted: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has deleted the ",
        divStatement2: " course.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Course Deleted",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Performed By:",
    },
    campaignEdited: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has edited the campaign ",
        divStatement2: " campaign.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Campaign Edited",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Performed By:",
    },
    campaignAssigned: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has assigned to the ",
        divStatement2: " campaign.",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Campaign Assigned",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Assigned to:",
    },
    campaignCompleted: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has completed the course ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Campaign Completed",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Assigned to:",
    },
    campaignDeleted: {
        divColor: "row log-card lms-log",
        firstname: "applied_by",
        divStatement1: " has deleted the campaign ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Campaign Deleted",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Assigned to:",
    },
    campaignOverdue: {
        divColor: "row log-card lms-log",
        firstname: "applied_on",
        divStatement1: " has a overdue course ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Campaign Overdue",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Assigned to:",
    },
    campaignOverdueCompletion: {
        divColor: "row log-card lms-log",
        firstname: "applied_on",
        divStatement1: " has a overdue course ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Campaign Overdue",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "LMS",
        assignedOrPerform: "Assigned to:",
    },

    riskTaskAssigned: {
        divColor: "row log-card risk-log",
        firstname: "applied_on",
        divStatement1: " has assigned to the  ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Risk Task Assigned",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Assigned to:",
    },
    riskTaskUpdated: {
        divColor: "row log-card risk-log",
        firstname: "applied_on",
        divStatement1: " has updated the",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Risk Task Updated",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Assigned to:",
    },
    riskTaskClosed: {
        divColor: "row log-card risk-log",
        firstname: "applied_on",
        divStatement1: " has closed the  ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Risk Task Closed",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Assigned to:",
    },
    taskCreated: {
        divColor: "",
        firstname: "event_description",
        divStatement1: " task has been created to  ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Task Created",
        subEvent: "applied_on.",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_on",
        logtype: "Task",
        assignedOrPerform: "Assigned to:",
    },
    subTaskCreated: {
        divColor: "",
        firstname: "event_description",
        divStatement1: " sub-task has been created to ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Sub Task Created",
        subEvent: "applied_on",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Assigned to:",
    },
    taskUpdated: {
        divColor: "",
        firstname: "",
        divStatement1: "The ",
        divStatement2: " task has been updated to",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Task Updated",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_on",
        logtype: "Task",
        assignedOrPerform: "Assigned to:",
    },
    subTaskUpdated: {
        divColor: "",
        firstname: "",
        divStatement1: "The ",
        divStatement2: " sub-task has been updated to",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Sub-Task Updated",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_on",
        logtype: "Task",
        assignedOrPerform: "Assigned to:",
    },
    taskCommentsAdded: {
        divColor: "",
        firstname: "applied_by",
        divStatement1: " has added a comment to the ",
        divStatement2: " task",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Task Comments Added",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Performed By:",
    },
    subTaskCommentsAdded: {
        divColor: "",
        firstname: "applied_by",
        divStatement1: "    has added the comments to the ",
        divStatement2: " task",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Sub-Task Comments Added",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Performed By:",
    },
    taskCompleted: {
        divColor: "",
        firstname: "event_description",
        divStatement1: " has completed by ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Task Completed",
        subEvent: "applied_on",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Performed By:",
    },
    uploadDocument: {
        divColor: "",
        firstname: "event_description",
        divStatement1: " has uploaded by ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Task Uploaded",
        subEvent: "applied_on",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Performed By: ",
    },
    taskOverdueCompletion: {
        divColor: "",
        firstname: "applied_on",
        divStatement1: " has completed the task with overdue ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: "Task Completed with Overdue",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Performed By:",
    },

    subTaskCompleted: {
        divColor: "",
        firstname: "event_description",
        divStatement1: " has completed by ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_on",
        description: " Sub Task Completed",
        subEvent: "applied_on",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Performed By:",
    },
    importData: {
        divColor: "",
        firstname: "applied_by",
        divStatement1: " has imported the data ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Imported the Data",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Task",
        assignedOrPerform: "Performed By:",
    },
    ipAdded: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_by",
        divStatement1: " has added the IP  ",
        divStatement2: " in the IP Configuration.",
        assignedBy: "",
        performedBy: "applied_by",
        description: "IP Added",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    ipDelete: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_by",
        divStatement1: " has deleted an IP ",
        divStatement2: " ",
        assignedBy: "",
        performedBy: "applied_by",
        description: "IP Deleted",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    retentionConfigChanges: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_by",
        divStatement1: " has configured the retention period to ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Retention configuration Changes",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "sub_event",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    policyConfig: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_by",
        divStatement1: " has configured the policy for ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Policy Configuration",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    slaConfigUpdate: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_by",
        divStatement1: " has updated the SLA Duration",//update the sla log
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: " SLA Configuration Updated",
        subEvent: "",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    configAlter: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_by",
        divStatement1: " has edit the feature",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Basic Information Update",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    roleAssigned: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_on",
        divStatement1: " has been assigned to the role of  ",
        divStatement2: " by ",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Permission Update",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_by",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    configEdit: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_on",
        divStatement1: " has edited the  ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Edit",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    configDelete: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_on",
        divStatement1: " has deleted IP Address from the   ",
        divStatement2: " by",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Delete",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    appOwnerAssigned: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_on",
        divStatement1: " has been assigned as the application owner of ",
        divStatement2: " by ",
        assignedBy: "",
        performedBy: "applied_by",
        description: " App Owner Assigned",
        subEvent: "application_name",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_by",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    roleChange: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_on",
        divStatement1: " has been assigned with the role of ",
        divStatement2: " by ",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Role Change",
        subEvent: "event_description",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_by",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    appOwnerRemoved: {
        divColor: "row log-card admin-config-log",
        firstname: "applied_by",
        divStatement1: "has deleted a user",
        divStatement2: " from the Application Owner list of  ",
        assignedBy: "",
        performedBy: "applied_by",
        description: " App Owner Removed",
        subEvent: "applied_on",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "application_name",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    toggleOn: {
        divColor: "row log-card admin-config-log",
        firstname: "",
        divStatement1: "Access Review Toggle is on by",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Toggle On",
        subEvent: "",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_by",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    toggleOff: {
        divColor: "row log-card admin-config-log",
        firstname: "",
        divStatement1: "Access Review Toggle is off by",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: " Toggle Off",
        subEvent: "",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_by",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    reviewDurationChange: {
        divColor: "row log-card admin-config-log",
        firstname: "",
        divStatement1: "The period of Access review has been changed by",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Access Review Duration changed ",
        subEvent: "",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_by",
        logtype: "Admin Configs",
        assignedOrPerform: "Performed By:",
    },
    //Events for Access Governance Activity
    approve: {
        divColor: "row log-card access-governance-log",
        firstname: "applied_by",
        divStatement1: " has approved the application ",
        divStatement2: " to the user",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Application Approve",
        subEvent: "application_name",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_on",
        logtype: "Access Request",
        assignedOrPerform: "Performed By:",
    },
    Unassign:{
        divColor: "row log-card access-governance-log",
        firstname: "application_name",
        divStatement1: " has been revoked to the user ",
        divStatement2: " due to ",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Application Revoke",
        subEvent: "applied_on",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "revoke_category",
        logtype: "Access Request",
        assignedOrPerform: "Performed By:",
    },
    notApprove:{
        divColor: "row log-card access-governance-log",
        firstname: "application_name",
        divStatement1: " has been not approved to the user ",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "No Action",
        subEvent: "",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_on",
        logtype: "Access Request",
        assignedOrPerform: "Performed By:",
    },
    Assign:{
        divColor: "row log-card access-governance-log",
        firstname: "applied_by",
        divStatement1: " has assigned a new application ",
        divStatement2: " to",
        assignedBy: "",
        performedBy: "applied_by",
        description: "Application Assigned",
        subEvent: "application_name",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "applied_on",
        logtype: "Access Request",
        assignedOrPerform: "Performed By:",
    },
    Add:{
        divColor: "row log-card access-governance-log",
        firstname: "applied_on",
        divStatement1: " has been assigned as a new user",
        divStatement2: "",
        assignedBy: "",
        performedBy: "applied_by",
        description: "New User Added",
        subEvent: "",
        subEventName: "",
        event: "event",
        dateTime: "date_time",
        Category: "category",
        lastname: "",
        logtype: "Access Request",
        assignedOrPerform: "Performed By:",
    }
    
};

/**
* Defining the Event logs const Value
 */
export let eventLogsConst = {
    failedLogin: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " has performed a failed login activity.",
    },
    successLogin: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " has performed a successful login activity.",
    },
    MultiFailLogin: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " has performed Multiple failed MFA attempts in short period.",
    },
    mfaSuccess: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " successfully verified the MFA authentication.",
    },
    mfaFail: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: "  has attempt a failed MFA attempt to login.",
    },
    untypicalTravel: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " observed traveling to a location not typical for his travel patterns.",
    },
    actblaclistedIp: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " attempted unauthorized access from a blacklisted IP address.",
    },
    mfaEnable: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: "MFA has been enabled for the user account",
        subject:"applied_on"
    },
    mfaDisable: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: "MFA has been disabled for the user account ",
        subject:"applied_on"
    },
    userAccDisable: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: "'s account has been disabled.",
    },
    userAccEnable: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: "'s account has been enabled.",
    },
    //overdueCampaign changing to campaign over due to trigger
    campaignOverdue: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " has an overdue in ",
        divStatement2: " Campaign."

    },
    massdownload: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: " has performed Mass Download in ",
    },
    massDelete: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: " has performed a Mass Delete in ",
    },
    highVolAppUsage: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: "The application ",
        divStatement2: " has observed a high volume usage."
    },
    spam: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "reason", //subject 
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " has received Spam email with the subject ",
        divStatement2: " from",
        subject: "sender_email",
        
    },
    malware: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "reason",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " has received Malware email with the subject ",
        divStatement2: " from",
        subject: "recipient_email",
 
    },
    Phishining: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " has received Phish email with the subject ",
        divStatement2: " from",
        subject: "reason",
        senderMail: "sender_email"
    },
    susUrlClick: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "",
        deviceName: "device_name",
        divStatement: " clicked on a URL flagged as suspicious in an email with the subject",
        subject: "reason",
    },
    externalEmailShare: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "recipient_email",
        deviceName: "device_name",
        divStatement: " sent an email outside the organization to the user ",
        divStatement2: " with the subject ",
        subject: "reason",
        senderMail: "recipient_email"
    },
    rootedOrJailbroken: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: "'s device has been identified as rooted or jailbroken.",
        subject: "reason",
    },
    antiVirusDisable: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: "'s device does not have active anti-virus protection.",
        subject: "reason",
    },
    bitLockerDisable: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: "'s device has been detected with disabled BitLocker encryption.",
        subject: "reason",
    },
    antiMalwareDisable: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: "'s device has been detected with disabled anti-malware protection",
        subject: "reason"
    },
    delete: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: " has deleted a file in ",
        subject: "reason",
    },
    download: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: " has downloaded a file in ",
        subject: "reason",
    },
    passwordChange: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: " Password has been changed for the user account ",
        subject: "reason",
    },
    realTimeNetworkProtectionDisable: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "event_description",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "device_name",
        divStatement: " has been detected with disabled real-time network protection.",
        subject: "reason",
    },
    multipleGeoLocation: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "",
        divStatement: " logged in from multiple locations in a short time, indicating potential unauthorized access.",
        subject: "",
    },
    loginUnusualHours: {
        event: "event",
        subEvent: "sub_event",
        appliedOn: "applied_on",
        riskType: "risk_type",
        event_description: "",
        dateTime: "date_time",
        isThreat: "is_threat",
        location: "city",
        applicationName: "application_name",
        deviceName: "",
        divStatement: "'s account activity detected outside of regular business hours.",
        subject: "",
    },

};

export let voga12 = 'e8da46c103997e491be139a68a10bdba';
export let voga23 = 'd51839ebe35ab143d00624e1cefac478d9656e0190cb8c858b898a94033e11b0';