import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { agovContext, defaultThumbnailBlobUrl, getFileDetails } from '../../Constant';
import { handleUpload } from '../../Configs/BlobContainer';
import { getCourseType, getVideoDetails, postVideoDetails } from '../../Services/LMS/LmsService';
import UploadedMediaSection from '../LMS/UploadedMediaSection';
import UploadMediaSection from '../LMS/UploadMediaSection';
import { isObject } from 'lodash';
import { Loader } from "../Loader/Loader";

const CreateMediaForm = () => {

    /*
    * PC_PVDFE_NO_10 - PC_PVDFE_NO_15
    * Initialzing the constant variables
    */
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, employeeName } = useContext(agovContext);

    const questionObject = [
        {
            questionId: '',
            question: '',
            questionType: 'radio',
            questionError: '',
            options: [
                { optionId: '', option: '', score: 0, optionError: '', scoreError: '' },
                { optionId: '', option: '', score: 0, optionError: '', scoreError: '' },
            ],
            validateScore: '',
        },
    ];

    const deletedQuizQuestionsObject = {
        "deletedQuestionIds": [],
        "deletedOptionIds": []
    }
    const videoDetailsObject = { userId: '', userName: '', videoId: '', videoName: '', videoDescription: '', videoPassScore: 0, courseTypeId: '', isDocument: '', videoBlobUrl: "", videoThumbnail: "", videoTotalDuration: '', questions: questionObject };
    const videoDetailsError = { videoNameError: '', videoDescriptionError: '', videoPassScoreError: '', courseTypeIdError: '', videoBlobUrlError: '', videoThumbnailError: '' };

    /*
    * PC_PVDFE_NO_16 - PC_PVDFE_NO_22
    * Initializing the state variable using use state hook
    */
    const [videoDetails, setVideoDetails] = useState(videoDetailsObject);
    const [questions, setQuestions] = useState(videoDetails.questions);
    const [validateError, setValidateError] = useState(videoDetailsError);
    const [mediaData, setMediaData] = useState(null);
    const [thumbnailData, setThumbnailData] = useState(null);
    const [courseTypeDropDownDetails, setCourseTypeDropDownDetails] = useState([]);
    const [deletedQuizQuestions, setDeletedQuizQuestions] = useState(deletedQuizQuestionsObject);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedThumbnailValue, setUploadedThumbnailValue] = useState("");
    const [uploadedMediaValue, setUploadedMediaValue] = useState("");


    /*
    * PC_PVDFE_NO_23 - PC_PVDFE_NO_26
    * Initialzing the use effect hook with empty dependencies
    */
    useEffect(() => {
        setVideoDetails({ ...videoDetails, userId: userId, userName: employeeName });
        mediaPageLoad();
    }, []);


    /*
    * PC_PVDFE_NO_27 - PC_PVDFE_NO_93
    * Fetching all the media details in the initial page load.
    */
    const mediaPageLoad = async () => {
        setIsLoading(true);
        if (location && location.state && location.state.value && location.state.value.videoId !== undefined && location.state.value.videoId !== "") {
            const mediaRequest = { videoId: location.state.value.videoId };
            // const mediaRequest = { videoId: "851AD6CB-ED6C-403C-89BB-33EBE548DEBE" };
            const result = await getVideoDetails(mediaRequest);
            if (result.statusCode !== 200) {
                setIsLoading(false);
                navigate('/error');
                return;
            }
            try {
                const mediaData = await getFileDetails(result.response.videoBlobUrl);
                setMediaData(mediaData);
            } catch (error) {
                console.error("Error fetching media data:", error);
            }
            try {
                const thumbnailData = await getFileDetails(result.response.videoThumbnail);
                setThumbnailData(thumbnailData);
            } catch (error) {
                console.error("Error fetching thumbnail data:", error);
            }
            result.response.isDocument = result.response.isDocument === true ? 1 : 0;
            setVideoDetails(result.response);
            setQuestions(result.response.questions);
        }

        if (location && location?.state && location?.state?.value && location?.state?.value?.fileDetails !== undefined && location.state?.value?.fileDetails !== "") {
            handleVideoChange(null, location.state.value.fileDetails);
        }

        try {
            const courseTypeDetails = await getCourseType({ userId: userId });
            if (courseTypeDetails.statusCode !== 200) {
                setIsLoading(false);
                navigate('/error');
                return;
            }
            setCourseTypeDropDownDetails(courseTypeDetails.response);
        } catch (error) {
        }
        setIsLoading(false);
    };

    /*
    * PC_PVDFE_NO_94 - PC_PVDFE_NO_95
    * Dynamic generation for course type dropdown binding
    */
    const generateCourseTypeDropDown = () => {
        return courseTypeDropDownDetails?.map((item) => {
            return <option key={item.courseTypeId} value={item.courseTypeId} selected={item.courseTypeId == videoDetails.courseTypeId}>{item.courseType}</option>;
        });
    };

    /*
    * PC_PVDFE_NO_97 - PC_PVDFE_NO_102
    * Funtion to convert seconds to HMS
    */
    const secondsToHMS = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const pad = (num) => (num < 10 ? `0${num}` : num);
        return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
    };

    /*
    * PC_PVDFE_NO_104 - PC_PVDFE_NO_109
    * Handling the input field value in onChange event
    */
    function inputHandler(event) {
        if (event.target.name === "videoPassScore") {
            if (!isNaN(event.target.value.trim()) && event.target.value.trim() <= 100) {
                event.target.value = Number(event.target.value);
            }
            else {
                event.target.value = 0;
            }
        }

        if (event.target.value.trim() === "") {
            setVideoDetails({ ...videoDetails, [event.target.name]: "" });
            return;
        }
        setVideoDetails({ ...videoDetails, [event.target.name]: event.target.value });
    }

    /*
    * PC_PVDFE_NO_112 - PC_PVDFE_NO_184
    * Handle media submit button functionality
    */
    const handleMediaSubmit = async () => {
        setIsLoading(true);
        const isMediaFormValid = validateMediaForm();
        const isQuizQuestionsValid = validateQuizQuestions();

        if (isMediaFormValid && isQuizQuestionsValid) {
            const request = videoDetails;

            if (isObject(request.videoThumbnail)) {
                request.videoThumbnail = await handleUpload(videoDetails.videoThumbnail);
            } else if (request.videoThumbnail !== "") {
                request.videoThumbnail = request.videoThumbnail.split('?')[0];
            }
            if (request.videoThumbnail == "") {
                request.videoThumbnail = defaultThumbnailBlobUrl;
            }
            if (isObject(request.videoBlobUrl)) {
                request.videoBlobUrl = await handleUpload(videoDetails.videoBlobUrl);
            } else {
                request.videoBlobUrl = request.videoBlobUrl.split('?')[0];
            }

            request.questions = questions;
            request.userId = userId;
            request.userName = employeeName;
            request.deletedQuestionIds = deletedQuizQuestions.deletedQuestionIds;
            request.deletedOptionIds = deletedQuizQuestions.deletedOptionIds;
            setVideoDetails({ ...videoDetails, videoThumbnail: request.videoThumbnail, videoBlobUrl: request.videoBlobUrl });

            await saveVideoDetails(request);
            setVideoDetails(videoDetailsObject);
            navigate('/lmsmanagement/medialibrary');
        }
        setIsLoading(false);
    };

    /*
    * PC_PVDFE_NO_167 - PC_PVDFE_NO_181
    * Save the media details
    */
    const saveVideoDetails = async (data) => {
        setIsLoading(true);
        const postVideoResult = await postVideoDetails(data);

        if (postVideoResult.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }
        setIsLoading(false);
    };

    /*
    * PC_PVDFE_NO_114 - PC_PVDFE_NO_134
    * validate the required input field validation
    */
    const validateMediaForm = () => {
        let isValidMediaDetails = true;
        const validateError = {};

        if (videoDetails.videoName === "") {
            validateError.videoNameError = "Please enter a video name";
            isValidMediaDetails = false;
        }
        if (videoDetails.videoDescription === "") {
            validateError.videoDescriptionError = "Please enter a description";
            isValidMediaDetails = false;
        }
        if (videoDetails.videoPassScore === 0) {
            validateError.videoPassScoreError = "Please enter a pass score";
            isValidMediaDetails = false;
        }
        if (videoDetails.courseTypeId === "") {
            validateError.courseTypeIdError = "Please select any course type";
            isValidMediaDetails = false;
        }
        if (videoDetails.videoBlobUrl === "") {
            validateError.videoBlobUrlError = "Please upload a video file";
            isValidMediaDetails = false;
        }
        setValidateError(validateError);
        return isValidMediaDetails;
    }
    const validateQuizQuestions = () => {
        let isValidQuizQuestions = true;
    
        const updatedQuestions = questions.map(question => {
            let questionErrors = {
                questionError: question.question.trim() === "" ? "Please enter the question" :
                    question.question.trim().length > 225 ? "Question should not exceed 225 characters" : "",
                validateScore: ""
            };
    
            // Ensure only one option has a score of 100, and the rest have a score of 0
            const scoreCounts = question.options.reduce((acc, option) => {
                if (option.score === 100) acc.correctCount++;
                if (option.score !== 100 && option.score !== 0) acc.invalidCount++;
                return acc;
            }, { correctCount: 0, invalidCount: 0 });
    
            if (scoreCounts.correctCount !== 1 || scoreCounts.invalidCount > 0) {
                questionErrors.validateScore = "Exactly one option should have a score of 100 And  the others should be 0";
                isValidQuizQuestions = false;
            }
    
            const updatedOptions = question.options.map(option => {
                let optionErrors = {
                    optionError: option.option.trim() === "" ? "Please enter the option" :
                        option.option.trim().length > 200 ? "Option should not exceed 200 characters" : "",
                    scoreError: ""
                };
    
                // Validate that the score is either 0 or 100
                if (option.score !== 100 && option.score !== 0) {
                    optionErrors.scoreError = "";
                    isValidQuizQuestions = false;
                }
    
                return { ...option, ...optionErrors };
            });
    
            return { ...question, options: updatedOptions, ...questionErrors };
        });
    
        setQuestions(updatedQuestions);
        return isValidQuizQuestions;
    };
    

    /*
    * PC_PVDFE_NO_136 - PC_PVDFE_NO_148
    * validate the quiz question validation
    */
  
   
    
    /*
    * PC_PVDFE_NO_61 - PC_PVDFE_NO_78
    * handle the uplaoded video or pdf file validation
    */
    const handleVideoChange = (event = null, fileDetails = null) => {
        const file = event?.target?.files[0] ?? fileDetails;
        setValidateError(videoDetailsError);
        const updatedError = { ...validateError };

        // Check file size (max size in bytes, here 50MB)
        const videoMaxFileSize = 500 * 1024 * 1024;
        const pdfMaxFileSize = 50 * 1024 * 1024;

        if (file) {
            const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);

            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (fileExtension === "mp4" && file.size > videoMaxFileSize) {
                updatedError.videoBlobUrlError = "File size exceeds the limit";
                setUploadedMediaValue("");
                setMediaData(null);
                setValidateError(updatedError);
                return;
            }

            if (fileExtension === "pdf" && file.size > pdfMaxFileSize) {
                updatedError.videoBlobUrlError = "File size exceeds the limit";
                setUploadedMediaValue("");
                setMediaData(null);
                setValidateError(updatedError);
                return;
            }

            const allowedExtensions = ['mp4', 'pdf'];
            if (!allowedExtensions.includes(fileExtension)) {
                updatedError.videoBlobUrlError = "Invalid file format. Please upload a valid file (e.g., MP4, PDF)";
                setUploadedMediaValue("");
                setMediaData(null);
                setValidateError(updatedError);
                return;
            }

            if (fileExtension == 'pdf') {
                setVideoDetails({ ...videoDetails, isDocument: 1, videoTotalDuration: '00:00:00', videoBlobUrl: file });
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const video = document.createElement('video');
                    video.src = e.target.result;

                    video.onloadedmetadata = () => {
                        const durationInSeconds = Math.round(video.duration);
                        const formattedDuration = secondsToHMS(durationInSeconds);
                        setUploadedMediaValue(file);
                        setVideoDetails({ ...videoDetails, isDocument: 0, videoTotalDuration: formattedDuration, videoBlobUrl: file });
                    };
                };

                reader.readAsDataURL(file);
            }
            setMediaData({ name: file.name, size: fileSizeInMB, extension: fileExtension, src: fileExtension == "pdf" ? "/images/pdf-icon.svg" : "/images/mp4-icon.svg" });
        }
    };

    /*
    * PC_PVDFE_NO_186 - PC_PVDFE_NO_202
    * handle uploaded thumbnail image validation
    */
    const handleThumbnailChange = (event) => {
        const image = event.target.files[0];
        setValidateError(videoDetailsError);
        const updatedError = { ...validateError };

        if (image) {
            // Check file size (max size in bytes, here 5MB)
            const maxFileSize = 5 * 1024 * 1024;
            if (image.size > maxFileSize) {
                updatedError.videoThumbnailError = "Thumbnail size exceeds the limit";
                setUploadedThumbnailValue("");
                setThumbnailData(null);
                setValidateError(updatedError);
                return;
            }

            const imageExtension = image.name.split('.').pop().toLowerCase();
            const imageSizeInMB = (image.size / (1024 * 1024)).toFixed(2);

            const allowedExtensions = ['jpg', 'jpeg', 'png'];
            if (!allowedExtensions.includes(imageExtension)) {
                updatedError.videoThumbnailError = "Invalid file format. Please upload a valid file (e.g., JPEG, PNG, JPG)";
                setUploadedThumbnailValue("");
                setThumbnailData(null);
                setValidateError(updatedError);
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const uploadedImage = e.target.result;
                setUploadedThumbnailValue(uploadedImage);
                setThumbnailData({ name: image.name, size: imageSizeInMB, extension: imageExtension, src: uploadedImage });
            };
            reader.readAsDataURL(image);
            setVideoDetails({ ...videoDetails, videoThumbnail: image });
        }
    };

    /*
    * PC_PVDFE_NO_204 - PC_PVDFE_NO_208
    * Funtionality to add new question
    */
    const addQuestion = () => {
        setQuestions([
            ...questions,
            {
                questionId: '',
                question: '',
                questionType: 'radio',
                questionError: '',
                options: [
                    { optionId: '', option: '', score: 0, optionError: '', scoreError: '' },
                    { optionId: '', option: '', score: 0, optionError: '', scoreError: '' },
                ],
                validateScore: '',
            },
        ]);
    };

    /*
    * PC_PVDFE_NO_209 - PC_PVDFE_NO_214
    * Funtionality to add new option
    */
    const addOption = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].options.push({ optionId: '', option: '', score: 0, optionError: '', scoreError: '' });
        setQuestions(updatedQuestions);
    };

    /*
    * PC_PVDFE_NO_216 - PC_PVDFE_NO_224
    * Funtionality to delete question
    */
    const deleteQuestion = (index) => {
        const updatedQuestions = [...questions];
        if (updatedQuestions[index].questionId !== undefined && updatedQuestions[index].questionId !== "") {
            let deletedQuestions = [...deletedQuizQuestions.deletedQuestionIds];
            deletedQuestions.push(updatedQuestions[index].questionId);
            setDeletedQuizQuestions({ ...deletedQuizQuestions, "deletedQuestionIds": deletedQuestions });
        }
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
    };

    /*
    * PC_PVDFE_NO_226 - PC_PVDFE_NO_235
    * Funtionality to delete option
    */
    const deleteOption = (questionIndex, optionIndex) => {
        const updatedQuestions = [...questions];
        if (updatedQuestions[questionIndex].questionId !== undefined &&
            updatedQuestions[questionIndex].questionId !== "" &&
            updatedQuestions[questionIndex].options[optionIndex].optionId !== undefined &&
            updatedQuestions[questionIndex].options[optionIndex].optionId !== "") {
            let deletedOptions = [...deletedQuizQuestions.deletedOptionIds];
            deletedOptions.push(updatedQuestions[questionIndex].options[optionIndex].optionId);
            setDeletedQuizQuestions({ ...deletedQuizQuestions, "deletedOptionIds": deletedOptions });
        }
        updatedQuestions[questionIndex].options.splice(optionIndex, 1);
        setQuestions(updatedQuestions);
    };


    /*
    * PC_PVDFE_NO_237 - PC_PVDFE_NO_242
    * Handle question input value
    */
    const handleQuestionChange = (index, question) => {
        const updatedQuestions = [...questions];
        if (question.trim() === "") {
            updatedQuestions[index].question = "";
            setQuestions(updatedQuestions);
            return;
        }

        updatedQuestions[index].question = question;
        setQuestions(updatedQuestions);
    };

    /*
    * PC_PVDFE_NO_244 - PC_PVDFE_NO_250
    * Handle question type
    */
    const handleQuestionType = (index, e) => {
        const isChecked = e.target.checked;
        const questionType = isChecked ? 'checkbox' : 'radio';
        const updatedQuestions = [...questions];
        updatedQuestions[index].questionType = questionType;
        setQuestions(updatedQuestions);
    };

    /*
    * PC_PVDFE_NO_252 - PC_PVDFE_NO_256
    * Handle option input value
    */
    const handleOptionChange = (questionIndex, optionIndex, option) => {
        const updatedQuestions = [...questions];
        if (option.trim() === "") {
            updatedQuestions[questionIndex].options[optionIndex].option = "";
            setQuestions(updatedQuestions);
        }
        updatedQuestions[questionIndex].options[optionIndex].option = option;
        setQuestions(updatedQuestions);
    };

    /*
    * PC_PVDFE_NO_258 - PC_PVDFE_NO_265
    * Handle score input value
    */
    const handleScoreChange = (questionIndex, optionIndex, score) => {
        if (!isNaN(score) && score <= 100) {
            score = Number(score);
        } else {
            score = 0;
        }
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex].score = score;
        setQuestions(updatedQuestions);
    };

    /*
    * PC_PVDFE_NO_267 - PC_PVDFE_NO_270
    * Handle cancel submit
    */
    const handleCancel = () => {
        setVideoDetails({
            ...videoDetails,
            videoName: "",
            videoDescription: "",
            videoBlobUrl: "",
            videoThumbnail: "",
            videoPassScore: "",
            videoTotalDuration: "",
            courseTypeId: "",
            isDocument: "",
            questions: questionObject
        });
        setQuestions(questionObject);
        setValidateError(videoDetailsError);
        setMediaData(null);
        setThumbnailData(null);
        setUploadedMediaValue("");
        setUploadedThumbnailValue("");
    };

    /*
    * PC_PVDFE_NO_273 - PC_PVDFE_NO_278
    * Handle delete on the uploaded media or thumbnail
    */
    const handleDeleteMedia = (isMedia = true) => {
        if (isMedia) {
            setVideoDetails({ ...videoDetails, videoBlobUrl: "" });
            setMediaData(null);
            setUploadedMediaValue("");
        } else {
            setVideoDetails({ ...videoDetails, videoThumbnail: "" });
            setThumbnailData(null);
            setUploadedThumbnailValue("");
        }
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="container-fluid" style={{ marginTop: '5%' }}>
                <div className="row">
                    <div className="col-md-12 px-5 my-5">
                        <h1 className="font-bold font-24 primary-textcolor mb-4">
                            <a className="text-decoration-none me-3 pointer" onClick={() => navigate(-1)}>
                                <img src="/images/left-arrow.svg" alt="left-arrow" />
                            </a>
                            Upload
                        </h1>
                        <div className="row">
                            <div className="col-md-6 my-3">
                                <label htmlFor="Name" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    Name<span className="required-text">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control font-regular font-14 custom-form-control py-2"
                                    id="Name"
                                    placeholder="Enter Course Name"
                                    name="videoName"
                                    maxLength={250}
                                    onChange={(e) => inputHandler(e)}
                                    value={videoDetails.videoName}
                                />
                                <span style={{ color: 'red' }}>{validateError.videoNameError}</span>
                            </div>
                            <div className="col-md-6 my-3">
                                <label htmlFor="WhatLearn" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    What You'll Learn<span className="required-text">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control font-regular font-14 custom-form-control py-2"
                                    id="WhatLearn"
                                    placeholder="Enter Course Overview"
                                    name="videoDescription"
                                    maxLength={250}
                                    onChange={(e) => inputHandler(e)}
                                    value={videoDetails.videoDescription}
                                />
                                <span style={{ color: 'red' }}>{validateError.videoDescriptionError}</span>
                            </div>
                            <div className="col-md-6 my-3">
                                <label htmlFor="Type" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    Type<span className="required-text">*</span>
                                </label>
                                <select
                                    className="form-select cust-input-sty font-12 font-regular"
                                    id="Type"
                                    aria-label="Default select example"
                                    name="courseTypeId"
                                    onChange={(e) => inputHandler(e)}
                                >
                                    <option value="0" selected="">Select</option>
                                    {generateCourseTypeDropDown()}
                                </select>
                                <span style={{ color: 'red' }}>{validateError.courseTypeIdError}</span>
                            </div>
                            <div className="col-md-6 my-3">
                                <label htmlFor="upload-video" className="form-label font-medium font-14 d-block primary-textcolor mb-1">
                                    {videoDetails.isDocument === 0 ? "Upload Video" : "Upload Document"}<span className="required-text">*</span>
                                </label>
                                {mediaData ? (
                                    <UploadedMediaSection mediaData={mediaData} onDelete={handleDeleteMedia} isMedia={true} />
                                ) : (
                                    <UploadMediaSection
                                        handleUpload={handleVideoChange}
                                        allowedExtensions=".pdf, .mp4"
                                        error={validateError.videoBlobUrlError}
                                        fileValue={uploadedMediaValue}
                                    />
                                )}

                            </div>
                            <div className="col-md-6 my-3">
                                <label htmlFor="upload-thumbnail" className="form-label font-medium font-14 d-block primary-textcolor mb-1">
                                    Upload Thumbnail
                                </label>
                                {thumbnailData ? (
                                    <UploadedMediaSection mediaData={thumbnailData} onDelete={handleDeleteMedia} isMedia={false} />
                                ) : (
                                    <UploadMediaSection
                                        handleUpload={handleThumbnailChange}
                                        allowedExtensions=".jpg, .png, .jpeg"
                                        error={validateError.videoThumbnailError}
                                        fileValue={uploadedThumbnailValue}
                                    />
                                )}
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="tooltip-container">
                                    <label htmlFor="Pass-Score" className="form-label font-medium font-14 primary-textcolor mb-1">
                                        Pass Score<span className="required-text">*</span>
                                    </label>
                                    <div className="tooltip4">Specify the minimum score required to pass the course</div>
                                </div>
                                <input
                                    type="text"
                                    className="form-control font-regular font-14 custom-form-control py-2"
                                    id="Pass-Score"
                                    placeholder="Enter Pass Score"
                                    name="videoPassScore"
                                    onChange={(e) => inputHandler(e)}
                                    value={videoDetails.videoPassScore}
                                />
                                <span style={{ color: 'red' }}>{validateError.videoPassScoreError}</span>
                            </div>
                        </div>
                        <h1 className="font-bold font-20 primary-textcolor my-4">Create Quiz</h1>
                        {questions.map((question, questionIndex) => (
                            <>
                                <div className="col-md-12 my-3" key={questionIndex}>
                                    <div>
                                        <div className="d-flex align-items-center mb-3">
                                            <label htmlFor={`Question${questionIndex}`} className="form-label mb-0 font-medium font-14 primary-textcolor">
                                                {"Question " + parseInt(questionIndex + 1)}
                                            </label>
                                            {questions.length > 1 &&
                                                <button
                                                    type="button"
                                                    className="border-0 p-0 shadow-none bg-transparent"
                                                    onClick={() => deleteQuestion(questionIndex)}
                                                >
                                                    <img src="/images/delete-icon.svg" alt="delete-icon" className="ms-2" />
                                                </button>
                                            }
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control font-regular font-14 custom-form-control py-2"
                                            id={`Question${questionIndex}`}
                                            placeholder="Enter Question"
                                            maxLength={250}
                                            value={question.question}
                                            onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
                                        />
                                        <span style={{ color: 'red' }}>{question.questionError}</span>
                                    </div>
                                    {question.options.map((option, optionIndex) => (
                                        <div className="row mb-3 mt-4" key={optionIndex}>
                                            <div className="col-lg-auto col-md-1 d-flex align-items-center mb-2">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input custom-radio-input"
                                                        type={question.questionType}
                                                        disabled
                                                        name={`flexRadioDefault${questionIndex}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control font-regular font-14 custom-form-control py-2"
                                                    placeholder="Enter Option"
                                                    value={option.option}
                                                    onChange={(e) => handleOptionChange(questionIndex, optionIndex, e.target.value)}
                                                />
                                                <span style={{ color: 'red' }}>{option.optionError}</span>
                                            </div>
                                            <div className="col-lg-2 col-md-5 mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control font-regular font-14 custom-form-control py-2"
                                                    placeholder="Enter Points"
                                                    value={option.score}
                                                    onChange={(e) => handleScoreChange(questionIndex, optionIndex, e.target.value)}
                                                />
                                                <span style={{ color: 'red' }}>{option.scoreError}</span>
                                            </div>
                                            {question.options.length > 2 &&
                                                <div className="col-lg-2 col-md-5 mb-2">
                                                    <button
                                                        type="button"
                                                        className="border-0 p-0 shadow-none bg-transparent"
                                                        onClick={() => deleteOption(questionIndex, optionIndex)}
                                                    >
                                                        <img src="/images/delete-icon.svg" alt="delete-icon" className="ms-2" />
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                    <div className="d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="me-3 border-0 shadow-none p-0 bg-transparent blue-btn d-flex align-items-center font-14 font-medium"
                                            onClick={() => addOption(questionIndex)}
                                        >
                                            <span>
                                                <img
                                                    src="/images/plus-icon-2.svg"
                                                    alt="plus-icon-2"
                                                    className="me-2 mb-1"
                                                />
                                            </span>
                                            Add Option
                                        </button>
                                        <div className="form-check form-switch ms-4">
                                            <input
                                                className="form-check-input custom-switch pointer"
                                                type="checkbox"
                                                id={`q${questionIndex}MultipleAnswers`}
                                                checked={question.questionType === "checkbox" ? true : false}
                                                onChange={(e) => handleQuestionType(questionIndex, e)}
                                            />
                                            <label className="form-check-label" htmlFor={`q${questionIndex}MultipleAnswers`}>
                                                Multiple Answers
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span style={{ color: 'red' }}>{question.validateScore}</span>
                                </div>
                            </>
                        ))}
                        <div className="col-md-12 mt-4 mb-3">
                            <button type="button" className="btn font-14 py-2 secondary-btn font-regular" onClick={() => addQuestion()}>
                                Insert New Question
                            </button>
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <button type="button" className="font-14 primary-btn font-medium" onClick={() => handleCancel()}>
                                Cancel
                            </button>
                            <button type="button" className="font-14 dark-btn font-medium ms-3" onClick={() => handleMediaSubmit()}>
                                {videoDetails.videoId !== "" ? "Update Video" : "Upload Video"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateMediaForm;