import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NoDataFound from './NoDataFound';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts'
import NoRecordsPage from './NoRecordsPage';
import { Loader } from "../Loader/Loader";
import { agovContext } from '../../Constant';
import { getUserInfoAPIdata, getExportExcelApidata } from '../../Services/LMS/LmsService';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import UserLearningDashBoardPopup from './userLearningDashBoardPopup';
import NoRecordFound from '../Dashboard/dashboardNoRecordFound';



const UserLearningInformation = () => {
  const { userId, roles } = useContext(agovContext);
  // PS_userLearning_03 state variabel for the userLearningINfo 
  const [userLearningInformation, setUserLearningInformation] = useState(null);
  const [userReqDuration, setUserReqDuration] = useState('Today')
  // const exportExcelPayload = {
  //   "userId": 1,
  //   "widget": "",
  //   "reqDuration": userReqDuration,

  // }
  // const [widget, setWidget] = useState(exportExcelPayload)
  const [exportExcelData, setExportExcelData] = useState([]);
  const [userPopUpStatus, setUserPopUpStatus] = useState('')
  const [userPopUp, setUserPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  


  //PS_userLearning_07  useeffect to invoke the api at start 
  useEffect(() => {

    getUserLearningInformation();

    // This function is invoked when the component is mounted
  }, [userReqDuration]);



  // PS_userLearning_10 function to make the userlearning to maKE API CALL and store the response 
  const getUserLearningInformation = async () => {
    setIsLoading(true)

    let payload = {
      employeeId: userId, // Replace with actual employeeId
      reqDuration: userReqDuration
    };

    try {
      const response = await getUserInfoAPIdata(payload);

      if (response.status === true && response.statusCode === 200) {
        const data = response.responseData;

        const counts = data.counts;

        // Update user learning information state
        setUserLearningInformation(counts);


      } else {
        console.error('Error fetching user learning information', response);
      }
    } catch (error) {
      console.error('Error fetching user learning information', error);
    } finally {
      setIsLoading(false)
    }
  };
  ; //console.log("userLearningInformation", userLearningInformation);
  
  const userLearningInfoOnClick = async (test) => {

    setUserReqDuration(test)
   
    //Make Call to get userLearninfINFO
    // getUserLearningInformation();
  }
  //console.log("reqDuration",userReqDuration);

//PS_userLearning_27 this function gets the data for the excel 

  const getExportExcelData = async () => {
    setIsLoading(true)
    const exportExcelPayload = {
      "userId": userId,
      "widget": 'userLearningInfo',
      "reqDuration": userReqDuration,
  
    }
    try {
      // debugger;



      const response = await getExportExcelApidata(exportExcelPayload);
      if (response && response.statusCode === 200) {
        //console.log('Response:', response);
        const data = response.responseData;
        setExportExcelData(data);
        handleExportToExcel(data);
      } else {
        console.error('Error:', response ? response.statusCode : 400, 'Error message');
      }
    } catch (error) {
      console.error('Error:', error);
    }finally {
      setIsLoading(false)
    }
  };

  const userLearningInfoData = [
    { name: 'Yet to Start', value: userLearningInformation?.yetToStart },
    { name: 'In Progress', value: userLearningInformation?.inProgress },
    { name: 'Completed', value: userLearningInformation?.completed },
    { name: 'Overdue', value: userLearningInformation?.overDue }
  ];
  const COLORS = ["#8CD8E8", "#78DC8E", "#ADADE8", "#FB8484"];// colours for the activecampaign progress  and userLearning info 



 
  //PS_userLearning_28 to  PS_userLearning_42 function to handle  the export 
  const handleExportToExcel = async (data) => {
    try {
      // debugger;
      let excelData = data; // Ensure exportExcelData is correctly populated
      //console.log("Export Data:", excelData);

       let header = [];
      // let tabName = widget.widget; // Define as let to allow reassignment
      // //console.log(widget, "widget", tabName);

      // Define headers based on widget.widget value

      header = ["EmployeeName", "CampaignName","taskStatus", "campaignEndDate"];
      let tabName = "userLearningInfo";


      // Create a new Excel workbook
      let workbook = new ExcelJS.Workbook();
      let worksheet = workbook.addWorksheet('LMSReport');

      // Define header row
      worksheet.addRow(header);

      // Populate data rows
      excelData.forEach((dataItem, index) => {
        let rowData = [];
        // Iterate through headers to match the order and populate rowData accordingly
        header.forEach((column) => {
          rowData.push(dataItem[column]); // Use dataItem[column] if excelData is an array of objects
        });

        worksheet.addRow(rowData);
      });

      // Set filename
      let filename = tabName + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".xlsx";
      let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      // Write workbook to buffer
      let buffer = await workbook.xlsx.writeBuffer();

      // Create Blob
      let dataBlob = new Blob([buffer], { type: fileType });

      // Save file using FileSaver.js
      FileSaver.saveAs(dataBlob, filename);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  }
  const statusUserINfo = (status) => {
    setUserPopUpStatus(status)
  }
  // a function which helps us t close the  popup
  const UserEmitterEvent = () => {

    setUserPopup(false)
  }


  return (
    <>
    <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3">
       <Loader isLoading={isLoading} />
      <div className="bg-white py-2 px-3 border-radius-8 rm-dashboard-card">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-14 font-medium primary-textcolor mb-0">User Learning Information</h3>
          <div className="align-items-center d-flex gap-2">
            <div className="d-flex mt-2" >

              <button className="advanced-filter-button ms-2 me-2 medium-text customflip-btn mb-2 mb-md-0" type="button"
                onClick={()=>getExportExcelData()}>
                <img src="images/excel-icon.svg" alt="Filter-icon" />
              </button>
              <select
                className="form-select custom-form-control py-2 font-12 font-regular" onChange={(e) => { userLearningInfoOnClick(e.target.value) }}>


              <option value="Today">Today</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              </select>
            </div>
          </div>
        </div>
        <div>

          {userLearningInformation?.assignedCampaigns === 0 &&
            userLearningInformation?.yetToStart === 0 &&
            userLearningInformation?.inProgress === 0 &&
            userLearningInformation?.completed === 0 &&
            userLearningInformation?.overDue === 0 ? (
            <NoRecordFound  data={"No campaigns Assigned "} />
          ) : (
            <div className="row align-items-center px-4">
              <div className="col-md-6">
                <div className="user-risk-image align-items-center">

                  <ResponsiveContainer width="80%" height={300}>
                    <PieChart>
                      <Pie
                        data={userLearningInfoData}
                        innerRadius={80}
                        outerRadius={100}
                        fill="#8884d8"
                        strokeWidth={2} // Set a thinner stroke width
                        cornerRadius={4}
                        dataKey="value"
                      >
                        <Label value="Assigned  Campaigns" position="center" fill="#808080" dy={-5} /> {/* Grey color */}
                        <Label value={userLearningInformation?.assignedCampaigns || 0} position="center" fill="#000000" dy={15} /> {/* Black color */}
                        {userLearningInfoData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                  <div className="align-items-center d-flex align-items-baseline">
                    <span className="legends legend-cat-2 me-2 cursor-pointer" />
                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Yet
                      to Start</span>
                  </div>
                  <div onClick={() => statusUserINfo('yetToStart')}>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => setUserPopup(!userPopUp)}>{userLearningInformation?.yetToStart}</label>
                    {userPopUp && <UserLearningDashBoardPopup Props={userPopUpStatus} onClose={UserEmitterEvent} eventValue={userReqDuration} />}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                  <div className="align-items-center d-flex align-items-baseline">
                    <span className="legends legend-completed me-2" />
                    <span className="font-12 grey-light text-decoration-none cursor-pointer">In
                      Progress</span>
                  </div>
                  <div onClick={() => statusUserINfo('InProgress')}>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => setUserPopup(!userPopUp)} >{userLearningInformation?.inProgress}</label>

                  </div>
                </div>
                <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                  <div className="align-items-center d-flex align-items-baseline">
                    <span className="legends legend-cat-1 me-2" />
                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Completed</span>
                  </div>
                  <div onClick={() => statusUserINfo('Completed')}>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => setUserPopup(!userPopUp)}>{userLearningInformation?.completed}</label>

                  </div>
                </div>
                <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                  <div className="align-items-center d-flex align-items-baseline">
                    <span className="legends legend-overdue me-2" />
                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Overdue</span>
                  </div>
                  <div onClick={() => statusUserINfo('Overdue')}>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer"  onClick={() => setUserPopup(!userPopUp)} >{userLearningInformation?.overDue}</label>

                  </div>
                </div>
              </div>
            </div>
          )}
          </div>
      </div>
    </div>
    </>
  )
}

export default UserLearningInformation