const CompliancePopUpLoader = ({ isLoading }) => {
  //console.log(isLoading, "******************************")
  if (isLoading) {
    return (
      <div>
        <div className="d-flex align-items-center justify-content-center flex-column" style={{ width: "500px", height: "500px" }}>
          <div className="loader spin">
            <div className="spin__blocker"></div>
            <div className="spin__bottom-left"></div>
            <div className="spin__bottom-right"></div>
            <div className="spin__top-left"></div>
          </div>
          <h3 className="mt-4 font-24 font-medium">Loading...</h3>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CompliancePopUpLoader;