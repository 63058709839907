
//PC_SSC_1 to PC_SSC_4
import React, { useState, useEffect, useContext } from 'react';
import { agovContext } from '../../Constant';
import { Loader } from '../Loader/Loader';
import { getSlaConfig, updateSlaConfig } from '../../Services/Admin/AdminService';

//PC_SSC_6 to PC_SSC_10
const SecuritySlaConfig = () => {
  const { userId, employeeName, reportsToId } = useContext(agovContext);

  let slaConfigObj = {
    highSla: '',
    mediumSla: '',
    lowSla: '',
    highSlaPeriod: null,
    mediumSlaPeriod: null,
    lowSlaPeriod: null,
    modifiedBy: employeeName,
    userId: userId,
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  };

  let slaConfigErrorObj = {
    highSlaError: false,
    mediumSlaError: false,
    lowSlaError: false,
    highSlaPeriodError: false,
    mediumSlaPeriodError: false,
    lowSlaPeriodError: false,
    RegexCheck: false,
    periodCheck: false
  }

  const [slaConfig, setSlaConfig] = useState(slaConfigObj);
  const [errors, setErrors] = useState(slaConfigErrorObj)
  const [period, setPeriod] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //PC_SSC_11 to PC_SSC_15
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if ((name === 'lowSla' || name === 'mediumSla' || name === 'highSla') && isNaN(Number(value))) {
      return;
    }    
    setSlaConfig(prevState => ({ ...prevState, [name]: value }));
  };

  //
  const handleCancel = () => {
    let slaConfigObj = {
      highSla: '',
      mediumSla: '',
      lowSla: '',
      highSlaPeriod: '',
      mediumSlaPeriod: '',
      lowSlaPeriod: '',
      modifiedBy: employeeName,
      userId: userId,
      managerId: reportsToId,
      appliedBy: employeeName,
      appliedOn: employeeName
    };
    setSlaConfig(slaConfigObj);
    setErrors(slaConfigErrorObj)
  };

  useEffect(() => {
    fetchData()
  }, []);

  function bindPeriod() {
    return (
      period.map((value, index) => {
        return (
          <option value={value.period_id}>{value.period_category}</option>
        )
      })
    )
  }
  //PC_SSC_16 to PC_SSC_32
  const fetchData = async () => {
    try {
      setIsLoading(true);
      let result = await getSlaConfig();
      if (result.statusCode === 200) {
        let slaData = result.responseData;
        let editSlaConfigObj = {
          highSla: slaData.highSla.priority_sla_time,
          mediumSla: slaData.mediumSla.priority_sla_time,
          lowSla: slaData.lowSla.priority_sla_time,
          highSlaPeriod: slaData.highSla.sla_time_id,
          mediumSlaPeriod: slaData.mediumSla.sla_time_id,
          lowSlaPeriod: slaData.lowSla.sla_time_id
        };
        setSlaConfig(editSlaConfigObj);
        setPeriod(slaData.periodCategory)
        //console.log("SLA Configuration Information fetched successfully");
      } else {
        //console.log("Something went wrong!");
      }
    } catch (error) {
      console.error("Error fetching SLA configuration:", error);
    }
    setIsLoading(false);
  };
  //PC_SSC_47 to PC_SSC_64
  const validateSlaConfigData = () => {
    let isValid = true;
    let slaConfigErrorObj = { ...errors };

    if (slaConfig.highSla === "") {
      slaConfigErrorObj.highSlaError = true;
      isValid = false;
    }
    if (slaConfig.mediumSla === "") {
      slaConfigErrorObj.mediumSlaError = true;
      isValid = false;
    }
    if (slaConfig.lowSla === "") {
      slaConfigErrorObj.lowSlaError = true;
      isValid = false;
    }
    if (slaConfig.highSlaPeriod === "") {
      slaConfigErrorObj.highSlaPeriodError = true;
      isValid = false;
    }
    if (slaConfig.mediumSlaPeriod === "") {
      slaConfigErrorObj.mediumSlaPeriodError = true;
      isValid = false;
    }
    if (slaConfig.lowSlaPeriod === "") {
      slaConfigErrorObj.lowSlaPeriodError = true;
      isValid = false;
    }
    if (slaConfig.highSla > slaConfig.mediumSla &&
      slaConfig.mediumSla > slaConfig.lowSla &&
      slaConfig.lowSla < slaConfig.mediumSla) {
      slaConfigErrorObj.RegexCheck = true;
      isValid = false;
    }

    slaConfig.highSla =  Number(slaConfig.highSlaPeriod) == 2 ? Number(slaConfig.highSla) * 24 : Number(slaConfig.highSlaPeriod) == 3 ? Number(slaConfig.highSla) * 720  : Number(slaConfig.highSla);
    slaConfig.mediumSla =  Number(slaConfig.mediumSlaPeriod) == 2 ? Number(slaConfig.mediumSla) * 24 : Number(slaConfig.mediumSlaPeriod) == 3 ? Number(slaConfig.mediumSla) * 720  : Number(slaConfig.mediumSla);
    slaConfig.lowSla = Number(slaConfig.lowSlaPeriod) == 2 ? Number(slaConfig.lowSla) * 24 : Number(slaConfig.lowSlaPeriod) == 3 ? Number(slaConfig.lowSla) * 720  : Number(slaConfig.lowSla);
   
    if ((Number(slaConfig.highSla) > Number(slaConfig.mediumSla))
      || (Number(slaConfig.highSla)> Number(slaConfig.lowSla))) {
      slaConfigErrorObj.highSlaPeriodError = true;
      isValid = false;
    }
    else {
      slaConfigErrorObj.highSlaPeriodError = false;
    }
    if ((Number(slaConfig.mediumSla) > Number(slaConfig.lowSla))
      || (Number(slaConfig.mediumSla) < Number(slaConfig.highSla))) {
      slaConfigErrorObj.mediumSlaPeriodError = true;
      isValid = false;
    }
    else {
      slaConfigErrorObj.mediumSlaPeriodError = false;
    }
    if ((Number(slaConfig.lowSla) < Number(slaConfig.mediumSla) )
      || (Number(slaConfig.lowSla) < Number(slaConfig.highSlaPeriod))) {
      slaConfigErrorObj.lowSlaPeriodError = true;
      isValid = false;
    }
    else {
      slaConfigErrorObj.lowSlaPeriodError = false;
    }

    setErrors(slaConfigErrorObj);
    return isValid;
  };
  //console.log(slaConfig, "slaConfig");

  //PC_SSC_33 to PC_SSC_46
  const updateSlaConfiguration = async () => {
  
    try {
      setIsLoading(true)
      let slaConfigObj = {
        highSla: slaConfig.highSla,
        mediumSla: slaConfig.mediumSla,
        lowSla: slaConfig.lowSla,
        highSlaPeriod: slaConfig.highSlaPeriod,
        mediumSlaPeriod: slaConfig.mediumSlaPeriod,
        lowSlaPeriod: slaConfig.lowSlaPeriod,
        userId: userId ? userId:'test123',
        managerId: reportsToId,
        appliedBy: employeeName,
        appliedOn: employeeName
      };
      setSlaConfig(slaConfigObj)
      if (validateSlaConfigData()) {
        //console.log(slaConfig, "slaConfigOf Upadte");
        let result = await updateSlaConfig(slaConfigObj);
        //console.log(result, "result of update");
        if (result.statusCode === 200) {
          //console.log("Sla Configuration updated successfully");
        } else {
          //console.log("Something went wrong!");
        }
      }
    }
    catch (error) {
      console.error("Error updating SLA configuration:", error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between mt-4 pt-2 align-items-center">
                <div className="col-md-8">
                  <h2 className="font-bold font-20 primary-textcolor">SLA
                    Config</h2>
                  <p className="font-14 grey-light mt-3">Define Service
                    Level Agreement (SLA) configurations to
                    establish clear guidelines and expectations for
                    response times, and resolution targets.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <h2 className="font-bold font-18 red-textcolor mt-4">High</h2>
              <label className="font-bold font-14 primary-textcolor mt-3" htmlFor="high-sla-deadline">SLA
                Deadline</label>
              <div className="col-md-12 d-flex align-items-center">
                <div className="col-md-6 d-flex gap-4">
                  <div className="col-md-7">
                    <input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                      id="high-sla-deadline"
                      name="highSla"
                      value={slaConfig.highSla}
                      placeholder="Enter Days"
                      onChange={(e) => handleInputChange(e)} />
                    {errors.RegexCheck ? (
                      <></>
                    ) : errors.highSlaError ? (<span>Enter High Sla</span>) : null}
                  </div>
                  <div className="col-md-4">
                    <select className="form-select form-control font-regular font-14 custom-form-control py-2" aria-label="Default select example"
                      id="high-sla-deadline"
                      name="highSlaPeriod"
                      value={slaConfig.highSlaPeriod}
                      onChange={(e) => handleInputChange(e)}
                    >
                      {bindPeriod()}
                    </select>
                    {errors.highSlaPeriodError ? (
                      <span className="required font-12">{'High SLA should be less than Medium SLA and Low SLA'}</span>
                    ) : errors.highSlaPeriodError ? (
                      <span id="alertMessage" style={{color:"red",font:"small-caption"}}>
                        Choose Time Period
                      </span>) : null}
                  </div>
                  <div className="col-md-6 d-flex">
                    <p className="font-14 grey-light my-0">AGov’s
                      recommendation 04 Hours</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <h2 className="font-bold font-18 orange-textcolor mt-4">Medium
              </h2>
              <label className="font-bold font-14 primary-textcolor mt-3" htmlFor="medium-sla-deadline">SLA
                Deadline</label>
              <div className="col-md-12 d-flex align-items-center">
                <div className="col-md-6 d-flex gap-4">
                  <div className="col-md-7">
                    <input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                      id="medium-sla-deadline"
                      name="mediumSla"
                      placeholder="Enter Days"
                      value={slaConfig.mediumSla}
                      onChange={(e) => handleInputChange(e)} />
                    <h6>
                      {errors.RegexCheck ? (
                        <></>
                      ) : errors.mediumSlaError ? (<span id="alertMessage"style={{color:"red",font:"small-caption"}}>Enter Medium Sla</span>) : null}
                    </h6>
                  </div>
                  <div className="col-md-4">
                    <select className="form-select form-control font-regular font-14 custom-form-control py-2" aria-label="Default select example"
                      id="medium-sla-deadline"
                      name="mediumSlaPeriod"
                      value={slaConfig.mediumSlaPeriod}
                      onChange={(e) => handleInputChange(e)}>
                      {bindPeriod()}
                    </select>
                    {errors.mediumSlaPeriodError ? (
                      <span className="required font-12">{'Medium SLA should be less than High SLA'}</span>
                    ) : errors.mediumSlaPeriodError ? (
                      <span id="alertMessage"style={{color:"red",font:"small-caption"}}>
                        Choose Time Period
                      </span>) : null}
                  </div>
                  <div className="col-md-6 d-flex">
                    <p className="font-14 grey-light my-0">AGov’s
                      recommendation 08 Hours</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <h2 className="font-bold font-18 green-textcolor mt-4">Low</h2>
              <label className="font-bold font-14 primary-textcolor mt-3" htmlFor="low-sla-deadline">SLA
                Deadline</label>
              <div className="col-md-12 d-flex align-items-center">
                <div className="col-md-6 d-flex gap-4">
                  <div className="col-md-7">
                    <input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                      id="low-sla-deadline"
                      name="lowSla"
                      value={slaConfig.lowSla}
                      placeholder="Enter Days"
                      onChange={(e) => handleInputChange(e)} />
                    <h6>
                      {errors.RegexCheck ? (
                        <></>
                      ) : errors.lowSlaError ? (<span id="alertMessage"style={{color:"red",font:"small-caption"}}>Enter Low Sla</span>) : null}
                    </h6>
                  </div>
                  <div className="col-md-4">
                    <select className="form-select form-control font-regular font-14 custom-form-control py-2" aria-label="Default select example"
                      id="low-sla-deadline"
                      name="lowSlaPeriod"
                      value={slaConfig.lowSlaPeriod}
                      onChange={(e) => handleInputChange(e)}>
                      {bindPeriod()}
                    </select>
                    {errors.lowSlaPeriodError ? (
                      <span className="required font-12">{'Low SLA should be greater than Medium SLA Period and less than High SLA Period'}</span>
                    ) : errors.lowSlaPeriodError ? (
                      <span id="alertMessage"style={{color:"red",font:"small-caption"}}>
                        Choose Time Period
                      </span>) : null}
                  </div>
                  <div className="col-md-6 d-flex">
                    <p className="font-14 grey-light my-0">AGov’s
                      recommendation 24 Hours</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 py-5">
              <div className="d-flex justify-content-end">
                <button type="button" className="primary-btn font-14 font-medium py-2 me-3" dismiss="modal" onClick={() => { handleCancel() }}>Cancel</button>
                <button type="button" className="btn dark-btn font-14 font-medium py-2" onClick={() => { updateSlaConfiguration() }}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  )
}

export { SecuritySlaConfig }