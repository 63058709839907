import React, { useState, useEffect } from 'react';

const QuizQuestionsPopUp = ({ setIsQuizQuestionsVisisble, quizQuestions, handlePostQuizQuestions }) => {

    /*
    * PC_GVMDFE_NO_196 - PC_GVMDFE_NO_197
    * Initialzing the constant variables
    */
    const [selectedOptions, setSelectedOptions] = useState({});
    const [isQuizSubmitEnabled, setIsQuizSubmitEnabled] = useState(false);

    /*
    * PC_GVMDFE_NO_198 - PC_GVMDFE_NO_201
    * Initialzing the useEffect hook with dependencies value of state variable selectedOptions.
    */
    useEffect(() => {
        const answeredQuestionsCount = Object.keys(selectedOptions).length;
        const totalQuestionsCount = quizQuestions.length;

        setIsQuizSubmitEnabled(answeredQuestionsCount === totalQuestionsCount);
    }, [selectedOptions]);

    /*
    * PC_GVMDFE_NO_212 - PC_GVMDFE_NO_224
    * handling the user selected option change
    */
    const handleOptionChange = (questionId, optionId, isCheckbox) => {
        if (isCheckbox) {
            setSelectedOptions(prevState => {
                const updatedOptions = { ...prevState };
                if (!updatedOptions[questionId]) {
                    updatedOptions[questionId] = [optionId];
                } else {
                    const index = updatedOptions[questionId].indexOf(optionId);
                    if (index === -1) {
                        updatedOptions[questionId].push(optionId);
                    } else {
                        updatedOptions[questionId].splice(index, 1);
                    }
                    if (updatedOptions[questionId].length === 0) {
                        delete updatedOptions[questionId];
                    }
                }
                return updatedOptions;
            });
        } else {
            setSelectedOptions(prevState => ({
                ...prevState,
                [questionId]: optionId,
            }));
        }
    };

    /*
    * PC_GVMDFE_NO_226 - PC_GVMDFE_NO_239
    * calculating the user answered total score.
    */
    const calculateTotalScore = () => {
        let score = 0;
        let shouldBreak = false; // Flag to control outer loop
    
        quizQuestions.forEach(question => {
            // if (shouldBreak) return; // Exit the outer loop if flag is set
    
            const selectedOptionIds = selectedOptions[question.questionId];
    
            if (selectedOptionIds) {
                const options = Array.isArray(selectedOptionIds) ? selectedOptionIds : [selectedOptionIds];
                for (let optionId of options) {
                    const selectedOption = question.options.find(option => option.optionId === optionId);
    
                    if (selectedOption) {
                        if (selectedOption.score === 0) {
                            score = 0;
                            shouldBreak = true; // Set the flag to break the outer loop
                            break; // Exit the inner loop
                        }
                        score += selectedOption.score;
                    }
                }
            }
        });
    
        const totalScore = Math.round(score / quizQuestions.length);
    
        handlePostQuizQuestions(totalScore);
    };

    /*
    * PC_GVMDFE_NO_202 - PC_GVMDFE_NO_209
    * dynamic quiz questions generation.
    */
    const generateQuizQuestions = () => {
        return quizQuestions.map((question, questionIndex) => {
            return (
                <div className="mb-4" key={`question-${questionIndex}`}>
                    <p className="font-regular font-14">
                        {question.question}
                    </p>
                    {question.options.map((option, optionIndex) => (
                        <div className="form-check mb-3 ms-3" key={`question-${questionIndex}-option-${optionIndex}`}>
                            <input
                                className={"form-check-input" + (question.questionType === "radio" ? " custom-radio-input" : "")}
                                type={question.questionType}
                                name={`ques${questionIndex}`}
                                id={`ques${questionIndex}opt${optionIndex}`}
                                defaultChecked=""
                                value={option.optionId}
                                onChange={() => handleOptionChange(question.questionId, option.optionId, question.questionType == "radio" ? false : true)}
                            />
                            <label
                                className="form-check-label font-regular font-14"
                                htmlFor={`ques${questionIndex}opt${optionIndex}`}
                            >
                                {option.option}
                            </label>
                        </div>
                    ))}
                </div>
            );
        });
    };


    return (
        <>
            {/* Modal */}
            <div
                className="modal"
                id="delete"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                style={{ display: 'block' }}
            >
                <div className="modal-dialog modal-dialog-centered quiz-question-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-bottom pb-3 px-4 mb-3">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">
                                Quiz
                            </h5>
                            <button
                                type="button"
                                className="btn-close ms-auto"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setIsQuizQuestionsVisisble(false)}
                            />
                        </div>
                        <div className="modal-body border-0 font-16 px-4 quiz-ques-popup-scroll">
                            <div className="row">
                                <div className="col-md-12 ">
                                    {generateQuizQuestions()}
                                </div>
                            </div>
                            <div className="modal-footer border-0 mb-3 px-4">
                                <button
                                    type="button"
                                    className="primary-btn font-14 font-medium py-2 me-2"
                                    data-bs-dismiss="modal"
                                    onClick={() => setIsQuizQuestionsVisisble(false)}
                                >
                                    Go Back
                                </button>
                                <button type="button" className={isQuizSubmitEnabled ? "dark-btn" : "disabled-btn" + " font-14 font-medium py-2"} onClick={() => calculateTotalScore()} disabled={!isQuizSubmitEnabled}>
                                    Submit
                                </button>
                            </div>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div class="modal-backdrop fade show"></div>
        </>
    );
};

export default QuizQuestionsPopUp;
