import { client } from "../../Client/Client";
import { backendUrl as URL, adminService } from "../../Constant";


//Admin Information
export const insertAdminInformation = async (data) => {

    ////console.log(data, "insertAdminInformationPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.insertAdminInformation,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAdminInformation = async () => {

    // let data='';
    let config = {
        method: 'POST',
        url: URL + adminService.getAdminInformation,
        headers: {
            'Content-Type': 'application/json'
        },
        data:{}
    }
    let response = await client(config);
    return response;
}

export const updateAdminInformation = async (data) => {

    ////console.log(data, "updateAdminInformationPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.updateAdminInformation,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAdminPermission = async (data) => {

    ////console.log(data, "getAdminPermissionPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.getAdminPermission,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateConfigureHeaders = async (data) => {

    ////console.log(data, "updateConfigureHeadersPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.updateConfigureHeaders,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const insertPolicyConfig = async (data) => {

    ////console.log(data, "insertPolicyConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.insertPolicyConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;

}

export const getPolicyConfig = async (data) => {

    ////console.log(data, "getPolicyConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.getPolicyConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;

}

export const getPolicyDynamicData = async (data) => {

    ////console.log(data, "getPolicyDynamicDataPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.getPolicyDynamicData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;

}

export const insertIpConfig = async (data) => {

    ////console.log(data, "insertIpConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.insertIpConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getIpConfig = async (data) => {

    ////console.log(data, "getIpConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.getIpConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateIpConfig = async (data) => {

    ////console.log(data, "updateConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.updateIpConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deleteIpConfig = async (data) => {

    ////console.log(data, "deleteIpConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.deleteIpConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAppOwnerConfig = async (data) => {

    ////console.log(data, "getAppOwnerConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.getAppOwnerConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const bindEmailId = async (data) => {
    ////console.log(data, "bindEmailIdPayload");
    let config = {
        method: 'POST',
        url: URL + adminService.bindEmailId,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const insertAppOwnerConfig = async (data) => {

    ////console.log(data, "insertAppOwnerConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.insertAppOwnerConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateAppOwnerConfig = async (data) => {

    ////console.log(data, "updateAppOwnerConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.updateAppOwnerConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deleteAppOwnerConfig = async (data) => {

    ////console.log(data, "deleteAppOwnerConfigPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.deleteAppOwnerConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getExportToExcelData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.getExportToExcelData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const getConnectorData = async () => {

    let config = {
        method: 'POST',
        url: URL + adminService.getConnectorData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}
export const insertConnectorData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.insertConnectorData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const updateConnectorData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.updateConnectorData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const deleteConnectorData = async () => {

    let config = {
        method: 'POST',
        url: URL + adminService.deleteConnectorData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const getSlaConfig = async () => {

    let config = {
        method: 'POST',
        url: URL + adminService.getSlaConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const updateSlaConfig = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.updateSlaConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const getRiskTaskConfig = async () => {

    let config = {
        method: 'POST',
        url: URL + adminService.getRiskTaskConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const updateRiskTaskConfig = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.updateRiskTaskConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}
export const insertRiskTaskConfig = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.insertRiskTaskConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const getRetentionConfig = async () => {

    let config = {
        method: 'POST',
        url: URL + adminService.getRetentionConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}

export const updateRetentionConfig = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.updateRetentionConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}
export const insertRetentionConfig = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.insertRetentionConfig,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    ////console.log(config, "config");
    let response = await client(config);
    return response;
}


export const updateUserRoles = async (data) => {

    ////console.log(data, "updateUserRolesPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.updateRoles,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getUserRoles = async (data) => {

    let config = {
        method: 'POST',
        url: URL + adminService.getUserRoles,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getPeriodicalAccessData = async () => {

    let config = {
        method: 'POST',
        url: URL + adminService.getPeriodicalAccessData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }

    let response = await client(config);
    return response;

}

// post
export const PostData = async (data) => {
    let config = {
        method: 'POST',
        url: URL + adminService.postAdminData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config)
    return response
}

//update
export const updateData = async (data) => {
    let config = {
        method: 'POST',
        url: URL + adminService.updateAdminData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config)
    return response

}

export const postToggleButton = async (data) => {
    let config = {
        method: 'POST',
        url: URL + adminService.postToggleButton,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config)
    return response
}

export const getNotifications = async (data) => {
    let config = {
        method: 'POST',
        url: URL + adminService.getNotifications,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config)
    return response
}

export const readNotifications = async (data) => {
    let config = {
        method: 'POST',
        url: URL + adminService.readNotifications,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config)
    return response
}

export const fetchConfigureHeaders = async () => {

    ////console.log(data, "fetchConfigureHeaders");

    let config = {
        method: 'POST',
        url: URL + adminService.fetchConfigureHeaders,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {}
    }
    let response = await client(config);
    return response;
}

export const getBillingDetails =async (data) => {
    let config = {
        method: 'POST',
        url: URL + adminService.getBillingDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const fetchUserDetails = async (data) => {

    ////console.log(data, "insertAdminInformationPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.fetchUserDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const countDetails = async (data) => {
    ////console.log(data, "insertAdminInformationPayload");

    let config = {
        method: 'POST',
        url: URL + adminService.countDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}