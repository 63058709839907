import React from 'react'
import { useEffect, useState, useContext } from 'react'
import { assignPopupDetails, mappingGRC } from '../../Services/GRC/GrcService'
import { agovContext } from '../../Constant'
import { encryptStorage } from '../../Constant';
import { Loader } from '../Loader/Loader';

const AssignPopupDetails = ({ identifier, mappingId, isAdded, setIsAdded, mappingInformation, reRender, setReRender, mappingFrom }) => {

    const [popupDetailResponse, setPopupDetailResponse] = useState([]);
    const [popupDetailMapping, setPopupDetailMapping] = useState([]);
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const [isLoading, setIsLoading] = useState(false);



    /**
     * PC_CC_227
     * The useEffect is triggered
     */
    useEffect(() => {
        loadAssignPopupDetails()
    }, [identifier, mappingId, mappingInformation, reRender])


    /**
     * PC_CC_228 - PC_CC_241
     * This brings all the data required for the assign detail popup
     */
    const loadAssignPopupDetails = async () => {
        setIsLoading(true);
        try {
            if (identifier && mappingId) {
                let result = await assignPopupDetails({ "identifier": identifier, "mappingId": `${mappingId}`, "startDate": encryptStorage.getItem('commencer'), "endDate": encryptStorage.getItem('fin') });
                let mappedDetails = [];
                if (result?.responseData.length > 0) {
                    setPopupDetailResponse(result?.responseData[0][0]);
                    result?.responseData[1]?.map((controls) => {
                        mappedDetails.push(controls?.mappedInfo)
                    })
                }
                setPopupDetailMapping(mappedDetails);
            }
        }
        catch (error) {
            //console.log(error, "error");
        }
        finally {
            setIsLoading(false);
        }
    }


    /**
     * PC_CC_242 - PC_CC_254
     * This function maps the value to the control or principle or policy 
     */
    const mappingFunciton = async (popupDetailResponse) => {
        setIsLoading(true);
        await mappingGRC({
            "identifier": identifier,
            "selectedId": mappingFrom == "controlMapping" ? mappingInformation?.controlDetails?.controlId : mappingFrom == "evidence" ? mappingId : mappingFrom == "policy" ? mappingId : '',
            "controlIdentity": mappingFrom == "controlMapping" ? mappingInformation?.controlDetails?.controlIdentity : mappingFrom == "evidence" ? popupDetailResponse.controlIdentity : mappingFrom == "policy" ? popupDetailResponse.controlIdentity : '',
            "selectedName": mappingFrom == "controlMapping" ? popupDetailResponse?.popupName : mappingFrom == "evidence" ? mappingInformation.evidenceName : mappingFrom == "policy" ? mappingInformation.policyName : '',
            "mappingId": mappingFrom == "controlMapping" ? mappingId : mappingFrom == "evidence" ? mappingInformation.evidenceId : mappingFrom == "policy" ? mappingInformation.policyId : '',
            "mappedName": mappingFrom == "controlMapping" ? mappingInformation?.controlDetails?.controlName : mappingFrom == "evidence" ? popupDetailResponse?.popupName : mappingFrom == "policy" ? popupDetailResponse?.popupName : '',
            "startDate": encryptStorage.getItem('commencer'),
            "endDate": encryptStorage.getItem('fin'),
            "userId": userId
        });
        // setIsLoading(false);
        setReRender(new Date());
        setIsAdded(!isAdded);
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="col-md-6 py-4">
                <div className="tab-content" id="v-pills-tabContent">
                    <div className="tab-pane fade show active" id="v-pills-access-control" role="tabpanel" aria-labelledby="v-pills-access-control-tab">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <h5 className="font-18 font-medium primary-textcolor mb-3">{popupDetailResponse?.popupName}</h5>
                                <div className="d-flex gap-3 border-bottom">
                                    <button type="button" className={isAdded ? "disabled-btn font-14 font-medium py-2 w-100 mb-4" : "dark-btn font-14 font-medium py-2 w-100 mb-4"} disabled={isAdded} onClick={async () => {
                                        mappingFunciton(popupDetailResponse);
                                        ;
                                    }} >Add</button>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="font-14 font-medium primary-textcolor w-100 mb-2">Description</label>
                                <label className="font-14 font-regular primary-textcolor">{popupDetailResponse?.popupDescription}</label>
                            </div>
                            {identifier != 'framework_mapping' ?
                                <div className="col-md-12 mb-4">
                                    <label className="font-14 font-medium primary-textcolor w-100 mb-2">Related Sections</label>
                                    <label className="font-14 font-regular primary-textcolor related-container">{popupDetailMapping.join(', ')}</label>
                                </div> : ''}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AssignPopupDetails