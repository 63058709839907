// PC_COM_AS_RD_4 to PC_COM_AS_RD_7 -> Import statements
import { useEffect, useState } from "react";
import { ChartComponent } from "./chartComponent";
import { auditorVerificationApiResponse } from "../../Services/GRC/GrcService";
import { RequestedDocumentPopUp } from "./requestedDocumentPopUp";
import { useNavigate } from "react-router-dom";
import NoRecordFound from "./dashboardNoRecordFound";
import { encryptStorage } from '../../Constant';
import { Loader } from "../Loader/Loader";

// PC_COM_AS_RD_16 -> Component declaration
export function AuditorAndReqDocComponent({ data }) {

  // PC_COM_AS_RD_17 to PC_COM_AS_RD_21 -> State variable creation
  const [auditorVerificationData, setAuditorVerificationData] = useState([])
  const [auditorTotalCount, setAuditorTotalCount] = useState(0)
  const [requestedDocumentData, setRequestedDocumentData] = useState([])
  const [reqDocTotalCount, setReqDocTotalCount] = useState(0)
  const [showCompletedPopUP, setShowCompletedPopUP] = useState(false)
  const [showNotCompletedPopUp, setShowNotCompletedPopUp] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  // PC_COM_AS_RD_22 -> useNavigate declaration
  const controlNavigation = useNavigate()

  // PC_COM_AS_RD_23 to PC_COM_AS_RD_24 -> useEffect declaration
  useEffect(() => {
    auditorStatusAndReqDoc()
  }, [data])

  // PC_COM_AS_RD_25 -> function declaration
  const auditorStatusAndReqDoc = async () => {
    try {

      // PC_COM_AS_RD_26 -> set state a variabel isLoading and API function call
      setIsLoading(true);
      const result = await auditorVerificationApiResponse(data)

      // PC_COM_AS_RD_37 -> state variale updation whether statuscode is 200
      if (result.statusCode = 200) {
        setAuditorVerificationData([{
          name: "completedCount",
          value: result.auditorVerificationStatus.completedCount
        },
        {
          name: "notCompletedCount",
          value: result.auditorVerificationStatus.notCompletedControlCount
        }]
        )
        setAuditorTotalCount(result.auditorVerificationStatus.totalControl)

        setRequestedDocumentData([{
          name: "completedCount",
          value: result.requestedDocument.reqDocCompleted
        },
        {
          name: "notCompletedCount",
          value: result.requestedDocument.reqDocNotCompleted
        }]
        )
        setReqDocTotalCount(result.requestedDocument.totalCount)
      }

      // PC_COM_AS_RD_39 -> console an error
      else {
        navigate('/error');
      }
      // PC_COM_AS_RD_38 -> set state a variable isLoading 
      setIsLoading(false);
    }

    // PC_COM_AS_RD_39 -> navigate to error page
    catch (error) {
      navigate('/error');
    }
  }

  // PC_COM_AS_RD_47 to PC_COM_AS_RD_48 -> completed popUp function declaration and set state a variable showPopUp
  const completedPopUp = () => {
    setShowCompletedPopUP(!showCompletedPopUP)
  }

  // PC_COM_RD_POP_49 to PC_COM_RD_POP_50 -> not completed popUp function
  const notCompletdPopUP = () => {
    setShowNotCompletedPopUp(!showNotCompletedPopUp)
  }

  // PC_COM_AS_RD_45 to PC_COM_AS_RD_47 -> Navigates to control page
  const controlPageNavigation = (payload) => {
    //console.log(payload, "audit cycle -> control payload")
    encryptStorage.setItem("commencer", data.startDate);
    encryptStorage.setItem("fin", data.endDate);
    encryptStorage.setItem("identifiant", data.auditCycleId);
    controlNavigation('/compliance/controls', { state: payload })
  }

  // html part
  return (
    <div className="col-xl-4 col-lg-4 col-sm-12 col-md-12 mb-3">
      <div className="bg-white py-2 px-3 border-radius-8 position-relative three-col-widget rm-dashboard-card">
        <div className="d-flex justify-content-between align-items-center">
          <Loader isLoading={isLoading} />
          <h3 className="font-14 font-medium primary-textcolor mb-0 mt-2">
            Auditor Verification Status</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center ps-1 my-2 custom-tabs">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link px-0 active font-14 tab-style rounded-0" id="information-tab" data-bs-toggle="pill" data-bs-target="#information" type="button" role="tab" aria-controls="information" aria-selected="true">
                <span className="tab-img"> <img src="images/verification-active.svg" alt="filled-icon" className="me-2" />
                  <img src="images/verification-status.svg" alt="information-icon" className="me-2" /></span>Verification Status</button>
            </li>
            <li className="nav-item ms-4" role="presentation">
              <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="permissions-tab" data-bs-toggle="pill" data-bs-target="#permissions" type="button" role="tab" aria-controls="permissions" aria-selected="false" tabIndex={-1}>
                <span className="tab-img"> <img src="images/requested-doc-active.svg" alt="filled-icon" className="me-2" /> <img src="images/requested-doc.svg" alt="permissions-icon" className="me-2" />
                </span>Requested Document</button>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="information" role="tabpanel" aria-labelledby="information-tab">
            <div className="row align-items-center px-4">
              {auditorTotalCount != 0 ? <ChartComponent data1={auditorVerificationData} data2={auditorTotalCount}></ChartComponent> : <NoRecordFound data={"No Auditor Assigned"}></NoRecordFound>}
              {auditorTotalCount != 0 && <div className="align-items-center justify-content-center d-flex gap-2 mt-3">
                <div className="align-items-baseline d-flex justify-content-between me-3">
                  <span className="legends legend-completed me-1" />
                  <label className="me-2 font-12 grey-light">Verified</label>
                  <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => auditorVerificationData[0].value === 0 ? null : controlPageNavigation("verified")}>{auditorVerificationData[0].value}</label>
                </div>
                <div className="align-items-baseline d-flex justify-content-between">
                  <span className="legends legend-medium me-1" />
                  <label className="me-2 font-12 grey-light">Not Verified</label>
                  <div>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => auditorVerificationData[1].value === 0 ? null : controlPageNavigation("not-verified")}>{auditorVerificationData[1].value}</label>
                  </div>
                </div>
              </div>}
            </div>
          </div>
          <div className="tab-pane fade" id="permissions" role="tabpanel" aria-labelledby="permissions-tab">
            <div className="row align-items-center px-4">

              {reqDocTotalCount != 0 ? <ChartComponent data1={requestedDocumentData} data2={reqDocTotalCount}></ChartComponent> : <NoRecordFound data={"No Documents Requested"}></NoRecordFound>}

              {/* <div className="d-flex py-2 px-2 justify-content-center">
                                                  <img src="images/author-verfication-img.svg" alt="heat-map" className="img-fluid" />
                                                </div> */}
              {reqDocTotalCount != 0 && <div className="align-items-center justify-content-center d-flex gap-2 mt-3">
                <div className="align-items-baseline d-flex justify-content-between me-3">
                  <span className="legends legend-completed me-1" />
                  <label className="me-2 font-12 grey-light">Completed</label>
                  <div>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => requestedDocumentData[0].value === 0 ? null : completedPopUp()}>{requestedDocumentData[0].value}</label>
                    {showCompletedPopUP && <RequestedDocumentPopUp payload={data} status="Accepted" onClose={completedPopUp} totalCount={requestedDocumentData[0].value}></RequestedDocumentPopUp>}
                  </div>
                </div>
                <div className="align-items-baseline d-flex justify-content-between">
                  <span className="legends legend-medium me-1" />
                  <label className="me-2 font-12 grey-light">Not Completed</label>
                  <div>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => requestedDocumentData[1].value === 0 ? null : notCompletdPopUP()}>{requestedDocumentData[1].value}</label>
                    {showNotCompletedPopUp && <RequestedDocumentPopUp payload={data} status="In Progress" onClose={notCompletdPopUP} totalCount={requestedDocumentData[1].value}></RequestedDocumentPopUp>}
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}