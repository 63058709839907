/**PS_DC_01  - Importing the necessary functions and constants */
import React, { useState, useEffect, useContext } from 'react';
import { getAllDeviceEmployee, getAllDevicesExcel, getAllDevicesInfo } from '../../Services/Risk/RiskService';
import AddZero from './AddZeroComponent';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import { useLocation } from 'react-router-dom';
import NoDataFound from '../LMS/NoDataFound';
import RiskNoDataFound from './RiskNoDataFound';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';


function AllDevicesInfo() {
    const location = useLocation();
    const navigate = useNavigate();
    /**PS_DC_04 - Creation of required state variables */
    const [allDevicesInfo, setAllDevicesInfo] = useState([]);
    const [allDevicesExcelInfo, setAllDevicesExcelInfo] = useState([]);
    const [showDeviceFilterPopup, setShowDeviceFilterPopup] = useState(false);
    const [deviceFilters, setDeviceFilters] = useState({
        assignedName: '',
        managedType: '',
        compliantType: '',
        fromDate: '',
        toDate: '',
    });
    const [applyButtonDisabled, setApplyButtonDisabled] = useState(false);
    const [limit, setLimit] = useState(10);
    const [sortKey, setSortKey] = useState('deviceName');
    const [sortOrder, setSortOrder] = useState('asc');
    const [deviceTotalCount, setDeviceTotalCount] = useState(0);
    const [deviceSearch, setDeviceSearch] = useState('');
    const [assignedtoDetails, setAssignedToDetails] = useState([])
    const ManagedDropdownArr = ['Managed', 'Unmanaged']
    const CompliantDropdownArr = ['Yes', 'No']
    const [isLoading, setIsLoading] = useState(false);
    const { roles, userId } = useContext(agovContext);


    /**PS_DC_07 - the getAllDevicesData and getAssignedToDetails get invoked */

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            try {
                await getAllDevicesData();
                await getAssignedToDetails();
                setIsLoading(false)

            } catch (error) {
                // Handle errors here
                console.error("Error fetching data:", error);
                setIsLoading(false)

            }
        };

        fetchData();
    }, [limit, sortKey, sortOrder]);

    //ApiCalls

    /**PS_DC_35 - Dependency useEffect for triggering the getAllDevicesData */
    // useEffect(() => {
    //     getAllDevicesData();
    // }, [limit, sortKey, sortOrder]);

    const { state } = location;
        
        /**PS_DC_43 - Checking for Non-Complaint Devices Navigation */
        const nonCompliantState = state?.NonComplaint;
 
    const getAllDevicesData = async (cancelFlag) => {
        setIsLoading(true)
        // console.log(nonCompliantState,"nonCompliantStatenonCompliantStatenonCompliantState")
        
        /**PS_DC_42 - Checking for risk Devices Navigation */
        const riskDeviceStatInfo = (state && state.riskDeviceStatInfo) || false;
        const body = {
            "deviceFromDate": cancelFlag ? "" : deviceFilters.fromDate || "",
            "deviceToDate": cancelFlag ? "" : deviceFilters.toDate || "",
            "assignedTo": cancelFlag ? "" : deviceFilters.assignedName || "",
            "deviceManaged": cancelFlag ? "" : (deviceFilters.managedType === "Managed" ? 1 : (deviceFilters.managedType ? 0 : "")),
            "riskDeviceInfo": riskDeviceStatInfo,
            "searchKey": deviceSearch.trim(),
            "sortKey": sortKey,
            "sortOrder": sortOrder,
            "limit": limit,
            "deviceCompliant":  nonCompliantState == 0
            ? nonCompliantState
            : cancelFlag ? ""
            : deviceFilters.compliantType === "Yes"
            ? 1
            : deviceFilters.compliantType === "No"
            ? 0
            : "",
            "userRoles": roles
        };

        // console.log(body,"bodybodybodybodybody")
        // const details = { employeeId: agovDetails.employeeId };
        try {
            const response = await getAllDevicesInfo(body)
            if (response.statusCode === 200) {
                const responseData = response.responseData;
                setAllDevicesInfo(responseData.deviceData);
                setDeviceTotalCount(responseData.totalCount);
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
            }
        } catch (error) {
            console.error('Error fetching device info:', error);
            setIsLoading(false)

        }
    };

    /**PS-DC-24 Function to set the Assigned Details Array */
    const getAssignedToDetails = async () => {
        let body = {
            "userRoles": roles
        }
        setIsLoading(true)
        const response = await getAllDeviceEmployee(body);
        try {

            if (response && response.statusCode === 200) {
                const assignedToArr = response.responseData
                setAssignedToDetails(assignedToArr);
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false)
        }
    }

    //handling functions 


    useEffect(() => {
        /** PS_DC_28  checking any filter field except toDate has a value as `isFiltersSelected`. */
        // let tempUserFilters = deviceFilters;
        // const isFilterSelected = Object.values(tempUserFilters).some((value, key) => key !== 'toDate' && value !== '');
        // const isFromDateValid = tempUserFilters.fromDate !== '' || tempUserFilters.toDate === '';
        // /**PS_DC_28 Disabling the applyButton based on the validations */
        // const applyButtonDisabled = !isFromDateValid || !isFilterSelected;

        // setApplyButtonDisabled(applyButtonDisabled);
        let tempUserFilters = deviceFilters;
        const isFilterSelected = Object.values(tempUserFilters).some(value => value !== '');
        const applyButtonDisabled = !isFilterSelected;
        setApplyButtonDisabled(applyButtonDisabled);


    }, [deviceFilters]);

    /**PS_DC_27 - Function to set the filterVariables onChange  */
    const handleFilterChange = (field, value) => {
        setDeviceFilters(prevFilters => ({
            ...prevFilters,
            [field]: value
        }));


        if (field === 'fromDate' && !value) {
            setDeviceFilters(prevFilters => ({
                ...prevFilters,
                toDate: ''
            }));
        }
    };

    /**PS_DC_31 - Function to handle the SortClick */
    const handleSortClicked = (columnName, order) => {
        setSortKey(columnName);
        setSortOrder(order);
    }
    /**PS_DC_34 - Function to handle the Search */
    const handleSearchClick = async (emptySearch) => {

        if (emptySearch) {
            setDeviceSearch('');
            await getAllDevicesData();
            return;
        }
        await getAllDevicesData();
    }

    /**PS_DC_35 - Function to handle the load More */
    const handleLoadMore = () => {
        if (deviceTotalCount > allDevicesInfo.length) {
            setLimit(prevLimit => prevLimit + 10);
        }
    };

    /**PS_DC_38 - handling the clear filters  */
    const handleCancelFilter = async () => {
        setDeviceFilters({
            assignedName: '',
            managedType: '',
            compliantType: '',
            fromDate: '',
            toDate: '',
        });
        await getAllDevicesData("cancel")
        setShowDeviceFilterPopup(false);
    };

    /**PS_DC_28 - Making the API call for the apply button */
    const handleApplyFilter = () => {
        getAllDevicesData()
    }

    /**PS_DE_26 - the async exporting Excel function gets invoked */
    const handleExportExcel = async () => {
        setIsLoading(true);
        const { state } = location;
        const riskDeviceStatInfo = (state && state.riskDeviceStatInfo) || false;
        const body = {
            "deviceFromDate": deviceFilters.fromDate || "",
            "deviceToDate": deviceFilters.toDate || "",
            "assignedTo": deviceFilters.assignedName || "",
            "deviceManaged": (deviceFilters.managedType === "Managed" ? 1 : (deviceFilters.managedType ? 0 : "")),
            "riskDeviceInfo": riskDeviceStatInfo,
            "searchKey": deviceSearch.trim(),
            "sortKey": sortKey,
            "sortOrder": sortOrder,
            "deviceCompliant": (nonCompliantState == 0
                ? nonCompliantState : deviceFilters.compliantType === "Yes" ? 1 : (deviceFilters.compliantType ? 0 : "")),
            "userRoles": roles,
            "userId": userId
        };

        try {
            const response = await getAllDevicesExcel(body);
            if (response && response.statusCode === 200) {
                setAllDevicesExcelInfo(response.responseData);
                setIsLoading(false);

                let excelData = response.responseData.map(device => ({
                    'Device Name': device.deviceName || '',
                    'Type': device.Type ? 'Managed' : "UnManaged",
                    'Compliance Status': device.compliantType ? "Compliant" : "Non-compliant",
                    'Last Sign-in': device.lastDeviceSignIn || "nil",
                    'Assigned To': device.assignedTo || ""
                }));

                const filename = "AGov-deviceReport-" +
                new Date().getFullYear() +
                "/" +
                (parseInt(new Date().getMonth()) + 1 < 10
                  ? "0" + (parseInt(new Date().getMonth()) + 1)
                  : parseInt(new Date().getMonth() + 1)) +
                "/" +
                (new Date().getDate() < 10
                  ? "0" + new Date().getDate()
                  : new Date().getDate()) ;
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Device Logs');
                worksheet.columns = [
                    { header: 'Device Name', key: 'Device Name', width: 30 },
                    { header: 'Type', key: 'Type', width: 15 },
                    { header: 'Compliance Status', key: 'Compliance Status', width: 20 },
                    { header: 'Last Sign-in', key: 'Last Sign-in', width: 20 },
                    { header: 'Assigned To', key: 'Assigned To', width: 20 }
                ];
                worksheet.addRows(excelData);

                const excelBuffer = await workbook.xlsx.writeBuffer();
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, filename + fileExtension);
            }
        } catch (error) {
            console.error("Error exporting Excel:", error);
            setIsLoading(false);
        }
    };

    //mockData 

    const responseData = [
        {

            "deviceName": "Mobile Phone",

            "Type": "Managed",

            "compliantType": true,

            "lastDeviceSignIn": "2023-01-15",

            "assignedTo": "John Doe",

            "userImage": ""

        },

        {

            "deviceName": "Laptop",

            "Type": "Managed",

            "compliantType": false,

            "lastDeviceSignIn": "2023-02-20",

            "assignedTo": "Alice Smith",

            "userImage": ""

        }


    ]



    const renderAllDevicesInfo = (responseData) => {
        return (
            <tbody>
                {responseData.map((device, index) => (
                    <tr key={index}>
                        <td className="font-12">{device.deviceName}</td>
                        <td className="font-12">
                            <div className="d-flex align-items-center">
                                {device.userImage && <img src={device.userImage} className="table-prof" alt="User Profile" />}
                                <p className="font-12 font-medium primary-textcolor mb-0 ms-2">{device.assignedTo}</p>
                            </div>
                        </td>
                        <td className="font-12">{device.lastDeviceSignIn}</td>
                        <td className="font-12">{device.Type ? 'Managed' : 'UnManaged'}</td>
                        <td className="font-12">
                            <span className={device.compliantType ? 'green-dot me-2' : 'red-dot me-2'} />
                            {device.compliantType ? 'Yes' : 'No'}
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    }

    return (<>        <Loader isLoading={isLoading} />


        <div>
            <div className="container-fluid mt-4 mt-custom">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between mt-4 px-5">
                        <h1 className="font-bold font-24 primary-textcolor">
                            Risk Management
                        </h1>
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-3 px-5 custom-tabs">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0  font-14 tab-style bold-text rounded-0" id="overview-tab" data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true" onClick={() => { navigate('/riskManagement') }}>
                                    <span className="tab-img"> <img src="/images/dashboard-icon-filled.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/dashboard-icon.svg" alt="overview-icon" className="me-2" /></span>Overview</button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-users-tab" data-bs-toggle="pill" data-bs-target="#all-users" onClick={() => { navigate('/riskManagement/allUsers') }} type="button" role="tab" aria-controls="all-users" aria-selected="false" tabIndex={-1}>
                                    <span className="tab-img"> <img src="/images/people-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/people-icon.svg" alt="all-users-icon" className="me-2" />
                                    </span>All Users</button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-apps-tab" data-bs-toggle="pill" data-bs-target="#all-apps" type="button" role="tab" aria-controls="all-apps" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allApplications') }}  >
                                    <span className="tab-img"> <img src="/images/app-icon-filled.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/app-icon.svg" alt="all-apps-icon" className="me-2" />All
                                        Applications</span></button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 active font-14 tab-style  rounded-0" id="all-devices-tab" data-bs-toggle="pill" data-bs-target="#all-devices" type="button" role="tab" aria-controls="all-devices" aria-selected="false" tabIndex={-1}>
                                    <span className="tab-img"> <img src="/images/device-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/device-icon.svg" alt="all-devices-icon" className="me-2" />All Devices</span></button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="event-logs-tab" data-bs-toggle="pill" data-bs-target="#event-logs" type="button" role="tab" aria-controls="event-logs" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/eventLogs') }}>
                                    <span className="tab-img"> <img src="/images/document-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/document-icon.svg" alt="event-logs-icon" className="me-2" />Event Logs</span></button>
                            </li>
                        </ul>
                    </div>
                    <div className="row position-relative">
                        <div className="tab-content px-0" id="pills-tabContent">

                            <div className="tab-pane active show px-5 ms-2 mt-3" id="all-devices" role="tabpanel" aria-labelledby="all-devices-tab">
                                <ul className="nav position-absolute end-0 custom-filter-tab me-5">
                                    <li>
                                        <div className="input-group flex-nowrap search-group me-2">
                                            {/**PS_DC_32 - Invoking the search based on user Actions  */}
                                            <input type="text" className="form-control search-align" placeholder="Search" value={deviceSearch} onChange={(e) => setDeviceSearch(e.target.value)} aria-label="Username" aria-describedby="addon-wrapping"
                                                onKeyDownCapture={(e) => {
                                                    if (e.key == "Enter") {
                                                        handleSearchClick(false)
                                                    }
                                                }} />
                                            <button className="input-group-text btn btn-search-custom" id="addon-wrapping" onClick={() => handleSearchClick(false)} ><img src="/images/search-icon.svg" alt="search-icon" /></button>
                                        </div>
                                    </li>
                                    <div>
                                        <button className="cust-filter-btn d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                        </button>
                                        <div className="dropdown-menu custom-filter border-0">
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                                    <button type="button" className="btn-close font-12" aria-label="Close" />
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-12 font-medium">Assigned
                                                                To</label>
                                                            <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example" onChange={e => { handleFilterChange('assignedName', e.target.value) }} value={deviceFilters.assignedName}>
                                                                <option value=''>Select</option>
                                                                {assignedtoDetails.map((assign, index) => (
                                                                    <option key={index} value={assign.assignedTo} >{assign.assignedTo}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-12 font-medium">Type
                                                            </label>
                                                            <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example" onChange={e => { handleFilterChange('managedType', e.target.value) }} value={deviceFilters.managedType}>
                                                                <option value=''>Select</option>
                                                                {ManagedDropdownArr.map((type, index) => (
                                                                    <option key={index} value={type}>{type}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-12 font-medium">Compliant
                                                            </label>
                                                            <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example" onChange={e => { handleFilterChange('compliantType', e.target.value) }}  value={deviceFilters.compliantType || (nonCompliantState === 0 ? 'No' : '')}>
                                                                <option value=''>Select</option>
                                                                {CompliantDropdownArr.map((compliant, index) => (
                                                                    <option key={index} value={compliant} >{compliant}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <h5 className="font-14 font-bold text-black mt-3">Date Range</h5>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="StartDate" className="form-label text-black font-12 font-medium">From</label>
                                                            <input type="date" className="form-control custom-date cust-input-sty font-12 font-regular" id="StartDate" value={deviceFilters.fromDate} onChange={e => { handleFilterChange('fromDate', e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="endDate" className="form-label text-black font-12 font-medium">To</label>
                                                            <input type="date"
                                                                value={deviceFilters.toDate}
                                                                onChange={e => handleFilterChange('toDate', e.target.value)}
                                                                min={deviceFilters.fromDate} className="form-control cust-input-sty font-12 font-regular" id="endDate" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-end mt-4 mb-2" >
                                                    <button type="button" className="primary-btn font-14 font-medium me-2" onClick={() => { handleCancelFilter() }}>
                                                        Clear
                                                    </button>
                                                    <button type="button" onClick={() => { handleApplyFilter() }} disabled={applyButtonDisabled} className="dark-btn font-14 font-medium px-4">
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <button className="advanced-filter-button ms-2 medium-text customflip-btn" type="button" id="dropdownMenuButton1" aria-expanded="false" onClick={() => handleExportExcel()} style={allDevicesInfo.length > 0? {cursor: "pointer",filter:"none"}:{cursor: "not-allowed" ,filter:"grayscale(80%)"}}>
                                        <img src="/images/excel-icon.svg" alt="Filter-icon" />
                                    </button>
                                </ul>
                                <h5 className="font-bold font-20 primary-textcolor">
                                    All Devices
                                </h5>
                                <div className={allDevicesInfo.length > 0 ? 'table-responsive' : ''}>
                                    {allDevicesInfo.length > 0 ? (<table className="table text-nowrap mb-0 custom-table-grid table-borderless mt-3">
                                        <thead>
                                            <tr>
                                                <th className="font-14 px-4 position-relative">
                                                    Device Name
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("deviceName", "asc")}>
                                                            <img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" />
                                                        </a>
                                                        <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("deviceName", "desc")}>
                                                            <img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" />
                                                        </a>
                                                    </span>
                                                </th>
                                                <th className="font-14 position-relative">
                                                    Assigned To
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("assignedTo", "asc")}>
                                                            <img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" />
                                                        </a>
                                                        <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("assignedTo", "desc")}>
                                                            <img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" />
                                                        </a>
                                                    </span>
                                                </th>
                                                <th className="font-14 position-relative">
                                                    Last Device Sign-in
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("lastDeviceSignIn", "asc")}>
                                                            <img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" />
                                                        </a>
                                                        <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("lastDeviceSignIn", "desc")}>
                                                            <img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" />
                                                        </a>
                                                    </span>
                                                </th>
                                                <th className="font-14 position-relative">
                                                    Type
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("is_managed", "asc")}>
                                                            <img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" />
                                                        </a>
                                                        <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("is_managed", "desc")}>
                                                            <img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" />
                                                        </a>
                                                    </span>
                                                </th>
                                                <th className="font-14 position-relative">
                                                    Compliant
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("is_compliant", "asc")}>
                                                            <img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" />
                                                        </a>
                                                        <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("is_compliant", "desc")}>
                                                            <img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" />
                                                        </a>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        {renderAllDevicesInfo(allDevicesInfo)}
                                    </table>)
                                        : (<RiskNoDataFound data={"No Device Found"}/>)}
                                </div>
                                <div className="mt-3">
                                    <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor"><AddZero number={allDevicesInfo.length}></AddZero> </span>
                                        out of <span className="font-medium primary-textcolor"> <AddZero number={deviceTotalCount}></AddZero></span> items</p>
                                </div>
                                <div className="text-center mt-3">
                                    {/**PS_DC_37 - Marking of the loadMore condition  */}
                                    {deviceTotalCount > allDevicesInfo.length && (
                                        <button className="primary-btn font-medium cursor-pointer" onClick={() => { handleLoadMore() }}>Load More</button>
                                    )}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div >

    </>

    );
}

export default AllDevicesInfo;
