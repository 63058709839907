//PC_COM_RD_POP_4 to PC_COM_RD_POP_6 -> Import statements
import { useEffect, useState } from "react";
import { requestedDocumentPopUpApiResponse } from "../../Services/GRC/GrcService";
import NoRecordFound from "./dashboardNoRecordFound";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import CompliancePopUpLoader from "./CompliancePopUpLoader";


// PC_COM_RD_POP_10 -> Component declaration
export function RequestedDocumentPopUp({ payload, status, onClose, totalCount }) {

  // PC_COM_RD_POP_11 to PC_COM_RD_POP_12 -> state variable creation
  const [popUpData, setPopUpData] = useState([])
  const [hasMoreEvent, setHasMoreEvent] = useState(false);
  const [popUpPayload, setPopUpPayload] = useState({
    startDate: payload.startDate,
    endDate: payload.endDate,
    frameworkId: 1,
    status: status,
    loadMore: 10,
    sortColumn: "endDate",
    sortOrder: "DESC"
  })
  const [isLoading, setIsLoading] = useState(false);

  // PC_COM_RD_POP_13 -> useNavigation declaration
  const taskPageNavigation = useNavigate()
  const navigate = useNavigate();

  // PC_COM_RD_POP_14 -> useEffect declaration
  useEffect(() => {
    setPopUpData([]);
    requestedDocumentPopUp()
  }, [popUpPayload])

  // PC_COM_RD_POP_15 -> function declaration
  const requestedDocumentPopUp = async () => {
    try {

      // PC_COM_RD_POP_16 -> set state a variable isLoading as true and Api call 
      setIsLoading(true)
      const result = await requestedDocumentPopUpApiResponse(popUpPayload)

      // PC_COM_RD_POP_26  -> Update a state variable whether statusCode is 200
      if (result.statusCode === 200) {
        
        //console.log(result, "requested document pop up")
        setPopUpData(result.responseData[0]);
        setHasMoreEvent(totalCount > result.responseData[0].length ? true : false);
      }

      // PC_COM_RD_POP_27 -> navigate to error page when statusCode in not equals to 200
      else {
        //console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$")
        navigate('/error');
      }

      setIsLoading(false)
    }
    // PC_COM_RD_POP_27 -> navigate to error page
    catch (error) {
      navigate('/error');
    }
  }

  // PC_COM_RD_POP_28 to PC_COM_RD_POP_30 -> data binding in a grid
  const bindGridData = () => {
    return popUpData.map((item) =>
      <tr key={item.taskId}>
        <td className="font-12 font-medium"><a href="#" className="text-decoration-none secondary-textcolor" onClick={() => item.taskId ? taskPageNavigation('/taskmanagement', {state: {tasktab: "allTasks", taskId : item.taskId}}) : null}>{!item.taskId ? 'nil' : item.taskId}</a></td>
        <td className="font-12 font-regular primary-textcolor ">{item.docName}</td>
        <td className="font-12 font-regular primary-textcolor">{item.type}</td>
        <td className="font-12 font-regular primary-textcolor">{!item.endDate ? 'nil' : item.endDate.slice(0, 10).split('-').reverse().join('/')}</td>
      </tr>
    )
  }

  // html part
  return (
    <div className="col-md-12 my-2">
      
      <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
        <div className="modal-dialog custom-popup-width modal-dialog-centered">
          <div className="modal-content custom-popup-width popup-wrap-content">
            <div className="modal-header d-flex justify-content-between align-items-center p-3 border-0">
              <h5 className="modal-title font-14 font-medium primary-textcolor" id="staticBackdropLabel">
                Requested Documents</h5>
              <div className="d-flex flex-row align-items-center">
                <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
              </div>
            </div>
            {/* <div className="modal-body px-3 pb-4 pt-0"> */}
            {/* <div className="table-responsive table-scroll"> */}
            {isLoading ? <CompliancePopUpLoader isLoading={isLoading} /> : 
            <>
              <div id='scrollID9' className="stack-table-scroll">
                <InfiniteScroll
                  dataLength={popUpData.length}
                  scrollThreshold={0.9}
                  scrollableTarget="scrollID9"
                  hasMore={hasMoreEvent}
                  next={() => {
                    //console.log("trigeredbnm");
                    setPopUpPayload(popUpPayload => ({
                      ...popUpPayload,
                      loadMore: popUpPayload.loadMore + 10
                    }))
                  }

                  } // Increment page when scrolling down d
                >
                  <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                    <thead>
                      <tr className="sticky-top">
                        <th className="font-12 px-4 font-medium position-relative">Task ID
                          <span className="position-absolute">
                            <a href="#" className="sort-up position-absolute ms-2"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => (popUpPayload.sortColumn === 'taskId' && popUpPayload.sortOrder === 'ASC') ? null : setPopUpPayload({ ...popUpPayload, sortColumn: 'taskId', sortOrder: 'ASC' })} /></a> <a href="#" className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => (popUpPayload.sortColumn === 'taskId' && popUpPayload.sortOrder === 'DESC') ? null : setPopUpPayload({ ...popUpPayload, sortColumn: 'taskId', sortOrder: 'DESC' })} /></a>
                          </span>
                        </th>
                        <th className="font-12 font-medium position-relative">Doc Name
                          <span className="position-absolute">
                            <a href="#" className="sort-up position-absolute ms-2"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => (popUpPayload.sortColumn === 'docName' && popUpPayload.sortOrder === 'ASC') ? null : setPopUpPayload({ ...popUpPayload, sortColumn: 'docName', sortOrder: 'ASC' })} /></a> <a href="#" className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => (popUpPayload.sortColumn === 'docName' && popUpPayload.sortOrder === 'DESC') ? null : setPopUpPayload({ ...popUpPayload, sortColumn: 'docName', sortOrder: 'DESC' })} /></a>
                          </span>
                        </th>
                        <th className="font-12 font-medium position-relative">Type
                          <span className="position-absolute">
                            <a href="#" className="sort-up position-absolute ms-2"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => (popUpPayload.sortColumn === 'type' && popUpPayload.sortOrder === 'ASC') ? null : setPopUpPayload({ ...popUpPayload, sortColumn: 'type', sortOrder: 'ASC' })} /></a> <a href="#" className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => (popUpPayload.sortColumn === 'type' && popUpPayload.sortOrder === 'DESC') ? null : setPopUpPayload({ ...popUpPayload, sortColumn: 'type', sortOrder: 'DESC' })} /></a>
                          </span>
                        </th>
                        <th className="font-12 font-medium position-relative">End Date
                          <span className="position-absolute">
                            <a href="#" className="sort-up position-absolute ms-2"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => (popUpPayload.sortColumn === 'endDate' && popUpPayload.sortOrder === 'ASC') ? null : setPopUpPayload({ ...popUpPayload, sortColumn: 'endDate', sortOrder: 'ASC' })} /></a> <a href="#" className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => (popUpPayload.sortColumn === 'endDate' && popUpPayload.sortOrder === 'DESC') ? null : setPopUpPayload({ ...popUpPayload, sortColumn: 'endDate', sortOrder: 'DESC' })} /></a>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {popUpData && bindGridData()}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
              <div className="mt-3 mb-3 ms-3">
                <p className="font-12 font-regular grey-primary m-0">Showing <span className="font-medium primary-textcolor">{totalCount}</span> items</p>
              </div>
              </>}
            </div>
            </div>
          </div>
        </div>
      // </div>
    // </div>
  // </div>
    // </div>
  );
}
