/**
 * Import the Required Hooks, Loader, Packages, and Services from Risksearvices 
 */

import { useContext, useEffect, useState } from "react"
import NoDataFound from "./RiskNoDataFound"
import { useLocation, useNavigate } from 'react-router-dom';
import FileSaver from "file-saver";
import ExcelJS from 'exceljs';
import moment, { duration } from "moment";
import { getExportEventLogs } from "../../Services/Risk/RiskService";
import { getEventLogsData } from "../../Services/Risk/RiskService";
import { getSubcategoryData } from "../../Services/Risk/RiskService";
import { eventLogsConst } from "../../Configs/logValues";
import { Loader } from "../Loader/Loader";
import { agovContext } from "../../Constant";




/**
 * EventLogs Component Initiates and Declare all state variables and global variables 
 */
function EventLogs() {

    let { employeeId, employeeName, roles, userId } = useContext(agovContext);

    const navigate = useNavigate();
    let [filterObj, setFilterObj] = useState({
        category: "",
        subCategory: "",
        type: "",
        fromDate: "",
        toDate: ""
    })
    let [subCategoryValue, setSubCategoryValue] = useState({
        category: ""
    })
    let [excelData, setExcelData] = useState([])
    let [logsCount, setLogsCount] = useState(0)
    let [logData, setLogData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    let [nextPage, setNextPage] = useState(10);
    let [searchValue, setSearchValue] = useState("")
    let [showfilter, setShowFilter] = useState(true)
    // const categoryOptions = ['User', 'Application', 'Device', 'Email'];
    // const typeOption = ['Threat', 'False Positive'];
    let [category, setCategory] = useState("")
    let [type, setType] = useState(1)
    let [subCategory, setSubcategory] = useState([])

    //UseEfect Triggers
    useEffect(() => {
        loadEventLogs()
    }, [nextPage]);


    useEffect(() => {
        getFilterSubcategory()
    }, [subCategoryValue]);



    let location = useLocation()
    const { state } = location

    let likelihood = state?.likelihood ? state?.likelihood : ""
    let impact = state?.impact ? state.impact : ""
    let navigatedFilterObj = {
        fromDate: state?.fromDate ? state.fromDate.split('T')[0] : "",
        toDate: state?.toDate ? state.toDate.split('T')[0] : "",
        duration:state?.duration,
        type: "",
        category: state?.category ? state.category : "",
        subCategory: state?.subCategory ? state.subCategory : "",
        duration:state?.duration?state.duration:"",
    }

    useEffect(() => {
        setFilterObj({
            category: navigatedFilterObj.category,
            subCategory: navigatedFilterObj.subCategory,
            type: "",
            fromDate: navigatedFilterObj.fromDate,
            toDate: navigatedFilterObj.toDate,
            duration:navigatedFilterObj.duration
        });
    }, [])

    /**
     * PC_EL_27 -PC_EL_48 loadEventLogs Functions gets definied  with Respective Payload to make a api call
     */

    const loadEventLogs = async (cancelFlag) => {
        cancelFlag = cancelFlag ? cancelFlag : false
        try {
            setIsLoading(true)
            let tempFilterObj = {
                fromDate: '',
                toDate: '',
                type: '',
                category: '',
                subCategory: ''
            }
            const logParams = {
                userId: userId,
                page: nextPage,
                search: searchValue,
                filter: cancelFlag ? tempFilterObj : Object.values(filterObj).some(value => value) ? filterObj : navigatedFilterObj,
                likelihood: cancelFlag ? '' : likelihood,
                impact: cancelFlag ? '' : impact,
            }

            let eventLog = await getEventLogsData(logParams)
            setLogData(eventLog.response[0].recordsets[0]);
            setLogsCount(eventLog.response[1][0].totalCount);
            setIsLoading(false)


        }
        catch (error) {

            console.log(error.message, "catch block");
            setIsLoading(false)

        }

    }

    /**
     * PC_ELE_13-PC_ELE_43 handleExportExcel Functions gets triggered OnClick of the Export Excel Option
     */
    let handleExportExcel = async () => {
        try {
            let excelObj = {
                id: userId,
                search: searchValue,
                filter: filterObj,
                likelihood: likelihood,
                impact: impact,
            };
            setIsLoading(true)
            let exportData = await getExportEventLogs(excelObj);
            let user = exportData.response;
            let excelData = [];

            for (var i = 0; i < user.length; i++) {
                let val = [];
                val.push(
                    user[i].primary_email,
                    user[i].manager_name,
                    user[i].employee_name,
                    user[i].application_name == null ? "-" : user[i].application_name,
                    user[i].date_time == null ? "Nil" : moment(user[i].date_time).format('L') + " " + moment(user[i].date_time).format('LT'),
                    user[i].event_description,
                    user[i].is_threat === 1 ? "Threat" : "False Positive",
                    user[i].reason === "null" ? "-" : user[i].reason
                );
                excelData.push(val);
            }

            let header = [["User Email", "Access Reviewed Manager", "End User", "Application", "Date and Time", "Event Type", "Status", "Reason"]];
            header = header.concat(excelData);
            let filename = "AGov-riskManagement-eventLogs-" +
            new Date().getFullYear() +
            "/" +
            (parseInt(new Date().getMonth()) + 1 < 10
              ? "0" + (parseInt(new Date().getMonth()) + 1)
              : parseInt(new Date().getMonth() + 1)) +
            "/" +
            (new Date().getDate() < 10
              ? "0" + new Date().getDate()
              : new Date().getDate());
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('EventLogs Report');
            worksheet.addRows(header);

            const excelBuffer = await workbook.xlsx.writeBuffer();
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, filename + fileExtension);
        } catch (error) {
            // Handle the error
        }
        finally{
            setIsLoading(false)
        }
    };

    /**
     * PC_EL_54-PC_EL_57 Handle Search Function for Searching event
     *
     */
    let handleSearch = async () => { 
        logData = [];
        setLogData(logData)
        setLogsCount(0);
        nextPage = 10;
        setNextPage(nextPage);
        await loadEventLogs();
    }
    /**
     * BindSubCatgeory Function binds the respective subCatgeiry received 
     */
    let bindSubCategory = () => {
        if (subCategory?.length) {
            return subCategory?.map((obj, index) => {
                return (
                    <option key={index} value={obj.event}>{obj.event_description}</option>

                )
            })
        }
    }

    /**
     * PC_EL_64-PC_EL_67 Handle Category Change functions defined to manage change of category selection
     *
     */
    const handleCategoryChange = async (event) => {
        const selectedCategory = event.target.value;
        setCategory(selectedCategory)
        let filterValue = '';
        if(selectedCategory == ''){
            setFilterObj(prevState => ({ ...prevState, subCategory: '' }));
        }else if (selectedCategory == 'User') {
            filterValue = 'userRisk';
        } else if (selectedCategory == 'Application') {
            filterValue = 'appRisk';
        } else if (selectedCategory == 'Device') {
            filterValue = 'deviceRisk';
        } else if (selectedCategory == 'Email') {
            filterValue = 'emailRisk';
        }

        setSubCategoryValue({ ...subCategoryValue, category: filterValue });
        // setCategory({...category, category: filterValue});

        // getFilterSubcategory(subCategoryValue)

         setFilterObj(prevState => ({ ...prevState, category: filterValue, subCategory: '' }));
        
    };
    /**
     * PC_EL_69-PC_EL_73   get Filter Sub Category
     *
     */

    let getFilterSubcategory = async () => {
        try {
            let subCategories = await getSubcategoryData(subCategoryValue)
            setSubcategory(subCategories.response)
        }
        catch {
            console.error("error message")
        }
    }
    const handleSubCategoryChange = async (event) => {
        const selectedSubCategory = event.target.value;
        setFilterObj({ ...filterObj, subCategory: selectedSubCategory })
    }

    /**
     * PC_EL_74-PC_EL_76 Handle type Change function triggers
     *
     */
    let handleTypeChange = (event) => {
        const selectedType = event.target.value || '';

        // let filterValue = '';
        // if (selectedType == "Threat") {
        //     filterValue = 0;
        // }
        // if (selectedType == "False Positive") {
        //     filterValue = 1;
        // }

        setFilterObj({ ...filterObj, type: selectedType });
    }
    const handleDateChange = (event) => {
        const { id, value } = event.target;
        setFilterObj({ ...filterObj, [id]: value });
    };

    /**
   * PC_EL_78-PC_EL_80 Handle close function for closing the filter 
   *
   */

    const handleClose = () => {
        setFilterObj({
            category: "",
            subCategory: "",
            type: "",
            fromDate: "",
            toDate: ""
        });
        setCategory('');
        setSubcategory('');
        setType('');
        loadEventLogs(true)
    }
    // format Date and Time 
    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString || dateTimeString == undefined) return "-"; // Return an empty string if dateTimeString is null or undefined

        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        const hour = date.getHours().toString().padStart(2, "0");
        const minute = date.getMinutes().toString().padStart(2, "0");
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour =
            hour % 12 === 0 ? "12" : (hour % 12).toString().padStart(2, "0");

        return (
            <span>
                {day}/{month}/{year} {formattedHour}:{minute} {ampm}
            </span>
        );
    };


    /**
     * PC_EL_49-PC_EL_52 Bind Event Logs functions to bind the data
     *
     */
    const bindEventLogs = () => {


        if (logsCount == 0) {
            return (
                <NoDataFound data={"No Records Found"}/>
            )
        }
        else {
            if (logsCount != 0) {
                return logData.map((obj, index) => {

                    return (
                        <div className="row log-card risk-log">
                        <div className="col-md-12 mt-4 pt-3">
                        <p className="font-14 text-black mb-2 mb-lg-0">
                            <span className="font-bold">
                            {obj.applied_by?.toLowerCase() === "null" || 
                            obj.applied_by?.toLowerCase() === undefined || 
                            obj.category === "riskTaskManagement" || 
                            obj.applied_by === "" 
                                ? obj.applied_on 
                                : obj.applied_by}
                            </span>
    
                                {obj.sub_event && eventLogsConst[obj.sub_event] 
                                ? (
                                    <>
                                    {eventLogsConst[obj.sub_event].divStatement}
                                    <span className="font-bold">
                                        {obj[eventLogsConst[obj.sub_event].event_description]}
                                    </span>
                                    {eventLogsConst[obj.sub_event].divStatement2}
                                    <span className="font-bold">
                                        {obj[eventLogsConst[obj.sub_event].subject]}
                                    </span>
                                    </>
                                ) : (
                                    <>
                                    {eventLogsConst[obj.event].divStatement}
                                    <span className="font-bold">
                                        {obj[eventLogsConst[obj.event].event_description]}
                                    </span>
                                    {eventLogsConst[obj.event].divStatement2}
                                    <span className="font-bold">
                                        {obj[eventLogsConst[obj.event].subject]!='null'?obj[eventLogsConst[obj.event].subject]!='null':""}
                                    </span>
                                    </>
                                )
                                }
                            </p>
                            </div>
                            <div className="col-lg-2 mt-lg-3"><span className="font-12 text-black font-bold mb-1 m-lg-0">{obj.risk_type == "userRisk" ? "User" : obj.risk_type == "appRisk" ? "Application" : obj.risk_type == "deviceRisk" ? "Device" : obj.risk_type == "emailRisk" ? "Email" : "-"}</span></div>
                            <div className="col-lg-2 mt-lg-3"><span className="font-12 text-color-grey mb-1 m-lg-0">Application: <span className="font-bold text-black">{obj?.application_name?.toLowerCase() != "null" && obj?.application_name ? obj.application_name : "-"}</span></span></div>
                            <div className="col-lg-2 mt-lg-3"><span className="font-12 text-color-grey mb-1 m-lg-0">{obj.device_name == null ? "-" : obj.device_name}</span></div>
                            <div className="col-lg-2 mt-lg-3 pt-1">
                                <span className="font-12 text-color-grey my-1 m-lg-0 d-flex align-items-center">
                                    <img src={obj.is_threat === 1 ? "/images/important-icon.svg" : obj.is_threat === 0 ? "/images/false-positive-icon.svg" : "False Positive"} width={13} className="me-1" />
                                    {obj.is_threat == 1 ? "Threat" : "False Positive"}
                                </span>
                            </div>
                            <div className="col-lg-2 mt-lg-3"><span className="font-12 text-color-grey mb-1 m-lg-0">Location: <span className="font-bold text-black">{obj?.location?.toLowerCase() != "null" && obj?.location ? obj?.location : "-"}</span></span></div>
                            <div className="col-lg-2 mt-lg-3"><span className="font-12 text-color-grey font-medium mb-1 m-lg-0"> {formatDateTime(obj[eventLogsConst[obj?.event]?.dateTime])}</span></div>
                        </div >

                    )
                })
            }
        }

    }


    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="container-fluid mt-4 mt-custom">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between mt-4 px-5">
                        <h1 className="font-bold font-24 primary-textcolor">
                            Risk Management
                        </h1>
                    </div>
                    {/*tabs starts here*/}
                    <div className="d-flex justify-content-between align-items-center my-3 px-5 custom-tabs">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0  font-14 tab-style bold-text rounded-0" id="overview-tab" data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true" onClick={() => { navigate('/riskManagement') }}>
                                    <span className="tab-img"> <img src="/images/dashboard-icon-filled.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/dashboard-icon.svg" alt="overview-icon" className="me-2" /></span>Overview</button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-users-tab" data-bs-toggle="pill" data-bs-target="#all-users" onClick={() => { navigate('/riskManagement/allUsers') }} type="button" role="tab" aria-controls="all-users" aria-selected="false" tabIndex={-1}>
                                    <span className="tab-img"> <img src="/images/people-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/people-icon.svg" alt="all-users-icon" className="me-2" />
                                    </span>All Users</button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-apps-tab" data-bs-toggle="pill" data-bs-target="#all-apps" type="button" role="tab" aria-controls="all-apps" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allApplications') }}  >
                                    <span className="tab-img"> <img src="/images/app-icon-filled.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/app-icon.svg" alt="all-apps-icon" className="me-2" />All
                                        Applications</span></button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style  rounded-0" id="all-devices-tab" data-bs-toggle="pill" data-bs-target="#all-devices" type="button" role="tab" aria-controls="all-devices" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allDevices') }}>
                                    <span className="tab-img"> <img src="/images/device-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/device-icon.svg" alt="all-devices-icon" className="me-2" />All Devices</span></button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 active font-14 tab-style bold-text rounded-0" id="event-logs-tab" data-bs-toggle="pill" data-bs-target="#event-logs" type="button" role="tab" aria-controls="event-logs" aria-selected="false" tabIndex={-1}>
                                    <span className="tab-img"> <img src="/images/document-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/document-icon.svg" alt="event-logs-icon" className="me-2" />Event Logs</span></button>
                            </li>
                        </ul>
                    </div>
                    {/*tabs ends here*/}
                    <div className="row position-relative">
                        <div className="tab-content px-0" id="pills-tabContent">
                            {/*Event logs tab*/}
                            <div className="tab-pane active show px-5 ms-2 mt-3" id="event-logs" role="tabpanel" aria-labelledby="event-logs-tab">
                                <ul className="nav position-absolute end-0 custom-filter-tab me-5">
                                    <li>
                                        <div className="input-group flex-nowrap search-group">
                                            <input type="search" onKeyDownCapture={(e) => {
                                                if (e.key === "Enter") {
                                                    handleSearch(e)
                                                }
                                            }} onChange={(e) => { setSearchValue(e.target.value) }} className="form-control search-align" placeholder="Search" maxLength={250} aria-label="Username" aria-describedby="addon-wrapping" />
                                            <button className="input-group-text btn btn-search-custom" onClick={() => handleSearch()} id="addon-wrapping"><img src="/images/search-icon.svg" alt="search-icon" /></button>
                                        </div>
                                    </li>
                                    <button className={showfilter ? "cust-filter-btn ms-2 d-flex align-items-center" : "cust-filter-btn ms-2 d-flex align-items-center show"} onClick={() => { setShowFilter(false) }} type="button">
                                        <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                    </button>
                                    <div className={showfilter ? "dropdown-menu custom-filter border-0" : "dropdown-menu custom-filter border-0 show"}>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                                <button type="button" className="btn-close font-12" onClick={() => setShowFilter(true)} />
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">Category
                                                        </label>
                                                        <select className="form-select cust-input-sty font-12 font-regular"
                                                            aria-label="Default select example"
                                                            onChange={(e) => handleCategoryChange(e)}
                                                            name="category"
                                                            value={category}>
                                                            <option selected value="">Select</option>
                                                            <option value="User">User</option>
                                                            <option value="Application">Application</option>
                                                            <option value="Device">Device</option>
                                                            <option value="Email">Email</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium"> Sub Category
                                                        </label>
                                                        <select className="form-select cust-input-sty font-12 font-regular"
                                                            aria-label="Default select example" value={filterObj.subCategory}
                                                            onChange={(e) => handleSubCategoryChange(e)}>
                                                            <option selected value="">Select</option>
                                                            {bindSubCategory()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">Type
                                                        </label>
                                                        <select value={filterObj.type} className="form-select cust-input-sty font-12 font-regular"
                                                            aria-label="Default select example"
                                                            onChange={(e) => handleTypeChange(e)} >
                                                            <option value="">Select</option>
                                                            <option value="1">Threat</option>
                                                            <option value="0">False Positive</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <h5 className="font-14 font-bold text-black mt-3">Date Range</h5>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="StartDate" className="form-label text-black font-12 font-medium">From</label>
                                                        <input type="date" className="form-control cust-input-sty font-12 font-regular" value={filterObj.fromDate} id="fromDate" onChange={handleDateChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="endDate" className="form-label text-black font-12 font-medium">To</label>
                                                        <input type="date" className="form-control cust-input-sty font-12 font-regular" value={filterObj.toDate} id="toDate" onChange={handleDateChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-end mt-4 mb-2">
                                                <button type="button" className="primary-btn font-14 font-medium me-2"
                                                    onClick={() => { setShowFilter(true); handleClose() }}>
                                                    Clear
                                                </button>
                                                <button type="button" className="dark-btn font-14 font-medium px-4" disabled={!Object.values(filterObj).some(value => value)}
                                                    onClick={() => { loadEventLogs(); setShowFilter(true) }}>
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Export Excel Button */}
                                    <button className="advanced-filter-button medium-text ms-2 customflip-btn" type="button" onClick={() => { handleExportExcel() }} aria-expanded="false" style={logsCount != 0? {cursor: "pointer",filter:"none"}:{cursor: "not-allowed" ,filter:"grayscale(80%)"}}>
                                        <img src="/images/excel-icon.svg" alt="Filter-icon" />
                                    </button>
                                </ul>
                                {/*Event filter ends */}
                                {/*Event body content starts here*/}
                                <div className="container-fluid p-0">
                                    <h2 className="font-20 font-bold text-black mb-0">Event Logs</h2>
                                    <div className="px-3">
                                        {/* Main Div return part */}
                                        {bindEventLogs()}

                                    </div>
                                    <div className="mt-4">
                                        <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{logData.length}</span> out of <span className="font-medium primary-textcolor">{logsCount}</span> items</p>
                                    </div>
                                    <div className="text-center my-3">
                                        <button className="primary-btn font-medium" hidden={logsCount > logData.length ? false : true} onClick={() => setNextPage(nextPage + 10)}>Load More</button>
                                    </div>
                                </div>
                                {/* Event body content Ends here */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}
export default EventLogs