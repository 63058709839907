import { useEffect, useState } from "react";
import { getUsersNonCompliantDetails } from "../../../../Services/Agov/governanceService";
import {abbreviateNumber} from "../../../../Constant"
import NonCompliantUsersGrid from "./NonCompliantUsersGrid";
import NonCompliantUsersHeader from "./NonCompliantUsersHeader";

const NonCompliantUsersOverview = ({
  roles,
  isSuperAdminAndManager,
  managerId,
}) => {
  // NCU_FE_PC_02 - NCU_FE_PC_03 -> State Variables
  const [nonCompliantUsers, setNonCompliantUsers] = useState({});
  const [activeTab, setActiveTab] = useState("overdue");
  const [filter, setFilter] = useState("team");
  const [sort, setSort] = useState({
    column: "",
    order: "",
  });
  const [isPopupClicked, setIsPopupClicked] = useState(false);
  const [riskCount, setRiskCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  /* NCU_FE_PC_07 - NCU_FE_PC_10, NCU_FE_PC_18 - NCU_FE_PC_19, NCU_FE_PC_24 - NCU_FE_PC_25, NCU_FE_PC_28 - NCU_FE_PC_32
     NCU_FE_PC_34 - NCU_FE_PC_38, NCU_FE_PC_40 - NCU_FE_PC_44, NCU_FE_PC_65 - NCU_FE_PC_69
     -> Function to Fetch the Non-Compliant Data Based on the Functionality the Users has Interacted With.
  */
  useEffect(() => {
    fetchUsersNonCompliantDetails();
  }, [activeTab, filter, sort]);

  const fetchUsersNonCompliantDetails = async () => {
    setIsLoading(true);
    try {
      const payload = {
        roles,
        isSuperAdminAndManager,
        managerId,
        activeTab,
        filter,
        sort,
      };
      const response = await getUsersNonCompliantDetails(payload);
      if (response?.statusCode === 200) {
        setRiskCount(response.riskCount);
        setNonCompliantUsers(response.result);
        /*
          const usersWithImages = await Promise.all(
            response.result.users.map(async (user) => {
              const profileImageResponse = await fetch(user.profileImages);
              const profileImageData = await profileImageResponse.blob();
              const profileImageUrl = URL.createObjectURL(profileImageData);
              return { ...user, profileImageUrl };
            })
          );
        
          setNonCompliantUsers(usersWithImages);
        */
      }
    } catch(error) {
      //console.log('API Error!!!');
    } finally {
      setIsLoading(false);
    }
  };

  // NCU_FE_PC_26 - NCU_FE_PC_27
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSort({
      column: "",
      order: "",
    });
    setNonCompliantUsers({});
    setFilter(filter);
  };

  // NCU_FE_PC_33
  const handleSort = (column, order) => {
    if (sort.column === column && sort.order === order) {
      return;
    }
    
    setSort({ column, order });
  };

  // NCU_FE_PC_64
  const handleFilterChange = (value) => {
    setFilter(value);
    setNonCompliantUsers([]);
  };

  const handlePopupClose = () => {
    setIsPopupClicked(false);
  };

  return (
    <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3">
      <div className="bg-white py-2 px-3 border-radius-8 rm-dashboard-card">
        <div className="d-flex justify-content-between align-items-center">
          <NonCompliantUsersHeader
            nonCompliantUsers={nonCompliantUsers}
            onTabChange={handleTabChange}
            onFilterChange={handleFilterChange}
            filter={filter}
            roles={roles}
            gridActiveTab={activeTab}
            isPopupClicked={isPopupClicked}
            onIsPopupClicked={setIsPopupClicked}
            managerId={managerId}
            isSuperAdminAndManager={isSuperAdminAndManager}
            onClose={handlePopupClose}
            riskCount={riskCount}
            abbreviateNumber={abbreviateNumber}
          />
        </div>
        <NonCompliantUsersGrid
          gridActiveTab={activeTab}
          onTabChange={handleTabChange}
          nonCompliantUsers={nonCompliantUsers}
          onSort={handleSort}
          isLoading={isLoading}
          roles={roles}
          isSuperAdminAndManager={isSuperAdminAndManager}
        />
      </div>
    </div>
  );
};

export default NonCompliantUsersOverview;